import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { indigo } from '@mui/material/colors';

export default function Footer() {

  return (
      <AppBar position="static" style={{backgroundColor: indigo[500]}}>
        <Toolbar>
          <Typography variant="body1">
            Need help? support@legalreflex.com
          </Typography>
        </Toolbar>
      </AppBar>
  );
}