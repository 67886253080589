import React, { useState } from 'react';

import { Grid, CircularProgress } from '@mui/material';

import AdminSideBar from '../features/admin/AdminSideBar';
import Users from '../features/admin/Users';
import Scenarios from '../features/admin/Scenarios';

import { useSelector } from 'react-redux';

/*


*/

const Admin = () => {
  
  const scenarioList = useSelector((store) => store.appData.scenarioList);
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  const getAdminContent = () => {
    switch(selectedIndex) {
      case 0:
        return (
          <Users />
      )
      case 1:
        return (
          <Scenarios />
      )
      case 2:
        return (
          <Users />
        )
      case 3:
        return (
          <Users />
      )
      case 4:
        return (
          <Users />
      )
      case 5:
        return (
          <Users />
      )
      case 6:
        return (
          <Users />
      )
      default:
        return (
          <Users />
        )
    }
  };

  return (
    <Grid container style={{width: "100%", minHeight: "100%", flexDirection: "column"}}>
      <Grid item style={{display: "flex", flex: 1}}>
        <Grid container style={{width: "100%", minHeight: "100%", flexWrap: "nowrap"}}>
        <AdminSideBar 
          selectedIndex={selectedIndex} 
          setSelectedIndex={setSelectedIndex} 
        />
        {Object.keys(scenarioList).length === 0
        ? 
        <Grid item sx={{flexGrow: 1}}>
        <Grid container sx={{width: "100%", justifyContent: "center", paddingTop: "10px"}}>
          <CircularProgress />
        </Grid>
        </Grid>
        :
        <Grid item style={{flex: 1, minHeight: "100%"}}>
          <Grid container style={{width: "100%", minHeight: "100%", flexWrap: "nowrap"}}>
          <Grid item style={{flex: 1}}>
            <Grid item style={{width: "100%", height: "100%"}}>
              {getAdminContent()}
            </Grid>
          </Grid>
          </Grid>
        </Grid>
        }
        </Grid>
      </Grid> 
    </Grid>
  )
  };
export default Admin;