import { 
    Button, 
    Grid, 
    Typography, 
    TextField, 
    Snackbar, 
    IconButton,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,     
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
} from '@mui/material'
import { Alert as MuiAlert } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';

import { updateScenarioProfile, loadScenarioIntoInk, updateNodeData } from './editorSlice';
import { updateScenarioList } from '../appData/appDataSlice';

import {v4 as uuidv4} from 'uuid';

export default function ScenarioProfile(props) {

    const dispatch = useDispatch();    
    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
    const userAccessToken = useSelector((state) => state.appData.accessToken);
    const scenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);

    const setCoverImage = props.setCoverImage;

    const [scenarioName, setScenarioName] = useState(scenarioProfile.scenarioName);
    const [scenarioShortDescription, setShortDescription] = useState(scenarioProfile.scenarioShortDescription);
    const [scenarioLongDescription, setLongDescription] = useState(scenarioProfile.scenarioLongDescription);    

    const [chooseFileData, setChooseFileData] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteDocumentID, setDeleteScenarioID] = useState("");

    const [learningObjectives, setLearningObjectives] = useState(scenarioProfile.learningNotes.text);
    const learningObjectiveLabel = {
        0: "First learning objective",
        1: "Second learning objective",
        2: "Third learning objective"
    };
    
    const learningObjectiveValue = {
    0: "E.g. A company's shares must not be allotted at a discount. ",
    1: "E.g. A company can issue partly-paid shares, but it must be entitled to receive the full amount.",
    2: "E.g. A public limited company must have a share capital of at least £50,000."
    };

    const nodes = useSelector((state) => state.editorData.nodes);
    const nodeID = useSelector((state) => state.editorData.nodeID);
    const edges = useSelector((state) => state.editorData.edges);
    const edgeID = useSelector((state) => state.editorData.edgeID);

    const handleLearningObjectives = (event, idx) => {
    setLearningObjectives({
        ...learningObjectives,
        [idx]: event.target.value,
    })
    setChangesMade(true);
    };

    const addLearningObjective = () => {
        setLearningObjectives({
          ...learningObjectives,
          [Object.keys(learningObjectives).length]: ""
        });
        setChangesMade(true);
    }
    
    const removeLearningObjective = () => {
    setLearningObjectives(current => {
        const {[Object.keys(learningObjectives).length-1]: text, ...rest} = current
        return rest
    });
    setChangesMade(true);
    };

    const [saveSuccess, setSaveSuccess] = useState(false); 
    const [changesMade, setChangesMade] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false); 

    const handleScenarioNameChange = (event) => {
        setScenarioName(event.target.value);
        setChangesMade(true);
    };
    const handleShortDescriptionChange = (event) => {
        setShortDescription(event.target.value);
        setChangesMade(true);
    };
    const handleLongDescriptionChange = (event) => {
        setLongDescription(event.target.value);
        setChangesMade(true);
    };
    const saveChanges = () => {

        const titleUpdate = {
            nodeId: "1",
            data: {
                scenarioTitle: scenarioName,
                scenarioSubTitle: scenarioShortDescription
            }
        };
      
        dispatch(updateNodeData(titleUpdate));
        dispatch(loadScenarioIntoInk());

        var profileUpdate = {
            scenarioName: scenarioName,
            scenarioShortDescription: scenarioShortDescription,
            scenarioLongDescription: scenarioLongDescription,
            learningNotes: {
                text: learningObjectives
            },
        };

        const editorData = {
            nodes: nodes,
            edges: edges,
            nodeID: nodeID,
            edgeID: edgeID
        };
        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            editorData: editorData,
            lastModified: Date.now(),
        };
        
        if (scenarioData.scenarioID === "") {
            const newUUID = uuidv4();
            scenarioData.scenarioID = newUUID;
            profileUpdate["scenarioID"] = newUUID;
        };

        const writeScenario = async () => {
        try {
            const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
            });
            const responseData = await scenarioResponse.json()
            if (responseData.modified > 0 || responseData.upsertID !== "") {
                setSaveSuccess(true);
                dispatch(updateScenarioProfile(profileUpdate));
                dispatch(updateScenarioList(scenarioData));
                setChangesMade(false);
            }
            } 
        catch(e) {console.log(e)}
        }
        writeScenario();
    };

    const uploadDocument = () => {

        var scenarioAssetsUpdate = {...scenarioAssets}
        const newUUID = uuidv4();
        scenarioAssetsUpdate["coverImage"] = [{type: chooseFileData["type"], documentID: newUUID, documentName: chooseFileData.name}]
        var profileUpdate = {
            scenarioAssets: scenarioAssetsUpdate
        };
        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            lastModified: Date.now(),
        };
        if (scenarioData.scenarioID === "") {
            const newScenarioUUID = uuidv4();
            scenarioData.scenarioID = newScenarioUUID;
            profileUpdate["scenarioID"] = newScenarioUUID;
        }

        let formData = new FormData();
        formData.append("file", chooseFileData);
        formData.append("documentID", newUUID);
        
        const writeScenarioMetaData = async () => {
        try {
            const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
            });

            const responseData = await scenarioResponse.json()
            if (responseData.modified > 0 || responseData.upsertID !== "") {
                dispatch(updateScenarioProfile(profileUpdate));
                dispatch(updateScenarioList(scenarioData));
            }
        } 
        catch(e) {console.log(e)}
        };

        const writeDocumentData = async () => {
            try {
                const scenarioResponse = await fetch('/api/editor/savedocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                },
                body: formData,
                });
    
                const responseData = await scenarioResponse.json()
                
                if (responseData.insertedCount === 1) {
                    setSaveSuccess(true);
                    setChooseFileData(null)
                }
            }
            catch(e) {console.log(e)}
        }

        writeScenarioMetaData();
        writeDocumentData();
    };

    const deleteDocument = () => {

        const {coverImage: currentDocuments, ...scenarioAssetsUpdate} = scenarioAssets
        const deleteDocumentFromDB = async () => {
            try {
                const scenarioRequest = {
                    documentID: deleteDocumentID, 
                    scenarioID: scenarioProfile.scenarioID,
                    scenarioAssetsUpdate: {"scenarioAssets": scenarioAssetsUpdate}
                }
                const scenarioResponse = await fetch('/api/editor/deletedocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioRequest),
                });
                const deleteData = await scenarioResponse.json()
                setDeleteModal(false);
            }
            catch(e) {console.log(e)}
        };
        deleteDocumentFromDB();
        setDeleteSuccess(true)
        setCoverImage(null);
        dispatch(updateScenarioProfile({"scenarioAssets": scenarioAssetsUpdate}));

    };

    const handleFileSelect = (event) => {
        const fileList = event.target.files
        setChooseFileData(fileList[0])
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const handleSaveClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSaveSuccess(false);
    };

    const handleDeleteClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setDeleteSuccess(false);
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const fileTypeMap = (fileType) => {
        const mime = fileType.split("/")[1]
        return mime
    }

    return (
        <Grid container sx={{padding: "10px", maxHeight: "100%"}}>
            <Grid item sx={{
                maxWidth: "600px", 
                maxHeight: `calc(100vh - 90px)`,
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "15px", 
                borderLeft: "1px solid #cccccc66", 
                borderTop: "1px solid #cccccc66", 
                boxShadow: "3px 3px 3px #cccccc",
                overflow: "auto",
                '&::-webkit-scrollbar': {
                width: "20px",
                },
                '&::-webkit-scrollbar-track': {
                backgroundColor: "#ffffff",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                },
                '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
                },
                '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
                },
                }}>
            <Typography variant="h5" paragraph>Scenario profile</Typography>
            <Typography variant="h6" paragraph>General</Typography>
            <form autoComplete='off' onSubmit={e => { e.preventDefault()}}>
            <TextField
                id="outlined-multiline-static"
                label="Scenario name"
                value={scenarioName}
                onChange={handleScenarioNameChange}
                variant="outlined"
                fullWidth
                sx={{marginBottom: "15px"}}
            />
            <TextField
                id="outlined-multiline-static"
                label="Short description"
                value={scenarioShortDescription}
                onChange={handleShortDescriptionChange}
                variant="outlined"
                fullWidth
                sx={{marginBottom: "15px"}}
            />
            <TextField
                id="outlined-multiline-static"
                label="Long description"
                value={scenarioLongDescription}
                onChange={handleLongDescriptionChange}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                sx={{marginBottom: "15px"}}
            />
            </form>
            <Grid container style={{width: "100%", marginTop: "20px", marginBottom: "20px"}}>
              <Grid item style={{width: "100%"}}>
              <Typography variant="h6" paragraph>Learning objectives</Typography>
              </Grid>
              <Grid item style={{width: "100%"}}>
              {Object.keys(learningObjectives).map((key, idx) => (
                  <TextField
                    id="outlined-multiline-static"
                    label={learningObjectiveLabel[idx]}
                    placeholder={learningObjectiveValue[idx]}
                    value={learningObjectives[idx]}
                    onChange={(event) => handleLearningObjectives(event, idx)}
                    fullWidth
                    multiline
                    key={"LO"+idx}
                    rows={3}
                    variant="outlined"
                    style={{marginBottom: 15}}
                  />
                )
              )}
              </Grid>
                <Grid container style={{width: "100%", justifyContent: "center"}}>
                    <Grid item>
                    <IconButton disabled={Object.keys(learningObjectives).length >= 3} onClick={() => addLearningObjective()}><AddCircleOutlineIcon /></IconButton>
                    </Grid>
                    <Grid item>
                    <IconButton disabled={Object.keys(learningObjectives).length < 2 || Object.keys(learningObjectives).length > 3} onClick={() => removeLearningObjective()}><RemoveCircleOutlineIcon /></IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sx={{width: "100%", marginTop: "20px", marginBottom: "20px"}}>
              <Grid item style={{width: "100%"}}>
              <Typography variant="h6" paragraph>Cover image</Typography>
              </Grid>
              <Typography paragraph color="textSecondary">Select a cover image for your scenario.</Typography>
                {scenarioAssets.hasOwnProperty("coverImage") ? 
                <Grid container >
                <TableContainer >
                    <Table aria-label="scenario table" size="small">
                        <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {scenarioAssets["coverImage"].map((document) => (
                            <TableRow
                            key={document.documentID}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell scope="row">
                                {document["documentName"]}
                            </TableCell>
                            <TableCell align="right">
                                <Button size="small" color="secondary" variant="outlined" onClick={() => {setDeleteModal(true); setDeleteScenarioID(document.documentID)}}>Delete</Button>
                            </TableCell>                        
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
                :
                null
                }
                {chooseFileData ? 
                    <Grid container sx={{width: "100%", alignItems: "center", margin: "20px 0px 20px 0px", backgroundColor: "#fafafa", padding: "10px", borderRadius: "15px"}}>
                        <Grid item sx={{width: "75%", paddingRight: "20px"}}>
                        <Typography>{chooseFileData["name"]}</Typography>
                        </Grid>
                        <Grid item sx={{flex: 1}}>
                        <Typography>{fileTypeMap(chooseFileData["type"])}</Typography>
                        </Grid>
                        <Grid item>
                        <Button size="small" variant='outlined' onClick={uploadDocument}>Upload</Button>
                        </Grid>
                    </Grid>
                :
                null
                }
                <Grid item sx={{paddingBottom: "20px"}}>
                <Button variant="contained" disabled={scenarioAssets.hasOwnProperty("coverImage")} component="label" onChange={handleFileSelect}>
                Choose image
                <input hidden type="file" />
                </Button>
                </Grid>
            </Grid>
            <Grid container>
            <Button variant="contained" disabled={!changesMade} onClick={() => saveChanges({type: "none"})}>Save changes</Button>
            <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{width: "250px"}}>
            <Alert onClose={handleSaveClose} severity="success" sx={{width: "100%"}}>
                Settings saved.
            </Alert>
            </Snackbar>
            <Snackbar open={deleteSuccess} autoHideDuration={6000} onClose={handleDeleteClose} sx={{width: "250px"}}>
            <Alert onClose={handleDeleteClose} severity="success" sx={{width: "100%"}}>
                Document deleted.
            </Alert>
            </Snackbar>
            </Grid>
            <Dialog
                open={deleteModal}
                onClose={handleDeleteModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Confirm document deletion:
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" size="small" onClick={handleDeleteModalClose}>Cancel</Button>
            <Button variant="outlined" size="small" color="secondary" onClick={deleteDocument} >
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        </Grid>
        </Grid>
    );
}