import { Handle, Position, useNodeId } from 'reactflow';
import ScenarioKnot from './ScenarioKnot';
import React from 'react';

import { useSelector } from 'react-redux';

const ScenarioNode = ({ data }) => {


  // need to tie and set type in node data
  //const [knotOrAction, setKnotorAction] = useState()

  const nodeId = useNodeId();
  const edges = useSelector((state) => state.editorData.edges);
  const nodeEdges = edges.filter((edge) => edge.source === nodeId);
  const nodeMode = data.hasOwnProperty("mode") ? data.mode : "unset"

  /*

  Connection validation:
  - no more than three options
  - if connects to end, that must be the only connection
  -- if have an existing option, cannot connect to end
  -- if connects to end, cannot create a new option

  */


  const connectsToEnd = nodeEdges.filter((edge) => edge.target === "3").length > 0;
  const isValidConnection = (connection) => {
    if (nodeEdges.length > 0 && connection.target === "3") {
      return false
    } else {
      return true
    }
  }
  
  return (
    <>
      <Handle 
        type="target" 
        position={Position.Top} 
        id="b" 
        style={{
          width: 30,
          height: 14,
          borderRadius: 3,
          backgroundColor: "#424242",
        }}
      />
      <ScenarioKnot data={data} nodeId={nodeId} key={nodeId} />
      <Handle 
        type="source" 
        position={Position.Bottom} 
        isConnectableStart={nodeMode === "action" ? !connectsToEnd : nodeMode === "AIblock" ? (nodeEdges.length === 0) : (nodeEdges.length < 5) && !connectsToEnd}
        isValidConnection={isValidConnection}
        id="a" 
        style={{
          width: 30,
          height: 14,
          borderRadius: 3,
          border: "2px dotted #424242",
          backgroundColor: "#ffffff",
        }}
      />
    </>
  );
};
export default ScenarioNode;