import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const BuildLoading = (props) => {
  
  const buildLoading = props.buildLoading

  return (
  <Grid container 
    direction="column"
    justifyContent="center"
    alignItems="center"
    sx={{paddingTop: "46px"}}
  >
      <Grid item>
      <Typography paragraph>{buildLoading["message"]}</Typography>
      </Grid>
      <Grid item sx={{width: "400px"}}>
      <LinearProgress variant="determinate" value={buildLoading["progress"]} />
      </Grid>
  </Grid>
  )
}

export default BuildLoading;