import React from 'react';

import { useNodeId } from 'reactflow';

import { Grid, FormControl, Select, MenuItem, Typography, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setNodeAction } from '../editorSlice';

const AIBlockCondition = (props) => {

    const dispatch = useDispatch();

    const actionUUID = props.actionUUID;
    const setChangesMade = props.setChangesMade;

    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const condition = nodeData.nodeActions[actionUUID]["condition"]

    const availableNodes = nodes.filter((node) => ((node.id !== nodeID) && (node.id !== "3") && (node.id !== "1") && (node.data?.mode === "AIblock")));
    // filter to only AI nodes

    const handleBlockSelect = (event) => {
        const blockID = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        updatedAction["condition"]["data"] = { ...updatedAction["condition"]["data"] };
    
        if (updatedAction["condition"]["data"].hasOwnProperty("AIBlockEvaluation")) {
            // If "AIBlockEvaluation" exists, update only the "blockID"
            updatedAction["condition"]["data"]["AIBlockEvaluation"] = {
                ...updatedAction["condition"]["data"]["AIBlockEvaluation"],
                "blockID": blockID
            };
        } else {
            // If "AIBlockEvaluation" doesn't exist, replace "data" with a new object
            updatedAction["condition"]["data"] = {
                "AIBlockEvaluation": {
                    "value": "",
                    "blockID": blockID
                }
            };
        }

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    const handleEvaluationSelect = (event) => {
        const evaluation = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        updatedAction["condition"]["data"] = { ...updatedAction["condition"]["data"] };
    
        if (updatedAction["condition"]["data"].hasOwnProperty("AIBlockEvaluation")) {
            // If "AIBlockEvaluation" exists, update only the "evaluation"
            updatedAction["condition"]["data"]["AIBlockEvaluation"] = {
                ...updatedAction["condition"]["data"]["AIBlockEvaluation"],
                "value": evaluation
            };
        } else {
            // If "AIBlockEvaluation" doesn't exist, replace "data" with a new object
            updatedAction["condition"]["data"] = {
                "AIBlockEvaluation": {
                    "value": evaluation,
                    "blockID": ""
                }
            };
        }
        
        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    const getMenuItems = (node, index) => {
        switch (node.data.nodeAI.type) {
            case "explanation":
                return (<MenuItem value={node.id} key={index}>Explanation {node.id}: {node.data.nodeAI.promptInputs.topic.slice(0,30)+"..."}</MenuItem>)
            case "questions":
                return (<MenuItem value={node.id} key={index}>Questions {node.id}: {node.data.nodeAI.promptInputs.topic.slice(0,30)+"..."}</MenuItem>)
            case "issuespot":
                return (Object.keys(node.data.nodeAI.issues).map((key, idx) => (
                    <MenuItem value={node.id+"issue"+idx} key={key}>Issue {node.id}: {node.data.nodeAI.issues[key]["issueName"].slice(0,30)+"..."}</MenuItem>
            )))
            default:
                return (<MenuItem value={node.id} key={index}>Dynamic Block {node.id}</MenuItem>)
        }
    }

    return (
        <Grid item sx={{paddingBottom: "15px"}}>
        <Typography>If:</Typography>
        <Grid container>
        <Grid item sx={{paddingTop: "3px"}}>
            <FormControl variant="standard" sx={{minWidth: "150px"}}>
            <InputLabel id="select-block">Select dynamic input</InputLabel>
            <Select
            labelId="select-block"
            id="block-select"
            size='small'
            value={condition.data?.AIBlockEvaluation ? condition.data.AIBlockEvaluation?.blockID ? condition.data.AIBlockEvaluation.blockID : "" : ""}
            label="select-block"
            onChange={handleBlockSelect}
            >
            {availableNodes.map((node, index) => getMenuItems(node, index))}
            </Select>
            </FormControl>
        </Grid>
        <Grid item sx={{paddingLeft: "8px", paddingRight: "8px", paddingTop: "18px"}}>
            <Typography>is</Typography>
        </Grid>
        <Grid item sx={{flexGrow: 1, paddingTop: "3px"}}>
            <FormControl variant="standard" sx={{minWidth: "170px"}}>
            <InputLabel id="select-evaluation-value">Select evaluation</InputLabel>
            <Select
            labelId="select-evaluation-value"
            id="evaluation-value-select"
            size='small'
            value={condition.data?.AIBlockEvaluation ? condition.data.AIBlockEvaluation?.value ? condition.data.AIBlockEvaluation.value : "" : ""}
            label="select-evaluation-value"
            onChange={handleEvaluationSelect}
            >
                <MenuItem value="correct">Correct</MenuItem>
                <MenuItem value="incorrect">Incorrect</MenuItem>
            </Select>
            </FormControl>
        </Grid>
        </Grid>
        </Grid>
    )
};
export default AIBlockCondition;