import { Grid, Button, Typography, Stack, Tooltip, Paper, Fab, Portal} from '@mui/material';
import { motion } from 'framer-motion';

import FormatPaintIcon from '@mui/icons-material/FormatPaint';

import { Editable, withReact, Slate, useSlate } from 'slate-react'
import {
  Editor,
  Transforms,
  Node,
  Text,
  Path,
  Range,
  createEditor,
} from 'slate'

import React, { useMemo, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { setEditorPlayerDocumentData, setEditorDocumentEvaluation } from '../editor/editorSlice';
import { setPlayerDocumentData, setDocumentEvaluation } from '../choice/choiceSlice';

// Debounce function
const debounce = (func, wait) => {
  let timeout;
  return function(...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

const RedactionPlayer = (props) => {

  /*

  Could set up the redact button as a hovering button that appear on selection. Small bit of faff though.

  */

  const initialDocData = Array.isArray(props.initialDocData) ? props.initialDocData : [{type: 'paragraph',children: [{ text: 'Edit document contents.' },],},]
  const loadSource = props.loadSource;
  const dispatch = useDispatch();

  /*********************************************

  Slate editor

  *********************************************/

  const goldEvaluation = (editor) => {
  
    const goldNodesEvaluation = [];
    
    let index = 0;
    let currentGroup = [];
    let currentRedactGold = null; // Track the current redaction status to group nodes correctly

    // Function to finalize and reset the current group
    // Evaluation -> if some text should be redacted, need to select all of that text to trigger variable in ink (can still show colour coding in doc review)
    // -> if some text should not be redacted, need to all be not redacted to trigger variable in ink
    const finalizeGroup = () => {
      if (currentGroup.length > 0) {
        let evaluation = false
        if (currentRedactGold === "shouldRedact") {
          // Is the whole thing redacted?
          evaluation = currentGroup.every(node => node.redactEval === "correctRedact")
        }
        if (currentRedactGold === "shouldNotRedact") {
          // Is there any over-redaction?
          evaluation = currentGroup.some(node => node.redactEval === "overRedact")
        }
        goldNodesEvaluation.push({
          inkLabel: "redaction" + index.toString(),
          nodes: currentGroup,
          evaluation: evaluation // Include redactGold status in the result
        });
        currentGroup = [];
        currentRedactGold = null;
        index++;
      }
    }
  
    for (const [node, path] of Node.descendants(editor)) {
      if (Text.isText(node) && (node.redactGold === "shouldRedact" || node.redactGold === "shouldNotRedact")) {
        const nextPath = Path.next(path);
        try {
          const nextNode = Node.get(editor, nextPath);
          if (Text.isText(nextNode) && nextNode.redactGold === node.redactGold) {
            // If next node has the same redactGold value, add to current group
            if (currentRedactGold === null || currentRedactGold === node.redactGold) {
              currentGroup.push(node);
              currentRedactGold = node.redactGold;
            }
          } else {
            // If next node has a different redactGold value or is not a text node, finalize current group
            currentGroup.push(node);
            currentRedactGold = node.redactGold;
            finalizeGroup();
          }
        } catch (e) {
          // Next path not in document, finalize current group
          currentGroup.push(node);
          currentRedactGold = node.redactGold;
          finalizeGroup();
        }
      }
    }
  
    // Ensure any remaining group is finalized
    finalizeGroup();
  
    return goldNodesEvaluation;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
    if (editor.selection === null) {
      return
    }
    if (format === "redactAnalysis") {

      if (isActive) {
        Editor.removeMark(editor, "redactAnalysis")
      } else {
        Editor.addMark(editor, "redactAnalysis", true)
      }
    } else {
      if (isActive) {
        Editor.removeMark(editor, format)
      } else {
        Editor.addMark(editor, format, true)
      }
    };

    // remove eval property from any node that does not have an analysis or gold property (to handle de-selection)

    Transforms.unsetNodes(
      editor, "redactEval",
      {
        at: [], 
        match: (node, path) => Text.isText(node) && (node.redactAnalysis !== true || !node.hasOwnProperty("redactGold")),
      }
    );
    
    // set correct redact at all nodes where redactAnalysis and redactGold === shouldRedact

    Transforms.setNodes(
      editor,
      { redactEval: "correctRedact" },
      {
        at: [], 
        match: (node, path) => Text.isText(node) && (node.redactAnalysis === true && node.redactGold === "shouldRedact"),
      }
    );

    // set incorrect redact at all nodes where no redactAnalysis and redactGold === shouldRedact (missed redactions)

    Transforms.setNodes(
      editor,
      { redactEval: "incorrectRedact" },
      {
        at: [], 
        match: (node, path) => Text.isText(node) && (node.redactAnalysis !== true && node.redactGold === "shouldRedact"),
      }
    );

    // set correct not redact at all nodes where no redactAnalysis and redactGold === shouldNotRedact

    Transforms.setNodes(
      editor,
      { redactEval: "correctNotRedact" },
      {
        at: [], 
        match: (node, path) => Text.isText(node) && (node.redactAnalysis !== true && node.redactGold === "shouldNotRedact"),
      }
    );
    
    
    // set overRedact redact at all nodes where redactAnalysis and redactGold === shouldNotRedact (over redactions)

    Transforms.setNodes(
      editor,
      { redactEval: "overRedact" },
      {
        at: [], 
        match: (node, path) => Text.isText(node) && (node.redactAnalysis === true && node.redactGold === "shouldNotRedact"),
      }
    );

    const evalNodes = goldEvaluation(editor);
    
    // save changes to player copy. so currently this re-renders on every save... feel like there is a better way to do this?
    const updateData = {
      documentJSONData: editor.children
    }
    if (loadSource === "editor") {
      dispatch(setEditorDocumentEvaluation(evalNodes));
      dispatch(setEditorPlayerDocumentData(updateData));
    } else {
      dispatch(setDocumentEvaluation(evalNodes));
      dispatch(setPlayerDocumentData(updateData));
    }
  };
  
  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  };

  const Element = ({ attributes, children, element }) => {
    const alignVariant = element.align

    let numberedListStyle = "decimal"
    if (element.listLevel === 0) {
      numberedListStyle = "decimal"
    };
    if (element.listLevel === 1) {
      numberedListStyle = "upper-alpha"
    };
    if (element.listLevel === 2) {
      numberedListStyle = "lower-roman"
    };
    if (element.listLevel > 2) {
      numberedListStyle = "lower-alpha"
    };

    switch (element.type) {
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <Typography align={alignVariant} variant='h6' {...attributes}>
            {children}
          </Typography>
        )
      case 'list-item':
        return (
            <Typography paragraph variant='body2' {...attributes} component="li" sx={{paddingLeft: "10px"}}>
            {children}
            </Typography>
        )
      case 'numbered-list':
        return (
          <ol {...attributes} style={{listStyleType: numberedListStyle, marginTop: "3px", marginBottom: "3px"}}>
            {children}            
          </ol>
        )
      default:
        return (
          <Typography paragraph align={alignVariant} variant='body2' {...attributes}>
            {children}
          </Typography>
        )
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    };
    if (leaf.italic) {
      children = <em>{children}</em>
    };
    if (leaf.underline) {
      children = <u>{children}</u>
    };
    if (leaf.redactAnalysis) {
      children = <motion.span initial={{
        backgroundColor: "#fcfdff",
        transition: {
            duration: 0.5,
            ease: "easeInOut",
        }
      }}
      animate={{
          backgroundColor: "#d5cad0",
          transition: {
              duration: 0.5,
              ease: "easeInOut",
          }
      }} >{children}</motion.span>
    };
    
    // Adding the bug fix text in leaf direct.

    return <span style={{
        paddingLeft: leaf.text === '' ? "0.1px" : null
      }} {...attributes}>{children}</span>
  }

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withReact(createEditor()), [])

  const ToolbarRedactButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title="Toggle redaction" placement='top'>
        <Button size="small" variant='outlined' color="primary" onClick={(event) => {
            event.preventDefault()
            toggleMark(editor, props.format)
        }} startIcon={<FormatPaintIcon fontSize='small' />}>
            Redact
        </Button>
        </Tooltip>
    )
  });

  const HoveringRedactButton = () => {
    const ref = useRef(null); // Reference to the floating button
    const editor = useSlate();
    
    useEffect(() => {

      const positionToolbar = () => {
        const el = ref.current;
        const { selection } = editor;
    
        if (!el) {
          return;
        }
    
        if (
          !selection ||
          Range.isCollapsed(selection) ||
          Editor.string(editor, selection) === ''
        ) {
          el.style.opacity = '0';
          return;
        }
    
        const domSelection = window.getSelection();      
        if (domSelection.rangeCount > 0) {
          const domRange = domSelection.getRangeAt(0);
          const rect = domRange.getBoundingClientRect();
          el.style.opacity = '1';
          el.style.position = 'absolute';
          el.style.zIndex = '1';
          el.style.top = `${rect.top + window.scrollY - 50}px`; // Adjust to position above the selection
          el.style.left = `${rect.left + window.scrollX + (rect.width)}px`;
          el.style.transition = 'opacity 0.75s';
          
        }
      };

      // Create a debounced version of positionToolbar
      const debouncedPositionToolbar = debounce(positionToolbar, 500);

      // Call the debounced function
      debouncedPositionToolbar();
      
    }, [editor.selection, editor]);
   
    return (
      <Portal>
        <div ref={ref} style={{ opacity: 0, position: 'absolute', pointerEvents: 'none' }}>
          <Tooltip title="Toggle redaction" placement="top">
            <Fab
              size="small"
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                toggleMark(editor, 'redactAnalysis');
                Transforms.deselect(editor);
              }}
              style={{ pointerEvents: 'auto' }} // Prevent the button from taking focus away from the editor
            >
              <FormatPaintIcon />
            </Fab>
          </Tooltip>
        </div>
      </Portal>
    );
  };

  return (
  <Grid item sx={{width: "100%"}}>
      <Slate editor={editor} initialValue={initialDocData}>
      <Typography align="center" variant='body2' color="textSecondary" paragraph>Highlight text and use the redact button to set or unset a redaction.</Typography>
      <Stack direction="row" sx={{marginBottom: "8px"}}>
        <ToolbarRedactButton format="redactAnalysis" />
      </Stack>
      <HoveringRedactButton />
      <Paper elevation={3}
        sx={{
          paddingLeft: "22px",
          paddingRight: "22px",
          paddingTop: "32px",
          paddingBottom: "32px",
          backgroundColor: "#fcfdff",
      }}
      >
      <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
      />
      </Paper>
      </Slate>
  </Grid>
  )
  };
export default RedactionPlayer;