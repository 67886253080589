import React from 'react';
import { motion } from "framer-motion";

import { Grid, Typography } from '@mui/material';

const ScenarioCompleteRoundel = (props) => {

    const scenarioScore = props.scenarioScore

    const getColor = (complete) => {
        if (complete >= 75) {
            return "#4caf50"
        };
        if (complete < 75 && complete >= 25) {
            return "#ffc107"
        }
        if (complete < 25) {
            return "#e91e63"
        }
    };

    const getPraise = (scenarioScore) => {
        if (scenarioScore === 100) {
          return "Excellent."
        };
        if (scenarioScore >= 75) {
          return "Great job."
        };
        if (scenarioScore < 75 && scenarioScore >= 25) {
            return "Nice try."
        }
        if (scenarioScore < 25) {
            return "Oh dear."
        }
    };

    const circleLineDraw = {
        initial: { 
            pathLength: 0,
            rotate: 270,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            stroke: "#e91e63",
        },
        final: { 
            pathLength: scenarioScore / 100,
            rotate: 270,
            stroke: getColor(scenarioScore),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: {
                ease: "easeInOut",
                duration: 1.2,
            }
        },
        initialTrack: { 
            pathLength: 0,
            rotate: 270,
            stroke: "#ccc",
            strokeLinecap: "round",
            strokeLinejoin: "round",
        },
        finalTrack: { 
            pathLength: 1,
            rotate: 270,
            stroke: "#ccc",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: {
                ease: "easeInOut",
                duration: 0.8,
            }
        }
    };
  

    return (
        <Grid item sx={{width: "100%", paddingBottom: "12px"}}>
        <Grid item sx={{
            height: "150px",
            width: "150px",
            margin: "auto",
        }}>
        <motion.svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                version="1.1"
                viewBox="0 0 26.458 26.458"
            >
                <motion.circle
                    initial="initialTrack"
                    animate="finalTrack"
                    variants={circleLineDraw}
                    cx="50%"
                    cy="50%"
                    fill= "#ffffff"
                    fillOpacity="0"
                    strokeWidth= "6"
                    strokeMiterlimit="3.9"
                    strokeOpacity="1"
                    r="8"
                />
                <motion.circle
                    initial="initial"
                    animate="final"
                    variants={circleLineDraw}
                    cx="50%"
                    cy="50%"
                    fill= "#ffffff"
                    fillOpacity="0"
                    strokeWidth= "6"
                    strokeMiterlimit="3.9"
                    strokeOpacity="1"
                    r="8"
                />
            </motion.svg>
        </Grid>
        <Grid item style={{width: "100%"}}>
            <Typography align="center" variant='h5' style={{color: getColor(scenarioScore)}}>{scenarioScore}%</Typography>
            <Typography align="center" variant='h5' style={{color: getColor(scenarioScore)}}>{getPraise(scenarioScore)}</Typography>
        </Grid>
        </Grid>
    )
};
export default ScenarioCompleteRoundel;