import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Box, Stack, Grid, FormControl, Select, MenuItem, Typography, Button } from '@mui/material';

import { useSelector } from 'react-redux';

import { useNavigate } from "react-router-dom";

/*


*/

const Scenarios = () => {

    const userAccessToken = useSelector((state) => state.appData.accessToken);
    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const [selectedScenario, setSelectedScenario] = useState("")
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);

    var navigate = useNavigate();

    const goEditor = () => {
        navigate(`/editor/${selectedScenario}`)
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 50 },
        {
            field: 'version',
            type: 'number',
            headerName: 'Version',
            width: 70,
            editable: false,
        },
        {
            field: 'timestamp',
            type: 'date',
            headerName: 'Date',
            width: 150,
            valueGetter: (value) => {
                const date = new Date(value)
                return (date)
            },
            valueFormatter: (value) => {
                return `${value.toDateString()}`;
            },
            editable: false
        },
        {
            field: 'topic',
            type: 'string',
            headerName: 'Topic',
            width: 200,
            editable: false
        },
        {
            field: 'nodeID',
            type: 'number',
            headerName: 'Question ID',
            width: 120,
            editable: false
        },
        {
            field: 'userInput',
            type: 'string',
            headerName: 'User input',
            width: 200,
            editable: false
        },
        {
            field: 'evaluation',
            type: 'string',
            headerName: 'Evaluation',
            width: 150,
            editable: false,
            valueFormatter: (value) => {
                switch (value) {
                    case "tbc":
                        return "TBC"
                    case "correct":
                        return "Correct"
                    case "incorrect":
                        return "Incorrect"
                    default:
                        return (value)
                }
            },
            cellClassName: (params) => {
                switch (params.value) {
                    case "tbc":
                        return "evaluation-cell-tbc"
                    case "correct":
                        return "evaluation-cell-correct"
                    case "incorrect":
                        return "evaluation-cell-incorrect"
                    default:
                        return ("")
                }
            }
        },
        {
            field: 'dynamicResponse',
            type: 'string',
            headerName: 'System response',
            width: 150,
            editable: false
        },
        {
            field: 'userComment',
            type: 'string',
            headerName: 'User comment',
            width: 150,
            editable: false
        },
        {
            field: 'userFeedback',
            type: 'string',
            headerName: 'User feedback',
            width: 150,
            editable: false
        },
    ];

    const handleScenarioSelect = (event) => {
        setLoading(true)
        const scenarioID = event.target.value
        setSelectedScenario(scenarioID)

        // Get scenario data

        const loadScenarioData = async (scenarioID) => {
            try {
                const scenarioResponse = await fetch('/api/analytics/scenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({scenarioID:scenarioID})
                });
                const scenarioData = await scenarioResponse.json()
                if (scenarioData?.scenarioData.length > 0) {
                    setRows(scenarioData.scenarioData)
                    setLoading(false)
                } else {
                    setRows([])
                    setLoading(false)
                    console.log(scenarioData)
                }
            }
            catch(e) {console.log(e)}
        }
        loadScenarioData(scenarioID);
        
    }

    return (
        <Grid item sx={{
            maxWidth: `calc(100vw - 250px)`,
            backgroundColor: "#ffffff",
            padding: "20px",
            marginLeft: "10px"
        }}>
            <Typography variant='h4' sx={{color: "#616161"}} paragraph>Scenarios</Typography>

            <Grid sx={{padding: "16px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px", marginBottom: "24px"}}>

            <Stack direction="row" spacing={1} sx={{marginBottom: "16px"}}>
            <Typography>Select scenario:</Typography>
            <FormControl variant="standard">
            <Select
            id="scenario-select"
            size='small'
            value={selectedScenario}
            label="Scenario"
            onChange={handleScenarioSelect}
            sx={{minWidth: "220px"}}
            >
                {Object.keys(scenarioList).map((scenarioID,idx) => {
                    if (scenarioList[scenarioID]?.status === "published") {
                        return (
                            <MenuItem key={idx} value={scenarioID}>{scenarioList[scenarioID].scenarioName}: {scenarioList[scenarioID].scenarioShortDescription}</MenuItem>
                        )
                    }
                })}
            </Select>
            </FormControl>
            </Stack>

            <Stack direction="column" spacing={1}>
                <Grid container>
                    <Grid item sx={{minWidth: "175px"}}>
                    <Typography paragraph>Live version:</Typography>
                    </Grid>
                    <Grid item>
                    <Typography>{selectedScenario === "" ? "" : scenarioList[selectedScenario]["published"]["version"]}</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{flexWrap: "nowrap"}}>
                    <Grid item sx={{minWidth: "175px"}}>
                    <Typography paragraph>Description:</Typography>
                    </Grid>
                    <Grid item>
                    <Typography paragraph>{selectedScenario === "" ? "" : scenarioList[selectedScenario]["scenarioLongDescription"]}</Typography>
                    </Grid>
                </Grid>
                <Grid container sx={{flexWrap: "nowrap"}}>
                    <Grid item sx={{minWidth: "175px"}}>
                    <Typography paragraph>Learning objectives:</Typography>
                    </Grid>
                    <Grid item>
                    {selectedScenario === "" ? "" : 
                        Object.values(scenarioList[selectedScenario]["learningNotes"]["text"]).map((text, idx) => (
                            <Typography paragraph key={idx}>{text}</Typography>
                        ))
                    }
                    </Grid>
                </Grid>
                <Grid container sx={{width: "100%", justifyContent: "flex-end"}}>
                    <Button disabled={selectedScenario === ""} onClick={() => goEditor()} variant="outlined">Open in Editor</Button>
                </Grid>
            </Stack>
            
            </Grid>

            {
                selectedScenario === "" ?
                <Box sx={{ 
                    height: 800, 
                    width: '100%',
                    backgroundColor: "#fafafa"
                    }}>
                    <Grid container sx={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Typography color="textSecondary">Select a scenario to begin.</Typography>
                    </Grid>
                </Box>
                :
                <Box sx={{ 
                    height: 800, 
                    width: '100%',
                    }}>
                    <DataGrid
                        rows={rows}
                        loading={loading}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 50,
                            },
                        },
                        sorting: {
                            sortModel: [{ field: 'timestamp', sort: 'desc' }],
                        },
                        }}
                        checkboxSelection
                        disableRowSelectionOnClick
                        slots={{ toolbar: GridToolbar }}
                        sx={{
                            '& .evaluation-cell-tbc': {
                                backgroundColor: 'rgba(255, 236, 179, 1)',
                            },
                            '& .evaluation-cell-correct': {
                                backgroundColor: 'rgba(200, 230, 201, 1)',
                            },
                            '& .evaluation-cell-incorrect': {
                                backgroundColor: 'rgba(255, 205, 210, 1)',
                            },
                        }}
                    />
                </Box>
            }
        </Grid>
    )
    };
export default Scenarios;

// {rows.length > 0 ?
//     <DataGrid
//         rows={rows}
//         loading={loading}
//         columns={columns}
//         initialState={{
//         pagination: {
//             paginationModel: {
//             pageSize: 50,
//             },
//         },
//         }}
//         checkboxSelection
//         disableRowSelectionOnClick
//         slots={{ toolbar: GridToolbar }}
//     />
//     :
//     null
// }