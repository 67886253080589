import React from 'react';

import { Grid, IconButton, TextField, Tooltip } from '@mui/material';

import TextFormatIcon from '@mui/icons-material/TextFormat';

const KnotOption = (props) => {

    const fullOptionData = props.optionData;
    const setOptionData = props.setOptionData;
    const optionIndex = props.optionIndex;
    const setChangesMade = props.setChangesMade;

    const optionData = fullOptionData[optionIndex]

    const optionText = optionData["text"];
    const optionFormat = optionData["format"];

    const formatMap = {
        action: {label: "Action", color: "#424242"},
        _speechYour_: {label: "Your dialogue", color: "#009688"},
        passThrough: {label: "Pass through", color: "#37474f"}
    }

    const handleOptionTextChange = (event) => {
        setOptionData({
            ...fullOptionData,
            [optionIndex]: {
                ...optionData,
                text: event.target.value,
            }
        });
        setChangesMade(true);
    };

    const toggleFormat = () => {

        const formats = Object.keys(formatMap)
        const currentFormat = formats.indexOf(optionFormat)
        const newFormatIdx = currentFormat === (formats.length - 1) ? 0 : currentFormat + 1
        const newFormat = formats[newFormatIdx]

        setOptionData({
            ...fullOptionData,
            [optionIndex]: {
                ...optionData,
                format: newFormat,
            }
        });
        setChangesMade(true);
    };

    return (
        <Grid item style={{width: "100%", paddingTop: 10}}>
        <Grid container>
            <Grid item style={{flexGrow: 1, paddingRight: 5}}>
            <form autoComplete='off' onSubmit={e => { e.preventDefault()}}>
                <TextField
                    id="outlined-multiline-static"
                    label={formatMap[optionFormat]["label"]}
                    disabled={optionFormat === "passThrough"}
                    value={optionFormat === "passThrough" ? "" : optionText}
                    onChange={handleOptionTextChange}
                    variant="standard"
                    className="nodrag"
                    fullWidth
                    sx={{
                        '& label': {
                            color: formatMap[optionFormat]["color"],
                        },
                        '& label.Mui-focused': {
                            color: formatMap[optionFormat]["color"],
                        },
                        '& .MuiInput-underline': {
                            '&:after': {
                            borderBottomColor: formatMap[optionFormat]["color"],
                            },
                            '&:before': {
                            borderBottomColor: formatMap[optionFormat]["color"],
                            },
                            '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: formatMap[optionFormat]["color"],
                            },
                        },
                        '& input': {
                            color: formatMap[optionFormat]["color"],
                        },
                    }}
                />
            </form>
            </Grid>
            <Grid item>
                <IconButton onClick={() => toggleFormat()} sx={{marginTop: 2}} className="nodrag">
                    <Tooltip title="Toggle format" placement='top'>
                    <TextFormatIcon fontSize='small' style={{color: formatMap[optionFormat]["color"]}}/>
                    </Tooltip>
                </IconButton>
            </Grid>
        </Grid>
        </Grid>
    )
};

export default KnotOption;