import React from 'react';

import { useNodeId } from 'reactflow';

import { Grid, FormControl, Select, MenuItem, Typography, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setNodeAction } from '../editorSlice';

import UserDocumentCondition from './UserDocumentCondition';
import VisitedBlockCondition from './VisitedBlockCondition';
import AIBlockCondition from './AIBlockCondition';

const ActionCondition = (props) => {

    const dispatch = useDispatch();

    const actionUUID = props.actionUUID;
    const setChangesMade = props.setChangesMade;

    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const condition = nodeData.nodeActions[actionUUID]["condition"]

    const AInodes = nodes.filter(node => (node.data?.mode === "AIblock"))

    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile)

    const handleConditionTypeSelect = (event) => {
        const conditionType = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Create a deep copy of the object at the current actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        
        // Now, update the type property and reset data
        updatedAction["condition"]["type"] = conditionType;
        updatedAction["condition"]["data"] = {}

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    return (
        <Grid item>
            <Stack direction="row" spacing={1} sx={{marginBottom: "8px"}}>
            <Typography>Condition type:</Typography>
            <FormControl variant="standard">
            <Select
            id="condition-type-select"
            size='small'
            value={condition.type}
            label="Condition type"
            onChange={handleConditionTypeSelect}
            >
                <MenuItem value="always">Always</MenuItem>
                <MenuItem value="userDocument">User document</MenuItem>
                <MenuItem value="visitedBlock">Visited block</MenuItem>
                <MenuItem value="AIBlockEvaluation">Dynamic block evaluation</MenuItem>
            </Select>
            </FormControl>
            </Stack>
            {condition.type === "userDocument" ?
            <>
            {scenarioProfile.scenarioAssets.hasOwnProperty("documents") ?
            <UserDocumentCondition 
                actionUUID={actionUUID}
                setChangesMade={setChangesMade}
            />
            :
            <Typography color="textSecondary" align="center">No scenario documents. Create one to set conditions.</Typography>
            }
            </>
            :
            null
            }
            {condition.type === "visitedBlock" ?
            <VisitedBlockCondition
                actionUUID={actionUUID}
                setChangesMade={setChangesMade}
            />
            :
            null
            }
            {condition.type === "AIBlockEvaluation" ?
            <>
            {AInodes.length > 0 ?
            <AIBlockCondition 
                actionUUID={actionUUID}
                setChangesMade={setChangesMade}
            />
            :
            <Typography color="textSecondary" align="center">No AI blocks. Add one to set conditions.</Typography>
            }
            </>
            :
            null
            }
        </Grid>
    )
};
export default ActionCondition;