import { useState, useCallback, useEffect } from 'react';

import { useNodeId } from 'reactflow';

import { Grid, IconButton, Tooltip, Typography, Button, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { setNodeAI } from '../editorSlice';
import { useDispatch, useSelector } from 'react-redux';

import {v4 as uuidv4} from 'uuid';

// Custom debounce function
const useDebounce = (callback, delay) => {
    const [timer, setTimer] = useState(null);

    const debouncedCallback = useCallback((...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            callback(...args);
        }, delay);
        setTimer(newTimer);
    }, [callback, delay, timer]);

    // Clean up
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return debouncedCallback;
};

const AIIssueSpot = (props) => {
    
    // Set prompt inputs
    
    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const nodeAI = nodeData.nodeAI
    const issues = nodeAI?.issues ? nodeAI.issues : {}

    const [issueData, setIssueData] = useState(issues)

    const setChangesMade = props.setChangesMade;

    const dispatch = useDispatch();

    const dispatchUpdate = (AIUpdate) => {
        dispatch(setNodeAI(AIUpdate));
        setChangesMade(true);
    };

    const addNewIssue = () => {
        
        const newUUID = uuidv4();
        let updatedAI = {...nodeAI}
        let updatedIssues = {...nodeAI.issues}
        updatedIssues[newUUID] = {
            issueName: "",
            issueDescription: ""
        };
        updatedAI["issues"] = updatedIssues

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        };

        setIssueData(updatedIssues);
        dispatch(setNodeAI(AIUpdate));
        setChangesMade(true);
    };


    const removeIssue = (issueUUID) => {

        let {[issueUUID]: issueData, ...rest} = issues
        let updatedAI = {...nodeAI,
            "issues": rest
        }
        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI
        };

        setIssueData(rest);
        dispatch(setNodeAI(AIUpdate));        
        setChangesMade(true);
    };

    // Use the custom debounce function
    const debouncedDispatchUpdate = useDebounce(dispatchUpdate, 300);

    const handleNameInputChange = (event) => {
        
        const issueID = event.target.id

        let updatedAI = {...nodeAI}
        let updatedIssues = {...nodeAI.issues}
        let updatedData = {
            ...nodeAI.issues[issueID],
            "issueName": event.target.value
        }
        updatedIssues[issueID] = updatedData

        setIssueData(updatedIssues)
        
        // Create a new object for the updated actions
        updatedAI["issues"] = updatedIssues
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        debouncedDispatchUpdate(AIUpdate)
        setChangesMade(true)
    };

    const handleDescriptionInputChange = (event) => {
        const issueID = event.target.id

        let updatedAI = {...nodeAI}
        let updatedIssues = {...nodeAI.issues}
        let updatedData = {
            ...nodeAI.issues[issueID],
            "issueDescription": event.target.value
        }
        updatedIssues[issueID] = updatedData

        setIssueData(updatedIssues)
        updatedAI["issues"] = updatedIssues

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        debouncedDispatchUpdate(AIUpdate)
        setChangesMade(true)
    }

    /*

    continue building out. plumb editor data, plumb ink parsing, plumb ai response, plumb conditions

    */

    return (
        <>
        {Object.keys(issues).length > 0 ?
        <Grid item>
        {Object.keys(issues).map((key, index) => {
            return(
                <Grid item sx={{marginBottom: "15px"}} key={index}>
                    <Grid container>
                        <Grid item sx={{borderRight: "1px solid #cccccc", backgroundColor: "#fafafa", padding: "10px", flex: 1, borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px"}}>
                        <Grid item sx={{marginBottom: "3px"}}>
                        <TextField
                            id={key}
                            label="Issue"
                            value={issueData[key]["issueName"]}
                            placeholder="E.g. Confidentiality."
                            onChange={handleNameInputChange}
                            variant="standard"
                            fullWidth
                            className="nodrag"
                        />
                        </Grid>
                        <Grid item>
                        <TextField
                            id={key}
                            label="Description"
                            value={issueData[key]["issueDescription"]}
                            placeholder="E.g. The documents should be shared on a confidential basis."
                            onChange={handleDescriptionInputChange}
                            variant="standard"
                            fullWidth
                            className="nodrag"
                        />
                        </Grid>
                        </Grid>
                        <Grid item sx={{width: "40px", minHeight: "100%", backgroundColor: "#fce4ec", borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}}>
                        <Grid container sx={{height: "100%", alignItems: "center"}}>
                        <IconButton onClick={() => removeIssue(key)} className="nodrag">
                            <Tooltip title="Delete issue" placement="top">
                            <DeleteIcon fontSize="medium" color='secondary'/>
                            </Tooltip>
                        </IconButton>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
        }
        </Grid>
        :
        <Grid item sx={{paddingBottom: "3px"}}>
            <Typography variant='body2' color="secondary" sx={{display: "inline"}}>Warning: No issues configured - add issues.</Typography>
        </Grid>
        }
        <Grid item sx={{width: "100%", padding: "20px"}}>
            <Grid container sx={{width: "100%", justifyContent: "center", alignContent: "center"}}>
                <Button size='medium' variant='outlined' onClick={() => addNewIssue()}>Add issue</Button>
            </Grid>
        </Grid>
        </>
    )
};
export default AIIssueSpot;