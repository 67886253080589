import React, { useState } from 'react';

import { List, ListItemButton, ListItemIcon, ListItemText, IconButton, Grid} from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import GavelIcon from '@mui/icons-material/Gavel';
import SettingsIcon from '@mui/icons-material/Settings';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';

import { useSelector, useDispatch } from 'react-redux';

import { updateScenarioList } from '../appData/appDataSlice';
import { 
  updateScenarioProfile, 
  loadScenarioIntoInk, 
} from './editorSlice';

import {v4 as uuidv4} from 'uuid';

// bad error something gone wrong

const EditorSideBar = (props) => {

  const selectedIndex = props.selectedIndex;
  const setSelectedIndex = props.setSelectedIndex;
  const setValidationWarnings= props.setValidationWarnings;
  const setSaveSuccess= props.setSaveSuccess;

  const [expandSideBar, setExpandSideBar] = useState(false);

  const nodes = useSelector((state) => state.editorData.nodes);
  const nodeID = useSelector((state) => state.editorData.nodeID);
  const edges = useSelector((state) => state.editorData.edges);
  const edgeID = useSelector((state) => state.editorData.edgeID);
  const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile)
  const userAccessToken = useSelector((state) => state.appData.accessToken);
  const dispatch = useDispatch();

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleSave = () => {
    dispatch(loadScenarioIntoInk())
    const editorData = {
        nodes: nodes,
        edges: edges,
        nodeID: nodeID,
        edgeID: edgeID
    };
    var scenarioData = {
        ...scenarioProfile, 
        editorData: editorData,
        lastModified: Date.now(),
    };
    if (scenarioData.scenarioID === "") {
        const newUUID = uuidv4();
        scenarioData.scenarioID = newUUID;
        const profileUpdate = {scenarioID: newUUID}
        dispatch(updateScenarioProfile(profileUpdate))
      }
      doValidation();
      const writeScenario = async () => {
        try {
          const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
          });
          const responseData = await scenarioResponse.json()
          if (responseData.modified > 0 || responseData.upsertID !== "") {
            dispatch(updateScenarioList(scenarioData))
            setSaveSuccess(true);
        }
        } 
    catch(e) {console.log(e)}
    }
    writeScenario();
  };

  const doValidation = () => {
    const validation = {}

    // no scenario name
    if (scenarioProfile.scenarioName === "") {
        validation["scenarioName"] = {
            text: "No scenario name. Set a scenario name in settings.",
            severity: "warning"
        }
    };

    // no scenario description
    if (scenarioProfile.scenarioShortDescription === "") {
        validation["scenarioDescription"] = {
            text: "No scenario description. Set a scenario description in settings.",
            severity: "warning"
        }
    };
    setValidationWarnings(validation);
  };

  return (
    <Grid item style={{width: expandSideBar ? 250 : "auto", minHeight: "100%", boxShadow: "3px 0px 3px #cccccc"}}>
    <List component="nav" aria-label="editor-toolbar">
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Scenario" /> : null}
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <GavelIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Primary Sources" /> : null}
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={(event) => handleListItemClick(event, 2)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <LibraryBooksIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Documents" /> : null}
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event) => handleListItemClick(event, 3)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <FolderOpenIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Load scenario" /> : null}
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event) => handleListItemClick(event, 4)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Scenario Profile" /> : null}
      </ListItemButton>
      
      <ListItemButton
        selected={selectedIndex === 5}
        onClick={(event) => handleListItemClick(event, 5)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Share and Publish" /> : null}
      </ListItemButton>
      <ListItemButton
        selected={false}
        onClick={() => handleSave()}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <SaveIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Save" /> : null}
      </ListItemButton>
    </List>
    <Grid item style={{marginLeft: 8}}>
    {expandSideBar ? 
      <IconButton onClick={() => setExpandSideBar(false)}>
        <KeyboardDoubleArrowLeftIcon />
      </IconButton>
      :
      <IconButton onClick={() => setExpandSideBar(true)}>
        <KeyboardDoubleArrowRightIcon />
      </IconButton>
    }
    </Grid>
    </Grid>
  )
};

export default EditorSideBar;

/*

<ListItemButton
        selected={selectedIndex === 5}
        onClick={(event) => handleListItemClick(event, 5)}
        style={{minHeight: 50}}
      >
        <ListItemIcon>
          <ThumbsUpDownIcon />
        </ListItemIcon>
        {expandSideBar ? <ListItemText primary="Scenario feedback" /> : null}
      </ListItemButton>

*/