import { Grid, Button, Typography, Stack, IconButton, Link, Tooltip, Divider, FormControl, Select, MenuItem, Slider, Box, InputLabel} from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CodeIcon from '@mui/icons-material/Code';

import { Editable, withReact, Slate, useSelected, useSlate } from 'slate-react'
import {
  Editor,
  Transforms,
  createEditor,
  Range,
  Element as SlateElement,
} from 'slate'

import { updateTopicOverview } from './appData/appDataSlice';

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion"

const TopicOverviewNotesEditor = (props) => {

    const dispatch = useDispatch();

    const setEditTopicOverview= props.setEditTopicOverview;
    const currentSubTopic = props.currentSubTopic;
    const subTopicOverviewNotes = props.subTopicOverviewNotes;
    const setSaveSuccess = props.setSaveSuccess; 

    const userAccessToken = useSelector((state) => state.appData.accessToken);
    const subTopicData = useSelector((state) => state.appData.subTopicList[currentSubTopic]);

    const [skillLevel, setSkillLevel] = useState(subTopicData["skillLevel"] ? subTopicData["skillLevel"] : "foundations")
    const [playTime, setPlayTime] = useState(subTopicData["playTime"] ? subTopicData["playTime"] : 5)
    const [readTime, setReadTime] = useState(subTopicData["readTime"] ? subTopicData["readTime"] : 5)

    const handleTopicOverviewUpdate = (editorContent) => {
        
        const updateTime = new Date().toISOString();

        const overviewUpdate = {
            currentSubTopic: currentSubTopic,
            content: editorContent,
            updateTime: updateTime,
            skillLevel: skillLevel,
            readTime: readTime,
            playTime: playTime
        }

        // Update backend

        const writeTopicOverview = async (overviewUpdate) => {
            try {
                const notesResponse = await fetch('/api/editor/updatetopicoverview', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(overviewUpdate),
                });
                const responseData = await notesResponse.json()
                if (responseData.modified > 0) {
                    dispatch(updateTopicOverview(overviewUpdate));
                    setSaveSuccess(true);
                    setEditTopicOverview(false);
                } else {
                    console.log("save error")
                    setEditTopicOverview(false);
                };
                }
            catch(e) {
                console.log(e)
                setEditTopicOverview(false);
            }
        };
        writeTopicOverview(overviewUpdate);
    };

    const handleSkillLevelSelect = (event) => {
        setSkillLevel(event.target.value);
    };
    const handleReadTimeSelect = (event) => {
        setReadTime(event.target.value);
    };
    const handlePlayTimeSelect = (event) => {
        setPlayTime(event.target.value);
    };

    /*********************************************

    Slate editor

    *********************************************/

    const isUrl = (text) => {
    try {
        new URL(text);
        return true;
    } catch (_) {
        return false;  
    }
    }

    const onKeyDown = event => {
    const { selection } = editor

    // Default left/right behavior is unit:'character'.
    // This fails to distinguish between two cursor positions, such as
    // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
    // Here we modify the behavior to unit:'offset'.
    // This lets the user step into and out of the inline without stepping over characters.
    // You may wish to customize this further to only use unit:'offset' in specific cases.
    if (selection && Range.isCollapsed(selection)) {
        const { nativeEvent } = event
        if (event.key === 'ArrowLeft') {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset', reverse: true })
        return
        }
        if (event.key === 'ArrowRight') {
        event.preventDefault()
        Transforms.move(editor, { unit: 'offset' })
        return
        }
    }
    };

    const withInlines = editor => {
        // extend editor for inline link elements
        const { insertText, isInline, isVoid } = editor

        editor.isInline = element =>
            ['link'].includes(element.type) || isInline(element)

        editor.insertText = text => {
            if (text && isUrl(text)) {
            wrapLink(editor, text)
            } else {
            insertText(text)
            }
        };

        editor.isVoid = element => (element.type === 'iframe' ? true : isVoid(element))

        return editor
    };

    const insertLink = (editor, url) => {
    if (editor.selection) {
        wrapLink(editor, url)
    }
    };

    const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
    };

    const unwrapLink = editor => {
    Transforms.unwrapNodes(editor, {
        match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    };

    const wrapLink = (editor, url) => {
        if (isLinkActive(editor)) {
            unwrapLink(editor)
        }

        const { selection } = editor
        const isCollapsed = selection && Range.isCollapsed(selection)
        const link = {
            type: 'link',
            url,
            children: isCollapsed ? [{ text: url }] : [],
        }

        if (isCollapsed) {
            Transforms.insertNodes(editor, link)
        } else {
            Transforms.wrapNodes(editor, link, { split: true })
            Transforms.collapse(editor, { edge: 'end' })
        }
    };

    const insertIFrame = (editor, url) => {
        const text = { text: '' }
        const image = { type: 'iframe', url, children: [text] }
        Transforms.insertNodes(editor, image)
    };

    // Put this at the start and end of an inline component to work around this Chromium bug:
    // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
    const InlineChromiumBugfix = () => (
        <span
            contentEditable={false}
            style={{fontSize:"0px"}}
        >
            {String.fromCodePoint(160) /* Non-breaking space */}
        </span>
    )

    const LIST_TYPES = ['numbered-list', 'bulleted-list']

    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(
            editor,
            format,
            'type'
        )
        const isList = LIST_TYPES.includes(format)

        Transforms.unwrapNodes(editor, {
            match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type),
            split: true,
        })
        let newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
        Transforms.setNodes(editor, newProperties)

        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        };
    };

    const isBlockActive = (editor, format, blockType = 'type') => {
        const { selection } = editor
        if (!selection) return false

        const [match] = Array.from(
            Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
            })
        )

        return !!match
    }

    const Element = (props) => {
        const { attributes, children, element } = props
        switch (element.type) {
            case 'block-quote':
            return (
                <blockquote {...attributes}>
                {children}
                </blockquote>
            )
            case 'bulleted-list':
            return (
                <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
                {children}
                </ul>
            )
            case 'heading-one':
            return (
                <Typography variant='h6' {...attributes} sx={{paddingBottom: "8px"}}>
                {children}
                </Typography>
            )
            case 'heading-two':
            return (
                <h2 {...attributes}>
                {children}
                </h2>
            )
            case 'list-item':
            return (
                <Typography variant='body1' {...attributes} component="li">
                    {children}
                </Typography>
            )
            case 'numbered-list':
            return (
                <ol {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
                    {children}
                </ol>
            )
            case 'link':
            return (
                <EditorLinkComponent element={element} {...attributes}>
                {children}
                </EditorLinkComponent>
            )
            case 'iframe':
            return (
                <IFrameElement {...props} />
            )
            default:
            return (
                <Typography paragraph variant='body1' {...attributes}>
                {children}
                </Typography>
            )
        }
    };

    const EditorLinkComponent = React.forwardRef((props, ref) => {
        // Not really sure why this needs forwardRef tbh.
        const { attributes, children, element } = props
        const selected = useSelected()
        return (
            <Link {...attributes} href={element.url} target="_blank" rel="noopener" underline="hover" sx={{boxShadow: selected ? "0 0 0 2px #d6dee1": null}}>
            <InlineChromiumBugfix />
                {children}
            <InlineChromiumBugfix />
            </Link>
        )
    });

    const IFrameElement = ({ attributes, children, element }) => {
        /*

        In Qumu, looks like can add ?captions=en to url to turn on captions be default. Not sure if / how can set auto mute.

        */
        const { url } = element
        return (
          <div {...attributes}>
            <div contentEditable={false}>
              <div
                style={{
                  padding: '75% 0 0 0',
                  position: 'relative',
                }}
              >
                <iframe
                  src={url}
                  title="Video Player"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                  allowFullScreen
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    border: '0px'
                  }}
                />
              </div>
            </div>
            {children}
          </div>
        )
    };

    const BugFixText = props => {
    const { attributes, children, leaf } = props
    return (
        <span
        // The following is a workaround for a Chromium bug where,
        // if you have an inline at the end of a block,
        // clicking the end of a block puts the cursor inside the inline
        // instead of inside the final {text: ''} node
        // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
        style={{
            paddingLeft: leaf.text === '' ? "0.1px" : null
        }}
        {...attributes}
        >
        {children}
        </span>
    )
    }

    const renderElement = useCallback(props => <Element {...props} />, [])
    const editor = useMemo(() => withInlines(withReact(createEditor())), [])

    const ToolbarSaveButton = () => {
    const editor = useSlate()
    return(
        <Button size="small" variant='outlined' color="primary" onClick={() => {
        handleTopicOverviewUpdate(editor.children)
        }} startIcon={<DoneIcon fontSize='small' />}>
        Save changes
        </Button>
    )
    };

    const ToolbarBlockButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <IconButton onMouseDown={event => {
            event.preventDefault()
            toggleBlock(editor, props.format)
            }}
            sx={{color: isBlockActive(editor, props.format, "type") ? "#3f51b5" : null}}
        >
            {props.format === "heading-one" ? <FormatSizeIcon /> : props.format === "bulleted-list" ? <FormatListBulletedIcon /> : props.format === "numbered-list" ? <FormatListNumberedIcon /> : null}
        </IconButton>
    )
    });

    return (
    <Grid item sx={{width: "100%"}} component={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.3, ease: "easeInOut"}} >
        <Slate editor={editor} initialValue={subTopicOverviewNotes}>
        <Grid container sx={{width: "100%", justifyContent: "flex-end"}}>
        <Stack direction="row" spacing={1}>
            <Button size="small" variant='outlined' color="secondary" onClick={() => setEditTopicOverview(false)} startIcon={<DeleteIcon fontSize='small' />}>
            Discard
            </Button>
            <ToolbarSaveButton />
        </Stack>
        </Grid>
        <Stack direction="column" spacing={2} sx={{marginBottom: "20px", marginTop: "16px", backgroundColor: "#fafafa", padding: "16px", borderRadius: "6px"}}>
        <Box sx={{width: "200px"}}>
        <FormControl variant="standard" fullWidth>
            <InputLabel id="skill-level-select">Skill Level</InputLabel>
            <Select
            id="skill-level-select"
            size='small'
            value={skillLevel}
            label="Skill level"
            onChange={handleSkillLevelSelect}
            >
                <MenuItem value="foundations">Foundations</MenuItem>
                <MenuItem value="experienced">Experienced</MenuItem>
                <MenuItem value="expert">Expert</MenuItem>
            </Select>
        </FormControl>
        </Box>
        <Box sx={{width: "200px"}}>
        <Typography variant='body2' color="textSecondary">Resources:</Typography>
        <Slider
            aria-label="Resources"
            value={readTime}
            onChange={handleReadTimeSelect}
            step={5}
            marks
            min={5}
            max={30}
            valueLabelDisplay="auto"
        />
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='body2' color="textSecondary">5 mins</Typography>
            <Typography variant='body2' color="textSecondary">30 mins</Typography>
        </Box>
        </Box>
        <Box sx={{width: "200px"}}>
        <Typography variant='body2' color="textSecondary">Play time:</Typography>
        <Slider
            aria-label="Play time"
            valueLabelDisplay="auto"
            value={playTime}
            onChange={handlePlayTimeSelect}
            step={5}
            marks
            min={5}
            max={30}
        />
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography variant='body2' color="textSecondary">5 mins</Typography>
            <Typography variant='body2' color="textSecondary">30 mins</Typography>
        </Box>
        </Box>
        </Stack>
        
        <Grid container sx={{width: "100%", justifyContent: "space-between", alignItems: "center", marginBottom: "8px"}}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
            <Tooltip title="Heading" placement='top'>
            <ToolbarBlockButton format="heading-one" />
            </Tooltip>
            <Tooltip title="Bullet list" placement='top'>
            <ToolbarBlockButton format="bulleted-list" />
            </Tooltip>
            <Tooltip title="Numbered list" placement='top'>
            <ToolbarBlockButton format="numbered-list" />
            </Tooltip>
            <Tooltip title="Add hyperlink" placement='top'>
            <IconButton 
                onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the link:')
                if (!url) return
                insertLink(editor, url)
                }}
            >
            <AddLinkIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title="Remove hyperlink" placement='top'>
            <IconButton       
                onMouseDown={event => {
                if (isLinkActive(editor)) {
                    unwrapLink(editor)
                }
                }}
            >
                <LinkOffIcon />
            </IconButton>
            </Tooltip>
            <Tooltip title="Embed content" placement='top'>
            <IconButton 
                onMouseDown={event => {
                event.preventDefault()
                const url = window.prompt('Enter the URL of the content:')
                if (!url) return
                insertIFrame(editor, url)
                }}
            >
            <CodeIcon />
            </IconButton>
            </Tooltip>
        </Stack>
        </Grid>
        <Editable
            renderElement={renderElement}
            renderLeaf={props => <BugFixText {...props} />}
            onKeyDown={onKeyDown}
            style={{
                padding: "8px",
                border: "solid 2px #d6dee1",
                borderRadius: "2px"
            }}
        />
        </Slate>
    </Grid>
    )
    };
export default TopicOverviewNotesEditor;