import React from "react";

function LogoWhite() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 43.016 9.358"
    >
      <path
        fill="#fff"
        fillOpacity="1"
        strokeWidth="0.094"
        d="M4.919 9.122l-.28-.236H0V1.534h.566l.005.683c.002.376.005 1.903.005 3.394v2.71l2.281-.001c1.585-.001 2.237-.031 2.139-.098-.728-.497-1.763-.75-3.07-.75h-.89V0L2.38.052c1.49.057 2.092.197 2.68.623l.378.274.298-.233C6.34.244 6.99.094 8.483.083L9.85.073v7.352l-1.178.064c-1.455.079-2.148.253-2.686.677-.173.137-.005.15 2.05.152l2.238.003V1.534h.566v7.352H6.196l-.219.236a.695.695 0 01-.498.236c-.161 0-.399-.1-.56-.236zm.118-8.066C4.955.966 4.668.795 4.4.676 3.99.496 3.703.455 2.616.421L1.32.381v6.714h.553c.858 0 2.174.261 2.746.545l.518.256.025-3.337c.022-2.936.007-3.357-.125-3.503zM6.2 7.661c.613-.296 1.405-.473 2.402-.536l.92-.058V.385L8.27.426C6.977.472 6.576.553 6.056.872l-.306.188v3.394c0 1.868.013 3.395.03 3.395.016 0 .204-.085.419-.188z"
      ></path>
      <text
        xmlSpace="preserve"
        style={{
          lineHeight: "1.25",
          InkscapeFontSpecification: "'Verdana, Normal'",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontFeatureSettings: "normal",
          WebkitTextAlign: "start",
          textAlign: "start",
        }}
        x="11.772"
        y="6.594"
        fill="#fff"
        fillOpacity="1"
        stroke="none"
        strokeWidth="0.137"
        fontFamily="Verdana"
        fontSize="5.477"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="normal"
        letterSpacing="0"
        textAnchor="start"
        wordSpacing="0"
        writingMode="lr-tb"
      >
        <tspan
          x="11.772"
          y="6.594"
          style={{
            InkscapeFontSpecification: "'Verdana, Normal'",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontFeatureSettings: "normal",
            WebkitTextAlign: "start",
            textAlign: "start",
          }}
          fill="#fff"
          fillOpacity="1"
          strokeWidth="0.137"
          fontFamily="Verdana"
          fontSize="5.477"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="normal"
          textAnchor="start"
          writingMode="lr-tb"
        >
          LegalReflex
        </tspan>
      </text>
    </svg>
  );
}

export default LogoWhite;