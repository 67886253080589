import React from 'react';
import { Grid, Typography } from '@mui/material';

/*

A container for rule analysis components. Uses paper background.
TODO style atom analysis
position ref
add conjunction
add prop for highlighting

*/

const RADocument = (props) => {

    return (
            <Grid item 
                sx={{
                    width: "100%",
                    paddingLeft: "10px",
                    paddingRight: "10px",    
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    margin: "auto",
                    marginBottom: "20px",
                }}
            >
                <Typography align="center" variant="body2" sx={{fontStyle: "italic"}} paragraph>{props.title}</Typography>
            {props.children}
            </Grid>
            )
    };

export default RADocument;