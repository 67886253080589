import React from "react";

function StarBadge({color}) {
  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19.884"
      version="1.1"
      viewBox="0 0 5.292 5.261"
    >
      <g transform="translate(-38.25 -31.5)">
        <path
          fill={color}
          fillOpacity="1"
          stroke={color}
          strokeOpacity="1"
          strokeWidth="1.757"
          d="M312.245 143.633c4.064 10.595-52.08 48.565-55.02 59.526-2.94 10.96 26.665 71.931 17.844 79.07-8.822 7.14-62.283-34.523-73.615-33.932-11.333.59-60.171 47.588-69.687 41.405-9.516-6.183 13.588-69.902 9.524-80.498-4.064-10.596-63.853-42.52-60.913-53.481 2.94-10.96 70.68-8.678 79.5-15.818 8.822-7.139 20.708-73.867 32.041-74.458 11.333-.59 30.095 64.539 39.61 70.722 9.517 6.184 76.652-3.132 80.716 7.464z"
          transform="matrix(.0059 .02273 -.0218 .00616 43.81 28.734)"
        ></path>
      </g>
    </svg>
  );
}

export default StarBadge;