import { Grid, Button, Typography, Snackbar, Alert } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { setCurrentSelection } from './appData/appDataSlice';

import TopicOverviewNotesEditor from './TopicOverviewNotesEditor';
import TopicOverviewNotesReader from './TopicOverviewNotesReader';

import { useParams } from "react-router-dom";

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SubTopicOverview = () => {

  /*

  To do:
  - write the redux handler
  - write the backend handler
  - add skil level chip
  - add play time and read time chips

  */

  const theme = useTheme();
    
  const fullView = useMediaQuery(theme.breakpoints.up('sm'))

  let params = useParams();
  const dispatch = useDispatch();

  const subTopicList = useSelector((state) => state.appData.subTopicList);
  const userData = useSelector((store) => store.appData.userData);

  const currentSubTopic = params.hasOwnProperty("subTopicID") ? params.subTopicID : "fd0d42fc-cd1d-47e4-afe5-ba9028fd33c9"

  const defaultContent = [
    {
      type: 'paragraph',
      children: [{ text: 'Set topic overview.' }],
    }
  ]
  const subTopicOverviewNotes = subTopicList[currentSubTopic]["subTopicOverviewNotes"] ? subTopicList[currentSubTopic]["subTopicOverviewNotes"]["content"] : defaultContent

  const [editTopicOverview, setEditTopicOverview] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false); 

  const handleSaveClose = (event, reason) => {
    if (reason === 'clickaway') {
    return;
    }
    setSaveSuccess(false);
  };

  useEffect(() => {
    
    dispatch(setCurrentSelection({
      "selectionType":"subTopicID",
      "selection": currentSubTopic,
    }));

  }, [params]);

  return (
  <Grid item sx={{width: "100%", marginTop: "20px"}}>
      <Grid container sx={{width: "100%", justifyContent: "space-between"}}>
      <Grid item sx={{margin: "0px 20px 10px 0px", color: "#424242"}}>
          <Typography variant={fullView ? 'h5' : 'h6'}>Topic Overview</Typography>
      </Grid>
      {userData?.userRoles.includes("write:topicDescriptions") ?
      <Grid item sx={{margin: "5px"}}>
          {editTopicOverview ?
          null
          :
          <Button size="small" variant='outlined' color="primary" onClick={() => {setEditTopicOverview(true)}} startIcon={<EditIcon fontSize='small' />}>
          Edit
          </Button>
          }
      </Grid>
      :
      null
      }
      </Grid>
      <Grid item sx={{marginBottom: "20px"}}>
      {editTopicOverview ?
      <TopicOverviewNotesEditor 
        subTopicOverviewNotes={subTopicOverviewNotes} 
        currentSubTopic={currentSubTopic} 
        setEditTopicOverview={setEditTopicOverview} 
        setSaveSuccess={setSaveSuccess}
      />
      :
      <TopicOverviewNotesReader subTopicOverviewNotes={subTopicOverviewNotes} currentSubTopic={currentSubTopic} />
      }
      <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{width: "250px"}}>
        <Alert onClose={handleSaveClose} severity="success" variant='filled' sx={{width: "100%"}}>
            Topic Overview saved.
        </Alert>
        </Snackbar>
      </Grid>
  </Grid>
  )
  };
export default SubTopicOverview;