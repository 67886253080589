import { configureStore } from '@reduxjs/toolkit'
import choiceReducer from '../features/choice/choiceSlice'
import appDataReducer from '../features/appData/appDataSlice'
import editorReducer from '../features/editor/editorSlice'

export default configureStore({
  reducer: {
    choices: choiceReducer,
    appData: appDataReducer,
    editorData: editorReducer,
  },
});