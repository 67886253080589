import React from 'react';

import { Grid, IconButton, TextField, Tooltip } from '@mui/material';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';

const KnotFeedback = (props) => {

    const fullOptionData = props.optionData;
    const setOptionData = props.setOptionData;
    const optionIndex = props.optionIndex;
    const setChangesMade = props.setChangesMade;

    const optionData = fullOptionData[optionIndex]
    const displayFeedback = optionData["format"] === "passThrough" ? "none" : "block"

    const feedbackTypeMap = {
        correct: {label: "Correct", color: "#4caf50"},
        incorrect: {label: "Incorrect", color: "#f44336"},
        neutral: {label: "Neutral", color: "#9e9e9e"},
    }

    const optionFeedbackText = optionData["feedbackText"];
    const optionFeedbackType = optionData["feedbackType"];

    const handleOptionTextChange = (event) => {
        setOptionData({
            ...fullOptionData,
            [optionIndex]: {
                ...optionData,
                feedbackText: event.target.value,
            }
        });
        setChangesMade(true);
    };

    const toggleFormat = () => {

        const feedbackTypes = Object.keys(feedbackTypeMap)
        const currentType = feedbackTypes.indexOf(optionFeedbackType)
        const newTypeIdx = currentType === (feedbackTypes.length - 1) ? 0 : currentType + 1
        const newFeedbackType = feedbackTypes[newTypeIdx]

        setOptionData({
            ...fullOptionData,
            [optionIndex]: {
                ...optionData,
                feedbackType: newFeedbackType,
            }
        });
        setChangesMade(true);
    };

    const getFeedbackIcon = () => {
        switch (optionFeedbackType) {
            case "correct":
                return(<ThumbUpIcon fontSize='small' style={{color: feedbackTypeMap[optionFeedbackType]["color"]}}/>)
            case "incorrect":
                return(<ThumbDownIcon fontSize='small' style={{color: feedbackTypeMap[optionFeedbackType]["color"]}}/>)
            case "neutral":
                return(<ThumbsUpDownIcon fontSize='small' style={{color: feedbackTypeMap[optionFeedbackType]["color"]}}/>)
            default:
                return(<ThumbsUpDownIcon fontSize='small' style={{color: feedbackTypeMap[optionFeedbackType]["color"]}}/>)
            
        }
        
    }

    return (
        <Grid item style={{width: "100%", paddingTop: 10, display: displayFeedback}}>
        <Grid container>
            <Grid item style={{flexGrow: 1, paddingRight: 5}}>
                <form autoComplete='off' onSubmit={e => { e.preventDefault()}}>
                <TextField
                    id="outlined-multiline-static"
                    label={"Option " + (optionIndex+1) + ": " + feedbackTypeMap[optionFeedbackType]["label"]}
                    value={optionFeedbackText}
                    disabled={optionFeedbackType === "neutral"}
                    onChange={handleOptionTextChange}
                    variant="standard"
                    className="nodrag"
                    fullWidth
                    sx={{
                        '& label': {
                            color: feedbackTypeMap[optionFeedbackType]["color"],
                        },
                        '& label.Mui-focused': {
                            color: feedbackTypeMap[optionFeedbackType]["color"],
                        },
                        '& .MuiInput-underline': {
                            '&:after': {
                            borderBottomColor: feedbackTypeMap[optionFeedbackType]["color"],
                            },
                            '&:before': {
                            borderBottomColor: feedbackTypeMap[optionFeedbackType]["color"],
                            },
                            '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: feedbackTypeMap[optionFeedbackType]["color"],
                            },
                        },
                        '& input': {
                            color: feedbackTypeMap[optionFeedbackType]["color"],
                        },
                    }}
                />
                </form>
            </Grid>
            <Grid item>
                <Tooltip title="Toggle feedback type" placement='top'>
                <IconButton onClick={() => toggleFormat()} sx={{marginTop: 2}} className="nodrag">
                    {getFeedbackIcon()}
                </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
        </Grid>
    )
};
export default KnotFeedback;