import React, { useCallback, useRef, useState } from 'react';
import ReactFlow, { 
    Background, 
    Controls, 
    useReactFlow,
    Panel,
} from 'reactflow';
import { Grid, IconButton, Snackbar, Tooltip } from '@mui/material';
import { Alert as MuiAlert } from '@mui/material';
import 'reactflow/dist/style.css';
import { useSelector, useDispatch } from 'react-redux';
import { 
    updateNodes, 
    updateEdges, 
    addNewEdge, 
    addNewNodeOnDrag, 
    updateEdgeTarget, 
    deleteEdge, 
    resetEditorData,
    setSaveError,
    setEditorScenarioUI
} from './editorSlice';

import TitleNode from './TitleNode';
import ScenarioNode from './ScenarioNode';
import CompleteNode from './CompleteNode';

import ParsingErrors from './ParsingErrors';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import HelpIcon from '@mui/icons-material/Help';

const nodeTypes = { titleNode: TitleNode, scenarioNode: ScenarioNode, completeNode: CompleteNode };

const EditorFlow = (props) => {

    const nodes = useSelector((state) => state.editorData.nodes);
    const edges = useSelector((state) => state.editorData.edges);
    const saveError = useSelector((state) => state.editorData.saveError);
    
    const dispatch = useDispatch();

    const saveSuccess= props.saveSuccess;
    const setSaveSuccess= props.setSaveSuccess;
    const validationWarnings= props.validationWarnings;

    const [copySuccess, setCopySuccess] = useState(false);

    // bring save success etc. in from editor

    const setSelectedIndex= props.setSelectedIndex 
    const setActiveStep = props.setActiveStep

    const parsingErrors = useSelector((state) => state.editorData.parsingErrors);
    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);

    const handleSaveClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSaveSuccess(false);
    };

    const handleCopyClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setCopySuccess(false);
    };

    const handleSaveErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        dispatch(setSaveError(false));
    };

    const onNodesChange = useCallback((changes) => dispatch(updateNodes(changes)),[])
    const onEdgesChange = useCallback((changes) => dispatch(updateEdges(changes)),[])

    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    const edgeUpdateSuccessful = useRef(true);
    
    const { screenToFlowPosition } = useReactFlow();
    const onConnect = useCallback((params) => {
        dispatch(addNewEdge(params)) 
    }, []);

    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event) => {
        const targetIsPane = event.target.classList.contains('react-flow__pane');

        if (targetIsPane && edgeUpdateSuccessful.current) {
            
            // we are removing the half of the node width (75) to center the new node
            
            dispatch(addNewNodeOnDrag({
                position: screenToFlowPosition({ x: event.clientX - 175, y: event.clientY }),
                source: connectingNodeId.current
                }));
        }
        },
        [screenToFlowPosition]
    );

    const onEdgeUpdate = useCallback(
        (oldEdge, newConnection) => {
            edgeUpdateSuccessful.current = true;
            const edgeUpdate = {oldEdge: oldEdge, newConnection: newConnection}
            dispatch(updateEdgeTarget(edgeUpdate))
        },[]
    );

    const onEdgeUpdateStart = useCallback(() => {
        edgeUpdateSuccessful.current = false;
    }, []);

    const onEdgeUpdateEnd = useCallback((_, edge) => {
        
        if (!edgeUpdateSuccessful.current) {
            dispatch(deleteEdge(edge));
        };
        edgeUpdateSuccessful.current = true;
    }, []);

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} {...props} />;
    });

    const copyTextToClipboard = () => {
        /// copy check helper
        var totalText = ""
        totalText += "# Scenario name:\n" + scenarioProfile.scenarioName + "\n\n"
        totalText += "# Scenario short description:\n" +scenarioProfile.scenarioShortDescription + "\n\n"
        totalText += "# Scenario long description:\n" +scenarioProfile.scenarioLongDescription + "\n\n"
        
        totalText += "# Learning notes:\n"
        Object.values(scenarioProfile.learningNotes.text).forEach((text) => {
            totalText += text + "\n"
        })
        
        totalText += "\n# Scenario text:\n\n"
        for (const node of nodes) {
            
            // Skip title and complete nodes
            if (node.id === "1" || node.id === "3") {
                continue
            }

            // Skip action nodes
            if (node.data?.mode === "action") {
                continue
            }

            var nodeText = `\n## Block ${node.id}:\n\n`
            if (node.data?.mode === "AIblock") {
                nodeText += `### AI block type: ${node.data.nodeAI.type}\n\n`
                for (const [key, value] of Object.entries(node.data.nodeAI.promptInputs)) {
                    nodeText += `${key}: ${value}\n`
                }
            } else {
                if (node.data?.text) {
                    nodeText += "### Text:\n\n"
                    for (const text of Object.values(node.data["text"])) {
                        nodeText += text + "\n"
                    }
                }
            }

            const nodeEdges = edges.filter((edge) => edge.source === node.id);
            

            if (nodeEdges.length > 0) {
                nodeText += "\n### Options:\n\n"
                for (const edge of nodeEdges) {
                    
                    // Mark pass through edges
                    if (edge.data.format === "passThrough") {
                        nodeText += "(Pass through)\n"
                        continue
                    }

                    // Mark end edges
                    if (edge.target === "3") {
                        nodeText += "(End)\n"
                        continue
                    }

                    nodeText += "Option Text: " + edge.label + "\n"
                    if (edge.data?.feedbackText) {
                        nodeText += "\n#### Feedback:\n\n"
                        nodeText += "Feedback Type: " + edge.data.feedbackType + "\n"
                        nodeText += "Feedback Text: " + edge.data.feedbackText + "\n\n"
                    }
                    
                }
            }
            totalText += nodeText
        }
        navigator.clipboard.writeText(totalText).then(
            () => {
                setCopySuccess(true);
            }
        )
    }


    return (       
        <Grid item style={{width: "100%", height: "100%"}} className="wrapper" ref={reactFlowWrapper}>
        <ReactFlow         
            nodeTypes={nodeTypes}
            nodes={nodes}
            onNodesChange={onNodesChange}
            edges={edges}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onEdgeUpdate={onEdgeUpdate}
            onEdgeUpdateStart={onEdgeUpdateStart}
            onEdgeUpdateEnd={onEdgeUpdateEnd}
        >
            <Background />
            <Controls />
            <Panel position="top-right">
                <Tooltip title="User Guide" placement='top'>
                <IconButton color='primary' onClick={() => dispatch(setEditorScenarioUI({
                    "viewResources": true,
                    "viewPrimarySources": false,
                    "viewHints": true,
                    "viewDocuments": false,
                    "viewOutline": false,
                    }))}>
                    <HelpIcon />
                </IconButton>
                </Tooltip>
            </Panel>
            <Panel position="bottom-right">
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                icon={<SpeedDialIcon />}
                sx={{paddingBottom: "10px"}}
            >
            <SpeedDialAction
                icon={<AccountTreeIcon />}
                tooltipTitle={"New Scenario"}
                onClick={() => {dispatch(resetEditorData()); setSelectedIndex(0); setActiveStep(0)}}
            />
            <SpeedDialAction
                icon={<CopyAllIcon />}
                tooltipTitle={"Copy Scenario text"}
                onClick={() => copyTextToClipboard()}
            />
            </SpeedDial>
            </Panel>
            <Panel position="bottom-center">
                {(parsingErrors.inkErrors.length > 0 || parsingErrors.reflexErrors.length > 0) ?
                <ParsingErrors />
                :
                null
                }
            </Panel>
        </ReactFlow>
        <Grid item sx={{width: "100%"}}>
        <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{maxWidth: "500px"}}>
            <Grid item sx={{width: "100%"}}>
            {Object.keys(validationWarnings).map((key, idx) => (
            <Grid item key={"grid"+idx} sx={{width: "100%", paddingTop: "10px"}}>
            <Alert variant="filled" onClose={handleSaveClose} key={"alert"+idx} severity={validationWarnings[key]["severity"]} sx={{width: "100%"}}>
                {validationWarnings[key]["text"]}
            </Alert>
            </Grid>
            ))}
            <Grid item sx={{width: "100%", paddingTop: "10px"}}>
            <Alert variant="filled" onClose={handleSaveClose} severity="success" sx={{width: "100%"}}>
                Scenario saved.
            </Alert>
            </Grid>
            </Grid>
        </Snackbar>
        <Snackbar open={saveError} onClose={handleSaveErrorClose} sx={{maxWidth: "500px"}}>
            <Alert variant="filled" onClose={handleSaveErrorClose} severity="error" sx={{width: "100%"}}>
                Error: changes are not being saved.
            </Alert>
        </Snackbar>
        <Snackbar open={copySuccess} autoHideDuration={6000} onClose={handleCopyClose} sx={{maxWidth: "500px"}}>
            <Alert variant="filled" onClose={handleCopyClose} severity="info" sx={{width: "100%"}}>
                Scenario text copied to clipboard.
            </Alert>
        </Snackbar>
        </Grid>
        </Grid>
    )
};
export default EditorFlow;