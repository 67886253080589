import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";


export default function ProfileCard() {

const { isAuthenticated, user } = useAuth0();

return (
    <Grid container sx={{
        padding: "10px", 
        borderLeft: "1px solid #cccccc66", 
        borderTop: "1px solid #cccccc66", 
        boxShadow: "3px 3px 3px #cccccc", 
        borderRadius: "15px", 
        backgroundColor: "#ffffff", 
        alignContent: "center",
        height: "100%"
        }}>
        <Grid item sx={{width: "100%"}}>
        <Grid container sx={{padding: "10px"}}>
        {isAuthenticated ?
            <>
            <Grid item>
            <Avatar src={user.picture}></Avatar>
            </Grid>
            <Grid item sx={{paddingLeft: "10px"}}>
            <Typography variant="body1">{user.nickname}</Typography>
            <Typography variant="body2" color="textSecondary">{user.email}</Typography>
            </Grid>
            </>
            :
            <>
            <Grid item>
            <Avatar>GU</Avatar>
            </Grid>
            <Grid item sx={{paddingLeft: "10px"}}>
            <Typography variant="body1">Guest</Typography>
            <Typography variant="body2" color="textSecondary">Guest</Typography>
            </Grid>
            </>
        }
        </Grid>
        </Grid>
    </Grid>
);
}