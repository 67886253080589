import React from 'react';
import { useSelector } from 'react-redux'

import { Grid, Typography } from '@mui/material';

// JS documents

import FinAsAnalysis from './assets/FinAsAnalysis';
import ReductAnalysis from './assets/ReductAnalysis';
import PrivLegalAdviceAnalysis from './assets/PrivLegalAdviceAnalysis';
import PrivLitAnalysis from './assets/PrivLitAnalysis';

import { useParams } from "react-router-dom";

/*
introduce loadsource
need to pass loadsource to ra atom too, to point at right ink globals
*/

export default function ScenarioOutline(props) {

    let params = useParams();
    const scenarioID = params.scenarioID;
    const loadSource = props.loadSource;

    const scenarioList = useSelector((state) => state.appData.scenarioList)
    const scenarioEditorAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets)
    var scenarioAssets = {}

    if (loadSource === "editor") {
        scenarioAssets = {...scenarioEditorAssets}
    } else {
        scenarioAssets = {...scenarioList[scenarioID]["scenarioAssets"]}
    };

    const JSDocument = (outline) => {
        switch(outline) {
            case "FinAsAnalysis":
                return (<FinAsAnalysis />)
            case "ReductAnalysis":
                return (<ReductAnalysis />)
            case "PrivLegalAdviceAnalysis":
                return (<PrivLegalAdviceAnalysis />)
            case "PrivLitAnalysis":
                return (<PrivLitAnalysis />)
            default:
                return(null)
        }
    };

    return (
        <Grid item>
            {scenarioAssets.hasOwnProperty("outline") ?
                <>
                {scenarioAssets.outline.map((outline, idx) => (
                    <div key={"mapcontainer" + idx}>
                    {outline.type === "js" &&
                        <Grid key={"grid" + idx} item>
                        {JSDocument(outline.outlineID)}
                        </Grid>
                    }
                    </div>
                ))}
                </>
                :
                <Typography variant='body1' sx={{marginBottom: "20px"}}>No documents.</Typography>
            }
        </Grid>
    );
}