import { Grid, Button, Stack, Tooltip } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { setEditorScenarioUI } from '../editor/editorSlice';

import MarkUpEditor from './MarkUpEditor';
import MarkUpPlayer from './MarkUpPlayer';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MarkUpDocument = (props) => {

  const dispatch = useDispatch();
  const editorScenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets)
  const editorScenarioUI = useSelector((state) => state.editorData.editorScenarioUI);
  const editorPlayerDocument = useSelector((state) => state.editorData.playerDocument);
  const playerDocument = useSelector((state) => state.choices.playerDocument);
  const loadSource = props.loadSource

  const handleEdit = () => {
    dispatch(setEditorScenarioUI({
        "documentPlayMode": "edit"
    }));
  };

  return (
  <Grid item sx={{width: "100%"}}>
    <Grid item sx={{marginLeft: "20px", marginRight: "20px", paddingBottom: "10px"}}>
      {loadSource === "editor" ?
      <>
        {editorScenarioUI.documentPlayMode === "edit" ?
        <MarkUpEditor initialDocData={editorScenarioAssets.documents[0]["documentData"]} />
        :
        <>
        <MarkUpPlayer initialDocData={editorPlayerDocument} loadSource={loadSource} />
        <Grid container sx={{width: "100%", justifyContent: "end", marginTop: "8px"}}>
          <Stack direction="row">
              <Tooltip title="Open the document for editing" placement='top'>
              <Button size="small" variant='outlined' color="primary" onClick={() => {handleEdit()}} startIcon={<EditIcon fontSize='small' />}>
              Edit document
              </Button>
              </Tooltip>
          </Stack>
        </Grid>
        </>
        }      
      </>
      :
      <MarkUpPlayer initialDocData={playerDocument} loadSource={loadSource} />
      }
    </Grid>
  </Grid>
  )
  };
export default MarkUpDocument;