import { Grid, Typography, Link, Box, Stack, Tooltip} from '@mui/material';

import { Editable, withReact, Slate, useSelected } from 'slate-react'
import {
  createEditor,
} from 'slate'
import { useSelector } from 'react-redux';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GamesIcon from '@mui/icons-material/Games';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import React, { useCallback, useMemo } from 'react';

const TopicOverviewNotesReader = (props) => {

  const subTopicOverviewNotes = props.subTopicOverviewNotes;
  const currentSubTopic = props.currentSubTopic;
  const subTopicData = useSelector((state) => state.appData.subTopicList[currentSubTopic]);

  const skillLevel = subTopicData["skillLevel"] ? subTopicData["skillLevel"] : "foundations"
  const playTime = subTopicData["playTime"] ? subTopicData["playTime"] : 5
  const readTime = subTopicData["readTime"] ? subTopicData["readTime"] : 5

  const SkillChip = () => {
    switch (skillLevel) {
      case "foundations":
        return (
        <Tooltip title="Foundation level scenarios typically involve: (1) spotting and resolving straightforward issues or (2) identifing more complex issues and the factors that affect them.">
          <Box><Typography variant='body1' sx={{display: "inline"}}>Foundations</Typography><Typography color="textSecondary" sx={{display: "inline", verticalAlign: "super", fontSize: "12px"}}>(?)</Typography></Box>
        </Tooltip>
        )
      case "experienced":
        return (
        <Tooltip title="Experienced level scenarios typically involve: (1) identifing complex issues, evaluating the different ways they might be resolved and exercising judgement to come to a conclusion or (2) identifying factors affecting the wider strategic approach to an issue.">
          <Box><Typography variant='body1' sx={{display: "inline"}}>Experienced</Typography><Typography color="textSecondary" sx={{display: "inline", verticalAlign: "super", fontSize: "12px"}}>(?)</Typography></Box>
        </Tooltip>
        )
      case "expert":
        return (
        <Tooltip title="Exert level scenarios typically involve: (1) identifying and evaluating the most complex aspects of a topic or (2) exercising strategic judgement in your approach to problems.">
          <Box><Typography variant='body1' sx={{display: "inline"}}>Expert</Typography><Typography color="textSecondary" sx={{display: "inline", verticalAlign: "super", fontSize: "12px"}}>(?)</Typography></Box>
        </Tooltip>
        )
      default:
        return (
          <Tooltip title="Foundation level scenarios typically involve: (1) spotting and resolving straightforward issues or (2) identifing more complex issues and the factors that affect them.">
            <Box><Typography variant='body1' sx={{display: "inline"}}>Foundations</Typography><Typography color="textSecondary" sx={{display: "inline", verticalAlign: "super", fontSize: "12px"}}>(?)</Typography></Box>
          </Tooltip>
        )
    }
  }

  /*********************************************

  Slate editor

  *********************************************/

  const withInlines = editor => {
    // extend editor for inline link elements
    const { isInline, isVoid } = editor
  
    editor.isInline = element =>
      ['link'].includes(element.type) || isInline(element)

    editor.isVoid = element => (element.type === 'iframe' ? true : isVoid(element))
  
    return editor
  };

  // Put this at the start and end of an inline component to work around this Chromium bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
  const InlineChromiumBugfix = () => (
    <span
      contentEditable={false}
      style={{fontSize:"0px"}}
    >
      {String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  )

  const Element = (props) => {
    const { attributes, children, element } = props
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote {...attributes}>
            {children}
          </blockquote>
        )
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <Typography variant='h6' {...attributes} sx={{paddingBottom: "8px"}}>
            {children}
          </Typography>
        )
      case 'heading-two':
        return (
          <h2 {...attributes}>
            {children}
          </h2>
        )
      case 'list-item':
        return (
            <Typography variant='body1' {...attributes} component="li">
              {children}
            </Typography>
        )
      case 'numbered-list':
        return (
          <ol {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ol>
        )
      case 'link':
        return (
          <EditorLinkComponent element={element} {...attributes}>
            {children}
          </EditorLinkComponent>
        )
      case 'iframe':
        return (
            <IFrameElement {...props} />
        )
      default:
        return (
          <Typography paragraph variant='body1' {...attributes}>
            {children}
          </Typography>
        )
    }
  };

  const EditorLinkComponent = React.forwardRef((props, ref) => {
    // Not really sure why this needs forwardRef tbh.
    const { attributes, children, element } = props
    const selected = useSelected()
    return (
      <Link {...attributes} href={element.url} target="_blank" rel="noopener" underline="hover" sx={{boxShadow: selected ? "0 0 0 2px #d6dee1": null}}>
        <InlineChromiumBugfix />
          {children}
        <InlineChromiumBugfix />
      </Link>
    )
  });

  const IFrameElement = ({ attributes, children, element }) => {
    const { url } = element
    return (
      <div {...attributes}>
        <div contentEditable={false}>
          <div
            style={{
              padding: '75% 0 0 0',
              position: 'relative',
            }}
          >
            <iframe
              src={url}
              title="Video Player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: '0px'
              }}
            />
          </div>
        </div>
        {children}
      </div>
    )
  };

  const BugFixText = props => {
    const { attributes, children, leaf } = props
    return (
      <span
        // The following is a workaround for a Chromium bug where,
        // if you have an inline at the end of a block,
        // clicking the end of a block puts the cursor inside the inline
        // instead of inside the final {text: ''} node
        // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
        style={{
          paddingLeft: leaf.text === '' ? "0.1px" : null
        }}
        {...attributes}
      >
        {children}
      </span>
    )
  };

  const renderElement = useCallback(props => <Element {...props} />, [])
  const editor = useMemo(() => withInlines(withReact(createEditor())), [])

  return (
  <Grid item sx={{width: "100%"}}>
    <Stack direction="column" spacing={2} sx={{marginBottom: "20px", marginTop: "16px", backgroundColor: "#fafafa", padding: "10px", borderRadius: "6px"}}>
      <Grid container>
        <Box sx={{width: "175px", display: "flex"}}>
        <ClearAllIcon color="primary" />
        <Typography sx={{marginLeft: "6px"}}>Skill Level:</Typography>
        </Box>
        <SkillChip />
      </Grid>
      <Grid container>
        <Box sx={{width: "175px", display: "flex"}}>
        <AccessTimeIcon color='primary' />
        <Typography sx={{marginLeft: "6px"}}>Resources:</Typography>
        </Box>
        <Typography>{readTime} minutes</Typography>
      </Grid>
      <Grid container>
        <Box sx={{width: "175px", display: "flex"}}>
        <GamesIcon color='primary' />
        <Typography sx={{marginLeft: "6px"}}>Play Time:</Typography>
        </Box>
        <Typography>{playTime} minutes</Typography>
      </Grid>
    </Stack>
    <Grid item sx={{paddingLeft: "10px"}}>
      <Slate editor={editor} initialValue={subTopicOverviewNotes}>
      <Editable
          readOnly
          renderElement={renderElement}
          renderLeaf={props => <BugFixText {...props} />}
      />
      </Slate>
    </Grid>
  </Grid>
  )
  };
export default TopicOverviewNotesReader;