import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

import FaceIcon from '@mui/icons-material/Face';

const SpeechYour = (props) => {

  const stripTags = props.stripTags
  const delay = props.delay
  const defaultStyles = props.defaultStyles
  const yourStyles = props.yourStyles

  return (
    <Grid container 
    sx={{
      flexGrow: 1,
      justifyContent: 'flex-end'
    }}
    component={motion.div} 
    initial={{opacity: 0,}}
    animate={{opacity: 1,
              transition: {                
                  delay: delay,
                  ease: "easeInOut",
                  duration: 0.5,
              }}}
    >
    <Grid item sx={{
      border: yourStyles.border,
      padding: defaultStyles.padding,
      marginBottom: defaultStyles.marginBottom,
      backgroundColor: yourStyles.backgroundColor,
      borderTopLeftRadius: yourStyles.borderTopLeftRadius,
      borderBottomLeftRadius: yourStyles.borderBottomLeftRadius,
      borderBottomRightRadius: yourStyles.borderBottomRightRadius,
      boxShadow: yourStyles.boxShadow,
      maxWidth: "80%",
      filter: defaultStyles.saturation,
    }}>
    <Typography variant={defaultStyles.typographyVariant}  align="left">{stripTags}</Typography>
    </Grid>
    
    </Grid>
  )
};
export default SpeechYour;

/*
style={{color: yourStyles.fontColor}}
<Grid item>
    <FaceIcon fontSize='large' sx={{marginLeft: "5px", backgroundColor: yourStyles.fontColor, borderRadius: "5px", color: yourStyles.backgroundColor, filter: defaultStyles.saturation}} />
    </Grid>

*/