import { useState } from 'react';
import { Handle, Position, useNodeId } from 'reactflow';

import TitleKnot from './TitleKnot';

const TitleNode = ({ data }) => {

  const nodeId = useNodeId();

  return (
    <>
      <TitleKnot data={data} nodeId={nodeId} />
      <Handle 
        type="source" 
        position={Position.Bottom} 
        id="a" 
        isConnectable={false}
        style={{
          width: 30,
          height: 14,
          borderRadius: 3,
          border: "2px dotted #424242",
          backgroundColor: "#ffffff",
        }}
      />
    </>
  );
};
export default TitleNode;