import React, { useState } from 'react';
import { useSelector } from 'react-redux'

import { Grid, ListItemButton, Box, AppBar, Toolbar, IconButton, Button, MenuItem, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GamesIcon from '@mui/icons-material/Games';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoWhiteBook from '../LogoWhiteBook';
import SearchBar from './SearchBar';

import { indigo } from '@mui/material/colors';
import { motion } from "framer-motion";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";

const Banner = () => {

    const theme = useTheme();
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
    const [menuVisible, setMenuVisible] = useState(false);
    const userRoles = useSelector((store) => store.appData.userData.userRoles); 

    const fullView = useMediaQuery(theme.breakpoints.up('md'))

    var navigate = useNavigate();
    
    const goLearn = () => {
      navigate("/learn")
    };
    const goExplore = () => {
      navigate("/explore")
    };
    const goCreate = () => {
      navigate("/editor")
    };
    const goAdmin = () => {
      navigate("/admin")
    };

    return (
      <AppBar position="static" sx={{backgroundColor: indigo[500], boxShadow: "0px 0px 0px 0px"}}>
          <Toolbar>
            <Grid container sx={{alignItems: "center"}}>
            {fullView ?
            <>
            <Grid item onClick={() => goLearn()} component={motion.div} whileHover={{cursor: "pointer"}} 
              sx={{
                width: "40px",
                height: "40px",
              }}>
            <LogoWhiteBook />
            </Grid>
            <Grid item>
            <Button color="inherit" onClick={() => goLearn()}>Learn</Button>
            </Grid>
            {userRoles.includes("read:editor") ?
            <>
            <Grid item>
            <Button color="inherit" onClick={() => goExplore()}>Explore</Button>
            </Grid>
            <Grid item>
            <Button color="inherit" onClick={() => goCreate()}>Create</Button>
            </Grid>
            </>
            :
            <Grid item>
            <Button color="inherit" onClick={() => goExplore()}>Explore</Button>
            </Grid>
            }
            {userRoles.includes("read:adminPanel") ?
            <>
            <Grid item>
            <Button color="inherit" onClick={() => goAdmin()}>Admin</Button>
            </Grid>
            </>
            :
            <>
            </>
            }
            <Grid item sx={{flexGrow: 1}}>
              <SearchBar />
            </Grid>
            <Grid item>
            {isAuthenticated ?
            <Button color="inherit" onClick={() => logout()}>Logout</Button>
             :
             <Button color="inherit" onClick={() => loginWithRedirect()}>Login / Sign up</Button>
            }
            </Grid>
            </>
            :
            <>
            <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={()=>setMenuVisible(true)}
              size="large">
              <MenuIcon />
            </IconButton>
            </Grid>
            <Drawer open={menuVisible} anchor="left" onClose={()=>{setMenuVisible(false)}}>
              <Box
              sx={{width: "250px"}}
              role="presentation"
              onClick={()=>{setMenuVisible(false)}}
              onKeyDown={()=>{setMenuVisible(false)}}
              >
              <List>
                <ListItem>
                  <ListItemButton onClick={()=>goLearn()}>
                    <ListItemIcon><GamesIcon /></ListItemIcon>
                    <ListItemText primary="Learn" />
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={()=>goExplore()}>
                  <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                  <ListItemText primary="Explore" />
                  </ListItemButton>
                </ListItem>
                {isAuthenticated ?
                <ListItem>
                  <ListItemButton onClick={()=>logout()}>
                  <ListItemIcon><LogoutIcon /></ListItemIcon>
                  <ListItemText primary="Logout" />
                  </ListItemButton>
                </ListItem>
                :
                null
                }
              </List>
              </Box>
            </Drawer>
            <Grid item sx={{flexGrow: 1}}>
              <SearchBar />
            </Grid>
            </>
            }
            </Grid>
          </Toolbar>
        </AppBar>
    );
};
export default Banner;