import React from 'react';
import RADocument from '../rule_analysis/RADocument';
import RAAtom from '../rule_analysis/RAAtom';
import RAConjunction from '../rule_analysis/RAConjunction';
import { Typography, Grid } from '@mui/material';
import { motion } from "framer-motion"

const FinAsAnalysis = () => {

    return (
    <RADocument title="Financial Assistance: Elements of the prohibition under s.678 CA">
        <Grid item sx={{
            width: "100%"    
        }}
        component={motion.div}
        initial={{
            backgroundColor: "#FFFF00",
            transition: {
                duration: 1.5,
                ease: "easeInOut",
            }
        }}
        animate={{
            backgroundColor: "#fff",
            transition: {
                duration: 1.5,
                ease: "easeInOut",
            }
        }}
        >
        <Typography variant="body2" align='center' paragraph>This outline will be updated to reflect your analysis as you progress.</Typography>
        </Grid>
        <RAAtom atomRef="1." atomID="firstRule">
            <Typography variant="body2">Acquisition of shares in a public company.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="2." atomID="secondRule">
        <Typography variant="body2" paragraph>Provision of financial assistance:</Typography>
            <RAAtom atomRef="a." atomID="twoA">
                <Typography variant="body2">Gift</Typography>
            </RAAtom>
            <RAConjunction content="or" />
            <RAAtom atomRef="b." atomID="twoB">
                <Typography variant="body2">Guarantee, security or indemnity (other than for indemnifier's own negligence or default) or by way of release or waiver</Typography>
            </RAAtom>
            <RAConjunction content="or" />
            <RAAtom atomRef="c." atomID="twoC">
                <Typography variant="body2">Loan, or quasi-loan, or assignment or novation of a loan or quasi-loan</Typography>
            </RAAtom>
            <RAConjunction content="or" />
            <RAAtom atomRef="d." atomID="twoD">
                <Typography variant="body2">Any other financial assistance which materially reduces giver's net assets or by company that has no net assets</Typography>
            </RAAtom>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="3." atomID="thirdRule">
            <Typography variant="body2">By the public company (or any of its UK subsidiaries).</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="4." atomID="fourthRule">
            <Typography variant="body2">For the purpose of the acquisition.</Typography>
        </RAAtom>
    </RADocument>
    )
};

export default FinAsAnalysis;