import React from 'react';

import { useNodeId } from 'reactflow';

import { Grid, FormControl, Select, Typography, Stack, MenuItem } from '@mui/material';

import { setNodeAI } from '../editorSlice';
import { useDispatch, useSelector } from 'react-redux';

import AIExplanation from './AIExplanation';
import AIQuestions from './AIQuestions';
import AIIssueSpot from './AIIssueSpot';

const AINodeContainer = (props) => {
    
    // This should hold the type selection
    // TODO sort out all the ink parsing.
    
    const nodes = useSelector((store) => store.editorData.nodes)
    const edges = useSelector((store) => store.editorData.edges)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]

    const nodeAI = nodeData.hasOwnProperty("nodeAI") ? nodeData.nodeAI : {}
    const setChangesMade = props.setChangesMade;
    const nodeOptions = edges.filter((edge) => edge.source === nodeID);

    // Set UI warnings for required fields.

    const emptyInputs = nodeAI.type === "issuespot" ? 
    Object.values(nodeAI.issues)
    .some(item => item.issueName === "" || item.issueDescription === "") : 
    Object.keys(nodeAI.promptInputs)
        .some(key => {
            // Ignoring optional keys
            if (key === "evaluationInstructions" || key === "feedbackExplanation") {
              return false;
            }
            // Check if the value is effectively empty (including strings that contain only whitespace)
            const value = nodeAI.promptInputs[key].trim();
            return value === "";
          })

    const dispatch = useDispatch();

    const handleInteractionTypeSelect = (event) => {
        const interactionType = event.target.value
        let updatedAI = {...nodeAI}
        
        // Now, update the type property and reset data
        updatedAI["type"] = interactionType;
        if (interactionType === "explanation") {
            updatedAI["promptInputs"] = {
                topic: "",
                userAnalysis: "",
                correctAnalysis: "",
                correctExplanation: "",
                evaluationInstructions: "",
                feedbackExplanation: "",
                agentKnowledge: "knows_the_answer",
                agentAttitude: "wants_to_help"
            }
        }
        if (interactionType === "questions") {
            updatedAI["promptInputs"] = {
                topic: "",
                topicBackground: "",
                exampleQuestions: "",
                evaluationInstructions: ""
            }
        }
        if (interactionType === "issuespot") {
            updatedAI["issues"] = {}
        }

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        dispatch(setNodeAI(AIUpdate))
        setChangesMade(true)
    };

    return (
        <Grid item>
        <Grid item sx={{paddingBottom: "3px"}}>
            <Typography variant='body2' color="textSecondary" sx={{display: "inline"}}>Set dynamic inputs</Typography>
            {(emptyInputs) ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: Empty inputs</Typography>: null}
            {nodeOptions.length === 0 ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: No connection</Typography>: null}
        </Grid>
        <Stack direction="row" spacing={1} sx={{marginBottom: "8px"}}>
            <Typography>Interaction type:</Typography>
            <FormControl variant="standard">
            <Select
            id="action-type-select"
            size='small'
            value={nodeAI.type}
            label="Action type"
            onChange={handleInteractionTypeSelect}
            >
                <MenuItem value="explanation">Provide an explanation</MenuItem>
                <MenuItem value="questions">Suggest questions</MenuItem>
                <MenuItem value="issuespot">Spot issues</MenuItem>
            </Select>
            </FormControl>
        </Stack>
        {nodeAI.type === "explanation" ?
        <Grid item sx={{backgroundColor: "#fafafa", padding: "10px", borderRadius: "3px", marginBottom: "15px"}}>
        <AIExplanation 
            setChangesMade={setChangesMade}
        />
        </Grid>
        :
        null
    }
        {nodeAI.type === "questions" ?
        <Grid item sx={{backgroundColor: "#fafafa", padding: "10px", borderRadius: "3px", marginBottom: "15px"}}>
        <AIQuestions
            setChangesMade={setChangesMade}
            />
        </Grid>
        :
        null
        }
        {nodeAI.type === "issuespot" ?
        <AIIssueSpot
            setChangesMade={setChangesMade}
        />
        :
        null
        }
        </Grid>
    )
};
export default AINodeContainer;

/*

Will be like

data
    nodeAI
        type: "string"
        promptInputs:
            x: ""
            y: ""
            z: ""



            <>
            <Grid item sx={{paddingBottom: "3px"}}>
                <Typography variant='body2' color="textSecondary" sx={{display: "inline"}}>Set AI inputs</Typography>{blockWarning["noPromptInputs"] ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: Empty prompt inputs</Typography> : null}{blockWarning["noAIBlockOptions"] ? <Typography variant='body2' color="secondary" sx={{display: "inline"}}> - Warning: Loose end. Connect to another block.</Typography> : null}
            </Grid>
            <Grid item sx={{backgroundColor: "#fafafa", padding: "10px", borderRadius: "3px", marginBottom: "15px"}}>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="topic"
                    label="Topic"
                    value={nodePromptInputs.topic}
                    placeholder="E.g. limb two of the test for legal advice privilege."
                    onChange={handlePromptInputChange}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="userAnalysis"
                    label="User analysis"
                    value={nodePromptInputs.userAnalysis}
                    placeholder='E.g. a communication sent by a trainee will not be privileged.'
                    onChange={handlePromptInputChange}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="correctAnalysis"
                    label="Correct analysis"
                    value={nodePromptInputs.correctAnalysis}
                    placeholder='E.g. a communication sent by a trainee may still attract privilege.'
                    onChange={handlePromptInputChange}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="correctExplanation"
                    label="Correct explanation"
                    value={nodePromptInputs.correctExplanation}
                    placeholder='E.g. with regard to limb two of the test, a communication sent by a trainee may still attract privilege because, for these purposes, a trainee working under the supervision of a lawyer will be a "lawyer".'
                    onChange={handlePromptInputChange}
                    multiline
                    rows={5}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: 10,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
            </Grid>
            </Grid>
            </>


    const handlePromptInputChange = (event) => {
        setNodePromptInputs({
            ...nodePromptInputs,
            [event.target.id]: event.target.value
        });
        setChangesMade(true);
    }

*/