import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import  store  from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import '@fontsource/roboto';
import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
import BasicPage from './pages/BasicPage';
import EditorBase from './pages/EditorBase';
import Landing from './pages/Landing';
import Play from './pages/Play';
import Learn from './pages/Learn';
import ErrorPage from './pages/ErrorPage'
import Editor from './pages/Editor';
import LoginRedirect from './pages/LoginRedirect';
import Explore from './pages/Explore';
import TopicDetail from './features/TopicDetail';
import SubTopicDetail from './features/SubTopicDetail';
import Admin from './pages/Admin';

/*

Continue testing. Don't think data loaders are straightforward here. But nesting and error components good.
Explore redirects and defaults.

*/

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: 
        <Landing />,
      },
      {
        path: "learn",
        element:
        <BasicPage footer={true} wide={true} />,
        children: [
          {
            index: true,
            element:
            <Learn />
          },
        ]
      },
      {
        path: "explore",        
        element: <BasicPage footer={true} wide={true} />,
        children: [
          {
            index: true,
            element:
            <Explore />
          },
          {
            path: ":practiceAreaID",
            element:
            <Explore />,
            children: [
            {
              path: ":topicID",
              element: <TopicDetail />,
              children: [
                {
                  path: ":subTopicID",
                  element: <SubTopicDetail />,
                  children: [
                    {
                      path: ":wikiSection",
                      element:
                      <SubTopicDetail />,
                    }
                  ]
                }
              ]
            }
            ]
          }
        ]
      },
      {
        path: "play",        
        element: <BasicPage footer={false} wide={true} />,
        children: [
          {
            path: ":scenarioID",
            element:
            <Play />
          }
        ]
      },
      {
        path: "editor",    
        element: <EditorBase footer={false} />,
        children: [
          {
            index: true,
            element:
            <Editor />
          },
          {
            path: ":editorScenarioID",
            element:
            <Editor />,
          }

        ]
      },
      {
        path: "admin",    
        element: <EditorBase footer={false} />,
        children: [
          {
            index: true,
            element:
            <Admin />
          },
        ]
      },
      {
        path: "login",
        element:
        <LoginRedirect />
      }
    ],
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    <Auth0Provider
    domain="legalreflex.eu.auth0.com"
    clientId="8wKNe8OydTWS1RfakI4FgopPMuxhyspW"
    redirectUri={window.location.origin+"/learn"}
    useRefreshTokens={true}
    audience="https://www.legalreflex.com/api"
    cacheLocation="localstorage"
    >
      <RouterProvider router={router} />
      <CssBaseline />
    </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();