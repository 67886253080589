import React from 'react';
import { Grid, Paper, Button, Divider, Chip, Tooltip, Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useNavigate } from "react-router-dom";

import { toggleScenarioDetails } from '../features/appData/appDataSlice';
import { useDispatch } from 'react-redux';

import { useTheme } from '@mui/material/styles';
  
  const ScenarioDetails = React.forwardRef((props, ref) => {

    const theme = useTheme();
    const title = props.title;
    const shortDescription = props.shortDescription
    const playTime = props.playTime;
    const playTimeDescription = "People typically spend "+ playTime + " minutes on this scenario"
    const playTimeLabel = playTime < 5 ? "Short" : playTime < 10 ? "Moderate" : "Extended"
    const difficulty = props.difficulty;
    const longDescription = props.longDescription;
    const scenarioID = props.scenarioID;

    var navigate = useNavigate();
    const dispatch = useDispatch();

    const goPlay = () => {
      navigate(`/play/${scenarioID}`);
    };    

    return (
      <Grid container ref={ref} tabIndex={-1} sx={{
        maxWidth: "800px",
        [theme.breakpoints.down('md')]: {
          maxWidth: "calc(100vw - 20px)"
        },
        position: "absolute", 
        top: '30%', 
        left: '50%', 
        transform: 'translate(-50%, -30%)',
        outline: 0,
        overflow: "auto",
        '&::-webkit-scrollbar': {
          width: "20px",
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: "#ffffff",
          borderTopRightRadius: "10px",
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "#d6dee1",
          borderRadius: "20px",
          border: "6px solid transparent",
          backgroundClip: "content-box",
        },
        '&::-webkit-scrollbar-thumb-hover': {
          backgroundColor: "#a8bbbf",
        },
      }}>
            <Paper sx={{
              margin: 'auto',
              elevation: 3,
              maxWidth: "800px",
              paddingTop: "30px",
              paddingBottom: "20px",
              paddingLeft: "25px",
              paddingRight: "25px",
            }}>
              <Grid container>
              <Grid item sx={{minWidth: "100%", marginTop: "20px", marginBottom: "20px"}}>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="h6">{shortDescription}</Typography>
              </Grid>
              <Grid item xs={12} sx={{display: "flex", marginBottom: "10px"}}>
                <Grid container stlye={{width: "100%"}}>
                <Grid item sx={{minWidth: "50%", marginBottom: "3px"}}>
                  <Grid container style={{alignItems: "center"}}>
                    <Grid item style={{marginRight: 5}}>
                    <Typography style={{display: "inline"}}>Skill level</Typography><Typography color="textSecondary" style={{display: "inline", verticalAlign: "super", fontSize: "12px"}}>(?)</Typography>
                    </Grid>
                    <Tooltip title="The complexity of the scenario">
                    <Grid item>
                        {difficulty === "Foundations" ? 
                          <Box sx={{backgroundColor: "#aed581", borderRadius: "18px", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", paddingBottom: "6px"}}>
                            <Typography variant='body2'>Foundations</Typography>
                          </Box>
                          :
                          null
                        }
                        {difficulty === "Experienced" ? 
                          <Box sx={{backgroundColor: "#4dd0e1", borderRadius: "18px", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", paddingBottom: "6px"}}>
                            <Typography variant='body2'>Experienced</Typography>
                          </Box>
                          :
                          null
                        }
                        {difficulty === "Expert" ? 
                          <Box sx={{backgroundColor: "#ba68c8", borderRadius: "18px", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", paddingBottom: "6px"}}>
                            <Typography variant='body2' sx={{color: "#fafafa"}}>Expert</Typography>
                          </Box>
                          :
                          null
                        }
                    </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item sx={{minWidth: "50%", marginBottom: "3px"}}>
                  <Grid container style={{alignItems: "center"}}>
                    <Grid item style={{marginRight: 5}}>
                    <Typography style={{display: "inline"}}>Scenario length</Typography><Typography color="textSecondary" style={{display: "inline", verticalAlign: "super", fontSize: "12px"}}>(?)</Typography>
                    </Grid>
                    <Grid item>
                    <Tooltip title={playTimeDescription}>
                      <Box sx={{backgroundColor: "#f06292", borderRadius: "18px", paddingLeft: "12px", paddingRight: "12px", paddingTop: "6px", paddingBottom: "6px"}}>
                        <Typography variant='body2' sx={{color: "#fafafa"}}>{playTimeLabel}: {playTime} minutes</Typography>
                      </Box>
                    </Tooltip>
                    </Grid>
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
              <Typography variant="body1">{longDescription}</Typography>
              </Grid>
              <Grid container sx={{justifyContent: 'center', marginTop: "16px", marginBottom: "16px"}}>
              <Grid item sx={{minWidth: "100%"}}>
                <Divider variant="middle" />
              </Grid>
              </Grid>
              <Grid container sx={{justifyContent: "center"}}>
                <Grid item sx={{display: "flex", margin: "10px"}}>
                  <Button variant="outlined" color="indigo" style={{marginRight: 5}} onClick={() => dispatch(toggleScenarioDetails())}>Back</Button>
                  <Button variant="contained" color="indigo" style={{marginLeft: 5}} onClick={() => {goPlay(); dispatch(toggleScenarioDetails())}}>Continue</Button>
                </Grid>
              </Grid>
              </Grid>
            </Paper>
            
      </Grid>
    )
    });
  export default ScenarioDetails;