import React from "react";

function Corporate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      className="iconify iconify--emojione"
      preserveAspectRatio="xMidYMid meet"
      version="1.1"
      viewBox="0 0 100 100"
    >
      <path
        fill="#3e4347"
        strokeWidth="1.667"
        d="M40 6.667h33.333v80H40z"
      ></path>
      <path
        fill="#dae3ea"
        strokeWidth="1.667"
        d="M23.333 0h53.334v6.667H23.333z"
      ></path>
      <path
        fill="#3e4347"
        strokeWidth="1.667"
        d="M3.333 6.667H20v76.666H3.333z"
      ></path>
      <path
        fill="#b2c1c0"
        strokeWidth="1.667"
        d="M40 83.333h46.667v13.334H40z"
      ></path>
      <g fill="#b4d7ee" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M30 52h4v8h-4z"></path>
        <path d="M38 52h4v8h-4z"></path>
        <path d="M46 52h4v8h-4z"></path>
        <path d="M46 42h10v4H46z"></path>
        <path d="M46 32h10v4H46z"></path>
        <path d="M46 22h10v4H46z"></path>
        <path d="M46 12h10v4H46z"></path>
        <path d="M38 42h4v4h-4z"></path>
        <path d="M38 32h4v4h-4z"></path>
        <path d="M38 22h4v4h-4z"></path>
        <path d="M38 12h4v4h-4z"></path>
        <path d="M30 42h4v4h-4z"></path>
        <path d="M30 32h4v4h-4z"></path>
        <path d="M30 22h4v4h-4z"></path>
        <path d="M30 12h4v4h-4z"></path>
      </g>
      <g fill="#3e4347" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M26 46h32v6H26z"></path>
        <path d="M26 36h32v6H26z"></path>
        <path d="M26 26h32v6H26z"></path>
        <path d="M26 16h32v6H26z"></path>
        <path d="M26 6h32v6H26z"></path>
      </g>
      <g fill="#b4d7ee" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M14 8h12v44H14z"></path>
        <path d="M4 52h22v10H4z"></path>
      </g>
      <path fill="#dae3ea" strokeWidth="1.667" d="M0 80h40v3.333H0z"></path>
      <g fill="#d6eef0" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M14 38h12v2H14z"></path>
        <path d="M14 28h12v2H14z"></path>
        <path d="M14 18h12v2H14z"></path>
        <path d="M14 10h12v2H14z"></path>
      </g>
      <path fill="#f5f5f5" strokeWidth="1.667" d="M20 6.667h20V10H20z"></path>
      <path
        fill="#b2c1c0"
        strokeWidth="1.667"
        d="M3.333 83.333h3.334V100H3.333z"
      ></path>
      <g fill="#f5f5f5" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M10 52h1.3v10H10z"></path>
        <path d="M10 52h12v1.1H10z"></path>
        <path d="M10 60.9h12V62H10z"></path>
        <path d="M15.3 52h1.3v10h-1.3z"></path>
        <path d="M20.7 52H22v10h-1.3z"></path>
      </g>
      <path
        fill="#83bf4f"
        strokeWidth="1.667"
        d="M40 93.333h60v3.334H40z"
      ></path>
      <path
        fill="#dae3ea"
        strokeWidth="1.667"
        d="M36.667 96.667H100V100H36.667z"
      ></path>
      <path
        fill="#83bf4f"
        strokeWidth="1.667"
        d="M98.333 93c.334-.5.5-1.167.5-2 0-2.167-1.666-3.833-3.833-3.833h-.167v-.5c0-1-.833-2-2-2-.5 0-.833.166-1.166.5-.167-1.334-1.5-2.334-2.834-2.334-1.666 0-2.833 1.334-2.833 3v.667c-.5-.333-1.167-.667-1.833-.667C83 85.833 82 86.5 81.5 87.5c-.333-.167-.833-.167-1.333-.167-2.167 0-3.834 1.667-3.834 3.834 0 .833.334 1.666.834 2.333a2.76 2.76 0 00-.834 2c0 1.667 1.334 3 2.834 3H94.5c.5.5 1.333.833 2 .833 1.833 0 3.333-1.5 3.333-3.333.167-1.333-.5-2.333-1.5-3"
      ></path>
      <g fill="#699635" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M51.7 59.6c0 .6.5-.3 1.2-.3.6 0 1.2.9 1.2.3 0-.6-.5-1.2-1.2-1.2s-1.2.6-1.2 1.2"></path>
        <path d="M55.1 57.6c0 .3.3 0 .6 0s.6.3.6 0-.3-.6-.6-.6-.6.2-.6.6"></path>
        <path d="M59.4 59.7c0 .3.3 0 .6 0s.6.3.6 0-.3-.6-.6-.6c-.3.1-.6.3-.6.6"></path>
      </g>
      <g fill="#b5f478" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M56.9 56c0 .3.3-.1.6-.1s.6.4.6.1c0-.3-.3-.6-.6-.6s-.6.2-.6.6"></path>
        <path d="M58.9 57.7c0 .3.3-.1.6-.1s.6.4.6.1c0-.3-.3-.6-.6-.6-.4 0-.6.2-.6.6"></path>
      </g>
      <g fill="#699635" transform="matrix(1.66667 0 0 1.66667 -3.333 -3.333)">
        <path d="M49.2 56.6c0 .7.5-.4 1.2-.4s1.2 1 1.2.4-.5-1.2-1.2-1.2-1.2.5-1.2 1.2"></path>
        <path d="M49.1 59.3c0 .3.3 0 .6 0s.6.3.6 0-.3-.6-.6-.6c-.3.1-.6.3-.6.6"></path>
      </g>
      <path
        fill="#b5f478"
        strokeWidth="1.667"
        d="M87.167 87.833c0 .667.5-.333 1.333-.333.667 0 1.333 1.167 1.333.333 0-.666-.5-1.333-1.333-1.333-.667 0-1.333.5-1.333 1.333"
      ></path>
      <path
        fill="#699635"
        strokeWidth="1.667"
        d="M90.833 96.5c0 .667.5-.333 1.334-.333.666 0 1.333 1.166 1.333.333 0-.667-.5-1.333-1.333-1.333-.834 0-1.334.5-1.334 1.333"
      ></path>
    </svg>
  );
}

export default Corporate;