import React from "react";

function CapitalMarkets() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 100 100"
    >
      <rect
        width="100"
        height="100"
        x="0"
        y="0"
        fill="#fff"
        strokeWidth="1.667"
        rx="16.667"
      ></rect>
      <path
        fill="#8d9cf4"
        strokeWidth="1.667"
        d="M56.667 89.167h-.045a2.5 2.5 0 01-2.367-1.834L36.772 23.222 25.758 67.273a2.5 2.5 0 01-4.746.322L14.973 52.5h-1.64a2.5 2.5 0 010-5h3.334a2.5 2.5 0 012.321 1.572l3.795 9.488 11.459-45.833a2.5 2.5 0 012.398-1.894h.027a2.5 2.5 0 012.411 1.842l17.755 65.088L70.95 32.597a2.5 2.5 0 014.708-.184L81.693 47.5h4.974a2.5 2.5 0 010 5H80a2.5 2.5 0 01-2.322-1.572l-4.055-10.14-14.57 46.624a2.5 2.5 0 01-2.386 1.755z"
      ></path>
      <path
        fill="#7bcdd1"
        strokeWidth="1.667"
        d="M56.667 89.167a2.5 2.5 0 01-2.41-1.834L34.257 14a2.5 2.5 0 114.823-1.317l20 73.334a2.5 2.5 0 01-2.413 3.15z"
      ></path>
      <path
        fill="#f2c4bb"
        strokeWidth="1.667"
        d="M73.333 89.167a2.5 2.5 0 01-2.5-2.5V13.333a2.5 2.5 0 015 0v73.334a2.5 2.5 0 01-2.5 2.5z"
      ></path>
      <circle
        cx="73.333"
        cy="33.333"
        r="6.667"
        fill="#f2c4bb"
        strokeWidth="1.667"
      ></circle>
      <path
        fill="#f28080"
        strokeWidth="1.667"
        d="M73.333 42.5a9.167 9.167 0 119.167-9.167 9.178 9.178 0 01-9.167 9.167zm0-13.333a4.167 4.167 0 104.167 4.166 4.167 4.167 0 00-4.167-4.166z"
      ></path>
    </svg>
  );
}

export default CapitalMarkets;