import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";

import EditorWizard from '../features/editor/EditorWizard';
import EditorStory from '../features/editor/EditorStory';
import EditorComplete from '../features/editor/EditorComplete';
import EditorFlow from '../features/editor/EditorFlow';
import EditorSideBar from '../features/editor/EditorSideBar';
import ScenarioProfile from '../features/editor/ScenarioProfile';
import ScenarioDocuments from '../features/editor/ScenarioDocuments';
import ScenarioPrimarySources from '../features/editor/ScenarioPrimarySources';
import ScenarioPublish from '../features/editor/ScenarioPublish';
import LoadScenario from '../features/editor/LoadScenario';
import BuildLoading from '../features/editor/BuildLoading';

import { loadSavedScenario, loadScenarioIntoInk, updateScenarioProfile, setEditorScenarioJSONDocumentData, setEditorPlayerDocumentData } from '../features/editor/editorSlice';

import { Grid, Typography, CircularProgress } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { ReactFlowProvider } from 'reactflow';
import { ResizableBox } from 'react-resizable';
import useMeasure from 'react-use-measure'
import "react-resizable/css/styles.css";

import { useNavigate, useParams } from "react-router-dom";

/*
EditorSideBar is the navigation component, control what's displayed on the left of the screen.
Pass setSelectedIndex to components that need to control this too. E.g. EditorStory where you can click "add documents" etc. if there are none associated with the scenario

The data flow for the editor is quite complex.

Saved scenario for part of the general scenario_meta list. They are identified by having type "unpublished" and do not show up in the main player 
(by virtue of being excluded from the skills tree as built in index).

TODO: consider permissioning saved scenarios so you can only see what you've created.

When you load a scenario, its meta data is read into editorData scenarioProfile. Changes to meta data sync to this but not the entry on the scenario list. This will only
be refreshed on save / reload.

Generally, saving is an active step. You have to press "save" for your changes to take effect.
Hitting save in the EditorFlow saves everything (flow and meta data) and includes validation to flag empty meta data fields
But hitting save in setting, primary sources, documents only save meta data

TODO: to consider whether this is right; it might be confusing if you'd made changes in the editor, then settings. You might expect your editor changes to be saved too

Saving in the flow also loads the scenario into ink (so it's playable right awway)
Alternatively, the refresh button in the story will also load the scenario into ink. The refresh button also refreshes scenario assets (and loads them for the first time)

TODO: consider whether loading scenario should pull primary source data

General approach to scenario assets: scenario meta should have information to point to scenario assets (e.g. primary sources, documents)
But they should hold copies of those assets in the DB (duplicative)
Instead, when you load a scenario, the player should also pull the asset data which can then be cached locally

Primary sources:
Editor Base loads a list of primary sources; this is so you can select from them in the editor
TODO: evolve this approach

Saveing changes to primary sources pulls relevant XML. Or otherwise the refresh button will fetch xml data if it is missing
Primary sources are rendered using the main primary source component, with polyfils to accomodate loading from editorData

Documents:
Single pdfs can be uploaded. They are stored as binary documents in a separate collection. When loading, they are held in react state not redux (because you can't store
uint8arrays in redux.)
Pdfs are rendered using the main pdf component, with polyfils to accomodate loading from DB

TODO: allow upload of other document types, allow upload of more than one document type

*/

const Editor = () => {

  const ending = useSelector((state) => state.editorData.ending);
  const runtimeError = useSelector((state) => state.editorData.runtimeError);
  const skillsTree = useSelector((store) => store.appData.skillsTree);
  const scenarioList = useSelector((store) => store.appData.scenarioList);
  const userAccessToken = useSelector((state) => state.appData.accessToken);
  const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
  const userData = useSelector((store) => store.appData.userData);
  let params = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loadingScenario, setLoadingScenario] = useState(params.hasOwnProperty("editorScenarioID"))

  const [saveSuccess, setSaveSuccess] = useState(false);
  const [validationWarnings, setValidationWarnings] = useState({});

  const [documentArray, setDocumentArray] = useState(null);
  const [coverImage, setCoverImage] = useState();

  const [buildLoading, setBuildLoading] = useState({"message":"Step 1/3: Building scenario...", "progress": 0})

  const [ref, bounds] = useMeasure()
  
  const dispatch = useDispatch();
  var navigate = useNavigate();

  const getEditorContent = () => {
    switch(selectedIndex) {
      case 0:
        if (activeStep <6) {
          return (
            <EditorWizard activeStep={activeStep} setActiveStep={setActiveStep} setBuildLoading={setBuildLoading} setCoverImage={setCoverImage} />
          )
        } if (activeStep === 6) {
          return(
            <BuildLoading buildLoading={buildLoading} />
          )
        } else {
          return (
            <ReactFlowProvider>
            <EditorFlow 
              setSelectedIndex={setSelectedIndex} 
              setActiveStep={setActiveStep} 
              saveSuccess={saveSuccess} 
              setSaveSuccess={setSaveSuccess} 
              validationWarnings={validationWarnings}
            />
            </ReactFlowProvider>
          )
        }
      case 1:
        return (
          <ScenarioPrimarySources />
        )
      case 2:
        return (
          <ScenarioDocuments />
        )
      case 3:
        return (
          <LoadScenario 
          setSelectedIndex={setSelectedIndex} 
          setActiveStep={setActiveStep} 
          setDocumentArray={setDocumentArray}
          setCoverImage={setCoverImage}
          />
      )
      case 4:
        return (
          <ScenarioProfile 
          setCoverImage={setCoverImage}
          />
      )
      case 5:
        return (
          <ScenarioPublish />
      )
      default:
        if (activeStep < 3) {
          return (
            <EditorWizard activeStep={activeStep} setActiveStep={setActiveStep} setBuildLoading={setBuildLoading} setCoverImage={setCoverImage}/>
          )
        } else {
          return (
            <ReactFlowProvider>
            <EditorFlow />
            </ReactFlowProvider>
          )
        }
    }
  };

  useEffect(() => {
    
    const handleOpen = async () => {
      // Load data
      const loadScenarioFromDB = async () => {
        const scenarioRequest = {
            scenarioID: params.editorScenarioID
        }
        try {
            const scenarioResponse = await fetch('/api/editor/loadScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioRequest),
            });
            const editorData = await scenarioResponse.json()
            return (editorData)
        } 
        catch(e) {console.log(e)}
      };

      const getDocuments = async (scenarioAssetData) => {

        const docType = scenarioAssetData["type"]
        let endPoint = docType === "pdf" ? '/api/assets/getdocument' : '/api/assets/getjsondocument'
        
        try {
            const documentData = {
                documentID: scenarioAssetData["documentID"]
            }            
            const dataResponse = await fetch(endPoint, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(documentData),
            });
  
            // handle pdf streaming
  
            if (docType === "pdf") {
              let result = new Uint8Array(0);
              const reader = dataResponse.body.getReader();
              while (true) { // eslint-disable-line no-constant-condition
                  const { done, value } = await reader.read();
                  if (done) {
                      break;
                  }
                  const newResult = new Uint8Array(result.length + value.length);
                  newResult.set(result);
                  newResult.set(value, result.length);
                  result = newResult;
              }
              return (result)
            } else {
              // otherwise assume json doc
              const responseData = await dataResponse.json()
              //console.log(responseData)
              dispatch(setEditorScenarioJSONDocumentData(responseData));
              dispatch(setEditorPlayerDocumentData(responseData));
              return ("jsonDocument")
            }
        }
        catch(e) {console.log(e)}
      };
  
      const getCoverImage = async (coverImageID) => {
        
        try {
            const imageData = {
                documentID: coverImageID
            }            
            const dataResponse = await fetch('/api/assets/getdocument', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(imageData),
            });
            let result = new Uint8Array(0);
            const reader = dataResponse.body.getReader();
            while (true) { // eslint-disable-line no-constant-condition
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const newResult = new Uint8Array(result.length + value.length);
                newResult.set(result);
                newResult.set(value, result.length);
                result = newResult;
            }
            const binString = Array.from(result, (x) => String.fromCodePoint(x)).join("");
            return(btoa(binString))
        }
        catch(e) {console.log(e)}
      };

    const editorData = await loadScenarioFromDB()

    // If have documents, load them
    if (editorData.scenarioAssets.hasOwnProperty("documents")) {
        const scenarioAssetData = editorData.scenarioAssets.documents[0]
        getDocuments(scenarioAssetData).then((result) => setDocumentArray({data: result, documentID: scenarioAssetData["documentID"], type: scenarioAssetData["type"]}));    
    }

    // If have cover image, load it
    if (editorData.scenarioAssets.hasOwnProperty("coverImage")) {
        const coverImageData = editorData.scenarioAssets.coverImage[0]
        getCoverImage(coverImageData["documentID"]).then((result) => setCoverImage({data: result, documentID: coverImageData["documentID"], type: coverImageData["type"]}));
    } else {
        setCoverImage(null);
    }

    // Load the flow data
    dispatch(loadSavedScenario(editorData.hasOwnProperty("editorData") ? editorData.editorData : "default"))

    // Push the scenario profile into editor store
    const profileUpdate = {
        scenarioID: editorData.scenarioID,
        scenarioName: editorData.scenarioName,
        pageID: editorData.pageID,
        scenarioShortDescription: editorData.scenarioShortDescription,
        scenarioLongDescription: editorData.scenarioLongDescription,
        learningNotes: editorData.learningNotes,
        scenarioELO: editorData.scenarioELO,
        scenarioPlayTime: editorData.scenarioPlayTime,
        playCount: editorData.playCount,
        scenarioSubTopics: editorData.scenarioSubTopics,
        scenarioAssets: editorData.scenarioAssets,
        scenarioEditable: true,
        published: editorData.published,
        UIOrder: editorData.UIOrder,
        status: editorData.status,
        sharing: editorData.sharing ? editorData.sharing : "linkSharing",
        RBAC: editorData.RBAC,
        author: editorData.author,
    }
    dispatch(updateScenarioProfile(profileUpdate));

    // Set the UI
    setSelectedIndex(0)
    setActiveStep(7)

    // Compile the scenario
    dispatch(loadScenarioIntoInk())
    setLoadingScenario(false)

    }
      
    if (userAccessToken !== "") {
      // UI guard for author permissions
      
      if (!userData?.userRoles.includes("read:editor")) {
        navigate("/learn");
      };
      
      // don't attempt to get scenario data before auth. use access token as there's a cycle to update everything
      if (Object.keys(skillsTree).length > 0 && params.hasOwnProperty("editorScenarioID")) {
        // guard for clean reload
        if (scenarioList[params.editorScenarioID].hasOwnProperty("scenarioEditable")) {
          // don't try and load non-editable scenarios
          if (scenarioProfile.scenarioID === "") {
            // don't reload if already loaded, e.g. through load or creation
            console.log("loading scenario")
            handleOpen();
          }
        }
      }
    };

  },[params, userAccessToken, skillsTree])

  return (
    <Grid container style={{width: "100%", minHeight: "100%", flexDirection: "column"}}>
      <Grid item style={{display: "flex", flex: 1}}>
        <Grid container style={{width: "100%", minHeight: "100%", flexWrap: "nowrap"}}>
        <EditorSideBar 
          selectedIndex={selectedIndex} 
          setSelectedIndex={setSelectedIndex} 
          setSaveSuccess={setSaveSuccess} 
          setValidationWarnings={setValidationWarnings} 
        />
        {loadingScenario || Object.keys(scenarioList).length === 0
        ? 
        <Grid item sx={{flexGrow: 1}}>
        <Grid container sx={{width: "100%", justifyContent: "center", paddingTop: "10px"}}>
          <CircularProgress />
        </Grid>
        </Grid>
        :
        <Grid item style={{flex: 1, minHeight: "100%"}}>
          <Grid container style={{width: "100%", minHeight: "100%", flexWrap: "nowrap"}}>
          <Grid item style={{flex: 1}}>
            <Grid item style={{width: "100%", height: "100%"}}>
              {getEditorContent()}
            </Grid>
          </Grid>
          {((selectedIndex !== 0 && window.screen.width < 1400) || selectedIndex === 3) ?
          null
          :
          <ResizableBox width={740} height={"calc(100vh - 70px)"} minConstraints={[450, Infinity]} maxConstraints={[window.screen.width - 300, Infinity]} resizeHandles={["w"]}>
          <Grid item style={{width: "100%", minHeight: "100%", display: "flex"}} ref={ref}>
          <Grid container style={{width: "100%", flexDirection: "column"}}>
            <Grid item style={{width: "100%", padding: 10, minHeight: "100%", flex: 1}} component={motion.div} layout={"position"}>
              {runtimeError ? 
              <Typography>Oh dear</Typography>
              :
              <>
              {ending ? 
              <Grid container style={{width: "100%", height: "100%", border: "1.5px solid #ced7db", justifyContent: "center", backgroundColor: "#ffffff"}}>
                <Grid item>
                  <EditorComplete confettiWidth={bounds.width} confettiHeight={bounds.height} />
                </Grid>
              </Grid>
              :
              
              <EditorStory 
              setSelectedIndex={setSelectedIndex} 
              documentArray={documentArray} 
              setDocumentArray={setDocumentArray} 
              coverImage={coverImage}
              setCoverImage={setCoverImage}
              />
              
              }
              </>
              }
            </Grid>
          </Grid>
          </Grid>
          </ResizableBox>
          }
          </Grid>
        </Grid>
        }
        </Grid>
      </Grid> 
    </Grid>
  )
  };
export default Editor;