import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

import FaceIcon from '@mui/icons-material/Face';

const PendingText = (props) => {

    const defaultStyles = props.defaultStyles
    const otherStyles = props.otherStyles

    return (
        <Grid container 
            component={motion.div} 
            initial={{opacity: 0,}}
            animate={{opacity: 1,
                    transition: {                
                        delay: 0.5,
                        ease: "easeInOut",
                        duration: 0.5,
                    }}}
        >
        <Grid item sx={{
          border: otherStyles.border,
          padding: defaultStyles.padding,
          marginBottom: defaultStyles.marginBottom,
          backgroundColor: otherStyles.backgroundColor,
          borderTopRightRadius: otherStyles.borderTopRightRadius,
          borderBottomLeftRadius: otherStyles.borderBottomLeftRadius,
          borderBottomRightRadius: otherStyles.borderBottomRightRadius,
          boxShadow: defaultStyles.boxShadow,
          filter: defaultStyles.saturation,
        }}>
        <Grid item
          component={motion.div}
          initial={{opacity: 0}}
          animate={{opacity: 1,
                    transition: {
                      ease: "easeInOut",
                      duration: 0.8,
                      repeat: Infinity,
                      repeatType: "reverse"
                    }}}
          >
            <Typography variant={defaultStyles.typographyVariant} sx={{color: otherStyles.fontColor}}>... ... ...</Typography>
          </Grid>
        </Grid>
        </Grid>
    )
};
export default PendingText;