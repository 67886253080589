import React from 'react';
import RADocument from '../rule_analysis/RADocument';
import RAAtom from '../rule_analysis/RAAtom';
import RAConjunction from '../rule_analysis/RAConjunction';
import { Typography, Grid } from '@mui/material';
import { motion } from "framer-motion"

const PrivLitAnalysis = () => {

    return (
    <RADocument title="Litigation Privilege">
        <Grid item sx={{
            width: "100%"    
        }}
        component={motion.div}
        initial={{
            backgroundColor: "#FFFF00",
            transition: {
                duration: 1.5,
                ease: "easeInOut",
            }
        }}
        animate={{
            backgroundColor: "#fff",
            transition: {
                duration: 1.5,
                ease: "easeInOut",
            }
        }}
        >
        <Typography variant="body2" align='center' paragraph>This outline will be updated to reflect your analysis as you progress.</Typography>
        </Grid>
        <RAAtom atomRef="1." atomID="firstRule">
            <Typography variant="body2">All communications.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="2." atomID="secondRule">
        <Typography variant="body2">Between a lawyer or client and a third party.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="3." atomID="thirdRule">
            <Typography variant="body2">Made in confidence.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="4." atomID="fourthRule">
            <Typography variant="body2">For the dominant purpose of giving or obtaining legal advice or collecting evidence or information in relation to litigation.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="5." atomID="fourthRule">
            <Typography variant="body2">Where litigation is in existence or reasonable contemplation.</Typography>
        </RAAtom>
    </RADocument>
    )
};

export default PrivLitAnalysis;