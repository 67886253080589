import { Grid, Typography, Link } from '@mui/material';
import { motion } from "framer-motion";

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Link as ReactRouterLink } from "react-router-dom";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const ExploreHeading = () => {

    const theme = useTheme();
    const subTopicList = useSelector((store) => store.appData.subTopicList)
    const topicList = useSelector((store) => store.appData.topicList)
    const practiceAreaList = useSelector((store) => store.appData.practiceAreaList)
    const fullView = useMediaQuery(theme.breakpoints.up('sm'))

    let params = useParams()

    const getBreadCrumb = () => {
        if (fullView) {
            return(
                <>
                <Link component={ReactRouterLink} to={'/explore'} style={{color: "#3f51b5"}} underline="hover">Competencies</Link>
                {params.hasOwnProperty("practiceAreaID") ?
                <> / <Link component={ReactRouterLink} to={`/explore/${params.practiceAreaID}`} style={{color: "#3f51b5"}} underline="hover">{practiceAreaList[params.practiceAreaID]["practiceAreaName"]}</Link>
                {params.hasOwnProperty("topicID") ?
                <> / <Link component={ReactRouterLink} to={`/explore/${params.practiceAreaID}/${params.topicID}`} style={{color: "#3f51b5"}} underline="hover">{topicList[params.topicID]["topicName"]}</Link>
                {params.hasOwnProperty("subTopicID") ?
                <> / <Link component={ReactRouterLink} to={`/explore/${params.practiceAreaID}/${params.topicID}/${params.subTopicID}`} style={{color: "#3f51b5"}} underline="hover">{subTopicList[params.subTopicID]["subTopicName"]}</Link> </>
                :
                null
                }
                </>
                :
                null
                }
                </>
                :
                null
                }
                </>
            )
        } else {
            if (params.hasOwnProperty("subTopicID")) {
                return (
                    <>
                    <Link component={ReactRouterLink} to={`/explore/${params.practiceAreaID}/${params.topicID}`} style={{color: "#3f51b5"}} underline="hover">{<KeyboardBackspaceIcon sx={{"transform":"translateY(6px)"}} />} {topicList[params.topicID]["topicName"]}</Link>
                    </>
                )
            } else {
                if (params.hasOwnProperty("topicID")) {
                    return (
                        <>
                        <Link component={ReactRouterLink} to={`/explore/${params.practiceAreaID}`} style={{color: "#3f51b5"}} underline="hover">{<KeyboardBackspaceIcon sx={{"transform":"translateY(6px)"}} />} {practiceAreaList[params.practiceAreaID]["practiceAreaName"]}</Link>
                        </>
                    )
                } else {
                    if (params.hasOwnProperty("practiceAreaID")) {
                        return (
                            <Link component={ReactRouterLink} to={'/explore'} style={{color: "#3f51b5"}} underline="hover">{<KeyboardBackspaceIcon sx={{"transform":"translateY(6px)"}} />} Competencies</Link>
                        )
                    }
                }
            }
        }
    };

    const getHeading = () => {
        if (params.hasOwnProperty("subTopicID")) {
            return (subTopicList[params.subTopicID]["subTopicName"])
        } else {
            if (params.hasOwnProperty("topicID")) {
                return (topicList[params.topicID]["topicName"])
            } else {
                if (params.hasOwnProperty("practiceAreaID")) {
                    return (practiceAreaList[params.practiceAreaID]["practiceAreaName"])
                } else {
                    return ("Explore courses")
                }
            }
        }
    };

    return (
        <Grid container sx={{width: "100%", justifyContent: "center"}}>
            <Grid item style={{
                width: "100%", 
                minHeight: "75px",
                marginLeft: "15px", 
                [theme.breakpoints.down('sm')]: {
                    marginLeft: "0px"
                }
            }}
            component={motion.div} 
            initial={{opacity: 0,}}
            animate={{opacity: 1,
                        transition: {                
                            ease: "easeInOut",
                            duration: 1,
                        }}}
            layout
            >
            
                <Grid container sx={{height: "100%", alignItems: "center"}}>
                    <Grid item sx={{width: "100%"}}>
                    <Typography variant={fullView ? "h4" : "h5"} style={{color: "#424242"}}>{getHeading()}</Typography>
                    <Typography variant="body1">{getBreadCrumb()}</Typography>
                    </Grid>
                </Grid>
            
            </Grid>
        </Grid>
      )
      };
export default ExploreHeading;

/*

<Grid item style={{maxWidth: "100%", borderLeft: "10px solid #3f51b5", height: "100%"}}>
            <Grid item style={{maxWidth: "100%", borderLeft: "10px solid #e91e63", height: "100%", paddingLeft: 16}}>
</Grid>
            </Grid>
*/