import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";

import { useSelector } from 'react-redux';

export default function LearningGoal() {

    var navigate = useNavigate();
    const weekScore = useSelector((store) => store.appData.userData.weekScore)
    const weekScoreCounter = weekScore > 500 ? 500 : weekScore;

    const goLearn = () => {
        navigate(`/learn`);
    };

return (
    <Grid container>
    <Grid item sx={{
        padding: "10px",
        marginBottom: "10px",
        width: "275px",
        borderLeft: "1px solid #cccccc66",
        borderTop: "1px solid #cccccc66",
        boxShadow: "3px 3px 3px #cccccc",
        borderRadius: "15px",
    }}>
        <Grid container style={{width: "100%", marginBottom: 10, justifyContent: "space-between"}}>
            <Grid item>
                <Typography variant='body1'>Score Progress</Typography>
            </Grid>
        </Grid>
        <Grid container style={{width: "100%"}}>
            <Grid item style={{marginRight: 8}}>
                <TrendingUpIcon fontSize='large' sx={{color: "#3f51b5"}} />
            </Grid>
            <Grid item sx={{
                    width: "55%",
                    height: "20px",
                    marginBottom: "5px"
            }}>
                <Typography variant='body2'>Weekly Goal</Typography> 
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            version="1.1"
            viewBox="0 0 26.452 1.323"
            >
            <g transform="translate(-36.587 -85.033)">
                <motion.path
                fill="none"
                stroke="#ccc"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.323"
                d="M36.587 85.694h26.452"
                initial={{
                    pathLength: 0,
                }}
                animate={{
                    pathLength: 1,
                }}
                transition={{ease: "easeInOut",
                duration: 0.5,}}
                ></motion.path>
            </g>
            <g transform="translate(-36.587 -85.033)">
                <motion.path
                fill="none"
                stroke="#4caf50"
                strokeDasharray="none"
                strokeLinecap="butt"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="1.323"
                d="M36.587 85.694h26.452"
                initial={{
                    pathLength: 0,
                }}
                animate={{
                    pathLength: weekScoreCounter/500,
                }}
                transition={{ease: "easeInOut",
                duration: 1.5,}}
                ></motion.path>
            </g>
            </svg>
            </Grid>
            <Grid item style={{alignSelf: "flex-end", marginLeft: 3}}>
                <Typography variant='body2'>{weekScoreCounter} / 500</Typography>
            </Grid>
        </Grid>
        <Grid item sx={{width: "100%", padding: "10px 0px 10px 0px"}}>
            <Grid container sx={{width: "100%", justifyContent: "center"}}>
            <Button size="small" variant='outlined' onClick={() => goLearn()}>
                Learning Dashboard
            </Button>
            </Grid>
        </Grid>
    </Grid>
    </Grid>
);
}

/*

            <Grid item>
            <Button size="small" color="secondary" onClick={() => goLearn()} startIcon={<EditIcon fontSize='small' color="secondary" />}>
                Edit
            </Button>
            </Grid>

*/