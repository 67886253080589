import React from 'react';

import { useNodeId } from 'reactflow';

import { Grid, FormControl, Select, MenuItem, Typography, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setNodeAction } from '../editorSlice';

import GoTo from './GoTo';
import SetOutline from './SetOutline';
import SetFeedback from './SetFeedback';

const ActionSelect = (props) => {

    const dispatch = useDispatch();

    const actionUUID = props.actionUUID;
    const setChangesMade = props.setChangesMade;

    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const action = nodeData.nodeActions[actionUUID]["action"]

    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile)

    // This pattern seems to work ok. Keep expanding down to goto etc.

    const handleActionTypeSelect = (event) => {
        const actionType = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}
        
        // Create a deep copy of the object at the current actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["action"] = { ...updatedAction["action"] };
        
        // Now, update the type property and reset data
        updatedAction["action"]["type"] = actionType;
        updatedAction["action"]["data"] = {}

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    return (
        <Grid item>
            <Stack direction="row" spacing={1} sx={{marginBottom: "8px"}}>
            <Typography>Action type:</Typography>
            <FormControl variant="standard">
            <Select
            id="action-type-select"
            size='small'
            value={action.type}
            label="Action type"
            onChange={handleActionTypeSelect}
            >
                <MenuItem value="goto">Go to</MenuItem>
                <MenuItem value="setOutline">Set outline</MenuItem>
                <MenuItem value="setFeedback">Set feedback</MenuItem>
            </Select>
            </FormControl>
            </Stack>
            {action.type === "goto" ?
            <GoTo 
                actionUUID={actionUUID}
                setChangesMade={setChangesMade}
            />
            :
            null
            }
            {action.type === "setOutline" ?
            <>
            {scenarioProfile.scenarioAssets.hasOwnProperty("outline") ?
            <SetOutline 
                actionUUID={actionUUID}
                setChangesMade={setChangesMade}
            />
            :
            <Typography color="textSecondary" align="center">No outline selected. Set an outline in scenario documents.</Typography>
            }
            </>
            :
            null
            }
            {action.type === "setFeedback" ?
            <SetFeedback 
                actionUUID={actionUUID}
                setChangesMade={setChangesMade}
            />
            :
            null
            }
        </Grid>
    )
};
export default ActionSelect;