import React from 'react';
import { Grid } from '@mui/material';
import { motion } from "framer-motion";


export default function LinearProgressBar(props) {

    // progress is between 0 and 1
    const progress = props.progress
    const getColor = (progress) => {
        if (progress >= 0.75) {
            return "#4caf50"
        };
        if (progress < 0.75 && progress >= 0.25) {
            return "#ffc107"
        }
        if (progress < 0.25) {
            return "#e91e63"
        }
    };

return (
    <Grid sx={{
        border: "1px dashed #aaaaaa",
        borderRadius: "8px",
        backgroundColor: "#cccccc66",
        paddingLeft: "3px",
        width: "150px",
        height: "20px",
        margin: "5px",
    }}>
            <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            version="1.1"
            viewBox="0 0 26.452 1.323"
            >
            <g transform="translate(-36.587 -85.033)">
                <motion.path
                fill="none"
                stroke={getColor(progress)}
                strokeDasharray="none"
                strokeLinecap="round"
                strokeLinejoin="miter"
                strokeMiterlimit="4"
                strokeOpacity="1"
                strokeWidth="2"
                d="M36.587 85.694h26.452"
                initial={{
                    pathLength: 0,
                }}
                animate={{
                    pathLength: progress,
                }}
                transition={{ease: "easeInOut",
                duration: 1.5,}}
                ></motion.path>
            </g>
            </svg>
    </Grid>
);
}