import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import LearningGoal from './LearningGoal';
import AchievementsBadge from './AchievementsBadge';
import AchievementsList from './AchievementsList';

const ExploreSidePanel = () => {

    const achievementsList = useSelector((store) => store.appData.achievementsList);
    const userAchievements = useSelector((state) => state.appData.userData.userAchievements);
    const userData = useSelector((store) => store.appData.userData);

    return (
        <Grid item sx={{margin: "15px"}}>
            <LearningGoal />
            {userData.nextAchievement !== "" &&
            <Grid container>
            <Grid item sx={{
                padding: "15px",
                marginBottom: "10px",
                width: "275px",
                borderLeft: "1px solid #cccccc66",
                borderTop: "1px solid #cccccc66",
                boxShadow: "3px 3px 3px #cccccc",
                borderRadius: "15px",
            }}>
            <Typography variant="body1" paragraph>Next achievement</Typography>
            <Grid item sx={{
                width: "80%",
                margin: "auto",
            }}>
            <AchievementsBadge badgeID={userData.nextAchievement} />
            </Grid>
            <Typography variant="body2" align="center" paragraph>{achievementsList[userData.nextAchievement]['achievementName']}</Typography>
            <Typography variant="body2" color="textSecondary" align="center">{achievementsList[userData.nextAchievement]['achievementDescription']}</Typography>
            </Grid>
            </Grid>
            }
            {userAchievements.length > 0 &&
            <Grid container>
            <Grid item sx={{
                padding: "15px",
                marginBottom: "10px",
                borderLeft: "1px solid #cccccc66",
                borderTop: "1px solid #cccccc66",
                boxShadow: "3px 3px 3px #cccccc",
                borderRadius: "15px",
                width: "100%"
            }}>
            <Grid item sx={{ marginBottom: "10px"}}>
                <Typography variant='body1'>Achievements</Typography>
            </Grid>
            <Grid item>
                <AchievementsList />
            </Grid>
            </Grid>
            </Grid>
            }
        </Grid>
      )
      };
export default ExploreSidePanel;