import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

const Narrative = (props) => {

    const stripTags = props.stripTags
    const delay = props.delay
    const defaultStyles = props.defaultStyles
    const narrativeStyles = props.narrativeStyles

    return (
        <Grid container
        component={motion.div} 
        initial={{opacity: 0,}}
        animate={{opacity: 1,
                  transition: {                
                      delay: delay,
                      ease: "easeInOut",
                      duration: 0.5,
                  }}}
      >
      <Grid item sx={{
          backgroundColor: narrativeStyles.backgroundColor,
          padding: defaultStyles.padding,
          borderBottomRightRadius: narrativeStyles.borderBottomRightRadius,
          borderLeft: narrativeStyles.borderLeft,
          borderLeftColor: narrativeStyles.borderLeftColor,
          marginBottom: defaultStyles.marginBottom,
          boxShadow: defaultStyles.boxShadow,
          maxWidth: "90%",
      }}>
      <Typography variant={defaultStyles.typographyVariant} align="left">
        {stripTags}
      </Typography>
      </Grid>
      </Grid>
    )
};
export default Narrative;