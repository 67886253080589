import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';

import { useSelector } from 'react-redux'

import { useParams } from "react-router-dom";

/*

To use: get the <Primary> content of a section from legislation.gov/.../123/data.xml
Remove line breaks: notepad++ -> line operations
Replace double quotes with single quotes
Create new JSON for import
Amend switch

*/

const XmlReader = require('xml-reader');
const xmlQuery = require('xml-query');

// This accepts xml string and returns a query

const getPblock = (xmlQ) => {

    // Some sections use Pblock as top element, other omit and just have Chapter.
    // If not getting any output, try tweaking here.

    if (xmlQuery(xmlQ).has('Pblock')) {
        return xmlQuery(xmlQ).find('Pblock')
    } else {
        return xmlQuery(xmlQ).find('Chapter')
    }
}

const getFirstPnumber = (xmlQ) => {
    const pnumber = xmlQuery(xmlQ).find('Pnumber').get(0)
    return pnumber
}

const PrimarySource = (props) => {

    let params = useParams();
    const loadSource = props.loadSource
    
    const editorScenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
    const scenarioID = params.scenarioID;
    const scenarioList = useSelector((state) => state.appData.scenarioList)
    const editorScenarioAssets = editorScenarioProfile.scenarioAssets
    
    /* hack whilst things become consistent between editor / mongo db serving xml and import from build

    in old scenarios, primary sources is an array of objects with {reference, title}. Reference points to build
    in new scenarios, primary sources is an object


    */
    var primarySourcesArray = []
    if (loadSource === "editor") {
        Object.keys(editorScenarioAssets["primarySource"]).forEach((sourceID) => {
            // point to app data equivalent for non editor
            if (editorScenarioAssets["primarySource"][sourceID].hasOwnProperty("title") && editorScenarioAssets["primarySource"][sourceID].hasOwnProperty("xml_string") && editorScenarioAssets["primarySource"][sourceID].hasOwnProperty("type")) {
                var primarySourceData = {
                    title: editorScenarioAssets["primarySource"][sourceID]["title"],
                    xml_string: editorScenarioAssets["primarySource"][sourceID]["xml_string"],
                    type: editorScenarioAssets["primarySource"][sourceID]["type"]
                }
                primarySourcesArray.push(primarySourceData)
            }
        })
    } else {
        if (loadSource === "dynamic") {
            // TODO: more complex, need to go and fetch the data as part of scenario loading
            const scenarioAssets = scenarioList[scenarioID]["scenarioAssets"]
            Object.keys(scenarioAssets["primarySource"]).forEach((sourceID) => {
                if (scenarioAssets["primarySource"][sourceID].hasOwnProperty("title") && scenarioAssets["primarySource"][sourceID].hasOwnProperty("xml_string") && scenarioAssets["primarySource"][sourceID].hasOwnProperty("type")) {
                    var primarySourceData = {
                        title: scenarioAssets["primarySource"][sourceID]["title"],
                        xml_string: scenarioAssets["primarySource"][sourceID]["xml_string"],
                        type: scenarioAssets["primarySource"][sourceID]["type"]
                    }
                    primarySourcesArray.push(primarySourceData)
                }
            })
        } else {
            const scenarioAssets = scenarioList[scenarioID]["scenarioAssets"]
            if (Array.isArray(scenarioAssets.primarySource)) {
                primarySourcesArray = scenarioAssets.primarySource
            }
        }
    };

    return (
            <Grid item style={{width: "100%", paddingLeft: 15, paddingRight: 15}}>
            {primarySourcesArray.length > 0 ?
            <>
            {primarySourcesArray.map((primarySource, idx) => (
            <Grid item xs={12} key={"ps"+idx}>
            <Typography variant='body2' align='center' sx={{fontStyle: 'italic', marginTop: "20px", marginBottom: "20px"}}>Extract from {primarySource.title}</Typography>
            {getPblock(primarySource.hasOwnProperty("type") ? XmlReader.parseSync(primarySource.xml_string) : null).map((PblockNode) => (
                <Grid container key={"psReference" + idx}>
                {xmlQuery(PblockNode).children().map((L1Node) => (
                    <>
                    {L1Node.name === "Title" &&
                    <Grid item xs={12}>
                    <Typography variant='body2' align='center' sx={{
                        fontStyle: 'italic',
                        marginBottom: "20px",
                        marginTop: "20px",
                    }}>{xmlQuery(L1Node).text()}</Typography>
                    </Grid>
                    }
                    {L1Node.name === "P1group" &&
                    <Grid item xs={12} sx={{marginBottom: "10px"}}>
                        {xmlQuery(L1Node).children().map((L2Node) => (
                            <>
                            {L2Node.name === "Title" &&
                            <Grid container>
                            <Grid item sx={{width: "8%"}}>
                                <Typography variant='body2' sx={{fontWeight: "bold", marginBottom: "5px"}} align='right'>{xmlQuery(getFirstPnumber(L1Node)).text()}</Typography>
                            </Grid>
                            <Grid item sx={{width: "92%", paddingLeft: "5px"}}>
                                <Typography variant='body2' sx={{fontWeight: "bold", marginBottom: "5px"}}>{xmlQuery(L2Node).text()}</Typography>
                            </Grid>
                            </Grid>
                            }
                            {L2Node.name === "P1" &&
                            <>
                                {xmlQuery(L2Node).children().map((L3Node) => (
                                    <>
                                    {L3Node.name === "P1para" &&
                                    <>
                                        {xmlQuery(L3Node).children().map((L4Node) => (
                                            <>
                                            {L4Node.name === "Text" &&
                                            <Typography variant='body2' sx={{marginLeft: "8%", paddingLeft: "5px", marginBottom: "5px"}}>{xmlQuery(L4Node).text()}</Typography>
                                            }
                                            {L4Node.name === "P2" &&
                                            <Grid container sx={{justifyContent: "flex-end"}}>
                                                {xmlQuery(L4Node).children().map((L5Node) => (
                                                    <>
                                                    {L5Node.name === "Pnumber" &&    
                                                    <Grid item sx={{width: "12%"}}>                                                
                                                    <Typography variant='body2' align="right">({xmlQuery(L5Node).text()})</Typography> 
                                                    </Grid>                                                   
                                                    }
                                                    {L5Node.name === "P2para" &&
                                                    <Grid item sx={{width: "88%", paddingLeft: "5px"}}>                                                    
                                                        {xmlQuery(L5Node).children().map((L6Node) => (
                                                        <>
                                                        {L6Node.name === "Text" &&
                                                            <Typography variant='body2' sx={{marginBottom: "5px"}}>{xmlQuery(L6Node).text()}</Typography>
                                                        }
                                                        {L6Node.name === "UnorderedList" &&
                                                            <>
                                                            {xmlQuery(L6Node).children().map((L13Node) => (
                                                                <>
                                                                {L13Node.name === "ListItem" &&
                                                                <Typography variant='body2' sx={{marginLeft: "8%", paddingLeft: "5px", marginBottom: "5px"}}>{xmlQuery(L13Node).text()}</Typography>
                                                                }
                                                                </>
                                                            ))}
                                                            </>
                                                        }
                                                        {L6Node.name === "P3" &&
                                                        <Grid container sx={{justifyContent: "flex-end"}}>
                                                            {xmlQuery(L6Node).children().map((L7Node) => (
                                                                <>
                                                                {L7Node.name === "Pnumber" &&    
                                                                <Grid item sx={{width: "8%"}}>                                                
                                                                <Typography variant='body2' align="right">({xmlQuery(L7Node).text()})</Typography> 
                                                                </Grid>                                           
                                                                }
                                                                {L7Node.name === "P3para" &&
                                                                <Grid item sx={{width: "92%", paddingLeft: "5px"}}> 
                                                                    {xmlQuery(L7Node).children().map((L8Node) => (
                                                                        <>
                                                                        {L8Node.name === "Text" &&
                                                                            <Typography variant='body2' sx={{marginBottom: "5px"}}>{xmlQuery(L8Node).text()}</Typography>
                                                                        }
                                                                        {L8Node.name === "P4" &&
                                                                            <Grid container sx={{justifyContent: "flex-end"}}>
                                                                            {xmlQuery(L8Node).children().map((L9Node) => (
                                                                                <>
                                                                                {L9Node.name === "Pnumber" &&
                                                                                <Grid item sx={{width: "8%"}}>                                                
                                                                                <Typography variant='body2' align="right">({xmlQuery(L9Node).text()})</Typography> 
                                                                                </Grid>
                                                                                }
                                                                                {L9Node.name === "P4para" &&
                                                                                <Grid item sx={{width: "92%", paddingLeft: "5px"}}>
                                                                                    {xmlQuery(L9Node).children().map((L12Node) => (
                                                                                        <>
                                                                                        {L12Node.name === "Text" &&
                                                                                            <Typography variant='body2' sx={{marginBottom: "5px"}}>{xmlQuery(L12Node).text()}</Typography>
                                                                                        }
                                                                                        </>
                                                                                    ))}
                                                                                </Grid>
                                                                                }
                                                                                </>
                                                                            ))}
                                                                            </Grid>
                                                                        }
                                                                        </>
                                                                    ))}
                                                                </Grid>
                                                                }
                                                                </>
                                                            ))}
                                                        </Grid>
                                                        }
                                                        </>
                                                        ))}
                                                    </Grid>
                                                    }
                                                    </>
                                                ))}
                                            </Grid>
                                            }
                                            {L4Node.name === "P3" &&
                                            <Grid container sx={{justifyContent: "flex-end"}}>
                                                {xmlQuery(L4Node).children().map((L10Node) => (
                                                    <>
                                                    {L10Node.name === "Pnumber" &&    
                                                    <Grid item sx={{width: "19.04%"}}>                                                
                                                    <Typography variant='body2' align="right">({xmlQuery(L10Node).text()})</Typography> 
                                                    </Grid>                                                   
                                                    }
                                                    {L10Node.name === "P3para" &&    
                                                    <Grid item sx={{width: "80.96%", paddingLeft: "5px"}}>  
                                                        {xmlQuery(L10Node).children().map((L11Node) => (
                                                            <>
                                                            {L11Node.name === "Text" &&
                                                                <Typography variant='body2' sx={{marginBottom: "5px"}}>{xmlQuery(L11Node).text()}</Typography>
                                                            }
                                                            </>
                                                        ))} 
                                                    </Grid>                                                   
                                                    }
                                                    </>
                                                ))}
                                            </Grid>
                                            }
                                            </>
                                        ))}
                                    </>
                                    }
                                    </>
                                ))
                                }
                            </>
                            }
                            </>
                        ))}
                    </Grid>
                    }
                    </>
                ))}
                </Grid>
            ))}
            <Grid container style={{width: "100%", justifyContent: "center"}}>
            <Grid item style={{width: "50%", margin: 15, marginTop: 25, marginBottom: 25}}>
            <Divider variant="middle" />
            </Grid>
            </Grid>
            </Grid>
            ))}
            </>
            :
            <Typography variant='body1' sx={{marginBottom: "20px"}}>No primary sources.</Typography>
            }
        </Grid>
    )
    };

export default PrimarySource;

// 