import { Grid, Typography, Paper, Stack, Box } from '@mui/material';
import { motion } from 'framer-motion';

import { Editable, withReact, Slate } from 'slate-react'
import {
  createEditor,
} from 'slate'

import React, { useMemo, useCallback } from 'react';

const RedactionEval = (props) => {

  const initialDocData = props.initialDocData;  

  /*********************************************

  Slate editor

  *********************************************/


  const Element = ({ attributes, children, element }) => {
    const alignVariant = element.align

    let numberedListStyle = "decimal"
    if (element.listLevel === 0) {
      numberedListStyle = "decimal"
    };
    if (element.listLevel === 1) {
      numberedListStyle = "upper-alpha"
    };
    if (element.listLevel === 2) {
      numberedListStyle = "lower-roman"
    };
    if (element.listLevel > 2) {
      numberedListStyle = "lower-alpha"
    };

    switch (element.type) {
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <Typography align={alignVariant} variant='h6' {...attributes}>
            {children}
          </Typography>
        )
      case 'list-item':
        return (
            <Typography paragraph variant='body2' {...attributes} component="li" sx={{paddingLeft: "10px"}}>
            {children}
            </Typography>
        )
      case 'numbered-list':
        return (
          <ol {...attributes} style={{listStyleType: numberedListStyle, marginTop: "3px", marginBottom: "3px"}}>
            {children}            
          </ol>
        )
      default:
        return (
          <Typography paragraph align={alignVariant} variant='body2' {...attributes}>
            {children}
          </Typography>
        )
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    };
    if (leaf.italic) {
      children = <em>{children}</em>
    };
    if (leaf.underline) {
      children = <u>{children}</u>
    };
    if (leaf.redactEval === "correctRedact") {
      children = <motion.span initial={{
        backgroundColor: "#fcfdff",
        transition: {
            duration: 1.5,
            ease: "easeInOut",
        }
      }}
      animate={{
          backgroundColor: "#388e3c66",
          transition: {
              duration: 1.5,
              ease: "easeInOut",
          }
      }}>{children}</motion.span>
    };
    if (leaf.redactEval === "incorrectRedact") {
      children = <motion.span initial={{
        backgroundColor: "#fcfdff",
        transition: {
            duration: 1.5,
            ease: "easeInOut",
        }
      }}
      animate={{
          backgroundColor: "#d32f2f66",
          transition: {
              duration: 1.5,
              ease: "easeInOut",
          }
      }}>{children}</motion.span>
    };
    if (leaf.redactEval === "overRedact") {
      children = <motion.span initial={{
        backgroundColor: "#fcfdff",
        transition: {
            duration: 1.5,
            ease: "easeInOut",
        }
      }}
      animate={{
          backgroundColor: "#f57c0066",
          transition: {
              duration: 1.5,
              ease: "easeInOut",
          }
      }}>{children}</motion.span>
    };
    
    // Adding the bug fix text in leaf direct.

    return <span style={{
        paddingLeft: leaf.text === '' ? "0.1px" : null
      }} {...attributes}>{children}</span>
  }

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withReact(createEditor()), [])

  // Do some testing, but nearly there.

  return (
  <Grid item sx={{width: "100%"}}>
      <Grid item sx={{marginTop: "20px"}}>
      <Stack direction="row" spacing={1} sx={{marginBottom: "3px"}}>
        <Box sx={{
          width: "20px",
          height: "20px",
          borderRadius: "2px",
          backgroundColor: "#388e3c66"
        }}></Box><Typography variant="body2" color="textSecondary">Correct Redactions</Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={{marginBottom: "3px"}}>
        <Box sx={{
          width: "20px",
          height: "20px",
          borderRadius: "2px",
          backgroundColor: "#f57c0066"
        }}></Box><Typography variant="body2" color="textSecondary">Over-redactions</Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={{marginBottom: "10px"}}>
        <Box sx={{
          width: "20px",
          height: "20px",
          borderRadius: "2px",
          backgroundColor: "#d32f2f66"
        }}></Box><Typography variant="body2" color="textSecondary">Missed Redactions</Typography>
      </Stack>
      </Grid>
      <Slate editor={editor} initialValue={initialDocData}>
      <Paper elevation={3}
        sx={{
          paddingLeft: "22px",
          paddingRight: "22px",
          paddingTop: "32px",
          paddingBottom: "32px",
          backgroundColor: "#fcfdff",
      }}
      >
      <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
      />
      </Paper>
      </Slate>
  </Grid>
  )
  };
export default RedactionEval;