import { Grid, Modal, Typography, Button } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

import React, { useEffect }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAchievementHero, setCurrentSelection, addLearningTopic } from './appData/appDataSlice'

import AchievementHero from './AchievementHero';
import ModuleCard from './ModuleCard';

import { useAuth0 } from "@auth0/auth0-react";
import { useParams, Outlet } from "react-router-dom";

import { useTheme } from '@mui/material/styles';

const TopicDetail = () => {

    // fix nav around skills (back button from scenario details)
    // give some animation to page header?
    const theme = useTheme();
    const dispatch = useDispatch()
    let params = useParams();

    const skillsTree = useSelector((store) => store.appData.skillsTree)
    const topicList = useSelector((store) => store.appData.topicList);
    const subTopicList = useSelector((store) => store.appData.subTopicList);
    const achievementHero = useSelector((store) => store.appData.appUI.achievementHero);
    const navigationDefault = useSelector((store) => store.appData.navigationDefault);
    const defaultPracticeArea = navigationDefault.practiceAreaID;
    const defaultTopic = navigationDefault.topicID;
    const subTopic = params.hasOwnProperty("subTopicID");
    const userTopic = useSelector((store) => (store.appData.userData.userTopic));
    const userMongoID = useSelector((state) => state.appData.userData.mongoID);
    const accessToken = useSelector((state) => state.appData.accessToken);
    
    const currentPracticeArea = params.hasOwnProperty("practiceAreaID") ? params.practiceAreaID : defaultPracticeArea
    const currentTopic = params.hasOwnProperty("topicID") ? params.topicID : defaultTopic

    const topicSelected = userTopic.practiceAreaID === params.practiceAreaID && userTopic.topicID === params.topicID
    const { isAuthenticated } = useAuth0();

    const handleLearningTopic = async(action) => {
      var learningTopic = {
        practiceAreaID: "",
        topicID: ""
      }

      if (action === "addTopic") {
        learningTopic["practiceAreaID"] = params.practiceAreaID
        learningTopic["topicID"] = params.topicID
      }
          
      dispatch(addLearningTopic(learningTopic))
      if (isAuthenticated) {
        const topicData = {
          userMongoID: userMongoID,
          practiceAreaID: learningTopic.practiceAreaID,
          topicID: learningTopic.topicID
        }
        try {
          const topicResponse = await fetch('/api/usertopic', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(topicData),
            });
          } catch(e) {console.log(e)}
      };
    };

    useEffect(() => {
      
      dispatch(setCurrentSelection({
        "selectionType":"practiceAreaID",
        "selection": currentPracticeArea
      }));

      dispatch(setCurrentSelection({
        "selectionType":"topicID",
        "selection": currentTopic
      }));

    }, [params]);
      
    // apply UI sort order, sorted subtopics looks like [["subtopicID", 1],["subtopicID", 2]]
    const currentSubTopics = skillsTree[currentPracticeArea]["topics"][currentTopic]["subTopics"];
    let sortedSubTopics = Object.keys(currentSubTopics).map((subTopic) => ([subTopic, subTopicList[subTopic]["UIOrder"]]))
    sortedSubTopics.sort((subTopicOne, subTopicTwo) => subTopicOne[1] - subTopicTwo[1])

    return (
      <Grid container>
        <Modal
          open={achievementHero.displayHero}
          onClose={() => dispatch(setAchievementHero(false))}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <AchievementHero achievementID={achievementHero.achievementID} />
        </Modal>
          {subTopic ?
          <Grid item sx={{flex: 1}}>
          <Outlet />
          </Grid>
          :
          <Grid container sx={{
            padding: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1),
            }
          }}>
            {topicList[params.topicID].hasOwnProperty("topicLongDescription") ?
            <Grid item sx={{
              width: "100%", 
              padding: theme.spacing(1),
              }}>
            <Grid item sx={{
              width: "100%",
            }}
            >
            {topicList[params.topicID]["topicLongDescription"].map((text, idx) => (
              <Typography variant="body2" key={idx} paragraph>{text}</Typography>
            ))}
            <Grid container sx={{width: "100%", justifyContent: "flex-end"}}>
              {topicSelected ?
              <Button variant="outlined" color="secondary" size="small" onClick={() => handleLearningTopic("removeTopic")} startIcon={<CloseIcon fontSize='small' color="secondary" />}>Remove from My Learning</Button>
              :
              <Button variant="outlined" size="small" onClick={() => handleLearningTopic("addTopic")} startIcon={<DoneIcon fontSize='small' color="primary" />}>Add to My Learning</Button>
              }
            </Grid>
            </Grid>
            </Grid>
            :
            null
            }
            <Grid container sx={{width: "100%", justifyContent: sortedSubTopics.length > 2 ? "center" : "flex-start"}}>
            {sortedSubTopics.map((sortedSubTopic, idx) => (
              <ModuleCard 
                subTopicID={sortedSubTopic[0]}
                currentTopic={currentTopic}
                currentPracticeArea={currentPracticeArea}
                key={idx} 
                idx={idx} 
              />
            ))}
            </Grid>
          </Grid>
          }
      </Grid>
    )
    };
export default TopicDetail;