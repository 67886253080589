import React, { useState } from 'react';

import { Grid } from '@mui/material';

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { useTheme } from '@mui/material/styles';

export default function PDFDisplay(props) {

    const theme = useTheme()
    const documentArray = props.documentArray;

    const [numPages, setNumPages] = useState(null);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }


    return (
        <Grid item sx={{
            maxWidth: "800px",
            [theme.breakpoints.down('lg')]: {
                maxWidth: "500px",
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: "350px",
            },
        }}>
            <Document file={documentArray} onLoadSuccess={onDocumentLoadSuccess}>
            {[...Array(numPages).keys()].map((pageNumber) => (
            <Page pageNumber={pageNumber+1} width={800} key={"pdfKey"+pageNumber}/>
            ))
            }
            </Document>
        </Grid>
    );
};

/*


    

    const fullWidth = useMediaQuery

    let maxWidth = 800
    let minWidth = 500


    

    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        // Convert the Uint8Array to a Blob
        const blob = new Blob([documentArray], { type: 'application/pdf' });
        // Create an object URL for the blob
        const url = URL.createObjectURL(blob);
        // Update the state with the blob URL
        setPdfUrl(url);
        console.log(url)

        // Clean up the object URL when the component unmounts
        return () => {
        URL.revokeObjectURL(url);
        };
    }, [documentArray]);
    // const getWidth = () => {
    //     //console.log(width)
    //     if (width >= maxWidth) {
    //         console.log("max width")
    //         return maxWidth
    //     } else if (width >= minWidth) {
    //                 console.log("dynamic width")
    //                 return (width - 25)
    //     }
    //     console.log("min width")
    //     return (minWidth)
    // };
    

            

*/

/*


            
        </Grid>

*/