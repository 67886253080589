import React from 'react';
import { motion } from "framer-motion"
import { Grid } from '@mui/material';

import stepsPlanIcon from '../assets/stepsPlanIcon2.png'
import outlineIcon from '../assets/outlineIcon2.png';
import documentIcon from '../assets/documentIcon2.png';

import { setScenarioUI } from '../choice/choiceSlice';
import { setEditorScenarioUI } from '../editor/editorSlice';

import { useDispatch } from 'react-redux'

const DocumentThumbnail = (props) => {

    const thumbnail = props.stripTags;
    const delay = props.delay;
    const defaultStyles = props.defaultStyles;
    const loadSource = props.loadSource;
    const readOnly = props.readOnly;

    const dispatch = useDispatch();

    const getThumbnail = () => {
        switch(thumbnail) {
            case "Steps plan icon":
              return (<img src={stepsPlanIcon} alt="Document thumbnail"/>)
            case "Document icon":
              return (<img src={documentIcon} alt="Document thumbnail"/>)
            case "Outline icon":
              return (<img src={outlineIcon} alt="Document thumbnail"/>)
            default:
              return (<img src={documentIcon} alt="Document thumbnail"/>)
        };
    };

    const handleDocDisplay = (thumbnail) => {
      if (!readOnly) {
        if (loadSource === "editor") {
          if (thumbnail === "Outline icon") {
            dispatch(setEditorScenarioUI({
              "viewResources": true,
              "viewDocuments": false,
              "viewHints": false,
              "viewPrimarySources": false,
              "viewOutline": true,
            }));
          } else {
            dispatch(setEditorScenarioUI({
              "viewResources": true,
              "viewDocuments": true,
              "viewHints": false,
              "viewPrimarySources": false,
              "viewOutline": false,
              "selectedDocumentID": "",
            }));
          }
        } else {
          if (thumbnail === "Outline icon") {
            dispatch(setScenarioUI({
              "viewResources": true,
              "viewDocuments": false,
              "viewHints": false,
              "viewPrimarySources": false,
              "viewOutline": true,
            }));
          } else {
            dispatch(setScenarioUI({
              "viewResources": true,
              "viewDocuments": true,
              "viewHints": false,
              "viewPrimarySources": false,
              "viewOutline": false,
              "selectedDocumentID": "",
            }));
          }
        }
      }
      };

    return (
        <Grid container sx={{display: "flex", justifyContent: "center",}}
        component={motion.div} 
        initial={{opacity: 0,}}
        animate={{opacity: 1,
                    transition: {                
                        delay: delay,
                        ease: "easeInOut",
                        duration: 0.5,
                    }}}
        >
        <Grid item sx={{
            padding: defaultStyles.padding,
            marginBottom: defaultStyles.marginBottom,
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            boxShadow: defaultStyles.boxShadow,
            filter: defaultStyles.saturation,
            border: "3px solid #ffc107"
        }}>
        <Grid item 
        onClick={() => handleDocDisplay(thumbnail)}
        component={motion.div} 
        whileHover={{cursor: readOnly ? "" : "pointer"}}
        >
        {getThumbnail()}
        </Grid>
        </Grid>
        </Grid>
    )
};
export default DocumentThumbnail;

// <DocumentThumbnail thumbnail={stripformatting} background="neutral"/>

/*
backgroundColor: amber[500],
const scenarioList = useSelector((state) => state.appData.scenarioList)
    const scenarioEditorAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets)
    var scenarioAssets = {}

    // where to look, depends on if used in player or in editor mode

    if (loadSource === "editor") {
        scenarioAssets = {...scenarioEditorAssets}
    } else {
        scenarioAssets = {...scenarioList[scenarioID]["scenarioAssets"]}
    };

    // there's an anchor error / warning in the media loader. not sure what that's about.

    // go back to docs loader
    // replicate a load source

    // Check if using specific thumbnails, if so set default selected document (only an issue where multiple documents - guard for when using outlines only).
    
    var selectedDocumentID = ""
    if (scenarioAssets.hasOwnProperty("documents")) {
      if (scenarioAssets.documents[0].hasOwnProperty("thumbnail")) {
        selectedDocumentID = (scenarioAssets.documents.filter(document => document.thumbnail === thumbnail).length === 1) ? scenarioAssets.documents.filter(document => document.thumbnail === thumbnail)[0]["documentID"] : ""
      }
    } 
*/