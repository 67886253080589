import React from 'react';

import { useNodeId } from 'reactflow';

import { Grid, FormControl, Select, MenuItem, Typography, InputLabel } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setNodeAction } from '../editorSlice';

const UserDocumentCondition = (props) => {

    const dispatch = useDispatch();

    const actionUUID = props.actionUUID;
    const setChangesMade = props.setChangesMade;

    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const condition = nodeData.nodeActions[actionUUID]["condition"]

    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile)
    const documentType = scenarioProfile.scenarioAssets.documents[0]["type"]

    const handleRedactionSelect = (event) => {
        const inkLabel = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        updatedAction["condition"]["data"] = { ...updatedAction["condition"]["data"] };
    
        if (updatedAction["condition"]["data"].hasOwnProperty("redactionDocument")) {
            // If "redactionDocument" exists, update only the "inkLabel"
            updatedAction["condition"]["data"]["redactionDocument"] = {
                ...updatedAction["condition"]["data"]["redactionDocument"],
                "inkLabel": inkLabel
            };
        } else {
            // If "redactionDocument" doesn't exist, replace "data" with a new object
            updatedAction["condition"]["data"] = {
                "redactionDocument": {
                    "value": "",
                    "inkLabel": inkLabel
                }
            };
        }

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    const handleRedactedValueSelect = (event) => {
        const value = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        updatedAction["condition"]["data"] = { ...updatedAction["condition"]["data"] };
    
        if (updatedAction["condition"]["data"].hasOwnProperty("redactionDocument")) {
            // If "redactionDocument" exists, update only the "value"
            updatedAction["condition"]["data"]["redactionDocument"] = {
                ...updatedAction["condition"]["data"]["redactionDocument"],
                "value": value
            };
        } else {
            // If "redactionDocument" doesn't exist, replace "data" with a new object
            updatedAction["condition"]["data"] = {
                "redactionDocument": {
                    "value": value,
                    "inkLabel": ""
                }
            };
        }

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };


    const handleMarkUpSelect = (event) => {
        const inkLabel = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        updatedAction["condition"]["data"] = { ...updatedAction["condition"]["data"] };
    
        if (updatedAction["condition"]["data"].hasOwnProperty("markupDocument")) {
            // If "markupDocument" exists, update only the "inkLabel"
            updatedAction["condition"]["data"]["markupDocument"] = {
                ...updatedAction["condition"]["data"]["markupDocument"],
                "inkLabel": inkLabel
            };
        } else {
            // If "markupDocument" doesn't exist, replace "data" with a new object
            updatedAction["condition"]["data"] = {
                "markupDocument": {
                    "value": "",
                    "inkLabel": inkLabel
                }
            };
        }

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    const handleMarkedUpValueSelect = (event) => {
        const value = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["condition"] = { ...updatedAction["condition"] };
        updatedAction["condition"]["data"] = { ...updatedAction["condition"]["data"] };
    
        if (updatedAction["condition"]["data"].hasOwnProperty("markupDocument")) {
            // If "markupDocument" exists, update only the "value"
            updatedAction["condition"]["data"]["markupDocument"] = {
                ...updatedAction["condition"]["data"]["markupDocument"],
                "value": value
            };
        } else {
            // If "markupDocument" doesn't exist, replace "data" with a new object
            updatedAction["condition"]["data"] = {
                "markupDocument": {
                    "value": value,
                    "inkLabel": ""
                }
            };
        }
        
        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    return (
        <Grid item sx={{paddingBottom: "15px"}}>
        <Typography>When:</Typography>
        <Grid container>
        {documentType === "redaction" ?
        <>
        {scenarioProfile.scenarioAssets.documents[0]["goldAnswers"].length > 0 ?
        <>
        <Grid item sx={{paddingTop: "3px"}}>
            <FormControl variant="standard" sx={{minWidth: "150px"}}>
            <InputLabel id="select-redaction">Select redaction</InputLabel>
            <Select
            labelId="select-redaction"
            id="redaction-select"
            size='small'
            value={condition.data?.redactionDocument ? condition.data.redactionDocument?.inkLabel ? condition.data.redactionDocument.inkLabel : "" : ""}
            label="select-redaction"
            onChange={handleRedactionSelect}
            >
            {scenarioProfile.scenarioAssets.documents[0]["goldAnswers"].map((redaction, index) => (
                <MenuItem value={redaction.inkLabel} key={index}>{redaction.text.slice(0,22)+"..."}</MenuItem>
            ))}
            </Select>
            </FormControl>
        </Grid>
        <Grid item sx={{paddingLeft: "8px", paddingRight: "8px", paddingTop: "18px"}}>
            <Typography>is</Typography>
        </Grid>
        <Grid item sx={{flexGrow: 1, paddingTop: "3px"}}>
            <FormControl variant="standard" sx={{minWidth: "150px"}}>
            <InputLabel id="select-redaction-value">Select user input</InputLabel>
            <Select
            labelId="select-redaction-value"
            id="redaction-value-select"
            size='small'
            value={condition.data?.redactionDocument ? condition.data.redactionDocument.hasOwnProperty("value") ? condition.data.redactionDocument.value : "" : ""}
            label="select-redaction-value"
            onChange={handleRedactedValueSelect}
            >
                <MenuItem value={true}>Redacted</MenuItem>
                <MenuItem value={false}>Not redacted</MenuItem>
            </Select>
            </FormControl>
        </Grid>
        </>
        :
        <Typography color="textSecondary" align="center" sx={{paddingTop: "3px"}}>No redactions set in the document. Create redactions to set conditions.</Typography>
        }
        </>
        :
        <>
        {documentType === "markup" ?
        <>
        {scenarioProfile.scenarioAssets.documents[0]["goldAnswers"].length > 0 ?
        <>
        <Grid item sx={{paddingTop: "3px"}}>
            <FormControl variant="standard" sx={{minWidth: "150px"}}>
            <InputLabel id="select-markup">Select markup</InputLabel>
            <Select
            labelId="select-markup"
            id="markup-select"
            size='small'
            value={condition.data?.markupDocument ? condition.data.markupDocument?.inkLabel ? condition.data.markupDocument.inkLabel : "" : ""}
            label="select-markup"
            onChange={handleMarkUpSelect}
            >
            {scenarioProfile.scenarioAssets.documents[0]["goldAnswers"].map((markup, index) => (
                <MenuItem value={markup.inkLabel} key={index}>{markup.text.slice(0,22)+"..."}</MenuItem>
            ))}
            </Select>
            </FormControl>
        </Grid>
        <Grid item sx={{paddingLeft: "8px", paddingRight: "8px", paddingTop: "18px"}}>
            <Typography>is</Typography>
        </Grid>
        <Grid item sx={{flexGrow: 1, paddingTop: "3px"}}>
            <FormControl variant="standard" sx={{minWidth: "150px"}}>
            <InputLabel id="select-markup-value">Select user input</InputLabel>
            <Select
            labelId="select-markup-value"
            id="markup-value-select"
            size='small'
            value={condition.data?.markupDocument ? condition.data.markupDocument?.value ? condition.data.markupDocument.value: "" : ""}
            label="select-markup-value"
            onChange={handleMarkedUpValueSelect}
            >
                <MenuItem value={"accept"}>Accepted</MenuItem>
                <MenuItem value={"reject"}>Rejected</MenuItem>
                <MenuItem value={"unset"}>Not set</MenuItem>
            </Select>
            </FormControl>
        </Grid>
        </>
        :
        <Typography color="textSecondary" align="center" sx={{paddingTop: "3px"}}>No mark-up set in the document. Add mark-up to set conditions.</Typography>
        }
        </>
        :
        null
        }
        </>
        }
        </Grid>
        </Grid>
    )
};
export default UserDocumentCondition;