import React, { useState, useCallback, useEffect} from 'react';

import { useNodeId } from 'reactflow';

import { Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { setNodeAction } from '../editorSlice';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

// Custom debounce function
const useDebounce = (callback, delay) => {
    const [timer, setTimer] = useState(null);

    const debouncedCallback = useCallback((...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            callback(...args);
        }, delay);
        setTimer(newTimer);
    }, [callback, delay, timer]);

    // Clean up
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return debouncedCallback;
};

const SetFeedback = (props) => {

    const dispatch = useDispatch();

    // const dispatchUpdate = (actionUpdate) => {
    //     dispatch(setNodeAction(actionUpdate));
    //     setChangesMade(true);
    // };

    // Use the custom debounce function
    //const debouncedDispatchUpdate = useDebounce(dispatchUpdate, 300);

    const actionUUID = props.actionUUID;
    const setChangesMade = props.setChangesMade;

    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const action = nodeData.nodeActions[actionUUID]["action"]

    const feedbackTypeMap = {
        correct: {label: "Correct", color: "#4caf50"},
        incorrect: {label: "Incorrect", color: "#f44336"},
    }

    const toggleFormat = () => {
        const currentFeedbackType = action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"
        const newFeedbackType = currentFeedbackType === "correct" ? "incorrect" : "correct"
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["action"] = { ...updatedAction["action"] };
        updatedAction["action"]["data"] = { ...updatedAction["action"]["data"] };
    
        if (updatedAction["action"]["data"].hasOwnProperty("feedback")) {
            // If "feedback" exists, update only the "type"
            updatedAction["action"]["data"]["feedback"] = {
                ...updatedAction["action"]["data"]["feedback"],
                "type": newFeedbackType
            };
        } else {
            // If "feedback" doesn't exist, replace "data" with a new object
            updatedAction["action"]["data"] = {
                "feedback": {
                    "content": "",
                    "type": newFeedbackType
                }
            };
        }

        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    const getFeedbackIcon = () => {
        switch (action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct") {
            case "correct":
                return(<ThumbUpIcon fontSize='small' style={{color: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"]}}/>)
            case "incorrect":
                return(<ThumbDownIcon fontSize='small' style={{color: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"]}}/>)
            default:
                return(<ThumbUpIcon fontSize='small' style={{color: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"]}}/>)
        }
    }

    const handleFeedbackChange = (event) => {
        const content = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["action"] = { ...updatedAction["action"] };
        updatedAction["action"]["data"] = { ...updatedAction["action"]["data"] };
    
        if (updatedAction["action"]["data"].hasOwnProperty("feedback")) {
            // If "feedback" exists, update only the "content"
            updatedAction["action"]["data"]["feedback"] = {
                ...updatedAction["action"]["data"]["feedback"],
                "content": content
            };
        } else {
            // If "feedback" doesn't exist, replace "data" with a new object
            updatedAction["action"]["data"] = {
                "feedback": {
                    "content": content,
                    "type": "correct"
                }
            };
        }
        // Create a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        
        // TODO debounce this. Need to weave in state though...
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    return (
        <Grid container sx={{paddingBottom: "15px"}}>
            <Grid item sx={{flexGrow: 1, paddingRight: "5px"}}>
                <TextField
                    id="feedback-input"
                    label="Set feedback"
                    value={action.data?.feedback ? action.data.feedback?.content ? action.data.feedback.content : "" : ""}
                    onChange={handleFeedbackChange}
                    variant="standard"
                    className="nodrag"
                    fullWidth
                    sx={{
                        '& label': {
                            color: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"],
                        },
                        '& label.Mui-focused': {
                            color: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"],
                        },
                        '& .MuiInput-underline': {
                            '&:after': {
                            borderBottomColor: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"],
                            },
                            '&:before': {
                            borderBottomColor: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"],
                            },
                            '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"],
                            },
                        },
                        '& input': {
                            color: feedbackTypeMap[action.data?.feedback ? action.data.feedback?.type ? action.data.feedback.type : "correct" : "correct"]["color"],
                        },
                    }}
                />
            </Grid>
            <Grid item>
                <Tooltip title="Toggle feedback type" placement='top'>
                <IconButton onClick={() => toggleFormat()} sx={{marginTop: "8px"}} className="nodrag">
                    {getFeedbackIcon()}
                </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )
};
export default SetFeedback;