import React from 'react';

import { useNodeId } from 'reactflow';

import { Grid, IconButton, Tooltip, Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionSelect from './ActionSelect';
import ActionCondition from './ActionCondition';

import { addNodeAction, removeNodeAction } from '../editorSlice';
import { useDispatch, useSelector } from 'react-redux';

import {v4 as uuidv4} from 'uuid';

const ActionNodeContainer = (props) => {
    
    // This should hold stuff like add action button and list current conditions / actions
    // TODO: have an AI output condition. So, if AI evaluation is correct / incorrect, do something.
    
    const nodes = useSelector((store) => store.editorData.nodes)
    const edges = useSelector((store) => store.editorData.edges)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const nodeActions = nodeData.hasOwnProperty("nodeActions") ? nodeData.nodeActions : {}
    const setChangesMade = props.setChangesMade;
    const nodeOptions = edges.filter((edge) => edge.source === nodeID);

    //console.log(nodeActions)

    const dispatch = useDispatch();

    const addNewNodeAction = () => {
        
        const newUUID = uuidv4();
        const newAction = {
            nodeID: nodeID,
            actionUUID: newUUID,
        }
        dispatch(addNodeAction(newAction))
        setChangesMade(true);
    };


    const removeAction = (actionUUID) => {

        const removeAction = {
            nodeID: nodeID,
            actionUUID: actionUUID
        }
        dispatch(removeNodeAction(removeAction))
        setChangesMade(true);
    };

    return (
        <Grid item>
        <Grid item sx={{paddingBottom: "3px"}}>
            <Typography variant='body2' color="textSecondary" sx={{display: "inline"}}>Configure rules</Typography>
        </Grid>
        {Object.keys(nodeActions).length > 0 ?
        <>
        {Object.keys(nodeActions).map((key, index) => {

            return(
                <Grid item sx={{marginBottom: "8px"}} key={index}>
                    <Grid container sx={{justifyContent: "space-between"}}>
                        <Grid item sx={{borderRight: "1px solid #cccccc", padding: "10px", flex: 1, backgroundColor: "#e8eaf6", borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px"}}>
                        <ActionCondition 
                            actionUUID={key}
                            setChangesMade={setChangesMade}
                        />
                        </Grid>
                        <Grid item sx={{padding: "10px", flex: 1, backgroundColor: "#e8f5e9"}}>
                        <ActionSelect 
                            actionUUID={key}
                            setChangesMade={setChangesMade}
                        />
                        </Grid>
                        <Grid item sx={{width: "40px", minHeight: "100%", backgroundColor: "#fce4ec", borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}}>
                        <Grid container sx={{height: "100%", alignItems: "center"}}>
                        <IconButton onClick={() => removeAction(key)} className="nodrag">
                            <Tooltip title="Delete this action" placement="top">
                            <DeleteIcon fontSize="medium" color='secondary'/>
                            </Tooltip>
                        </IconButton>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
        }
        </>
        :
        <Grid item sx={{paddingBottom: "3px"}}>
            <Typography variant='body2' color="secondary" sx={{display: "inline"}}>Warning: No rules configured - add rules.</Typography>
        </Grid>
        }
        <Grid item sx={{paddingBottom: "3px"}}>
            <Typography variant='body2' color="textSecondary" sx={{display: "inline"}}>Default connection: {nodeOptions.length > 0 ? `Block ${nodeOptions[0].target}` : null}</Typography>
        </Grid>
        {nodeOptions.length === 0 ?
        <Grid item sx={{paddingBottom: "3px"}}>
            <Typography variant='body2' color="secondary" sx={{display: "inline"}}>Warning: No connections. Connect to an existing block.</Typography>
        </Grid>
        :
        null
        }
        <Grid item sx={{width: "100%", padding: "20px"}}>
        <Grid container sx={{width: "100%", justifyContent: "center", alignContent: "center"}}>
            <Button size='medium' variant='outlined' onClick={() => addNewNodeAction()}>Add rule</Button>
        </Grid>
        </Grid>
        </Grid>
    )
};
export default ActionNodeContainer;



/*

                            





    const nodeActions = props.nodeActions
    const setNodeActions = props.setNodeActions
    const addNodeAction = props.addNodeAction;

*/