import { useState, useCallback, useEffect } from 'react';

import { useNodeId } from 'reactflow';

import { Grid, TextField, Tooltip, FormControl, Select, MenuItem, Stack, Typography } from '@mui/material';

import { setNodeAI } from '../editorSlice';
import { useDispatch, useSelector } from 'react-redux';

// Custom debounce function
const useDebounce = (callback, delay) => {
    const [timer, setTimer] = useState(null);

    const debouncedCallback = useCallback((...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            callback(...args);
        }, delay);
        setTimer(newTimer);
    }, [callback, delay, timer]);

    // Clean up
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return debouncedCallback;
};

const AIExplanation = (props) => {
    
    // Set prompt inputs
    
    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]

    const nodeAI = nodeData.nodeAI
    const setChangesMade = props.setChangesMade;

    const [topic, setTopic] = useState(nodeAI.promptInputs.topic);
    const [userAnalysis, setUserAnalysis] = useState(nodeAI.promptInputs.userAnalysis);
    const [correctAnalysis, setCorrectAnalysis] = useState(nodeAI.promptInputs.correctAnalysis);
    const [correctExplanation, setCorrectExplanation] = useState(nodeAI.promptInputs.correctExplanation);

    // Progressive addition of fields
    const [evaluationInstructions, setEvaluationInstructions] = useState(nodeAI.promptInputs?.evaluationInstructions ? nodeAI.promptInputs.evaluationInstructions : "");
    const [feedbackExplanation, setFeedbackExplanation] = useState(nodeAI.promptInputs?.feedbackExplanation ? nodeAI.promptInputs.feedbackExplanation : "");
    const [agentKnowledge, setAgentKnowledge] = useState(nodeAI.promptInputs?.agentKnowledge ? nodeAI.promptInputs.agentKnowledge : "knows_the_answer");
    const [agentAttitude, setAgentAttitude] = useState(nodeAI.promptInputs?.agentAttitude ? nodeAI.promptInputs.agentAttitude : "wants_to_help");

    const dispatch = useDispatch();

    const dispatchUpdate = (AIUpdate) => {
        dispatch(setNodeAI(AIUpdate));
        setChangesMade(true);
    };

    // Use the custom debounce function
    const debouncedDispatchUpdate = useDebounce(dispatchUpdate, 300);

    const handlePromptInputChange = (event) => {
        
        const promptID = event.target.id
        const promptContent = event.target.value

        switch (promptID) {
            case "topic":
                setTopic(promptContent)
                break
            case "userAnalysis":
                setUserAnalysis(promptContent)
                break
            case "correctAnalysis":
                setCorrectAnalysis(promptContent)
                break
            case "correctExplanation":
                setCorrectExplanation(promptContent)
                break
            case "evaluationInstructions":
                setEvaluationInstructions(promptContent)
                break
            case "feedbackExplanation":
                setFeedbackExplanation(promptContent)
                break
            case "agentKnowledge":
                setAgentKnowledge(promptContent)
                break
            case "agentAttitude":
                setAgentAttitude(promptContent)
                break
            default:
                break
        }

        let updatedAI = {...nodeAI}
        let updatedPromptInputs = {...nodeAI.promptInputs}
        
        // Now, update the type property and reset data
        updatedPromptInputs[promptID] = promptContent
        updatedAI["promptInputs"] = updatedPromptInputs

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        debouncedDispatchUpdate(AIUpdate)
        setChangesMade(true)
    };

    const handleAgentKnowledgeChange = (event) => {
        
        const content = event.target.value
        setAgentKnowledge(content)

        let updatedAI = {...nodeAI}
        let updatedPromptInputs = {...nodeAI.promptInputs}
        
        // Now, update the type property and reset data
        updatedPromptInputs["agentKnowledge"] = content
        updatedAI["promptInputs"] = updatedPromptInputs

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        dispatchUpdate(AIUpdate)
        setChangesMade(true)
    };

    const handleAgentAttitudeChange = (event) => {
        
        const content = event.target.value
        setAgentAttitude(content)

        let updatedAI = {...nodeAI}
        let updatedPromptInputs = {...nodeAI.promptInputs}
        
        // Now, update the type property and reset data
        updatedPromptInputs["agentAttitude"] = content
        updatedAI["promptInputs"] = updatedPromptInputs

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        dispatchUpdate(AIUpdate)
        setChangesMade(true)
    };

    return (
        <>
            <Grid item sx={{paddingTop: "10px"}}>
                <Tooltip title="Required: The topic being discussed. This will be referred to in the dynamic feedback.">
                <TextField
                    id="topic"
                    label="Topic"
                    value={topic}
                    onChange={handlePromptInputChange}
                    placeholder="E.g. limb two of the test for legal advice privilege."
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
                </Tooltip>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Tooltip title="Required: A summary of the user's analysis at this point in the scenario. This will form part of the dynamic evaluation.">
                <TextField
                    id="userAnalysis"
                    label="User analysis"
                    value={userAnalysis}
                    placeholder="E.g. a communication sent by a trainee will not be privileged."
                    onChange={handlePromptInputChange}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
                </Tooltip>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Tooltip title="Required: The correct analysis at this point in the scenario. This will form part of the dynamic evaluation.">
                <TextField
                    id="correctAnalysis"
                    label="Correct analysis"
                    value={correctAnalysis}
                    onChange={handlePromptInputChange}
                    placeholder="E.g. a communication sent by a trainee may still attract privilege."
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
                </Tooltip>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Tooltip title="Required: The explanation that the user's input will be evaluated against.">
                <TextField
                    id="correctExplanation"
                    label="Evaluation explanation"
                    value={correctExplanation}
                    onChange={handlePromptInputChange}
                    placeholder="E.g. with regard to limb two of the test, a communication sent by a trainee may still attract privilege because, for these purposes, a trainee working under the supervision of a lawyer will be a 'lawyer'."
                    multiline
                    rows={5}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
                </Tooltip>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Tooltip title="Optional: any additional instructions as to how user answers should be evaluated.">
                <TextField
                    id="evaluationInstructions"
                    label="Evaluation instructions"
                    value={evaluationInstructions}
                    onChange={handlePromptInputChange}
                    multiline
                    rows={5}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
                </Tooltip>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Tooltip title="Optional: the reference answer provided to the user in the scenario feedback (if none is set, the evaluation explanation will be used).">
                <TextField
                    id="feedbackExplanation"
                    label="Feedback explanation"
                    value={feedbackExplanation}
                    onChange={handlePromptInputChange}
                    multiline
                    rows={5}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
                </Tooltip>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Stack direction="row" spacing={1} sx={{marginBottom: "8px"}}>
                <Typography color="textSecondary">Agent knowledge:</Typography>
                <FormControl variant="standard">
                <Select
                data-id="agentKnowledge"
                size='small'
                value={agentKnowledge}
                label="Agent knowledge"
                onChange={handleAgentKnowledgeChange}
                >
                    <MenuItem value="knows_the_answer">Agent knows the answer</MenuItem>
                    <MenuItem value="doesnt_know_the_answer">Agent does not know the answer</MenuItem>
                </Select>
                </FormControl>
                </Stack>
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <Stack direction="row" spacing={1} sx={{marginBottom: "8px"}}>
                <Typography color="textSecondary">Agent attitude:</Typography>
                <FormControl variant="standard">
                <Select
                data-id="agentAttitude"
                size='small'
                value={agentAttitude}
                label="Agent attitude"
                onChange={handleAgentAttitudeChange}
                >
                    <MenuItem value="wants_to_help">Agent wants to be helpful</MenuItem>
                    <MenuItem value="doesnt_want_to_help">Agent does not want to be helpful</MenuItem>
                </Select>
                </FormControl>
                </Stack>
            </Grid>
        </>
    )
};
export default AIExplanation;