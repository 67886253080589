import React from 'react';
import { useRouteError } from "react-router-dom";
import { Grid, Typography, Link } from '@mui/material';

/*

TODO: build this out a bit
Return to LegalReflex as react-router link to avoid page refesh (loose redux progress?). Or safe to have hard link?

*/

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Grid container style={{height: "100vh", alignItems: "center"}}>
        <Grid item style={{width:"100%"}}>
          <Typography align="center" variant='h2'>Oh dear.</Typography>
          <Typography align="center" paragraph>Sorry, an unexpected error has occured.</Typography>
          <Typography align="center" paragraph color="textSecondary" style={{fontStyle: "italic"}}>{error.statusText || error.message}</Typography>
          <Typography align="center" paragraph>Return to <Link href="/learn" underline="hover">LegalReflex</Link></Typography>
        </Grid>
    </Grid>
  );
}