import React, {useState} from 'react';

import { Grid, 
        Stack,
        Typography, 
        Button, 
        Stepper, 
        Step, 
        StepLabel, 
        StepContent, 
        Box, 
        TextField, 
        IconButton,
        Tooltip,
        Checkbox,
        FormGroup,
        FormControlLabel,
        CircularProgress
       } from '@mui/material';

import HelpIcon from '@mui/icons-material/Help';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { updateScenarioProfile, setEditorScenarioUI, loadSavedScenario, loadScenarioIntoInk, } from './editorSlice';
import { updateScenarioList } from '../appData/appDataSlice';

import { useAuth0 } from "@auth0/auth0-react";

import {v4 as uuidv4} from 'uuid';

import dagre from '@dagrejs/dagre';

const EditorWizard = (props) => {

  const { user } = useAuth0();
  const activeStep = props.activeStep;
  const setActiveStep = props.setActiveStep;
  const setBuildLoading = props.setBuildLoading;
  const setCoverImage = props.setCoverImage;
  const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
  const userAccessToken = useSelector((state) => state.appData.accessToken);
  const dispatch = useDispatch();

  const [caseStudy, setCaseStudy] = useState("");
  const [factPattern, setFactPattern] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  
  const [pendingDataExtraction, setPendingDataExtraction] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    setActiveStep(7);
  };

  const handleCaseStudyChange = (event) => {
    setCaseStudy(event.target.value);
  };

  const handleFactPatternChange = (event) => {
    setFactPattern(event.target.value);
  };

  const handleTaskDescriptionChange = (event) => {
    setTaskDescription(event.target.value);
  };

  /////////// Issue and solution handling

  const defaultIssueUUID = uuidv4();

  const [issues, setIssues] = useState({
    checked: true,
    [defaultIssueUUID]: {
      title: "",
      description: "",
      solution: "",
    }
  })

  const addNewIssue = () => {
    const newUUID = uuidv4();
    setIssues(prevIssues => ({
      ...prevIssues,
      [newUUID]: {
        title: "",
        description: "",
        solution: "",
      }
    }))
  };

  const removeIssue = (issueID) => {
    setIssues(prevIssues => {
      const newIssues = { ...prevIssues };
      delete newIssues[issueID];
      return newIssues;
    });
  };

  const handleIssueChange = (issueID, key, value) => {
    setIssues(prevIssues => ({
      ...prevIssues,
      [issueID]: {
        ...prevIssues[issueID],
        [key]: value
      }
    }));
  };

  const handleIssueSpotChange = (event) => {
    setIssues(prevIssues => ({
      ...prevIssues,
      "checked": event.target.checked
    }))
  }

  /////////// Doc upload handling

  const scenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);

  const [chooseFileData, setChooseFileData] = useState(null)
  const [wizardScenarioDocument, setWizardScenarioDocument] = useState(false);

  const handleFileSelect = (event) => {
    const fileList = event.target.files
    setChooseFileData(fileList[0])
    setWizardScenarioDocument(true)
  };

  const removeDocument = () => {
    setChooseFileData(null);
    setWizardScenarioDocument(false)
  };

  const fileTypeMap = {
    "application/pdf": "PDF"
  };

  ////////// Scenario builder
  
  const getNodeHeight = (node) => {
      const height = node.data.hasOwnProperty("text") ? Object.keys(node.data.text).length * 100 : 65
      return (height)
  }

  const getNodePositions = (nodes, edges, nodeID, edgeID) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 350;
    const nodeHeight = 36;
    
    dagreGraph.setGraph({ rankdir: "TB" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: getNodeHeight(node) });
    });
  
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
    
    dagre.layout(dagreGraph);
  
    const newNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      const newNode = {
        ...node,
        targetPosition: 'top',
        sourcePosition: 'bottom',
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        position: {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        },
      };
  
      return newNode;
    });

    return { nodes: newNodes, edges, nodeID, edgeID };
  }

  const showEditorGuide = () => {
    dispatch(setEditorScenarioUI({
      "viewResources": true,
      "viewHints": true,
      "viewDocuments": false,
      "viewPrimarySources": false,
      "viewOutline": false,
    }));
  };

  const extractCaseStudyData = async () => {

    // Handle build
    if (caseStudy === "") {
      handleNext();
      return
    } else {
      // Set an iterstial
      setPendingDataExtraction(true)
      try {
        //console.log("Sending case study extract data...");
        // Get scenario build data
        const extractResponse = await fetch('/api/editor/extractscenario', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"caseStudy": caseStudy}),
        });

        const extractData = await extractResponse.json();
        //console.log(extractData)
        setFactPattern(extractData.data.fact_pattern)
        setTaskDescription(extractData.data.task_description)
        
        var parsedIssues = {
          "checked": true
        }
        for (const issue of extractData.data.issues) {
          const issueKey = uuidv4()
          parsedIssues[issueKey] = {
            title: issue.issue_title,
            description: issue.issue_description,
            solution: issue.issue_solution,
          }
        };
        setIssues(parsedIssues);
        handleNext();
      }
      catch (e) {
        console.log(e);
      }
    }
  };

  const buildScenario = async () => {
    
    // Set loading screen
    handleNext();
    setBuildLoading({ "message": "Step 1/3: Building scenario...", "progress": 33 });

    var wizardData = {
      factPattern: factPattern,
      scenarioDocument: wizardScenarioDocument,
      taskDescription: taskDescription,
      issues: issues
    }
    
    try {
        //console.log("Sending scenario build data...");
        // Get scenario build data
        const buildResponse = await fetch('/api/editor/buildscenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(wizardData),
        });

        const scenarioData = await buildResponse.json();
        //console.log("Scenario build data received:", scenarioData);

        const coverImageDescription = scenarioData.data["metaData"]["cover_image_description"];

        // Cover image generation flow
        const getCoverImage = async (coverImageDescription) => {
            setBuildLoading({ "message": "Step 2/3: Generating scenario art...", "progress": 66 });

            try {
                //console.log("Requesting cover image generation...");
                const imageResponse = await fetch('/api/editor/generatecoverimage', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userAccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "imageDescription": coverImageDescription }),
                });

                const imageResponseData = await imageResponse.json();
                //console.log("Cover image response data:", imageResponseData);

                if (imageResponseData.error) {
                    console.error('Error:', imageResponseData.data);
                    return null;
                }

                // Directly use the base64 data returned by the server
                const base64Image = imageResponseData.data.image_data;

                // Set image data for display
                return base64Image;
            }
            catch (error) {
                console.error('Error fetching image:', error);
                return null;
            }
        };

        // Document upload flow
        const writeDocumentData = async (formData) => {
            try {
                //console.log("Uploading document data...");
                const scenarioResponse = await fetch('/api/editor/savedocument', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userAccessToken}`,
                    },
                    body: formData,
                });

                const responseData = await scenarioResponse.json();
                //console.log("Document upload response data:", responseData);

                if (responseData.insertedCount === 1) {
                    //console.log("Saved an image");
                    setChooseFileData(null);
                }
            }
            catch (e) {
                console.log(e);
            }
        };

        // Scenario meta data flow
        const writeScenarioMetaData = async (scenarioUpdateData) => {
            //console.log("Writing metadata...");
            try {
                const scenarioResponse = await fetch('/api/editor/saveScenario', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userAccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(scenarioUpdateData),
                });

                const responseData = await scenarioResponse.json();
                //console.log("Scenario metadata response data:", responseData);
                if (responseData.modified > 0 || responseData.upsertID !== "") {
                    dispatch(updateScenarioProfile(scenarioUpdateData));
                    dispatch(loadSavedScenario(scenarioUpdateData.editorData))
                    dispatch(updateScenarioList(scenarioUpdateData));
                }
            }
            catch (e) {
                console.log(e);
            }
        };

        const scenarioUUID = uuidv4();
        const imageDocID = uuidv4();
        var scenarioAssetsUpdate = { ...scenarioAssets };

        // Call cover image generation
        //console.log("Generating cover image...");
        const base64Image = await getCoverImage(coverImageDescription);

        if (base64Image) {
            //console.log("Cover image generated.");
            setCoverImage({ data: base64Image, documentID: imageDocID, type: "image/png" });

            // Write to backend
            const base64Encoded = "data:image/png;base64," + base64Image;
            const byteString = atob(base64Encoded.split(',')[1]);
            const mimeString = base64Encoded.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([ab], { type: mimeString });

            // Create FormData and append Blob
            let imageFormData = new FormData();
            imageFormData.append("file", blob, "generated_cover_image" + imageDocID + ".png");
            imageFormData.append("documentID", imageDocID);
            await writeDocumentData(imageFormData);

        } else {
            console.error('Failed to generate image.');
        }
        setBuildLoading({ "message": "Step 3/3: Tidying up...", "progress": 100 });
        scenarioAssetsUpdate["coverImage"] = [{ type: "image/png", documentID: imageDocID, documentName: "generated_cover_image" + imageDocID }];

        // If document uploaded, call this flow
        if (wizardScenarioDocument) {
            const newDocumentUUID = uuidv4();
            scenarioAssetsUpdate["documents"] = [{ type: "pdf", documentID: newDocumentUUID, documentName: chooseFileData.name }];
            let formData = new FormData();
            formData.append("file", chooseFileData);
            formData.append("documentID", newDocumentUUID);
            await writeDocumentData(formData);
        }

        // Construct learning notes
        //console.log("Constructing learning notes...")
        //console.log("Issues:", wizardData.issues)
        let learningNotes = {"text": {}}
        let index = 0
        for (const issue in wizardData.issues) {
          if (issue !== "checked") {
            learningNotes["text"][index] = wizardData.issues[issue]["solution"]
            index += 1
          }
        }
        //console.log("Learning notes", learningNotes)

        // Layout nodes
        //console.log("Laying out nodes...")
        const layoutData = getNodePositions(
          scenarioData.data.nodes,
          scenarioData.data.edges,
          scenarioData.data.nodeID,
          scenarioData.data.edgeID,
        );
        //console.log("Layout data",layoutData)

        const profileUpdate = {
            scenarioID: scenarioUUID,
            scenarioName: scenarioData.data.metaData.title,
            scenarioShortDescription: scenarioData.data.metaData.sub_title,
            scenarioLongDescription: scenarioData.data.metaData.description,
            learningNotes: learningNotes,
            author: {
                ID: user.sub,
                email: user.email
            },
            wizardData: wizardData,
            scenarioAssets: scenarioAssetsUpdate,
        };

        const editorData = {
          nodes: layoutData.nodes,
          edges: layoutData.edges,
          nodeID: layoutData.nodeID,
          edgeID: layoutData.edgeID
        };

        var scenarioUpdateData = {
            ...scenarioProfile,
            ...profileUpdate,
            editorData: editorData,
            lastModified: Date.now(),
        };

        // Call meta data write
        await writeScenarioMetaData(scenarioUpdateData);
        handleNext();
    }
    catch (e) {
        console.log(e);
    }
  };

  return (
      <Grid item sx={{
        width: "100%", 
        padding: "30px",
        maxHeight: "calc(100vh - 65px)",
        overflow: "auto",
        '&::-webkit-scrollbar': {
          width: "20px",
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: "#fafafa",
          borderTopRightRadius: "10px",
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "#d6dee1",
          borderRadius: "20px",
          border: "6px solid transparent",
          backgroundClip: "content-box",
        },
        '&::-webkit-scrollbar-thumb-hover': {
          backgroundColor: "#a8bbbf",
        },
      }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={"step1"}>
          <StepLabel>
            Quick start
          </StepLabel>
          <StepContent>
            {pendingDataExtraction ? 
            <Grid container 
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{paddingTop: "46px", paddingBottom: "26px"}}
            >
              <Grid item>
              <Typography variant='body2' paragraph>Reading case study data...</Typography>
              </Grid>
              <Grid item>
              <CircularProgress variant="indeterminate" />
              </Grid>
            </Grid>
            :
            <>
            <Typography variant='body2' paragraph>Provide your case study details and click Build to generate a wireframe scenario.</Typography>
            <Grid container sx={{maxWidth: "600px"}}>
              <Grid item sx={{width: "100%", marginBottom: "15px"}}>
                <TextField
                  id="case-study-multiline"
                  label="Case study"
                  value={caseStudy}
                  onChange={handleCaseStudyChange}
                  fullWidth
                  size="small"
                  multiline
                  rows={15}
                  variant="outlined"
                  sx={{
                    '& .MuiInputBase-inputMultiline': {
                        '&::-webkit-scrollbar': {
                            width: "20px",
                        },
                        '&::-webkit-scrollbar-track': {
                            borderTopRightRadius: "10px",
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: "#d6dee1",
                            borderRadius: "20px",
                            border: "6px solid transparent",
                            backgroundClip: "content-box",
                        },
                        '&::-webkit-scrollbar-thumb-hover': {
                            backgroundColor: "#a8bbbf",
                        },
                    }
                  }}
                />
              </Grid>
              <Grid item sx={{width: "100%"}}>
                <Typography paragraph variant='body2' color="textSecondary">Optional: Upload a PDF to include in your scenario.</Typography>
                {chooseFileData ? 
                  <Grid container sx={{width: "100%", alignItems: "center", margin: "20px 0px 20px 0px", backgroundColor: "#fafafa", padding: "10px", borderRadius: "15px"}}>
                    <Grid item sx={{width: "75%", paddingRight: "20px"}}>
                    <Typography>{chooseFileData["name"]}</Typography>
                    </Grid>
                    <Grid item sx={{flex: 1}}>
                    <Typography >{fileTypeMap[chooseFileData["type"]]}</Typography>
                    </Grid>
                    <Grid item>
                    <Button size="small" variant='outlined' color='secondary' onClick={removeDocument}>Remove</Button>
                    </Grid>
                  </Grid>
                :
                null
                }
                <Grid item sx={{marginBottom: "12px"}}>
                <Button variant="outlined" size='medium' disabled={scenarioAssets.hasOwnProperty("documents") || (chooseFileData ? true : false)} component="label" onChange={handleFileSelect}>
                Select document
                <input hidden type="file" />
                </Button>
                </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{width: "100%", justifyContent: "flex-end"}}>
            <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  onClick={handleNext}
                  color='secondary'
                  sx={{ mt: 1, mr: 1 }}
                >
                  Skip quick start
                </Button>
                <Button
                  variant="contained"
                  onClick={async () => {
                    extractCaseStudyData()
                  }}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
            </Stack>
            </Grid>
            </>
            }
          </StepContent>
        </Step>
        <Step key={"step2"}>
          <StepLabel>
            Fact pattern
          </StepLabel>
          <StepContent>
            <Grid container sx={{maxWidth: "600px"}}>
              <Grid item sx={{width: "100%"}}>
              <Typography variant='body2' paragraph>What are the background facts for the scenario?</Typography>
              <Typography variant='body2' paragraph>Add a few sentences and upload any relevant documents.</Typography>
              </Grid>
              <Grid item sx={{width: "100%", marginBottom: "15px"}}>
                <TextField
                  id="background-fact-multiline"
                  label="Fact pattern"
                  value={factPattern}
                  onChange={handleFactPatternChange}
                  size='small'
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                  sx={{
                    '& .MuiInputBase-inputMultiline': {
                        '&::-webkit-scrollbar': {
                            width: "20px",
                        },
                        '&::-webkit-scrollbar-track': {
                            borderTopRightRadius: "10px",
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: "#d6dee1",
                            borderRadius: "20px",
                            border: "6px solid transparent",
                            backgroundClip: "content-box",
                        },
                        '&::-webkit-scrollbar-thumb-hover': {
                            backgroundColor: "#a8bbbf",
                        },
                    }
                  }}
                />
              </Grid>
              <Grid item sx={{width: "100%"}}>
                <Typography paragraph variant='body2' color="textSecondary">Upload a PDF to include in your scenario.</Typography>
                {chooseFileData ? 
                  <Grid container sx={{width: "100%", alignItems: "center", margin: "20px 0px 20px 0px", backgroundColor: "#fafafa", padding: "10px", borderRadius: "15px"}}>
                    <Grid item sx={{width: "75%", paddingRight: "20px"}}>
                    <Typography>{chooseFileData["name"]}</Typography>
                    </Grid>
                    <Grid item sx={{flex: 1}}>
                    <Typography >{fileTypeMap[chooseFileData["type"]]}</Typography>
                    </Grid>
                    <Grid item>
                    <Button size="small" variant='outlined' color='secondary' onClick={removeDocument}>Remove</Button>
                    </Grid>
                  </Grid>
                :
                null
                }
                <Grid item sx={{marginBottom: "12px"}}>
                <Button variant="outlined" size='medium' disabled={scenarioAssets.hasOwnProperty("documents") || (chooseFileData ? true : false)} component="label" onChange={handleFileSelect}>
                Select document
                <input hidden type="file" />
                </Button>
                </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{"width": "100%", "justifyContent": "space-between"}}>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size='medium'
                onClick={() => {
                  handleNext();
                  setPendingDataExtraction(false);
                }}
                sx={{ mt: 1, mr: 1 }}
              >
                Continue
              </Button>
              <Button
                disabled={false}
                size='medium'
                onClick={() => {
                  handleBack();
                  setPendingDataExtraction(false);
                }}
                sx={{ mt: 1, mr: 1 }}
              >
                Back
              </Button>
            </Stack>
            <Button
              variant="outlined"
              onClick={handleSkip}
              color='secondary'
              sx={{ mt: 1, mr: 1 }}
            >
              Exit wizard
            </Button>
            </Grid>
          </StepContent>
        </Step>
        <Step key={"step3"}>
          <StepLabel>
            Task description
          </StepLabel>
          <StepContent>
            <Grid container sx={{maxWidth: "600px"}}>
              <Grid item sx={{width: "100%"}}>
              <Typography variant='body2' paragraph>What task will the user carry out in the scenario?</Typography>
              <Typography variant='body2' paragraph>Add a few sentences.</Typography>
              </Grid>
              <Grid item sx={{width: "100%", marginBottom: "15px"}}>
                <TextField
                  id="task-multiline"
                  label="Task description"
                  value={taskDescription}
                  onChange={handleTaskDescriptionChange}
                  fullWidth
                  multiline
                  rows={5}
                  variant="outlined"
                  size="small"
                  sx={{
                    '& .MuiInputBase-inputMultiline': {
                        '&::-webkit-scrollbar': {
                            width: "20px",
                        },
                        '&::-webkit-scrollbar-track': {
                            borderTopRightRadius: "10px",
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: "#d6dee1",
                            borderRadius: "20px",
                            border: "6px solid transparent",
                            backgroundClip: "content-box",
                        },
                        '&::-webkit-scrollbar-thumb-hover': {
                            backgroundColor: "#a8bbbf",
                        },
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="medium"
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                Continue
              </Button>
              <Button
                disabled={false}
                size="medium"
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Back
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step key={"step4"}>
          <StepLabel>
            Issues
          </StepLabel>
          <StepContent>
            <Grid item sx={{maxWidth: "600px"}}>
              <Grid item sx={{width: "100%"}}>
              <Typography variant='body2' paragraph>What are the legal issues in the scenario?</Typography>
              <Typography variant='body2' paragraph>Add a title and description for each issue.</Typography>
              </Grid>
              <Grid item sx={{width: "100%", marginBottom: "15px"}}>
                {Object.keys(issues)
                .filter(issueID => issueID !== "checked")
                .map((issueID, idx) => (
                  <Grid container key={idx} sx={{justifyContent: "space-between", marginBottom: "24px"}}>
                  <Grid item sx={{flex: 1, paddingRight: "6px"}}>
                  <TextField
                      id="issue-title"
                      label="Issue title"
                      size="small"
                      value={issues[issueID].title}
                      onChange={e => handleIssueChange(issueID, "title", e.target.value)}
                      variant="outlined"
                      fullWidth
                      sx={{marginBottom: "15px"}}
                  />
                    <TextField
                      id="issue-description-multiline"
                      label="Issue description"
                      value={issues[issueID].description}
                      onChange={e => handleIssueChange(issueID, "description", e.target.value)}
                      fullWidth
                      multiline
                      rows={5}
                      variant="outlined"
                      size="small"
                      sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                      }}
                    />
                  </Grid>
                  <Grid item sx={{width: "40px", minHeight: "100%", backgroundColor: Object.keys(issues).length === 2 ? "#e0e0e0" : "#fce4ec", borderTopRightRadius: "3px", borderBottomRightRadius: "3px"}}>
                  <Grid container sx={{height: "100%", alignItems: "center"}}>
                  <IconButton disabled={Object.keys(issues).length === 2} onClick={() => removeIssue(issueID)} className="nodrag">
                      <Tooltip title="Delete this issue" placement="top">
                      <DeleteIcon fontSize="medium" color={Object.keys(issues).length === 2 ? "disabled" : "secondary" } />
                      </Tooltip>
                  </IconButton>
                  </Grid>
                  </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item sx={{width: "100%", marginBottom: "24px"}}>
              <Grid container sx={{width: "100%", justifyContent: "center", alignContent: "center"}}>
                  <Button size='medium' variant='outlined' onClick={() => addNewIssue()}>Add another issue</Button>
              </Grid>
              </Grid>
              <Grid item sx={{width: "100%", marginBottom: "24px"}}>
              <Typography variant='body2'>Should the user have to 'spot' the issues, or just describe them?</Typography>
              <FormGroup>
              <FormControlLabel control={<Checkbox checked={issues.checked} onChange={handleIssueSpotChange} />} label={<Typography variant="body2">Issue spot</Typography>} />
              </FormGroup>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ mt: 1, mr: 1 }}
              >
                Continue
              </Button>
              <Button
                disabled={false}
                onClick={handleBack}
                sx={{ mt: 1, mr: 1 }}
              >
                Back
              </Button>
            </Stack>
          </StepContent>
        </Step>
        <Step key={"step5"}>
          <StepLabel>
            Possible solutions
          </StepLabel>
          <StepContent>
            <Grid container sx={{maxWidth: "600px"}}>
              <Grid item sx={{width: "100%"}}>
              <Typography variant='body2' paragraph>How might the issues be resolved? Add a few sentences for each issue.</Typography>
              </Grid>
              {Object.keys(issues)
              .filter(issueID => issueID !== "checked")
              .map((issueID, idx) => (
                <Grid item key={idx} sx={{width: "100%", marginBottom: "15px"}}>
                  <TextField
                    id="solution-multiline"
                    label={issues[issueID].title === "" ? `Issue ${idx+1}` : issues[issueID].title}
                    value={issues[issueID].solution}
                    onChange={e => handleIssueChange(issueID, "solution", e.target.value)}
                    fullWidth
                    multiline
                    rows={5}
                    variant="outlined"
                    size='small'
                    sx={{
                      '& .MuiInputBase-inputMultiline': {
                          '&::-webkit-scrollbar': {
                              width: "20px",
                          },
                          '&::-webkit-scrollbar-track': {
                              borderTopRightRadius: "10px",
                          },
                          '&::-webkit-scrollbar-thumb': {
                              backgroundColor: "#d6dee1",
                              borderRadius: "20px",
                              border: "6px solid transparent",
                              backgroundClip: "content-box",
                          },
                          '&::-webkit-scrollbar-thumb-hover': {
                              backgroundColor: "#a8bbbf",
                          },
                      }
                    }}
                  />
                </Grid>
              ))
              }
            </Grid>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Continue
                </Button>
                <Button
                  disabled={false}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
        <Step key={"step6"}>
          <StepLabel>
            Customisation
          </StepLabel>
          <StepContent>
            <Typography variant='body2'>You have now completed the setup process. Click Build to generate and continue editing your wireframe scenario.</Typography>
            <Typography variant='body2'>You can access the Editor Guide at any point by clicking <IconButton onClick={() => showEditorGuide()}><HelpIcon color="primary" fontSize="small" /></IconButton> or selecting "Editor Guide" in the scenario preview window.</Typography>
            <Box sx={{ mb: 2 }}>
              <div>
                <Button
                  variant="contained"
                  onClick={async () => {
                    await buildScenario();
                    dispatch(loadScenarioIntoInk());
                  }}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Build
                </Button>
                <Button
                  disabled={false}
                  onClick={handleBack}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Back
                </Button>
              </div>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      </Grid>
  )
};

export default EditorWizard;