import React from "react";

function Arbitration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 100 100"
      xmlSpace="preserve"
    >
      <circle
        cx="50"
        cy="50"
        r="50"
        fill="#ff7069"
        strokeWidth="0.195"
      ></circle>
      <path
        fill="#ffe356"
        strokeWidth="0.195"
        d="M89.267 80.938l-1.4-4.41s8.563-8.236 7.607-18.805c-.957-10.57-11.62-17.22-22.422-14.326-10.803 2.87-12.6 11.55-12.646 12.95-.047 1.422 1.283 5.062.326 7.535-.956 2.473-4.41 6.37-4.083 7.536.327 1.167 3.29 1.354 3.29 1.354s.047 1.12-.35 1.353c-.396.233-.163 1.167.467 1.68.653.513 1.726.863 1.726.863s-1.236.28-1.073 1.167c.163.886.607 1.33 1.517 1.353.91.047 1.166-.397 1.33.07.163.49.256.513.256.513s-.91 2.38-.116 4.153c.793 1.75 2.03 1.774 3.476 1.4 1.447-.35 4.246-1.166 4.246-1.166l.77 3.896c.327 2.263-.093 4.083-.723 5.46-.117.28-.327.933-.607 1.89A50.351 50.351 0 0089.29 80.89z"
      ></path>
      <g fill="#fcd34e" transform="scale(.19531)">
        <path d="M327.436 432.441c4.062 6.451 9.915 6.331 16.485 4.659 7.406-1.792 21.741-5.973 21.741-5.973l3.942 19.95c1.434 9.915 0 18.158-2.389 24.847 13.738-19.591 3.584-34.524 5.376-41.93 1.792-7.406 30.462-14.454 36.435-18.994 5.973-4.659 10.273-17.322 10.273-17.322s-5.973 9.198-12.424 14.813-56.265 20.308-65.822 23.414c-9.079 2.986-13.26-2.867-13.738-3.464z"></path>
        <path d="M360.526 226.733c34.524-12.663 75.259-5.853 99.987 19.472 29.506 30.223 36.435 85.055-15.171 143.231l10.751 26.281.956-1.195-7.168-22.578s43.841-42.169 38.944-96.284c-4.898-54.115-59.49-88.161-114.8-73.348a121.275 121.275 0 00-13.618 4.539z"></path>
      </g>
      <path
        fill="#ffe356"
        strokeWidth="0.195"
        d="M10.733 80.938l1.4-4.41S3.57 68.292 4.526 57.723c.957-10.57 11.62-17.22 22.422-14.326 10.803 2.87 12.6 11.55 12.646 12.95.047 1.422-1.283 5.062-.326 7.535.956 2.473 4.41 6.37 4.083 7.536-.327 1.167-3.29 1.354-3.29 1.354s-.047 1.12.35 1.353c.396.233.163 1.167-.467 1.68-.653.513-1.726.863-1.726.863s1.236.28 1.073 1.167c-.163.886-.607 1.33-1.517 1.353-.91.047-1.166-.397-1.33.07-.163.49-.256.513-.256.513s.91 2.38.116 4.153c-.793 1.75-2.03 1.774-3.476 1.4-1.447-.35-4.246-1.166-4.246-1.166l-.77 3.896c-.327 2.263.093 4.083.723 5.46.117.28.327.956.607 1.89A50.351 50.351 0 0110.71 80.89z"
      ></path>
      <g fill="#fcd34e" transform="scale(.19531)">
        <path d="M184.683 432.441c-4.062 6.451-9.915 6.331-16.485 4.659-7.406-1.792-21.741-5.973-21.741-5.973l-3.942 19.95c-1.434 9.915 0 18.158 2.389 24.847-13.738-19.591-3.584-34.524-5.376-41.93-1.792-7.406-30.462-14.454-36.435-18.994-5.973-4.659-10.273-17.322-10.273-17.322s5.973 9.198 12.424 14.813 56.265 20.308 65.822 23.414c9.079 2.986 13.26-2.867 13.738-3.464z"></path>
        <path d="M151.593 226.733c-34.524-12.663-75.259-5.853-99.987 19.472-29.506 30.222-36.435 85.054 15.171 143.23l-10.751 26.281-.956-1.195 7.168-22.578s-43.841-42.169-38.944-96.284c4.897-54.115 59.49-88.161 114.8-73.348a121.275 121.275 0 0113.618 4.539z"></path>
      </g>
      <path
        fill="#ffe356"
        strokeWidth="0.195"
        d="M49.417 10.43h20.368a2.818 2.818 0 012.823 2.823v13.159a2.818 2.818 0 01-2.823 2.823h-4.97l3.36 4.97-12.225-4.783a2.583 2.583 0 00-1.027-.187h-5.506a2.818 2.818 0 01-2.823-2.823v-13.16a2.818 2.818 0 012.823-2.823z"
      ></path>
      <path
        fill="#fcd34e"
        strokeWidth="0.195"
        d="M61.76 31.708l-5.834-2.287a2.583 2.583 0 00-1.026-.186h-5.507a2.818 2.818 0 01-2.823-2.824v-6.673h12.226c1.61 0 2.94 1.33 2.94 2.94v9.006z"
      ></path>
      <path
        fill="#fff"
        strokeWidth="0.195"
        d="M52.31 17.265h14.116a.27.27 0 00.28-.28v-.21a.27.27 0 00-.28-.28H52.31a.27.27 0 00-.28.28v.21c0 .164.116.28.28.28zm0 3.897h14.116a.27.27 0 00.28-.28v-.21a.27.27 0 00-.28-.28H52.31a.27.27 0 00-.28.28v.21c0 .163.116.28.28.28zm0-1.96h14.116a.27.27 0 00.28-.28v-.21a.27.27 0 00-.28-.28H52.31a.27.27 0 00-.28.28v.21c0 .163.116.28.28.28zm0 3.896h14.116a.27.27 0 00.28-.28v-.21a.27.27 0 00-.28-.28H52.31a.27.27 0 00-.28.28v.21c0 .164.116.28.28.28z"
      ></path>
      <path
        fill="#fefefe"
        strokeWidth="0.195"
        d="M58.33 20.322H35.393a2.818 2.818 0 00-2.823 2.823v15.026a2.818 2.818 0 002.823 2.823h5.74l-3.686 5.46 13.485-5.274c.35-.14.654-.186 1.027-.186h6.37a2.818 2.818 0 002.823-2.823V23.145a2.818 2.818 0 00-2.824-2.823z"
      ></path>
      <path
        fill="#c6cbd6"
        strokeWidth="0.195"
        d="M54.9 34.274H39.337a.27.27 0 01-.28-.28v-.303a.27.27 0 01.28-.28H54.9a.27.27 0 01.28.28v.303a.27.27 0 01-.28.28zm0-2.146H39.337a.27.27 0 01-.28-.28v-.303a.27.27 0 01.28-.28H54.9a.27.27 0 01.28.28v.303a.27.27 0 01-.28.28zm0-2.147H39.337a.27.27 0 01-.28-.28v-.303a.27.27 0 01.28-.28H54.9a.27.27 0 01.28.28v.303a.27.27 0 01-.28.28zm0-2.146H39.337a.27.27 0 01-.28-.28v-.304a.27.27 0 01.28-.28H54.9a.27.27 0 01.28.28v.304a.27.27 0 01-.28.28z"
      ></path>
    </svg>
  );
}

export default Arbitration;