import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { Box, Stack, Grid, Typography } from '@mui/material';

import { useSelector } from 'react-redux';

/*



*/

const Users = () => {

    const userAccessToken = useSelector((state) => state.appData.accessToken);
    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalActiveUsers, setTotalActiveUsers] = useState(0);
    const [totalScenarios, setTotalScenarios] = useState(0);

    useEffect(() => {

        const loadUserData = async () => {
            try {
                const userResponse = await fetch('/api/analytics/users', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                }
                });
                const userData = await userResponse.json()

                // Define grid columns
                let gridColumns = [
                    { field: 'id', headerName: 'ID', width: 90 },
                    {
                        field: 'userID',
                        type: 'string',
                        headerName: 'User Name',
                        width: 200,
                        editable: false,
                    },
                    {
                        field: 'scenarioCount',
                        type: 'number',
                        headerName: 'Scenario Count',
                        width: 150,
                        editable: false,
                    },
                    {
                        field: 'lastLogin',
                        type: 'date',
                        headerName: 'Last Login',
                        width: 150,
                        valueFormatter: (value) => {
                            if (value.valueOf() === 0) {
                              return 'N/A';
                            }
                            return `${value.toDateString()}`;
                        },
                        editable: false
                    }
                ]

                for (const scenario in scenarioList) {
                    if (scenarioList[scenario]?.status === "published") {
                        gridColumns.push(
                            {
                                field: scenarioList[scenario].scenarioID,
                                headerName: scenarioList[scenario].scenarioName + ": " + scenarioList[scenario].scenarioShortDescription,
                                width: 200,
                                editable: false
                            }
                        )
                    }
                }
                setColumns(gridColumns)

                // Define grid rows

                let gridRows = []
                let IDCount = 1
                let activeUserCount = 0
                let completedScenariosCount = 0
                for (const user of userData.userData) {
                    let userEntry = {
                        id: IDCount,
                        userID: user.userID,
                        scenarioCount: user.scenarioCount,
                        lastLogin: new Date(user.lastLogin)
                    }
                    completedScenariosCount += user.scenarioCount
                    let isActiveUser = false

                    for (const scenarioColumn of gridColumns.slice(4)) {
                        if (user.userScenarios.includes(scenarioColumn.field)) {
                            userEntry[scenarioColumn.field] = "Completed"
                            isActiveUser = true
                        } else {
                            userEntry[scenarioColumn.field] = "N/A"
                        }
                    }
                    gridRows.push(userEntry)
                    IDCount += 1
                    if (isActiveUser) {
                        activeUserCount += 1
                    }
                }

                setRows(gridRows)
                setTotalActiveUsers(activeUserCount)
                setTotalScenarios(completedScenariosCount)
                setLoading(false)
            } 
            catch(e) {console.log(e)}
        };
        loadUserData();

    },[userAccessToken])

    return (
        <Grid item sx={{
            maxWidth: `calc(100vw - 250px)`,
            backgroundColor: "#ffffff",
            padding: "20px",
            marginLeft: "10px"
        }}>
            <Typography variant='h4' sx={{color: "#616161"}} paragraph>Users</Typography>

            <Grid sx={{padding: "16px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px", marginBottom: "24px"}}>
            <Stack direction="row" spacing={2}>
            <Grid item sx={{
                    padding: "10px", 
                    borderRadius: "15px", 
                    borderLeft: "1px solid #cccccc66", 
                    borderTop: "1px solid #cccccc66", 
                    boxShadow: "3px 3px 3px #cccccc",
                    
                    }}>
            <Grid container sx={{width: "100%", height: "100%", alignContent: "center", justifyContent: "center"}}>
                <Grid item>
                <Typography variant='h5' align="center" color="primary" sx={{ fontWeight: "bold"}}>{totalActiveUsers}</Typography>
                <Typography variant='body2' align="center" >{"Users trained"}</Typography>
                </Grid>
            </Grid>
            </Grid>
            <Grid item sx={{
                    padding: "10px", 
                    borderRadius: "15px", 
                    borderLeft: "1px solid #cccccc66", 
                    borderTop: "1px solid #cccccc66", 
                    boxShadow: "3px 3px 3px #cccccc",
                    
                    }}>
            <Grid container sx={{width: "100%", height: "100%", alignContent: "center", justifyContent: "center"}}>
                <Grid item>
                <Typography variant='h5' align="center" color="primary" sx={{fontWeight: "bold"}}>{totalScenarios}</Typography>
                <Typography variant='body2' align="center">{"Scenarios completed"}</Typography>
                </Grid>
            </Grid>
            </Grid>
            </Stack>
            </Grid>

            <Box sx={{ 
                height: 800, 
                width: '100%',
                }}>
                <DataGrid
                    rows={rows}
                    loading={loading}
                    columns={columns}
                    initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 50,
                        },
                    },
                    }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                />
            </Box>
        </Grid>
    )
    };
export default Users;