import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import { useSelector, useDispatch } from 'react-redux'

import { makeChoice, setAIDialogue, handlePlayerError, setScenarioUI} from './choiceSlice'
import { updateScenarioScore, completeScenario } from '../appData/appDataSlice';

import { useAuth0 } from "@auth0/auth0-react";

import { useParams } from "react-router-dom";

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Typography, Grid, IconButton, TextField, Button } from '@mui/material';

export default function Choice() {

  const { isAuthenticated } = useAuth0();

  let params = useParams();

  const scenarioID = params.scenarioID;
  const userMongoID = useSelector((state) => state.appData.userData.mongoID);
  const userAccessToken = useSelector((state) => state.appData.accessToken);

  const choices = useSelector((state) => state.choices.parseChoices)
  const penultimate = useSelector((state) => state.choices.parseChoices)[0].text === "Complete"
  const scenarioTotalPoints = useSelector((state) => state.choices.globals.total_points);
  const userPoints = useSelector((state) => state.choices.globals.points_received);  
  const sceneText = useSelector((state) => state.choices.sceneText);
  const fullText = useSelector((state) => state.choices.fullText);
  const sessionID = useSelector((state) => state.choices.sessionID);
  const version = useSelector((state) => state.choices.version);

  const achievementsList = useSelector((state) => state.appData.achievementsList);
  const scenarioList = useSelector((state) => state.appData.scenarioList);
  const userAchievements = useSelector((state) => state.appData.userData.userAchievements);
  const currentAchievement = useSelector((state) => state.appData.userData.nextAchievement);
  const weekScore = useSelector((state) => state.appData.userData.weekScore);
  const currentSelection = useSelector((state) => state.appData.currentSelection);

  const [userInputText, setUserInputText] = useState("");
  const [pendingAIResponse, setPendingAIResponse] = useState(false);

  const AIMessageStack = useSelector((state) => state.choices.AIMessageStack);

  const handleTextChange = (event) => {
    setUserInputText(event.target.value)
  };
  
  // content you are sending is the current message stack. so on first click; nothing, second history plus what you've written.
  // consequence of playing direct from play is that subtopic etc. is null in DB. Doesn't seem to break anything... But better to try and infer?

  const scenarioScore = {
    scenarioID: scenarioID,
    subTopicID: currentSelection.hasOwnProperty("subTopicID") ? currentSelection.subTopicID : null,
    topicID: currentSelection.hasOwnProperty("topicID") ? currentSelection.topicID : null,
    practiceAreaID: currentSelection.hasOwnProperty("practiceAreaID") ? currentSelection.practiceAreaID : null,
    scenarioTotalPoints: scenarioTotalPoints,
    userPoints: userPoints, 
  }

  const reduxScoringData = useSelector((state) => state.appData.scenarioScore);
  const [choiceDateKey, setChoiceDateKey] = useState(String(Date.now()));

  const handleTextSubmit = async () => {

    setPendingAIResponse(true);

    var promptInputs = {}
    var issues = {}

    // TODO update this approach in Choice
    // And the call to API
    // And the playback and complete components...

    sceneText.forEach(str => {
      if (str.startsWith("_AIPromptInput_")) {

        // Deconstruct issues. Create a issues object with keys as issue id, then name and description as fields.
        if (str.startsWith("_AIPromptInput__issue")) {
          let parts = str.split("_")
          let issueIDx = parts[3]
          let key = parts[5]
          let value = parts.slice(6).join("_").trim();
          issues[issueIDx] = {
            ...issues[issueIDx],
            [key]: value
          }

        } else {
          let parts = str.split("_");
          // The key is at index 3 and the value starts from index 4.
          let key = parts[3];
          let value = parts.slice(4).join("_").trim();
          promptInputs[key] = value;
        }
      };
    })

    promptInputs["issues"] = issues

    const interactionType = promptInputs.type
    const promptHeading = interactionType === "explanation" ? "### My Explanation\n" : interactionType === "questions" ? "### My Question\n" : ""

    const AIDialogue = {
      role:"user",
      content: userInputText,
      promptHeading: promptHeading,
      blockID: promptInputs["blockID"]
    };

    dispatch(setAIDialogue({dialogueData: AIDialogue, interactionType: interactionType}));
    dispatch(setScenarioUI({
      "pendingText": true,
    }))


    let messageStack = AIMessageStack.hasOwnProperty(promptInputs["blockID"]) ? AIMessageStack[promptInputs["blockID"]] : []
    promptInputs["messageStack"] = messageStack;
    promptInputs["currentMessage"] = {
      role:AIDialogue.role,
      content:promptHeading+AIDialogue.content
    };

    const AIData = {
      interactionType: interactionType,
      interactionData: promptInputs
    }

    try {
      const AIResponse = await fetch('/api/scenarioAI/interface', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(AIData),
        });

        const parsedAIResponse = await AIResponse.json()
        if (parsedAIResponse.error === false) {

          const messageResponse = {
            role: "assistant",
            content: JSON.stringify(parsedAIResponse.data)
          }
  
          if (parsedAIResponse.data.type === "followUp") {
            const followUp = {
              role:"assistant",
              type: "followUp",
              evaluation: parsedAIResponse.data.evaluation,
              content: parsedAIResponse.data.message,
              raw: messageResponse,
              blockID: promptInputs["blockID"]
            };
            dispatch(setAIDialogue({dialogueData: followUp, interactionType: interactionType}));
            dispatch(setScenarioUI({
              "pendingText": false,
            }))
            setPendingAIResponse(false);
            setUserInputText("");
          } else if (parsedAIResponse.data.type === "feedback") {
            const feedback = {
              role:"assistant",
              type: "feedback",
              evaluation: parsedAIResponse.data.evaluation,
              content: parsedAIResponse.data.message,
              raw: messageResponse,
              blockID: promptInputs["blockID"]
            };
            dispatch(setAIDialogue({dialogueData: feedback, interactionType: interactionType}));
            dispatch(setScenarioUI({
              "pendingText": false,
            }))
            setPendingAIResponse(false);
            setUserInputText("");
            dispatch(makeChoice(0));
          } else if (parsedAIResponse.data.type === "issuespot") {
            const feedback = {
              role:"assistant",
              type: "issuespot",
              evaluation: parsedAIResponse.data.evaluation,
              raw: messageResponse,
              blockID: promptInputs["blockID"]
            };
            dispatch(setAIDialogue({dialogueData: feedback, interactionType: interactionType}));
            dispatch(setScenarioUI({
              "pendingText": false,
            }))
            setPendingAIResponse(false);
            setUserInputText("");
            dispatch(makeChoice(0));
          }
        } else {
          console.log(parsedAIResponse)
          dispatch(handlePlayerError())
        }

    } catch(e) {console.log(e)}
  };

  const achievementsScoring = (scenarioScore) => {
    
    var achievementsData = {
      newAchievements: [],
      nextAchievement: "",
    }
    let newScenarioAchievement = false

    // Achievements calculations
    Object.keys(achievementsList).forEach((achievementID) => {
      
      // ignore achievements that the user already has
      
      if (!userAchievements.includes(achievementID)) {
        
        // for scenario completion type achievements, loop through the scenarios associated with the subtopic
        
        let achievementMet = true;
        if (achievementsList[achievementID]["achievementCriteria"]["type"] === "scenarioCompletion") {
          achievementsList[achievementID]["achievementCriteria"]["scenarios"].forEach((scenarioID) => {
            if (!scenarioList.hasOwnProperty(scenarioID)) {
              // skip if don't have access to relevant scenarios.
              achievementMet = false
            } else {
              if (scenarioID === scenarioScore.scenarioID) {
                if (scenarioScore.userPoints < scenarioScore.scenarioTotalPoints) {
                  achievementMet = false  
                }
              } else { 
                if (scenarioList[scenarioID]["userScore"] < 100) {
                  achievementMet = false
                };
            }}
          });
          
          if (achievementMet) {
            // add to user achievements
            achievementsData.newAchievements.push(achievementID);
            newScenarioAchievement = true;
          };          
        };

        // work out what the increment from the scenario is
        // so check previous user score for the scenario
        // and if bigger, add the difference

        if (achievementsList[achievementID]["achievementCriteria"]["type"] === "weekScore") {
          const scorePcent = Math.round((scenarioScore.userPoints / scenarioScore.scenarioTotalPoints) * 100);
          const scoreIncrement = scorePcent - scenarioList[scenarioScore.scenarioID]["userScore"]
          if ((weekScore + scoreIncrement) >= achievementsList[achievementID]["achievementCriteria"]["score"]) {
            achievementsData.newAchievements.push(achievementID);
          };
        };
      };
    });

    if (newScenarioAchievement) {
      // set next achievement - only set scenario completion achievements

      let nextAchievementFound = false;
      Object.keys(achievementsList).forEach((achievementID) => {
        if (!nextAchievementFound) {
            if (!userAchievements.includes(achievementID) && !achievementsData.newAchievements.includes(achievementID) && achievementsList[achievementID]["achievementCriteria"]["type"] === "scenarioCompletion") {
              achievementsData.nextAchievement = achievementID;
              nextAchievementFound = true;
            };
        };
      });
    } else {
      achievementsData.nextAchievement = currentAchievement;
    };

    return(achievementsData)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateScenarioScore(scenarioScore));
  },[choices]);

  const scenarioComplete = async() => {
    // write to front end
    
    const timeStamp = Date.now()
    const achievementsData = achievementsScoring(scenarioScore);
    let scenarioLog = [...fullText, [...sceneText]]

    dispatch(completeScenario({timeStamp: timeStamp, achievementsData: achievementsData}));

    if (isAuthenticated) {
      // will need to get the elo from redux. // add scenario text.
      const scenarioData = {
        userMongoID: userMongoID,
        scenarioNewELO: reduxScoringData.scenarioNewELO,
        userNewELO: reduxScoringData.userNewELO,
        timeStamp: timeStamp,
        achievementsData: achievementsData,
        scenarioLog: scenarioLog,
        sessionID: sessionID,
        version: version,
        ...scenarioScore,
      }

      try {
      const scenarioResponse = await fetch('/api/scenariocomplete', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(scenarioData),
        });
      } catch(e) {console.log(e)}

      if (Object.keys(AIMessageStack).length > 0) {
        const AIFeedbackData = {
          scenarioID: scenarioID,
          sessionID: sessionID,
          version: version,
          messages: AIMessageStack,
          timeStamp: timeStamp,
        }
        try {
        const AIFeedbackResponse = await fetch('/api/scenarioAI/feedback', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${userAccessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(AIFeedbackData),
          });
        } catch(e) {console.log(e)}
      }
    };

    dispatch(makeChoice(0));
  };

  /*
  add date stamp to key to ensure animation keeps happening (treat like new component with each story step)
  think about randomising choice presentation
  */

  const parseChoice = (choice) => {
    
    let textInputOption = new RegExp('_textInputOption_','g');
    if (textInputOption.test(choice.text)) {
      return (
        <Grid container sx={{justifyContent: "center", alignItems: "center"}} key={choice.index + choiceDateKey}>
          <Grid item 
            sx={{
              paddingTop: "3px",
              paddingBottom: "3px",
              paddingLeft: "8px",
              paddingRight: "8px",
              margin: "3px",
              maxWidth: "450px",
              flex: 1
            }}
            key={choice.index + choiceDateKey + "GI"}
          >
            <TextField
              id="outlined-multiline-static"
              value={userInputText}
              onChange={handleTextChange}
              disabled={pendingAIResponse}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              key={choice.index + choiceDateKey + "TF"}
            />
            <Typography variant='body2' color="textSecondary" sx={{fontSize: "x-small"}}><em>Do not input client or personal data.</em></Typography>
          </Grid>
          <Grid item key={choice.index + choiceDateKey + "GIB"}>
            <IconButton disabled={pendingAIResponse} onClick={() => {handleTextSubmit()}} color='primary'><PlayCircleIcon /></IconButton>
        </Grid>
      </Grid>
      )
    } else {
      return (
        <Grid item 
          sx={{
            border: "1px solid",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "8px",
            paddingRight: "8px",
            borderColor: "#ccc",
            boxShadow: "0px 3px 0px 0px #ccc",
            borderRadius: "8px",
            margin: "3px",
            marginBottom: "8px",
          }}
          key={choice.index + choiceDateKey}
          onClick={() => {dispatch(makeChoice(choice.index)); setChoiceDateKey(String(Date.now()));}}
          component={motion.div} 
          initial={{
              opacity: 0,
          }}
          animate={{
              opacity: 1,
              transition: {                
                delay: (choice.index/3)+0.5+((sceneText.flat().length-1)/3),
                ease: "easeInOut",
                duration: 0.5,
              }}}
          whileHover={{cursor: "pointer", backgroundColor: "#ced7db"}}
          whileTap={{transform: "translateY(3px)", boxShadow: "0px 0px 0px 0px #ccc", transition: {duration: 0}}}
        >
        <Typography align="center" sx={{fontStyle: "italic", paddingLeft: "5px", paddingRight: "5px"}}>{choice.text}</Typography> 
        </Grid>
      )
    }
  };

  return (
    <>
    {penultimate ? 
    <Typography align="center">
    <Button 
    variant="contained" 
    color="primary"
    onClick={() => scenarioComplete()}
    >
    Review Feedback
    </Button>
    </Typography>
    : 
    <Grid container sx={{
      maxHeight: "100%",
      justifyContent: "center", 
      overflow: "auto",
      '&::-webkit-scrollbar': {
        width: "20px",
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: "#ffffff",
        borderTopRightRadius: "10px",
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#d6dee1",
        borderRadius: "20px",
        border: "6px solid transparent",
        backgroundClip: "content-box",
      },
      '&::-webkit-scrollbar-thumb-hover': {
        backgroundColor: "#a8bbbf",
      },
    }}>
    {choices.map((choice) => (
      parseChoice(choice)
    ))}
    </Grid>
    }
    </>
  )
}