import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { motion } from "framer-motion";

import { Grid, Typography } from '@mui/material';
import { setEditorScenarioUI } from './editorSlice';

import { indigo, pink } from '@mui/material/colors';

const EditorPlayerBar = () => {

    const dispatch = useDispatch();
    const editorScenarioUI = useSelector((store) => store.editorData.editorScenarioUI);  

    const indigo_500 = indigo[500];
    const pink_500 = pink[500];

    const handleUI = (uiElement) => {
        switch (uiElement) {
          case "togglePrimarySources":
            dispatch(setEditorScenarioUI({
              "viewResources": true,
              "viewPrimarySources": true,
              "viewHints": false,
              "viewDocuments": false,
              "viewOutline": false,
            }))
            break
          case "toggleHints":
            dispatch(setEditorScenarioUI({
              "viewResources": true,
              "viewHints": true,
              "viewDocuments": false,
              "viewPrimarySources": false,
              "viewOutline": false,
            }));
            break
          case "toggleDocuments":
            dispatch(setEditorScenarioUI({
              "viewResources": true,
              "viewDocuments": true,
              "viewHints": false,
              "viewPrimarySources": false,
              "viewOutline": false,
            }));
            break
          case "toggleOutline":
            dispatch(setEditorScenarioUI({
              "viewResources": true,
              "viewDocuments": false,
              "viewHints": false,
              "viewPrimarySources": false,
              "viewOutline": true,
            }));
            break
          case "closeResources":
              dispatch(setEditorScenarioUI({
                "viewResources": false,
                "viewHints": false,
                "viewDocuments": false,
                "viewPrimarySources": false,
                "viewOutline": false,
              }));
          default:
            break
        }
      }

    const getTabColor = (tab) => {
      switch (tab) {
        case "primarySources":
          return(editorScenarioUI.viewPrimarySources ? indigo_500 : "#ced7db")
        case "documents":
          return(editorScenarioUI.viewDocuments ? indigo_500 : "#ced7db")
        case "outline":
          return(editorScenarioUI.viewOutline ? indigo_500 : "#ced7db")
        case "hints":
          return(editorScenarioUI.viewHints ? pink_500 : "#ced7db")
      };
    };

    const tabAnimation = {
      click: {
        transform: "translateY(3px)", 
        boxShadow: "0px 0px 0px 0px #ffffff", 
        transition: {
          duration: 0.2
        }, 
      },
      selected: {
        boxShadow: "0px 3px 0px 0px #3f51b5"
      },
      selectedPink: {
        boxShadow: "0px 3px 0px 0px #e91e63"
      }
    };

  return (
    <Grid container sx={{
        justifyContent: 'space-between',
        width: "100%",
        padding: "5px",
        borderBottom: "1.5px solid #ced7db",
    }}>
        <Grid item>
        <Grid container>
            <Grid item 
            component={motion.div}
            style={{
                margin: 5,
                borderBottom: "1px solid" + getTabColor("primarySources"),
                boxShadow: "0px 3px 0px 0px" + getTabColor("primarySources"),
                paddingLeft: 2,
                paddingRight: 2,
            }}
            variants={tabAnimation}
            whileHover={{cursor: "pointer"}}
            whileTap={["click", "selected"]}
            onClick={() => handleUI("togglePrimarySources")}
            >
            <Typography variant="button" color={editorScenarioUI.viewPrimarySources ? "primary" : "textSecondary"}>Primary Sources</Typography>
            </Grid>
            <Grid item 
            component={motion.div}
            style={{
                margin: 5,
                borderBottom: "1px solid" + getTabColor("documents"),
                boxShadow: "0px 3px 0px 0px" + getTabColor("documents"),
                paddingLeft: 2,
                paddingRight: 2,
            }}
            variants={tabAnimation}
            whileHover={{cursor: "pointer"}}
            whileTap={["click", "selected"]}
            onClick={() => handleUI("toggleDocuments")}
            >
            <Typography variant="button" color={editorScenarioUI.viewDocuments ? "primary" : "textSecondary"}>Documents</Typography>
            </Grid>
            <Grid item 
            component={motion.div}
            style={{
                margin: 5,
                borderBottom: "1px solid" + getTabColor("outline"),
                boxShadow: "0px 3px 0px 0px" + getTabColor("outline"),
                paddingLeft: 2,
                paddingRight: 2,
            }}
            variants={tabAnimation}
            whileHover={{cursor: "pointer"}}
            whileTap={["click", "selected"]}
            onClick={() => handleUI("toggleOutline")}
            >
            <Typography variant="button" color={editorScenarioUI.viewOutline ? "primary" : "textSecondary"}>Outline</Typography>
            </Grid>
            <Grid item 
            component={motion.div}
            style={{
                margin: 5,
                borderBottom: "1px solid" + getTabColor("hints"),
                boxShadow: "0px 3px 0px 0px" + getTabColor("hints"),
                paddingLeft: 2,
                paddingRight: 2,
            }}
            variants={tabAnimation}
            whileHover={{cursor: "pointer"}}
            whileTap={["click", "selectedPink"]}
            onClick={() => handleUI("toggleHints")}
            >
            <Typography variant="button" color={editorScenarioUI.viewHints ? "secondary" : "textSecondary"}>Editor Guide</Typography>
            </Grid>
        </Grid>
        </Grid>
    </Grid>
  )
  };
export default EditorPlayerBar;