import React from 'react';
import { Grid, Typography } from '@mui/material';

/*

A container for rule analysis components. Uses paper background.

*/

const RAConjunction = (props) => {


    return (
            <Grid container sx={{width: "100%", justifyContent: "center", marginBottom: "5px"}}>
                <Grid item>
                    <Typography variant="button" color="textSecondary">{props.content}</Typography>
                </Grid>
            </Grid>
            )
    };

export default RAConjunction;