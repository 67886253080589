import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { motion } from "framer-motion"

import { setFeedbackType, setFeedbackContent } from './appData/appDataSlice'

import { Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Narrative from './story/Narrative';
import SpeechOther from './story/SpeechOther';
import SpeechYour from './story/SpeechYour';
import Heading from './story/Heading';
import SubHeading from './story/SubHeading';
import Fact from './story/Fact';
import FactMinor from './story/FactMinor';
import DocumentThumbnail from './story/DocumentThumbnail';
import CoverImage from './story/CoverImage';
import PlayerError from './story/PlayerError';
import PendingText from './story/PendingText';

const Story = (props) => {

  const theme = useTheme();
  const bottomOfStory = useRef(null);
  const dispatch = useDispatch();
  const coverImage = props.coverImage;

  const scrollToBottom = () => {
    bottomOfStory.current?.scrollIntoView({ behavior: "smooth" })
  }
  
  const sceneText = useSelector((state) => state.choices.sceneText);
  const previousText = useSelector((state) => state.choices.fullText);
  const pendingText = useSelector((state) => state.choices.scenarioUI.pendingText);

  const fullWidth = useMediaQuery(theme.breakpoints.up('sm'))
  const fullHeight = useMediaQuery('(min-height:610px)')
  const titleVariant = (fullWidth && fullHeight) ? "h3" : "h4"
  const subTitleVariant = (fullWidth && fullHeight) ? "h4" : "h5"

  /* 
  create an array of figures for the fade transition
  */

  const fullText = [previousText.flat(), sceneText.flat()].flat();

  // Set styles for story components

  const defaultStyles = {
    padding: "8px",
    marginBottom: "10px",
    boxShadow: "2px 2px 3px 0px #ccc",
    saturation: "none",
    typographyVariant: "body1"
  }

  const narrativeStyles = {
    backgroundColor: "#fcfcfc",
    borderBottomRightRadius: "10px",
    borderLeft: "4px solid",
    borderLeftColor: "#aaaaaa",
  }

  const otherStyles = {
    border: "3px solid #3f51b5",
    backgroundColor: "#fafafa",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: "-3px 3px 0px 0px #7986cb",
  }

  const yourStyles = {
    border: "3px solid #009688",
    boxShadow: "3px 3px 0px 0px #4db6ac",
    backgroundColor: "#fafafa",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  }

  const getDelay = (idx) => {
    let delay = (idx - previousText.flat().length)/3
    if (delay < 0) {
        return 0
    }
    if (delay >= 0) {
        return delay
    }
  };

  // check whether have got to the end of the story
  let eos = false

  // Define regexes outside the function
  const regexPatterns = {
    narrative: new RegExp('_narrative_'),
    speechOther: new RegExp('_speechOther_'),
    speechOtherTextResponse: new RegExp('_speechOtherTextResponse_'),
    speechYour: new RegExp('_speechYour_'),
    speechYourTextInput : new RegExp('_speechYourTextInput_'),
    heading: new RegExp('_heading_'),
    subHeading: new RegExp('_subHeading_'),
    fact: new RegExp('_fact_'),
    factMinor: new RegExp('_factMinor_'),
    documentThumbnailNeutral: new RegExp('_documentThumbnailNeutral_'),
    feedback: new RegExp('_feedback_'),
    maxims: new RegExp('_maxims_'),
    AIPromptInput: new RegExp('_AIPromptInput_'),
    playerError: new RegExp('_playerError_'),
    inlineFeedback: new RegExp('_inlineFeedback'),
    coverImageRxp: new RegExp('_coverImage_'),
  };

  const parseText = (paraText, idx) => {
    
    if (eos) {
      dispatch(setFeedbackContent(paraText));
      return
    };

    if (regexPatterns.narrative.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.narrative, "");
      const delay = getDelay(idx)
      
      return (
        <Narrative stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} narrativeStyles={narrativeStyles} key={"narrative"+idx} />
      )
    };
    if (regexPatterns.speechOther.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechOther, "");
      const delay = getDelay(idx)
      
      return (
        <SpeechOther stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} otherStyles={otherStyles} key={"speechother"+idx} />
      )
    };
    if (regexPatterns.speechOtherTextResponse.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechOtherTextResponse, "");
      const delay = getDelay(0)
      
      return (
        <SpeechOther stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} otherStyles={otherStyles} key={"speechOtherTextResponse"+idx} />    
      )
    };
    if (regexPatterns.speechYour.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechYour, "").split("_inlineFeedback")[0]
      const delay = getDelay(idx)

      return (
        <SpeechYour stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} yourStyles={yourStyles} key={"speechYour"+idx} />
      )
    };
    if (regexPatterns.speechYourTextInput.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechYourTextInput, "");
      const delay = getDelay(0)

      return (
        <SpeechYour stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} yourStyles={yourStyles} key={"speechYourTextInput"+idx} />
      )
    };
    if (regexPatterns.heading.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.heading, "");
      const delay = getDelay(idx)

      return (
        <Heading stripTags={stripTags} delay={delay} otherStyles={otherStyles} headingSaturate="saturate(100%)" typographyVariant={titleVariant} key={"heading"+idx} />
      )
    };
    if (regexPatterns.subHeading.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.subHeading, "");
      const delay = getDelay(idx)

      return (
        <SubHeading stripTags={stripTags} delay={delay} otherStyles={otherStyles} headingSaturate="saturate(100%)" typographyVariant={subTitleVariant} key={"subHeading"+idx} />
      )
    };
    if (regexPatterns.fact.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.fact, "");
      const delay = getDelay(idx)
      
      return (
        <Fact stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} key={"fact"+idx} />
      )
    };
    if (regexPatterns.factMinor.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.factMinor, "");
      const delay = getDelay(idx)
      
      return (
        <FactMinor stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} key={"factMinor"+idx} />
      )
    };
    if (regexPatterns.documentThumbnailNeutral.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.documentThumbnailNeutral, "").replaceAll("\n", "")
      const delay = getDelay(idx)
      
      return (
        <DocumentThumbnail stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} readOnly={false} loadSource="player" key={"documentThumbnail"+idx} />
      )
    };
    if (regexPatterns.coverImageRxp.test(paraText)) {
      if (coverImage) {
        const delay = getDelay(idx)
        return (
          <CoverImage delay={delay} coverImage={coverImage} key={"coverImage"+idx}/>  
        )
      }
    };
    if (regexPatterns.playerError.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.playerError, "");
      const delay = getDelay(idx)
      
      return (
        <PlayerError stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} key={"playError"+idx} />
      )
    };

    // write the feedback type to redux, set eos flag so don't parse any more of the ink content 

    if (regexPatterns.feedback.test(paraText)) {
      eos = true;
      dispatch(setFeedbackType("feedback"));
      return
    };
    if (regexPatterns.maxims.test(paraText)) {
      eos = true;
      dispatch(setFeedbackType("maxims"));
      return
    };
    if (regexPatterns.AIPromptInput.test(paraText) || regexPatterns.inlineFeedback.test(paraText)) {
      return
    };
    if (["\n","_setHintsVisible_\n","_setPrimarySourcesVisible_\n","_coverImage_\n"].includes(paraText)) {
      // skip any UI flags and stray line breaks generated by ink
      return
    } else {
      // Fall back
      return (
        <Narrative stripTags={paraText} delay={getDelay(idx)} defaultStyles={defaultStyles} narrativeStyles={narrativeStyles} key={"narrative"+idx} />
      )
    };
  };

  useEffect(() => {
    scrollToBottom()
  }, [fullText]);


  return (
      <Grid container sx={{display: 'flex', flexGrow: 1, minWidth: "100%", marginBottom: "10px"}} component={motion.div} layout={"position"}>
        {fullText.flat(1).map((paraText, idx) => (
          parseText(paraText,idx)
        ))}
        {pendingText ?
          <PendingText defaultStyles={defaultStyles} otherStyles={otherStyles} />
          :
          null
        }
      <div ref={bottomOfStory} />
      </Grid>
  );
}

export default Story;