import React from 'react';

import { Grid, IconButton, Tooltip, Typography, Avatar, Stack } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const KnotComments = (props) => {

    const knotComments = props.knotComments;
    const setKnotComments = props.setKnotComments;
    const setChangesMade = props.setChangesMade;

    const stringToColor = (string) => {
        let hash = 0;
        let i;
        
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        
        let color = '#';
        
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
        
        return color;
    };

    const stringAvatar = (name) => {
        const namePortion = name.split("@")[0]
        if (namePortion.split(".") > 1) {
            return {
              sx: {
                bgcolor: stringToColor(name),
              },
              children: `${namePortion.split(".")[0][0].toUpperCase()}${namePortion.split(".")[1][0].toUpperCase()}`,
            };
        } else {
            return {
              sx: {
                bgcolor: stringToColor(name),
              },
              children: `${namePortion.split(".")[0][0].toUpperCase()}${namePortion.split(".")[0][1].toUpperCase()}`,
            };
        };
    };

    const getDateString = (timeEpoch) => {
        // Create a new Date object using the timestamp
        const date = new Date(parseInt(timeEpoch));

        // Define the options for formatting the date
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Convert the date to the desired string format
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate
    };

    const deleteComment = (key) => {
        setKnotComments({
            ...knotComments,
            [key]: {
                ...knotComments[key],
                "status": "deleted"
            }
        });
        setChangesMade(true);
    };

    const resolveComment = (key) => {
        setKnotComments({
            ...knotComments,
            [key]: {
                ...knotComments[key],
                "status": "resolved"
            }
        });
        setChangesMade(true);
    };

    return (
        <Grid item sx={{width: "100%", flexGrow: 1}}>
            {knotComments && Object.keys(knotComments).filter((key) => (knotComments[key]["status"] !== "deleted")).length > 0 ? 
            <>
            {Object.keys(knotComments).filter((key) => (knotComments[key]["status"] !== "deleted")).map((key) => {
                return (
                <Grid item key={key} sx={{paddingBottom: "5px"}}>
                <Grid container sx={{width: "100%", flexWrap: "nowrap"}}>
                    <Grid item>
                    <Tooltip title={knotComments[key]["user"]} placement="top">
                    <Avatar {...stringAvatar(knotComments[key]["user"])} sx={{ width: 32, height: 32}}/>
                    </Tooltip>
                    </Grid>
                    <Grid item>
                    {knotComments[key]["status"] === "open" ?
                    <Typography variant="body2" sx={{paddingLeft: "8px"}}>{knotComments[key]["text"]}</Typography> 
                    :
                    <Typography variant="body2" color="textSecondary" sx={{paddingLeft: "8px", textDecoration: "line-through"}}>{knotComments[key]["text"]}</Typography>
                    }
                    </Grid>
                </Grid>
                <Grid container sx={{width: "100%", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "center"}}>
                    <Grid item sx={{paddingLeft: "40px"}}>
                        <Typography variant='body2' color="textSecondary">{getDateString(key)}</Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={0.5}>
                        <IconButton onClick={() => resolveComment(key)} className="nodrag">
                            <Tooltip title="Mark resolved" placement="top">
                            <TaskAltIcon fontSize="small"/>
                            </Tooltip>
                        </IconButton>
                        <IconButton onClick={() => deleteComment(key)} className="nodrag">
                            <Tooltip title="Delete" placement="top">
                            <DeleteIcon fontSize="small"/>
                            </Tooltip>
                        </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
                </Grid>
            )})}
            </>
            :
            <Typography variant="body2" color="textSecondary" align="center" paragraph>No comments</Typography>
            }
        </Grid> 
    )
};
export default KnotComments;

/*

<TextField
                        id="outlined-multiline-static"
                        label={formatMap[knotFormat]["label"]}
                        value={knotText}
                        onChange={handleTextChange}
                        variant="standard"
                        fullWidth
                        className="nodrag"
                        sx={{
                            '& label': {
                                color: formatMap[knotFormat]["color"],
                            },
                            '& label.Mui-focused': {
                                color: formatMap[knotFormat]["color"],
                            },
                            '& .MuiInput-underline': {
                                '&:after': {
                                borderBottomColor: formatMap[knotFormat]["color"],
                                },
                                '&:before': {
                                borderBottomColor: formatMap[knotFormat]["color"],
                                },
                            },
                            '& input': {
                                color: formatMap[knotFormat]["color"],
                            },
                        }}
                    />

*/