import React, {useState} from 'react';
import { useSelector } from 'react-redux'
import { motion } from "framer-motion";

import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';

import { useParams } from "react-router-dom";

/*

a single feedback endpoint, take user and feedback type content, timestamp, scenario id and name

timestamp
feedback type rating / comment
feedback content positive / content of comment

*/

const FeedbackForm = () => {
    
    let params = useParams();
    const scenarioID = params.scenarioID;
    const userMongoID = useSelector((state) => state.appData.userData.mongoID);
    const userScenarios = useSelector((state) => state.appData.userData.userScenarios);
    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const scenarioScore = userScenarios.hasOwnProperty(scenarioID) ? userScenarios[scenarioID]["score"] : 0

    const [thumbsDown, setThumbsDown] = useState(false);
    const [thumbsUp, setThumbsUp] = useState(false);
    const [thumbsFeedback, setThumbsFeedBack] = useState(false);
    const [feedbackProvided, setFeedBackProvided] = useState(false);
    const [feedbackError, setFeedBackError] = useState(false);

    const handleThumbsUp = () => {
        if (thumbsFeedback) {
            return
        } else {
            setThumbsUp(true);
            setThumbsFeedBack(true);
            writeFeedback("rating", "thumbsUp")
        }
    };

    const handleThumbsDown = () => {
        if (thumbsFeedback) {
            return
        } else {
            setThumbsDown(true);
            setThumbsFeedBack(true);
            writeFeedback("rating", "thumbsDown")
        }
    };

    const [textFeedback, setTextFeedback] = React.useState("");
    const handleFeedbackChange = (event) => {
        setTextFeedback(event.target.value);
    };

    const submitFeedback = () => {
        if (textFeedback === "") {
            setFeedBackError(true)
        } else {
            setFeedBackError(false)
            setFeedBackProvided(true)
            writeFeedback("comment", textFeedback)
        }
    };

    const writeFeedback = async(feedbackType, feedbackContent) => {
        const feedbackData = {
            scenarioID: scenarioID,
            scenarioName: scenarioList[scenarioID]["scenarioName"],
            scenarioScore: scenarioScore,
            userMongoID: userMongoID,
            timestamp: Date.now(),
            feedbackType: feedbackType,
            feedbackContent: feedbackContent,
        };

        try {
            const feedbackResponse = await fetch('/api/scenariofeedback', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(feedbackData),
              });
            } catch(e) {console.log(e)}
    };

    /*
  let params = useParams();
  const scenarioID = params.scenarioID;
  
*/

  const getThumbsUpColor = (element) => {
    if (element === "icon") {
        if (thumbsUp) {
            return "#fff"
        } else {
            return "#4caf50"
        }
    }
    if (element === "background") {
        if (thumbsUp) {
            return "#4caf50"
        } else {
            return "#fff"
        }
    }
};

  return (
    <Grid container>
        <Grid item style={{width: "100%"}}>
            <Typography variant="body1" color="textSecondary" align="center">Let us know if this scenario helped you understand the topic.</Typography>
        </Grid>
        <Grid item style={{width: "100%"}}>
        <Grid container style={{width: "100%", justifyContent: "center"}}>
            <Grid item
            style={{
                width: 60,
                height: 60,
                borderRadius: 15,
                margin: 10,
                border: thumbsUp ? "2px solid #388e3c" : "2px solid #ccc",
                boxShadow: "0px 3px 0px 0px #ccc",
                backgroundColor: thumbsUp ? "#4caf50": "#fff",
            }}
            component={motion.div} 
            whileHover={thumbsFeedback ? 
                {} :
                {cursor: "pointer", border: "2px solid #4caf50",}}
            whileTap={thumbsFeedback ?
                {}:
                {transform: "translateY(3px)", boxShadow: "none", transition: {duration: 0}}}
            onClick={() => {handleThumbsUp()}}
            >
                <Grid container style={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                <Grid item>
                <ThumbUpOutlinedIcon fontSize='large' style={{color: thumbsUp ? "#fff" : "#4caf50"}}/>
                </Grid>
                </Grid>
            </Grid>
            <Grid item
            style={{
                width: 60,
                height: 60,
                borderRadius: 15,
                margin: 10,
                border: thumbsDown ? "2px solid #c51162" : "2px solid #ccc",
                boxShadow: "0px 3px 0px 0px #ccc",
                backgroundColor: thumbsDown ? "#f50057": "#fff",
            }}
            component={motion.div} 
            whileHover={thumbsFeedback ? 
                {} : 
                {cursor: "pointer", border: "2px solid #f50057",}}
            whileTap={thumbsFeedback ?
                {}:
                {transform: "translateY(3px)", boxShadow: "none", transition: {duration: 0}}}
            onClick={() => {handleThumbsDown()}}
            >
                <Grid container style={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                    <Grid item>
                    <ThumbDownOutlinedIcon fontSize='large' style={{color: thumbsDown ? "#fff" : "#f50057"}}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Grid>
        <Grid item style={{width: "100%"}}>
        <Grid container style={{width: "100%", justifyContent: "center"}}>
            <Grid item>
            <form noValidate style={{width: 300, marginTop: 10}} autoComplete="off">
            <TextField
            id="outlined-multiline-static"
            label="Tell us more..."
            value={textFeedback}
            error={feedbackError}
            disabled={feedbackProvided}
            onChange={handleFeedbackChange}
            fullWidth
            multiline
            rows={8}
            variant="outlined"
            />
            </form>
            <Grid container style={{width: "100%", justifyContent: "center", marginTop: 15}}>
            <Button
            type="submit"
            size="medium"
            variant="contained"
            color="primary"
            disabled={feedbackProvided}
            onClick={() => submitFeedback()}
            >
                Submit
            </Button>
            </Grid>
            <Grid item style={{width: "100%", marginTop: 6}} component={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} key={feedbackProvided && thumbsFeedback}>
            <Typography variant="body1" color="textSecondary" align="center">{(feedbackProvided || thumbsFeedback) && "Thank you for your feedback."}</Typography>
            </Grid>
            </Grid>
        </Grid>
        </Grid>
    </Grid>
  )
  };
export default FeedbackForm;

/*

<Grid item style={{width: "100%", minHeight: 25}} component={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} key={thumbsFeedback}>
            <Typography variant="body1" color="textSecondary" align="center">{thumbsFeedback && "Thank you for your feedback."}</Typography>
        </Grid>

*/