import React, { useEffect, useState } from 'react';
import { motion } from "framer-motion";

import ScenarioComplete from '../features/ScenarioComplete';
import Choice from "../features/choice/Choice";
import { setScenarioUI, loadGame, setPlayerDocumentData } from '../features/choice/choiceSlice';
import { setFeedbackType, clearFeedbackContent, setPrimarySourceData, setDisplayTopBarHelper, setScenarioJSONDocumentData } from '../features/appData/appDataSlice'
import Story from "../features/Story";
import ScenarioResources from '../features/ScenarioResources';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { Typography, Grid, Modal, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";

import { indigo, pink } from '@mui/material/colors';
import TopBarHelper from '../features/TopBarHelper';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// fix story container so scroll experience is slightly less shonky
// starting to look ok with tabs, mobile experience still not great.

const Play = () => {

    const dispatch = useDispatch();
    const theme = useTheme();

    const ending = useSelector((state) => state.choices.ending);
    const loadingPuzzle = useSelector((state) => state.choices.status);
    const scenarioUI = useSelector((state) => state.choices.scenarioUI);
    const displayTopBarHelper = useSelector((state) => state.appData.appUI.displayTopBarHelper);

    const [documentArray, setDocumentArray] = useState();
    const [coverImage, setCoverImage] = useState();
    const [triggerModalHelper, setTriggerModalHelper] = useState(false);

    const handleUI = (uiElement) => {
      switch (uiElement) {
        case "togglePrimarySources":
          dispatch(setScenarioUI({
            "viewResources": true,
            "viewPrimarySources": true,
            "viewHints": false,
            "viewDocuments": false,
            "viewOutline": false,
          }))
          break
        case "toggleHints":
          dispatch(setScenarioUI({
            "viewResources": true,
            "viewHints": true,
            "viewDocuments": false,
            "viewPrimarySources": false,
            "viewOutline": false,
          }));
          break
        case "toggleDocuments":
          dispatch(setScenarioUI({
            "viewResources": true,
            "viewDocuments": true,
            "viewHints": false,
            "viewPrimarySources": false,
            "viewOutline": false,
          }));
          break
        case "toggleOutline":
          dispatch(setScenarioUI({
            "viewResources": true,
            "viewDocuments": false,
            "viewHints": false,
            "viewPrimarySources": false,
            "viewOutline": true,
          }));
          break
        case "closeResources":
          setTriggerModalHelper(true);
          dispatch(setScenarioUI({
            "viewResources": false,
            "viewHints": false,
            "viewDocuments": false,
            "viewPrimarySources": false,
            "viewOutline": false,
          }));
          break
        default:
          break
      }
    }

    let params = useParams();
    //var navigate = useNavigate();
    const scenarioID = params.scenarioID;
    const scenarioList = useSelector((store) => store.appData.scenarioList);
    const skillsTree = useSelector((store) => store.appData.skillsTree);
    const userAccessToken = useSelector((state) => state.appData.accessToken);

    useEffect(() => {

      const getPrimarySources = async () => {
        // TODO: does this stuff get sensibly cached?
        try {
            const scenarioData = {
              sourceIDs: Object.keys(scenarioList[scenarioID]["scenarioAssets"]["primarySource"])
            }            
            const scenarioResponse = await fetch('/api/assets/getprimarysources', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
            });
            const responseData = await scenarioResponse.json()
            dispatch(setPrimarySourceData({primarySource: responseData, scenarioID: scenarioID}));
            } 
        catch(e) {console.log(e)}        
      };

      const getDocuments = async () => {
      
        const docType = scenarioList[scenarioID]["scenarioAssets"]["documents"][0]["type"]
        let endPoint = docType === "pdf" ? '/api/assets/getdocument' : '/api/assets/getjsondocument'

        try {
          const documentData = {
              documentID: scenarioList[scenarioID]["scenarioAssets"]["documents"][0]["documentID"]
          }
          const dataResponse = await fetch(endPoint, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(documentData),
          });
          
          // handle pdf streaming

          if (docType === "pdf") {

            // If only could rely on browser iframes.
            // const blob = await dataResponse.blob();
            // const url = URL.createObjectURL(blob);

            let result = new Uint8Array(0);
            const reader = dataResponse.body.getReader();
            while (true) { // eslint-disable-line no-constant-condition
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const newResult = new Uint8Array(result.length + value.length);
                newResult.set(result);
                newResult.set(value, result.length);
                result = newResult;
            }

            dispatch(setPlayerDocumentData({documentJSONData:[]}));
            return (result)
          } else {
            // otherwise assume json doc
            const responseData = await dataResponse.json()
            const updateData = {
              ...responseData,
              scenarioID: scenarioID
            }
            dispatch(setScenarioJSONDocumentData(updateData));
            dispatch(setPlayerDocumentData(updateData));
            return ("jsonDocument")
          }
        }
        catch(e) {console.log(e)}
      };

      const getCoverImage = async () => {
        
        try {
            const imageData = {
                documentID: scenarioList[scenarioID]["scenarioAssets"]["coverImage"][0]["documentID"]
            }            
            const dataResponse = await fetch('/api/assets/getdocument', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(imageData),
            });
            let result = new Uint8Array(0);
            const reader = dataResponse.body.getReader();
            while (true) { // eslint-disable-line no-constant-condition
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const newResult = new Uint8Array(result.length + value.length);
                newResult.set(result);
                newResult.set(value, result.length);
                result = newResult;
            }
            const binString = Array.from(result, (x) => String.fromCodePoint(x)).join("");
            return(btoa(binString))
        }
        catch(e) {console.log(e)}
      };

      if (Object.keys(skillsTree).length > 0) {
        // Guard for clean re-load, need to wait for app data
        if (scenarioList[scenarioID]["status"] !== "unpublished") {
          // Don't expose unpublished scenarios for play here. Could make this part of the back end? That would be part of broader work about data return.
          const loadData = {
            scenarioID: scenarioID, 
            userAccessToken: userAccessToken, 
          }
          dispatch(clearFeedbackContent());
          dispatch(setFeedbackType(""));
          dispatch(loadGame(loadData));
          

          // TODO: probably don't need these conditionals on scenario editable now; it's all editable.

          // Dynamically load primary source data
          if (scenarioList[scenarioID].hasOwnProperty("scenarioEditable") && scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("primarySource")) {
            if (Object.keys(scenarioList[scenarioID]["scenarioAssets"]["primarySource"]).length > 0) {
              getPrimarySources();
            };
          };

          // Dynamically load document data
          if (scenarioList[scenarioID].hasOwnProperty("scenarioEditable") && scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("documents")) {
            getDocuments().then((result) => setDocumentArray({data: result, documentID: scenarioList[scenarioID]["scenarioAssets"]["documents"][0]["documentID"]}));
          } else {
            // Clean up player document if not setting here.
            dispatch(setPlayerDocumentData({documentJSONData:[]}));
          };

          // Dynamically load cover image
          if (scenarioList[scenarioID].hasOwnProperty("scenarioEditable") && scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("coverImage")) {
            getCoverImage().then((result) => setCoverImage({data: result, documentID: scenarioList[scenarioID]["scenarioAssets"]["coverImage"][0]["documentID"], type: scenarioList[scenarioID]["scenarioAssets"]["coverImage"][0]["type"]}));
          };
        } else {
          // TODO: get this text working.
          throw new Response("This scenario is not available.", { status: 404 });
        }
      };

    dispatch(clearFeedbackContent());
    dispatch(setFeedbackType(""));

    },[params, userAccessToken, skillsTree]);

    const indigo_500 = indigo[500];
    const pink_500 = pink[500];

    const getTabColor = (tab) => {
      switch (tab) {
        case "primarySources":
          return(scenarioUI.viewPrimarySources ? indigo_500 : "#ced7db")
        case "documents":
          return(scenarioUI.viewDocuments ? indigo_500 : "#ced7db")
        case "outline":
          return(scenarioUI.viewOutline ? indigo_500 : "#ced7db")
        case "hints":
          return(scenarioUI.viewHints ? pink_500 : "#ced7db")
      };
    };

    const tabAnimation = {
      click: {
        transform: "translateY(3px)", 
        boxShadow: "0px 0px 0px 0px #ffffff", 
        transition: {
          duration: 0.2
        }, 
      },
      selected: {
        boxShadow: "0px 3px 0px 0px #3f51b5"
      },
      selectedPink: {
        boxShadow: "0px 3px 0px 0px #e91e63"
      }
    };

    const fullWidth = useMediaQuery(theme.breakpoints.up('sm'))
    const fullHeight = useMediaQuery('(min-height:610px)')
    const fullPadding = useMediaQuery(theme.breakpoints.up("md"))
    const fullResources = useMediaQuery(theme.breakpoints.up('lg'))

    /*

    Player should always take up the screen
    On play, the top nav bar will be hidden if below full width 600px or full height (610px)
    Screen padding increases above mid size (900px) so account for this too
    Compress choice bar when full width but not full height (targetting mobile landscape mode)


    TODO: cut out modal shit

    */

    const topNavBar = fullHeight && fullWidth

    return (
      <>
      {loadingPuzzle === 'loading' || Object.keys(scenarioList).length === 0
        ? 
          <Grid container style={{justifyContent: "center"}}>
          <Grid item>
          <CircularProgress />
          </Grid>
          </Grid>
        : <>
          {ending 
          ? 
          <ScenarioComplete /> 
          : 
          <Stack direction="column" sx={{
            borderRadius: "10px", 
            border: "2px solid #ced7db",
            height: `calc(${topNavBar ? "100vh - 64px" : "100dvh - 0px"} ${fullPadding ? "- 48px" : "- 16px"})`,
            width: "100%"
            }}>
              <Grid item sx={{
                width: "100%", 
                height: "50px",
                minHeight: "50px",
                borderBottom: "1.5px solid #ced7db",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}>
              <Grid container sx={{
                justifyContent: 'flex-start',
                width: "100%",
                padding: "5px",
              }}>
                {(scenarioUI.viewResources && !fullResources) ?
                <Grid item 
                sx={{
                  paddingLeft: "2px",
                  paddingRight: "2px",
                }}
                onClick={() => handleUI("closeResources")}
              >
                <Typography variant="button" color="secondary">{<KeyboardBackspaceIcon sx={{"transform":"translateY(6px)"}} />}Return</Typography>
              </Grid>
                :
                <>
                {scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("primarySource") && 
                  <Grid item 
                    component={motion.div}
                    style={{
                      margin: 5,
                      borderBottom: "1px solid" + getTabColor("primarySources"),
                      boxShadow: "0px 3px 0px 0px" + getTabColor("primarySources"),
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    variants={tabAnimation}
                    whileHover={{cursor: "pointer"}}
                    whileTap={["click", "selected"]}
                    onClick={() => handleUI("togglePrimarySources")}
                  >
                    <Typography variant="button" color={scenarioUI.viewPrimarySources ? "primary" : "textSecondary"}>Primary Sources</Typography>
                  </Grid>
                  }
                  {scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("documents") && 
                  <Grid item 
                    component={motion.div}
                    style={{
                      margin: 5,
                      borderBottom: "1px solid" + getTabColor("documents"),
                      boxShadow: "0px 3px 0px 0px" + getTabColor("documents"),
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    variants={tabAnimation}
                    whileHover={{cursor: "pointer"}}
                    whileTap={["click", "selected"]}
                    onClick={() => handleUI("toggleDocuments")}
                  >
                    <Typography variant="button" color={scenarioUI.viewDocuments ? "primary" : "textSecondary"}>Documents</Typography>
                  </Grid>
                  }
                  {scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("outline") && 
                  <Grid item 
                    component={motion.div}
                    style={{
                      margin: 5,
                      borderBottom: "1px solid" + getTabColor("outline"),
                      boxShadow: "0px 3px 0px 0px" + getTabColor("outline"),
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    variants={tabAnimation}
                    whileHover={{cursor: "pointer"}}
                    whileTap={["click", "selected"]}
                    onClick={() => handleUI("toggleOutline")}
                  >
                    <Typography variant="button" color={scenarioUI.viewOutline ? "primary" : "textSecondary"}>Outline</Typography>
                  </Grid>
                  }
                  <Grid item 
                    component={motion.div}
                    style={{
                      margin: 5,
                      borderBottom: "1px solid" + getTabColor("hints"),
                      boxShadow: "0px 3px 0px 0px" + getTabColor("hints"),
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                    variants={tabAnimation}
                    whileHover={{cursor: "pointer"}}
                    whileTap={["click", "selectedPink"]}
                    onClick={() => handleUI("toggleHints")}
                  >
                    <Typography variant="button" color={scenarioUI.viewHints ? "secondary" : "textSecondary"}>{fullResources ? "Practice Notes" : "Notes"}</Typography>
                  </Grid>
                  </>
                }
                
              </Grid>
              </Grid>
            <Grid container sx={{
              flexGrow: 1,
              maxWidth: "100%",
              height: "100%",
              overflow: "hidden"
            }}>
            {scenarioUI.viewResources ?
              <>
              {fullResources ?
                <>
                <Grid item component={motion.div} layout={"position"} sx={{
                  maxHeight: "100%",
                  width: "35%",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                  padding: "10px",
                  overflow: "auto",
                  '&::-webkit-scrollbar': {
                    width: "20px",
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: "#ffffff",
                    borderTopRightRadius: "10px",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: "#d6dee1",
                    borderRadius: "20px",
                    border: "6px solid transparent",
                    backgroundClip: "content-box",
                  },
                  '&::-webkit-scrollbar-thumb-hover': {
                    backgroundColor: "#a8bbbf",
                  },
                  background: "linear-gradient(135deg, #ffffff 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px),\nlinear-gradient(225deg, #ffffff 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px)0 64px",
                  backgroundColor:"#ffffff",
                  backgroundSize:"64px 128px",
                }}>
                  <Story coverImage={coverImage} />
                </Grid>
                <Grid item component={motion.div} layout={"position"}
                sx={{
                  height: "100%",
                  overflow: "auto",
                  width: "65%",
                  '&::-webkit-scrollbar': {
                    width: "20px",
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: "transparent",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: "#d6dee1",
                    borderRadius: "20px",
                    border: "6px solid transparent",
                    backgroundClip: "content-box",
                  },
                  '&::-webkit-scrollbar-thumb-hover': {
                    backgroundColor: "#a8bbbf",
                  },
                  backgroundColor: "#ffffff",
                }}
              >
              <ScenarioResources setTriggerModalHelper={setTriggerModalHelper} documentArray={documentArray} loadSource={scenarioList[scenarioID].hasOwnProperty("scenarioEditable") ? "dynamic" : "static"}/>
              </Grid>
              </>
              :
              <Grid item component={motion.div} layout={"position"}
                sx={{
                  maxHeight: "100%",
                  overflow: "auto",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  width: "100%",
                  '&::-webkit-scrollbar': {
                    width: "20px",
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: "transparent",
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: "#d6dee1",
                    borderRadius: "20px",
                    border: "6px solid transparent",
                    backgroundClip: "content-box",
                  },
                  '&::-webkit-scrollbar-thumb-hover': {
                    backgroundColor: "#a8bbbf",
                  },
                  backgroundColor: "#ffffff",
                }}
              >
              <ScenarioResources setTriggerModalHelper={setTriggerModalHelper} documentArray={documentArray} loadSource={scenarioList[scenarioID].hasOwnProperty("scenarioEditable") ? "dynamic" : "static"}/>
              </Grid>
              }
              </>
              :
              <Grid item component={motion.div} layout={"position"} sx={{
                maxHeight: "100%",
                width: "100%",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
                overflow: "auto",
                '&::-webkit-scrollbar': {
                  width: "20px",
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: "#ffffff",
                  borderTopRightRadius: "10px",
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: "#d6dee1",
                  borderRadius: "20px",
                  border: "6px solid transparent",
                  backgroundClip: "content-box",
                },
                '&::-webkit-scrollbar-thumb-hover': {
                  backgroundColor: "#a8bbbf",
                },
                background: "linear-gradient(135deg, #ffffff 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px),\nlinear-gradient(225deg, #ffffff 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px)0 64px",
                backgroundColor:"#ffffff",
                backgroundSize:"64px 128px",
              }}>
                <Story coverImage={coverImage} />
              </Grid>
            }
            </Grid>
            {(!scenarioUI.viewResources || fullResources) ?
            <Grid item sx={{
              width: "100%",
              minHeight: (fullWidth && !fullHeight) ? "115px" : "150px",
              borderTop: "1.5px solid #ced7db",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              padding: "10px",
            }}>
              <Choice />
            </Grid>
            :
            null
            }
            <Modal
              open={triggerModalHelper && displayTopBarHelper}
              onClose={() => dispatch(setDisplayTopBarHelper(false))}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              closeAfterTransition
            >
            <TopBarHelper />
            </Modal>
          </Stack>
          }
          </>
        }
      </>
    )
    };
export default Play;