import { Grid, Button, Typography, Stack, IconButton, Tooltip, Divider} from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';

import { setEditorScenarioUI, setEditorScenarioJSONDocumentData, setEditorPlayerDocumentData, cleanUpNodeActions } from '../editor/editorSlice';

import { Editable, withReact, Slate, useSlate } from 'slate-react'
import {
  Editor,
  Node,
  Text,
  Transforms,
  createEditor,
  Path,
  Element as SlateElement,
} from 'slate'

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const RedactionEditor = (props) => {

  const dispatch = useDispatch();
  const userAccessToken = useSelector((state) => state.appData.accessToken);
  const editorScenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets)
  const documentID = editorScenarioAssets.documents[0].documentID;
  const initialDocData = Array.isArray(props.initialDocData) ? props.initialDocData : [{type: 'paragraph',children: [{ text: 'Edit document contents.' },],},]

  const handleDiscard = () => {
    dispatch(setEditorScenarioUI({
        "documentPlayMode": "play"
    }));
  };

  const handleDocumentUpdate = (documentContent) => {
      
      const updateTime = new Date().toISOString();

      // Extract the gold redactions for use in scoring and flow builder.

      const goldRedactions = extractMarkedText(documentContent);
      const documentUpdate = {
          documentID: documentID,
          lastUpdated: updateTime,
          documentJSONData: documentContent.children,
          goldAnswers: goldRedactions,
      }

      // Update backend

      const writeJSONDocument = async (documentUpdate) => {
          try {
              const documentResponse = await fetch('/api/editor/updatejsondocument', {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${userAccessToken}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(documentUpdate),
              });
              const responseData = await documentResponse.json()
              if (responseData.modified > 0) {

                // update the redux copy of the scenario doc
                dispatch(setEditorScenarioJSONDocumentData(documentUpdate));
                dispatch(cleanUpNodeActions({"cleanUpType":"editDocument","goldAnswers":documentUpdate.goldAnswers}))

                // update the user doc - the copy of the doc that users interact with
                dispatch(setEditorPlayerDocumentData(documentUpdate));

                dispatch(setEditorScenarioUI({
                "documentPlayMode": "play"
                }));
              } else {
                    console.log("save error")
                    dispatch(setEditorScenarioUI({
                    "documentPlayMode": "play"
                    }));
              };
              }
          catch(e) {
              console.log(e)
              dispatch(setEditorScenarioUI({
                "documentPlayMode": "play"
                }));
          }
      };
      writeJSONDocument(documentUpdate);
  };

  /*********************************************

  Slate editor

  *********************************************/

  const LIST_TYPES = ['numbered-list', 'bulleted-list']
  const TEXT_ALIGN_TYPES = ['left', 'center', 'right']

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)
  
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })

    let newProperties = {}
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
          align: isActive ? undefined : format,
        }
      } else {
        newProperties = {
          type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
      }
    Transforms.setNodes(editor, newProperties)
  
    if (!isActive && isList) {
      const listLevel = calculateListLevel(editor); // Function to calculate the list level
      const block = { type: format, children: [], listLevel };
      Transforms.wrapNodes(editor, block)
    }
  };

  // Function to calculate the list level
  const calculateListLevel = (editor) => {
    let level = 0;
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      mode: 'highest',
    });

    if (match) {
      const [, path] = match;
      // Each level in the path indicates a level of nesting
      level = path.length - 1;
    }

    return level;
  };

  const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
  
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    )
  
    return !!match
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
    const isGoldActive = Editor.marks(editor)?.redactGold
    
    if (format === "redactGoldCorrect") {
      if (isGoldActive === "shouldRedact") {
        Editor.removeMark(editor, "redactGold")
        Editor.removeMark(editor, "redactEval")
      } else {
        Editor.addMark(editor, "redactGold", "shouldRedact")
        Editor.addMark(editor, "redactEval", "incorrectRedact")
      }
    } else {
    if (format === "redactGoldIncorrect") {
      if (isGoldActive === "shouldNotRedact") {
        Editor.removeMark(editor, "redactGold")
        Editor.removeMark(editor, "redactEval")
      } else {
        Editor.addMark(editor, "redactGold", "shouldNotRedact")
        Editor.addMark(editor, "redactEval", "correctNotRedact")
      }
    } else {
      if (isActive) {
        Editor.removeMark(editor, format)
      } else {
        Editor.addMark(editor, format, true)
      }
    }};
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  };

  const increaseListLevel = (editor) => {
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    });
  
    if (match) {
      const [listItem, path] = match;
  
      // To increase the level, wrap the list item in a new list
      
      const parentList = Node.parent(editor, path)
      const listType = parentList.type
      const listLevel = parentList.listLevel
      const newList = listItem.type === 'list-item' ? { type: listType, listLevel: listLevel+1, children: []} : null;
      if (newList) {
        Transforms.wrapNodes(editor, newList, { at: path });
      }
    }
  };

  const decreaseListLevel = (editor) => {
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      mode: 'lowest', // To get the closest list wrapping the list item
    });
  
    if (match) {
      // The path of the list, not the list item
      const [, listPath] = match;
  
      // Unwrap the list to decrease the level of its items
      Transforms.unwrapNodes(editor, { at: listPath });
    }
  };

  const Element = ({ attributes, children, element }) => {
    const alignVariant = element.align
    let numberedListStyle = "decimal"
    if (element.listLevel === 0) {
      numberedListStyle = "decimal"
    };
    if (element.listLevel === 1) {
      numberedListStyle = "upper-alpha"
    };
    if (element.listLevel === 2) {
      numberedListStyle = "lower-roman"
    };
    if (element.listLevel > 2) {
      numberedListStyle = "lower-alpha"
    };

    switch (element.type) {
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <Typography align={alignVariant} variant='h6' {...attributes}>
            {children}
          </Typography>
        )
      case 'list-item':
        return (
            <Typography paragraph variant='body2' {...attributes} component="li" sx={{paddingLeft: "10px"}}>
            {children}
            </Typography>
        )
      case 'numbered-list':
        return (
          <ol {...attributes} style={{listStyleType: numberedListStyle, marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ol>
        )
      default:
        return (
          <Typography paragraph align={alignVariant} variant='body2' {...attributes}>
            {children}
          </Typography>
        )
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
      children = <strong>{children}</strong>
    };
    if (leaf.italic) {
      children = <em>{children}</em>
    };
    if (leaf.underline) {
      children = <u>{children}</u>
    };
    if (leaf.redactGold === "shouldRedact") {
      children = <span style={{backgroundColor: "#ebe4c2"}} >{children}</span>
    };
    if (leaf.redactGold === "shouldNotRedact") {
      children = <span style={{backgroundColor: "#ebcec2"}} >{children}</span>
    };
    
    // Adding the bug fix text in leaf direct.

    return <span style={{
        paddingLeft: leaf.text === '' ? "0.1px" : null
      }} {...attributes}>{children}</span>
  };

  const extractMarkedText = (editor) => {
  
    const markedText = [];
    
    let index = 0;
    let currentGroup = [];
    let redactionText = "";
    let currentRedactGold = null; // Track the current redaction status to group nodes correctly

    // Function to finalize and reset the current group
    const finalizeGroup = () => {
      if (currentGroup.length > 0) {
        markedText.push({
          inkLabel: "redaction" + index.toString(),
          nodes: currentGroup,
          text: redactionText,
          redactGold: currentRedactGold // Include redactGold status in the result
        });
        currentGroup = [];
        redactionText = "";
        currentRedactGold = null;
        index++;
      }
    }
  
    for (const [node, path] of Node.descendants(editor)) {
      if (Text.isText(node) && (node.redactGold === "shouldRedact" || node.redactGold === "shouldNotRedact")) {
        const nextPath = Path.next(path);
        try {
          const nextNode = Node.get(editor, nextPath);
          if (Text.isText(nextNode) && nextNode.redactGold === node.redactGold) {
            // If next node has the same redactGold value, add to current group
            if (currentRedactGold === null || currentRedactGold === node.redactGold) {
              currentGroup.push(node);
              redactionText += node.text;
              currentRedactGold = node.redactGold;
            }
          } else {
            // If next node has a different redactGold value or is not a text node, finalize current group
            currentGroup.push(node);
            redactionText += node.text;
            currentRedactGold = node.redactGold;
            finalizeGroup();
          }
        } catch (e) {
          // Next path not in document, finalize current group
          currentGroup.push(node);
          redactionText += node.text;
          currentRedactGold = node.redactGold;
          finalizeGroup();
        }
      }
    }
  
    // Ensure any remaining group is finalized
    finalizeGroup();
  
    return markedText;
  };

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withReact(createEditor()), [])

  const ToolbarBlockButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          toggleBlock(editor, props.format)
          }}
          sx={{color: isBlockActive(editor, props.format, "type") ? "#3f51b5" : null}}
          size="small"
        >
            {props.format === "heading-one" ? <FormatSizeIcon /> : null}
            {props.format === "bulleted-list" ? <FormatListBulletedIcon /> : null}
            {props.format === "numbered-list" ? <FormatListNumberedIcon /> : null}
            {props.format === "left" ? <FormatAlignLeftIcon /> : null}
            {props.format === "center" ? <FormatAlignCenterIcon /> : null}
            {props.format === "right" ? <FormatAlignRightIcon /> : null}
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarMarkButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          toggleMark(editor, props.format)
          }}
          sx={{color: isMarkActive(editor, props.format, "type") ? "#3f51b5" : null}}
          size="small"
        >
            {props.format === "bold" ? <FormatBoldIcon /> : null}
            {props.format === "italic" ? <FormatItalicIcon /> : null}
            {props.format === "underline" ? <FormatUnderlinedIcon /> : null}
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarRedactButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={`Mark text that the user ${props.format === "redactGoldCorrect" ? "must" : "must not"} redact`} placement='top'>
        <Button size="small" color="primary" onClick={(event) => {
            event.preventDefault()
            toggleMark(editor, props.format)
        }} startIcon={<FormatPaintIcon fontSize='small' />}>
            {props.format === "redactGoldCorrect" ? "Correct" : "Incorrect"}
        </Button>
        </Tooltip>
    )
  });

  const ToolbarIndentButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          if (props.direction === "increase") {
            increaseListLevel(editor)
          } else {
            decreaseListLevel(editor)
          }
          }}
          size="small"
        >
          {props.direction === "increase" ?
          <FormatIndentIncreaseIcon />
          :
          <FormatIndentDecreaseIcon />
          }
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarSaveButton = () => {
    const editor = useSlate()
    return(
      <Button size="small" variant='outlined' color="primary" onClick={() => {
        handleDocumentUpdate(editor)
        }} startIcon={<DoneIcon fontSize='small' />}>
      Save changes
      </Button>
    )
  };

  return (
  <Grid item sx={{width: "100%"}}>
      <Slate editor={editor} initialValue={initialDocData}>
      <Stack direction="row" sx={{marginBottom: "8px"}} divider={<Divider orientation="vertical" flexItem />}>
        <ToolbarBlockButton tooltip="Heading" format="heading-one" />
        <ToolbarBlockButton tooltip="Bullet list" format="bulleted-list" />
        <ToolbarBlockButton tooltip="Numbered list" format="numbered-list" />
        <ToolbarMarkButton tooltip="Bold" format="bold" />
        <ToolbarMarkButton tooltip="Italic" format="italic" />
        <ToolbarMarkButton tooltip="Underline" format="underline" />
        <ToolbarIndentButton tooltip="Decrease list level" direction="decrease" />
        <ToolbarIndentButton tooltip="Increase list level" direction="increase" />
        <ToolbarBlockButton tooltip="Left align" format="left" />
        <ToolbarBlockButton tooltip="Centre align" format="center" />
        <ToolbarBlockButton tooltip="Right align" format="right" />
        <ToolbarRedactButton format="redactGoldCorrect" />
        <ToolbarRedactButton format="redactGoldIncorrect" />
      </Stack>
      <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={event => {
            if (!event.ctrlKey) {
                return
            }
            switch (event.key) {  
                case 'b': {
                event.preventDefault()
                toggleMark(editor, "bold")
                break
                }
                case 'i': {
                event.preventDefault()
                toggleMark(editor, "italic")
                break
                }
                case 'u': {
                event.preventDefault()
                toggleMark(editor, "underline")
                break
                }
            }
          }}
          style={{
            padding: "8px",
            border: "solid 2px #d6dee1",
            borderRadius: "2px"
        }}
      />
      <Grid container sx={{width: "100%", justifyContent: "end", marginTop: "8px"}}>
        <Stack direction="row" spacing={1}>
            <Button size="small" variant='outlined' color="secondary" onClick={() => handleDiscard()} startIcon={<DeleteIcon fontSize='small' />}>
            Discard
            </Button>
            <ToolbarSaveButton />
        </Stack>
      </Grid>
      </Slate>
  </Grid>
  )
  };
export default RedactionEditor;