import React from 'react';
import { motion } from "framer-motion"
import { Grid } from '@mui/material';

const CoverImage = (props) => {

    const delay = props.delay
    const coverImage = props.coverImage

    return (
        <Grid container 
            component={motion.div} 
            initial={{opacity: 0,}}
            animate={{opacity: 1,
                      transition: {                
                          delay: delay,
                          ease: "easeInOut",
                          duration: 0.5,
                      }}}
            sx={{width: "100%", justifyContent: "center", marginBottom: "30px"}}
        >
        <Grid item>
            <img width="300px" src={`data:${coverImage["type"]};base64,${coverImage["data"]}`} alt="Cover image" />
        </Grid>
        </Grid>  
    )
};
export default CoverImage;