import React from 'react';
import { Grid, Typography } from '@mui/material';

import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { green, amber, red } from '@mui/material/colors';
import { useParams } from "react-router-dom";

/*

A container for rule analysis components. Uses paper background.

*/

const RAAtom = (props) => {

    const green_600 = green[700];
    const amber_600 = amber[700];
    const red_600 = red[700];

    let params = useParams();
    var playerSource = params.hasOwnProperty("scenarioID") ? "choices" : "editorData"

    const atomAnalysis = useSelector((store) => store[playerSource]["globals"][props.atomID])   

    const getBackgroundColor = (analysis) => {
        switch(analysis) {
            case "tbc":
                return("#ced7db")
            case "met":
                return(green_600)
            case "notMet":
                return(red_600)
            case "unclear":
                return(amber_600)
        }
    }

    // pass id rather than atomanalysis and use to look up variable in global choice

    return (
            <Grid container sx={{marginBottom: "5px"}}>
                <Grid item sx={{
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    paddingLeft: "8px",
                    width: "5%",
                    borderTop: "1px solid #ced7db",
                    borderBottom: "1px solid #ced7db",
                    borderLeft: "1px solid #ced7db",
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                }}>
                    <Typography variant="body2">{props.atomRef}</Typography>
                </Grid>
                <Grid item sx={{
                    paddingTop: "3px",
                    paddingBottom: "3px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    width: "80%",
                    border: "1px solid #ced7db",
                }}>
                    {props.children}
                </Grid>
                <Grid item 
                    sx={{
                        padding: "3px",
                        width: "15%",
                        borderTop: "1px solid #ced7db",
                        borderBottom: "1px solid #ced7db",
                        borderRight: "1px solid #ced7db",
                        borderTopRightRadius: "10px",
                        borderBottomRightRadius: "10px",
                    }}
                    component={motion.div} 
                    initial={{background: getBackgroundColor(atomAnalysis)}} 
                    animate={{
                        background: getBackgroundColor(atomAnalysis),
                        transition: {
                            ease: "easeInOut",
                            duration: 0.3
                        }
                    }}>
                    <Grid container style={{alignItems: "center", justifyContent: "center", width: "100%", height: "100%"}}>
                    <Grid item>
                    {atomAnalysis === "tbc" && 
                    <Typography variant="body2" color="textPrimary">?</Typography>
                    }
                    {atomAnalysis === "met" && 
                    <Typography variant="body2" style={{color: "#ffffff"}}>Limb met</Typography>
                    }
                    {atomAnalysis === "notMet" && 
                    <Typography variant="body2" style={{color: "#ffffff"}}>Limb not met</Typography>
                    }
                    {atomAnalysis === "unclear" && 
                    <Typography variant="body2" style={{color: "#ffffff"}}>Potentially met</Typography>
                    }
                    </Grid>
                    </Grid>
                </Grid>
            </Grid>
            )
    };

export default RAAtom;