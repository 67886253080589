import { Grid,Typography, Button } from '@mui/material';
import React from 'react';

import { setDisplayTopBarHelper } from '../features/appData/appDataSlice'

import { useDispatch } from 'react-redux';

const TopBarHelper = React.forwardRef((props, ref) => {

    const dispatch = useDispatch();

    return (
        <Grid container sx={{
            width: "90%", 
            position: "absolute", 
            top: '50%', 
            left: '50%',
            transform: 'translate(-175px, -90px)',
            outline: 0,
        }} tabIndex={-1} ref={ref}>
            <Grid item sx={{
                height: "180px",
                overflow: "auto",
                width: "350px",
                backgroundColor: "#fff",
                padding: "5px",
                borderRadius: "8px"
            }}>
                <Grid container sx={{width: "100%", height: "100%", alignContent: "center"}}>
                    <Grid item sx={{width: "100%", padding: "5px"}}>
                    <Typography align="center" paragraph>You can access scenario documents and other resources from the top player bar.</Typography>
                    </Grid>
                    <Grid container sx={{justifyContent: "center", width: "100%"}}>
                    <Button variant="contained" onClick={() => dispatch(setDisplayTopBarHelper(false))}>Got it</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
});
export default TopBarHelper;