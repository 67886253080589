import React from "react";

function FABadgeColour() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 26.458 27.517"
    >
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse">
          <rect
            width="2.647"
            height="3.487"
            x="89.479"
            y="112.773"
            fill="#fff"
            fillOpacity="1"
            stroke="#fff"
            strokeOpacity="1"
            strokeWidth="0.42"
            ry="0"
          ></rect>
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse">
          <rect
            width="2.647"
            height="3.487"
            x="92.984"
            y="117.994"
            fill="#fff"
            fillOpacity="1"
            stroke="#fff"
            strokeOpacity="1"
            strokeWidth="0.42"
            ry="0"
          ></rect>
        </clipPath>
      </defs>
      <g transform="translate(-61.537 -114.088)">
        <g
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeOpacity="1"
        >
          <circle
            cx="74.766"
            cy="128.375"
            r="13.229"
            fill="#4d4d4d"
            strokeWidth="1.847"
          ></circle>
          <circle
            cx="74.766"
            cy="127.317"
            r="13.229"
            fill="#00897b"
            strokeWidth="1.847"
          ></circle>
          <circle
            cx="74.766"
            cy="127.317"
            r="11.906"
            fill="#26a69a"
            strokeWidth="1.662"
          ></circle>
          <path
            fill="#009688"
            strokeWidth="1.662"
            d="M82.687 136.207a11.906 11.906 0 01-11.66 2.414 11.906 11.906 0 01-7.92-8.89 11.906 11.906 0 013.739-11.303l7.92 8.89z"
          ></path>
        </g>
      </g>
      <g fillOpacity="1" transform="translate(-61.537 -114.088)">
        <g transform="translate(-33.717 -4.027)">
          <rect
            width="7.256"
            height="2.341"
            x="108.263"
            y="134.944"
            fill="#ffdba9"
            strokeWidth="0.299"
            ry="1.171"
          ></rect>
          <rect
            width="3.007"
            height="2.078"
            x="103.716"
            y="131.244"
            fill="#ffc473"
            strokeWidth="0.181"
            ry="0"
          ></rect>
          <rect
            width="5.806"
            height="2.542"
            x="103.716"
            y="133.155"
            fill="#ffdba9"
            strokeWidth="0.279"
            ry="0"
          ></rect>
          <path
            fill="#4db6ac"
            strokeWidth="0.347"
            d="M100.305 130.547H103.816V136.227H100.305z"
          ></path>
          <rect
            width="5.576"
            height="1.91"
            x="156.131"
            y="64.757"
            fill="#ffc473"
            strokeWidth="0.237"
            ry="0.955"
            transform="rotate(28.337)"
          ></rect>
          <rect
            width="5.576"
            height="2.495"
            x="155.948"
            y="67.484"
            fill="#ffdba9"
            strokeWidth="0.27"
            ry="1.247"
            transform="rotate(28.043)"
          ></rect>
          <rect
            width="1.123"
            height="0.349"
            x="146.991"
            y="83.542"
            fill="#ffc473"
            strokeWidth="0.365"
            ry="0.174"
            transform="rotate(21.286)"
          ></rect>
        </g>
        <circle
          cx="75.441"
          cy="124.267"
          r="1.621"
          fill="#ffeb3b"
          stroke="#ffc107"
          strokeOpacity="1"
          strokeWidth="0.22"
        ></circle>
        <circle
          cx="79.048"
          cy="120.895"
          r="1.621"
          fill="#ffeb3b"
          stroke="#ffc107"
          strokeOpacity="1"
          strokeWidth="0.22"
        ></circle>
        <circle
          cx="80.046"
          cy="126.187"
          r="1.621"
          fill="#ffeb3b"
          stroke="#ffc107"
          strokeOpacity="1"
          strokeWidth="0.22"
        ></circle>
      </g>
    </svg>
  );
}

export default FABadgeColour;
