import React from "react";

const BricksPerformant = (props) => {

  const complete = props.complete
  //const complete = 25

  const getGridColor = (complete, row) => {
    if (complete / 9 > row) {
      return "#4caf50"
    } else {
      return "#ffffff"
    }
  };

  const getFillColor = (complete, row) => {
      if (complete / 9 > row) {
          return "#1b5e2066"
      } else {
        return "#cccccc"
      }
  };

  const gridColor = {
    y1: getGridColor(complete, 1),
    y2: getGridColor(complete, 2),
    y3: getGridColor(complete, 3),
    y4: getGridColor(complete, 4),
    y5: getGridColor(complete, 5),
    y6: getGridColor(complete, 6),
    y7: getGridColor(complete, 7),
    y8: getGridColor(complete, 8),
    y9: getGridColor(complete, 9),
    y10: getGridColor(complete, 10),
    y11: getGridColor(complete, 11),
  }

  const fillColor = {
    y1: getFillColor(complete, 1),
    y2: getFillColor(complete, 2),
    y3: getFillColor(complete, 3),
    y4: getFillColor(complete, 4),
    y5: getFillColor(complete, 5),
    y6: getFillColor(complete, 6),
    y7: getFillColor(complete, 7),
    y8: getFillColor(complete, 8),
    y9: getFillColor(complete, 9),
    y10: getFillColor(complete, 10),
    y11: getFillColor(complete, 11),
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 52.988 21.197"
    >
      <g
        fill="none"
        stroke="#ccc"
        strokeLinejoin="bevel"
        strokeMiterlimit="0.2"
        transform="translate(-16.974 -49.905)"
      >
        <g
          strokeDasharray="none"
          stroke="#ccc"
          strokeLinecap="butt"
          strokeOpacity="1"
          strokeWidth="0.191"
          paintOrder="normal"
        >
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M17.07 67.118H22.17V69.04299999999999H17.07z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M22.17 67.118H27.270000000000003V69.04299999999999H22.17z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M27.271 67.118H32.371V69.04299999999999H27.271z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M32.371 67.118H37.471000000000004V69.04299999999999H32.371z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M37.472 67.118H42.572V69.04299999999999H37.472z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M42.572 67.118H47.672000000000004V69.04299999999999H42.572z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M47.673 67.118H52.773V69.04299999999999H47.673z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M52.773 67.118H57.873000000000005V69.04299999999999H52.773z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M57.874 67.118H62.974000000000004V69.04299999999999H57.874z"></path>
          <path stroke={gridColor["y2"]} fill={fillColor["y2"]}  d="M62.974 67.118H68.074V69.04299999999999H62.974z"></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          stroke="#ccc"
          strokeOpacity="1"
          paintOrder="normal"
        >
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M19.054 65.193H24.153999999999996V67.118H19.054z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M24.155 65.193H29.255000000000003V67.118H24.155z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M29.255 65.193H34.355V67.118H29.255z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M34.356 65.193H39.456V67.118H34.356z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M39.456 65.193H44.556000000000004V67.118H39.456z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M44.557 65.193H49.657000000000004V67.118H44.557z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M49.657 65.193H54.757V67.118H49.657z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M54.758 65.193H59.858000000000004V67.118H54.758z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.191"
            d="M59.858 65.193H64.958V67.118H59.858z"
          ></path>
          <path
          
         stroke={gridColor["y3"]} fill={fillColor["y3"]}
          
            strokeWidth="0.186"
            d="M64.956 65.19H69.781V67.12H64.956z"
          ></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          stroke="#ccc"
          strokeOpacity="1"
          strokeWidth="0.191"
          paintOrder="normal"
        >
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M17.07 63.267H22.17V65.19200000000001H17.07z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M22.17 63.267H27.270000000000003V65.19200000000001H22.17z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M27.271 63.267H32.371V65.19200000000001H27.271z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M32.371 63.267H37.471000000000004V65.19200000000001H32.371z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M37.472 63.267H42.572V65.19200000000001H37.472z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M42.572 63.267H47.672000000000004V65.19200000000001H42.572z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M47.673 63.267H52.773V65.19200000000001H47.673z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M52.773 63.267H57.873000000000005V65.19200000000001H52.773z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M57.874 63.267H62.974000000000004V65.19200000000001H57.874z"></path>
          <path stroke={gridColor["y4"]} fill={fillColor["y4"]}  d="M62.974 63.267H68.074V65.19200000000001H62.974z"></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeOpacity="1"
          stroke="#ccc"
          paintOrder="normal"
        >
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M19.054 61.342H24.153999999999996V63.266999999999996H19.054z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M24.155 61.342H29.255000000000003V63.266999999999996H24.155z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M29.255 61.342H34.355V63.266999999999996H29.255z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M34.356 61.342H39.456V63.266999999999996H34.356z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M39.456 61.342H44.556000000000004V63.266999999999996H39.456z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M44.557 61.342H49.657000000000004V63.266999999999996H44.557z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M49.657 61.342H54.757V63.266999999999996H49.657z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M54.758 61.342H59.858000000000004V63.266999999999996H54.758z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.191"
            d="M59.858 61.342H64.958V63.266999999999996H59.858z"
          ></path>
          <path
            stroke={gridColor["y5"]} fill={fillColor["y5"]} 
            strokeWidth="0.186"
            d="M64.956 61.339H69.781V63.269H64.956z"
          ></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeOpacity="1"
          strokeWidth="0.191"
          stroke="#ccc"
          paintOrder="normal"
        >
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M17.07 59.416H22.17V61.340999999999994H17.07z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M22.17 59.416H27.270000000000003V61.340999999999994H22.17z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M27.271 59.416H32.371V61.340999999999994H27.271z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M32.371 59.416H37.471000000000004V61.340999999999994H32.371z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M37.472 59.416H42.572V61.340999999999994H37.472z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M42.572 59.416H47.672000000000004V61.340999999999994H42.572z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M47.673 59.416H52.773V61.340999999999994H47.673z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M52.773 59.416H57.873000000000005V61.340999999999994H52.773z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M57.874 59.416H62.974000000000004V61.340999999999994H57.874z"></path>
          <path stroke={gridColor["y6"]} fill={fillColor["y6"]}  d="M62.974 59.416H68.074V61.340999999999994H62.974z"></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          stroke="#ccc"
          strokeOpacity="1"
          paintOrder="normal"
        >
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M19.054 57.491H24.153999999999996V59.416H19.054z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M24.155 57.491H29.255000000000003V59.416H24.155z"
          ></path>
          <path
           stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M29.255 57.491H34.355V59.416H29.255z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M34.356 57.491H39.456V59.416H34.356z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M39.456 57.491H44.556000000000004V59.416H39.456z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M44.557 57.491H49.657000000000004V59.416H44.557z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M49.657 57.491H54.757V59.416H49.657z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M54.758 57.491H59.858000000000004V59.416H54"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.191"
            d="M59.858 57.491H64.958V59.416H59.858z"
          ></path>
          <path
            stroke={gridColor["y7"]} fill={fillColor["y7"]} 
            strokeWidth="0.186"
            d="M64.956 57.488H69.781V59.418H64.956z"
          ></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeOpacity="1"
          stroke="#ccc"
          strokeWidth="0.191"
          paintOrder="normal"
        >
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M17.07 55.565H22.17V57.489999999999995H17.07z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M22.17 55.565H27.270000000000003V57.489999999999995H22.17z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M27.271 55.565H32.371V57.489999999999995H27.271z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M32.371 55.565H37.471000000000004V57.489999999999995H32.371z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M37.472 55.565H42.572V57.489999999999995H37.472z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M42.572 55.565H47.672000000000004V57.489999999999995H42.572z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M47.673 55.565H52.773V57.489999999999995H47.673z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M52.773 55.565H57.873000000000005V57.489999999999995H52.773z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M57.874 55.565H62.974000000000004V57.489999999999995H57.874z"></path>
          <path stroke={gridColor["y8"]} fill={fillColor["y8"]}  d="M62.974 55.565H68.074V57.489999999999995H62.974z"></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeOpacity="1"
          stroke="#ccc"
          paintOrder="normal"
        >
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M19.054 53.64H24.153999999999996V55.565H19.054z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M24.155 53.64H29.255000000000003V55.565H24.155z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M29.255 53.64H34.355V55.565H29.255z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M34.356 53.64H39.456V55.565H34.356z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M39.456 53.64H44.556000000000004V55.565H39.456z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M44.557 53.64H49.657000000000004V55.565H44.557z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M49.657 53.64H54.757V55.565H49.657z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M54.758 53.64H59.858000000000004V55.565H54.758z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.191"
            d="M59.858 53.64H64.958V55.565H59.858z"
          ></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeWidth="0.185"
            d="M64.956 53.637H69.735V55.568H64.956z"
          ></path>
        </g>
        <g strokeWidth="0.191" stroke="#ccc">
          <path stroke={gridColor["y9"]} fill={fillColor["y9"]}  d="M17.853 51.714h4.317v1.926l-5.053-.035c.12-.888.21-1.133.736-1.89z"></path>
          <path
            stroke={gridColor["y9"]} fill={fillColor["y9"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M22.17 51.714H27.270000000000003V53.638999999999996H22.17z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M27.271 51.714H32.371V53.638999999999996H27.271z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M32.371 51.714H37.471000000000004V53.638999999999996H32.371z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M37.472 51.714H42.572V53.638999999999996H37.472z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M42.572 51.714H47.672000000000004V53.638999999999996H42.572z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M47.673 51.714H52.773V53.638999999999996H47.673z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M52.773 51.714H57.873000000000005V53.638999999999996H52.773z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M57.874 51.714H62.974000000000004V53.638999999999996H57.874z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y10"]} fill={fillColor["y10"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            d="M62.974 51.714H68.074V53.638999999999996H62.974z"
            paintOrder="normal"
          ></path>
        </g>
        <g
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeOpacity="1"
          paintOrder="normal"
        >
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M19.054 69.044H24.153999999999996V70.969H19.054z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M24.155 69.044H29.255000000000003V70.969H24.155z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M29.255 69.044H34.355V70.969H29.255z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M34.356 69.044H39.456V70.969H34.356z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M39.456 69.044H44.556000000000004V70.969H39.456z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M44.557 69.044H49.657000000000004V70.969H44.557z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M49.657 69.044H54.757V70.969H49.657z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M54.758 69.044H59.858000000000004V70.969H54.758z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.191"
            d="M59.858 69.044H64.958V70.969H59.858z"
          ></path>
          <path
            stroke={gridColor["y1"]} fill={fillColor["y1"]}
            strokeWidth="0.186"
            d="M64.956 69.041H69.781V70.971H64.956z"
          ></path>
        </g>
        <path
          strokeWidth="0.262"
          d="M69.82 70.97c.037-5.573-.034-11.66-.03-16.965a3.96 3.96 0 00-3.969-3.969H21.105a3.96 3.96 0 00-3.97 3.969v4.6h-.03V70.97z"
        ></path>
        <g>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeWidth="0.175"
            d="M19.096 50.518l1.176-.433 4.009-.07v1.707h-5.185z"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M24.281 50.108H29.383V51.721999999999994H24.281z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M29.383 50.108H34.485V51.721999999999994H29.383z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M34.485 50.108H39.587V51.721999999999994H34.485z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M39.587 50.108H44.68900000000001V51.721999999999994H39.587z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M44.69 50.108H49.792V51.721999999999994H44.69z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M49.792 50.108H54.894000000000005V51.721999999999994H49.792z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M54.894 50.108H59.995999999999995V51.721999999999994H54.894z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeOpacity="1"
            strokeWidth="0.175"
            d="M59.997 50.108H65.099V51.721999999999994H59.997z"
            paintOrder="normal"
          ></path>
          <path
            stroke={gridColor["y11"]} fill={fillColor["y11"]} 
            strokeWidth="0.159"
            d="M65.063 50.014l1.97.188 1.245.678.842.811h-4.057z"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default BricksPerformant;