import React, { useEffect } from 'react';
import { Grid } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'

import Banner from '../features/Banner';
import Footer from '../features/Footer';
import ScrollToTop from '../ScrollToTop';
import { setAppData, setAccessToken } from '../features/appData/appDataSlice';

import { Outlet } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams } from "react-router-dom";

/*

could try, bring auth on here main component (use effect) / neater
make dispatch condition so dones't overwrite if have got user data.

*/

const EditorBase = (props) => {

    let params = useParams();
    const { isAuthenticated, getAccessTokenSilently, isLoading, loginWithRedirect } = useAuth0();
    var navigate = useNavigate();
    const footer = props.footer;
    const dispatch = useDispatch()

    const skillsTree = useSelector((store) => store.appData.skillsTree);
    
    useEffect(() => {

      // run on authentication

      const getAppData = async () => {

        if (isAuthenticated && Object.keys(skillsTree).length === 0) {
          // Generally don't need to reload app data. This might happen if move between editor and learn (and can cause problems)
          try {
          const userAccessToken = await getAccessTokenSilently({
            audience: "https://www.legalreflex.com/api", 
          });
          const appUserDataResponse = await fetch('/api/appuserdata', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
            }
          });

          const appUserData = await appUserDataResponse.json();
          dispatch(setAppData(appUserData));
          dispatch(setAccessToken(userAccessToken));

          // re-direct. set to "" if null

          const practiceAreaID = sessionStorage.getItem("practiceAreaID") ? sessionStorage.getItem("practiceAreaID") : ""
          const topicID = sessionStorage.getItem("topicID") ? sessionStorage.getItem("topicID") : ""
          const subTopicID = sessionStorage.getItem("subTopicID") ? sessionStorage.getItem("subTopicID") : ""
          const wikiSection = sessionStorage.getItem("wikiSection")
          const scenarioID = sessionStorage.getItem("scenarioID") ? sessionStorage.getItem("scenarioID") : ""
          const editorScenarioID = sessionStorage.getItem("editorScenarioID") ? sessionStorage.getItem("editorScenarioID") : ""

          if (scenarioID !== "") {
            navigate(`/play/${scenarioID}`);
          } else {
            if (subTopicID !== "") {
              navigate(`/explore/${practiceAreaID}/${topicID}/${subTopicID}`);
            } else {
              if (topicID !== "") {
                navigate(`/explore/${practiceAreaID}/${topicID}`);
              } else {
                if (practiceAreaID !== "") {
                  navigate(`/explore/${practiceAreaID}`);
                } else {
                  if (editorScenarioID !== "") {
                    navigate(`/editor/${editorScenarioID}`);
                  }
                }
              }
            }
          }

          // clear session data
          sessionStorage.setItem("practiceAreaID", "")
          sessionStorage.setItem("topicID", "")
          sessionStorage.setItem("subTopicID", "")
          sessionStorage.setItem("wikiSection", "")
          sessionStorage.setItem("scenarioID", "")
          sessionStorage.setItem("editorScenarioID", "")

          } catch(e) {
            console.log(e.message)
          };
        };

        if (!isAuthenticated) {
          
          // initiate and set landing params for re-direct

          sessionStorage.setItem("practiceAreaID", "")
          sessionStorage.setItem("topicID", "")
          sessionStorage.setItem("subTopicID", "")
          sessionStorage.setItem("wikiSection", "")
          sessionStorage.setItem("scenarioID", "")
          sessionStorage.setItem("editorScenarioID", "")

          Object.keys(params).forEach((key) => {
            sessionStorage.setItem(key, params[key])
          })

          loginWithRedirect()
        };
      };

      if (!isLoading) {
        getAppData()
      };

  },[isAuthenticated, isLoading]);

    return (
        <>
        <ScrollToTop />
        <Grid item style={{
          flexGrow: 1,
          display: "flex",
          backgroundColor: "#fafafa",
          
        }}>
        <Grid container style={{flexDirection: "column"}}>
        <Grid item>
            <Banner />
        </Grid>
        <Grid item style={{flexGrow: 1}}>
            <Outlet />
        </Grid>
        </Grid>
        </Grid>
        {footer &&
        <Grid item sx={{width: "100%"}}>
            <Footer />
        </Grid>
        }
        </>
        )
    };

export default EditorBase;