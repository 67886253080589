import React from 'react';
import { Grid, CircularProgress} from '@mui/material';
import { useParams, Outlet } from "react-router-dom";
import { useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import TopicCard from '../features/TopicCard';
import ExploreHeading from '../features/ExploreHeading';
import ExploreSidePanel from '../features/ExploreSidePanel';

/*

This is a container for the explore pages (topic and subtopic)

review icons
and highlighting
add breadcrumb
sort fade in

*/

const Explore = () => {

    let params = useParams();
    const theme = useTheme();
    const exploreTopics = params.hasOwnProperty("practiceAreaID")
    const topic = params.hasOwnProperty("topicID")

    const skillsTree = useSelector((store) => store.appData.skillsTree)
    const topicList = useSelector((store) => store.appData.topicList)
    const waitingForAppData = Object.keys(skillsTree).length === 0

    const showSidePanel = useMediaQuery(theme.breakpoints.up('md'))

    const getSortedTopics = () => {
        let sortedTopics = Object.keys(skillsTree[params.practiceAreaID]["topics"]).map((topicID) => ([topicID, topicList[topicID]["UIOrder"]]))
        sortedTopics.sort((topicOne, topicTwo) => topicOne[1] - topicTwo[1])
        return (sortedTopics)
    };
    
    return (
        <Grid container sx={{width: "100%"}}>
        {waitingForAppData ?
        <Grid container sx={{width: "100%", justifyContent: "center"}}>
        <CircularProgress />
        </Grid>
        :
        <>
        <Grid item sx={{
            width: "100%", 

            }}>
            <ExploreHeading />
        </Grid>
        <Grid container sx={{
            justifyContent: "space-between", 
            paddingLeft: "15px",
            paddingRight: "15px"
            }}>
        {topic ?
        <Grid item sx={{flex: 1}}>
        <Outlet />
        </Grid>
        :
        <Grid item sx={{flex: 1}}>
        <Grid container sx={{justifyContent: "center"}}>
            {exploreTopics ? 
            <>
            {getSortedTopics().map((item, idx) => {
                const topicID = item[0]
                return(
                <TopicCard contentID={topicID} contentType="topicList" key={topicID} fadeIndex={idx}/>
                )
            })}
            </>
            :
            <>
            {Object.keys(skillsTree).map((key, idx) => {
                return(
                <TopicCard contentID={key} contentType="practiceAreaList" key={key} fadeIndex={idx}/>
                )
            })}
            </>
            }
        </Grid>
        </Grid>
        }
        {showSidePanel ?
        <Grid item>
        <ExploreSidePanel />
        </Grid>
        :
        null
        }
        </Grid>
        </>
        }
        </Grid>
        
    )
    };

export default Explore;