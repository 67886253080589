import React from 'react';
import { Grid } from '@mui/material';

import AchievementsBadge from './AchievementsBadge';
import { useSelector } from 'react-redux';

/*
Flexible component - pass single achievement name and grey/colour to get back svg.
*/

const AchievementsList = () => {

  const userAchievements = useSelector((state) => state.appData.userData.userAchievements);

  return (
      <Grid container>
        {userAchievements.map((achievementID, idx) => (
          <Grid item style={{width:50, margin: 5}} key={"achievementsListG" + idx} >
            <AchievementsBadge badgeID={achievementID} key={"achievementBadge" + idx} />
          </Grid>
        ))}
      </Grid>
    )
};
export default AchievementsList;