import { Grid, Button, Typography, Stack, IconButton, Tooltip, Divider, Box} from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import FontDownloadOffIcon from '@mui/icons-material/FontDownloadOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';

import { setEditorScenarioUI, setEditorScenarioJSONDocumentData, setEditorPlayerDocumentData, cleanUpNodeActions } from '../editor/editorSlice';

import { Editable, withReact, Slate, useSlate } from 'slate-react'
import {
  Editor,
  Node,
  Transforms,
  createEditor,
  Path,
  Element as SlateElement,
} from 'slate'

import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const MarkUpEditor = (props) => {

  const dispatch = useDispatch();
  const userAccessToken = useSelector((state) => state.appData.accessToken);
  const editorScenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets)
  const documentID = editorScenarioAssets.documents[0].documentID;
  const initialDocData = Array.isArray(props.initialDocData) ? props.initialDocData : [{type: 'paragraph',children: [{ text: 'Edit document contents.' },],},]

  const handleDiscard = () => {
    dispatch(setEditorScenarioUI({
        "documentPlayMode": "play"
    }));
  };

  const handleDocumentUpdate = (documentContent) => {
      
      const updateTime = new Date().toISOString();

      const goldMarkUp = extractTextFromNodes(documentContent, 'goldMarkUp');
      const documentUpdate = {
          documentID: documentID,
          lastUpdated: updateTime,
          documentJSONData: documentContent.children,
          goldAnswers: goldMarkUp
      }

      // Update backend

      const writeJSONDocument = async (documentUpdate) => {
          try {
              const documentResponse = await fetch('/api/editor/updatejsondocument', {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${userAccessToken}`,
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(documentUpdate),
              });
              const responseData = await documentResponse.json()
              if (responseData.modified > 0) {

                // update the redux copy of the scenario doc
                dispatch(setEditorScenarioJSONDocumentData(documentUpdate));
                dispatch(cleanUpNodeActions({"cleanUpType":"editDocument","goldAnswers":documentUpdate.goldAnswers}))

                // update the user doc - the copy of the doc that users interact with
                dispatch(setEditorPlayerDocumentData(documentUpdate));

                dispatch(setEditorScenarioUI({
                "documentPlayMode": "play"
                }));
              } else {
                    console.log("save error")
                    dispatch(setEditorScenarioUI({
                    "documentPlayMode": "play"
                    }));
              };
              }
          catch(e) {
              console.log(e)
              dispatch(setEditorScenarioUI({
                "documentPlayMode": "play"
                }));
          }
      };
      writeJSONDocument(documentUpdate);
  };

  /*********************************************

  Slate editor

  *********************************************/

  const LIST_TYPES = ['numbered-list', 'bulleted-list']
  const TEXT_ALIGN_TYPES = ['left', 'center', 'right']  

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
    )
    const isList = LIST_TYPES.includes(format)
  
    Transforms.unwrapNodes(editor, {
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        LIST_TYPES.includes(n.type) &&
        !TEXT_ALIGN_TYPES.includes(format),
      split: true,
    })

    let newProperties = {}
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
          align: isActive ? undefined : format,
        }
      } else {
        newProperties = {
          type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
      }
    Transforms.setNodes(editor, newProperties)
  
    if (!isActive && isList) {
      const listLevel = calculateListLevel(editor); // Function to calculate the list level
      const block = { type: format, children: [], listLevel };
      Transforms.wrapNodes(editor, block)
    }
  };

  // Function to calculate the list level
  const calculateListLevel = (editor) => {
    let level = 0;
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      mode: 'highest',
    });

    if (match) {
      const [, path] = match;
      // Each level in the path indicates a level of nesting
      level = path.length - 1;
    }

    return level;
  };

  const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false
  
    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n[blockType] === format,
      })
    )
  
    return !!match
  };

  const toggleMarkUp = (editor, markUp) => {
    // unlike in redact documents, can pre-set playerMarkUp - it will always be either unset, accept or reject.
    const isActive = isMarkUpActive(editor, markUp);
    if (isActive) {
      Transforms.unsetNodes(editor, "goldMarkUp")
      Transforms.unsetNodes(editor, "evalMarkUp")
      Transforms.unsetNodes(editor, "playerMarkUp")
    } else {
      Transforms.setNodes(editor, {goldMarkUp: markUp})
      Transforms.setNodes(editor, {evalMarkUp: "unset"})
      Transforms.setNodes(editor, {playerMarkUp: "unset"})
    }
  };

  const isMarkUpActive = (editor, markUp) => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n.goldMarkUp === markUp,
    });

  return !!match;
  };

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)
    // const isAddActive = isMarkActive(editor, "addText")
    // const isRemoveActive = isMarkActive(editor, "removeText")

    // Add logic around add / removing toggle
    // Would be better to just have a track changes button which would then work on the inputs...
    
    if (format === "addText") {
      if (isActive) {
        Editor.removeMark(editor, "addText")
      } else {
        Editor.addMark(editor, "addText", true)
      }
    } else {

    if (format === "removeText") {
      if (isActive) {
        Editor.removeMark(editor, "removeText")
      } else {
        Editor.addMark(editor, "removeText", true)
      }
    } else {
      if (isActive) {
        Editor.removeMark(editor, format)
      } else {
        Editor.addMark(editor, format, true)
      }
    };
    };
  };

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  };

  const increaseListLevel = (editor) => {
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
    });
  
    if (match) {
      const [listItem, path] = match;
  
      // To increase the level, wrap the list item in a new list
      
      const parentList = Node.parent(editor, path)
      const listType = parentList.type
      const listLevel = parentList.listLevel
      const newList = listItem.type === 'list-item' ? { type: listType, listLevel: listLevel+1, children: []} : null;
      if (newList) {
        Transforms.wrapNodes(editor, newList, { at: path });
      }
    }
  };

  const decreaseListLevel = (editor) => {
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      mode: 'lowest', // To get the closest list wrapping the list item
    });
  
    if (match) {
      // The path of the list, not the list item
      const [, listPath] = match;
  
      // Unwrap the list to decrease the level of its items
      Transforms.unwrapNodes(editor, { at: listPath });
    }
  };

  const Element = ({ attributes, children, element }) => {
    const alignVariant = element.align

    const accept = () => {
      return(
        <Stack direction="row">
          <Box sx={{flex: 1}}>
          <Typography variant='body2' sx={{color: "#4caf50"}}>Accept</Typography>
          </Box>
          <CheckCircleOutlineIcon fontSize='small' sx={{color: "#4caf50"}} />
        </Stack>
      )
    };
    
    const reject = () => {
      return(
        <Stack direction="row">
          <Box sx={{flex: 1}}>
          <Typography variant='body2' sx={{color: "#f44336"}}>Reject</Typography>
          </Box>
          <HighlightOffIcon fontSize='small' sx={{color: "#f44336"}} />
        </Stack>
      )
    }

    let numberedListStyle = "decimal"
    if (element.listLevel === 0) {
      numberedListStyle = "decimal"
    };
    if (element.listLevel === 1) {
      numberedListStyle = "upper-alpha"
    };
    if (element.listLevel === 2) {
      numberedListStyle = "lower-roman"
    };
    if (element.listLevel > 2) {
      numberedListStyle = "lower-alpha"
    };

    switch (element.type) {
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Box sx={{flex: 1}}>
          <Typography align={alignVariant} variant='h6' {...attributes}>
            {children}
          </Typography>
          </Box>
          <Box sx={{width: "80px"}}>
          {element?.goldMarkUp === "accept" ? 
          <>
          {accept()}
          </> :
          element?.goldMarkUp === "reject" ?
          <>
          {reject()}
          </> :
          null }
          </Box>
          </Stack>
        )
      case 'list-item':
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Box sx={{flex: 1}}>
            <Typography paragraph variant='body2' {...attributes} component="li" sx={{paddingLeft: "10px"}}>
            {children}
            </Typography>
          </Box>
          <Box sx={{width: "80px"}}>
          {element?.goldMarkUp === "accept" ? 
          <>
          {accept()}
          </> :
          element?.goldMarkUp === "reject" ?
          <>
          {reject()}
          </> :
          null }
          </Box>
          </Stack>
        )
      case 'numbered-list':
        return (
          <ol style={{listStyleType: numberedListStyle}} {...attributes}>
            {children}
          </ol>
        )
      default:
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Box sx={{flex: 1}}>
            <Typography paragraph align={alignVariant} variant='body2' {...attributes}>
              {children}
            </Typography>
          </Box>
          <Box sx={{width: "80px"}}>
          {element?.goldMarkUp === "accept" ? 
          <>
          {accept()}
          </> :
          element?.goldMarkUp === "reject" ?
          <>
          {reject()}
          </> :
          null }
          </Box>
          </Stack>
        )
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {

    if (leaf.bold) {
      children = <strong>{children}</strong>
    };
    if (leaf.italic) {
      children = <em>{children}</em>
    };
    if (leaf.underline) {
      children = <u>{children}</u>
    };
    if (leaf.addText) {
      children = <ins style={{ textDecoration: 'underline #3f51b5', color: '#3f51b5'}}>{children}</ins>
    };
    if (leaf.removeText) {
      children = <del style={{ textDecoration: 'line-through #f44336', color: '#f44336' }}>{children}</del>
    };
    return (
      <span style={{
        paddingLeft: leaf.text === '' ? "0.1px" : null
      }} {...attributes}>{children}</span>
    )
  };

  const extractTextFromNodes = (editor, markType) => {
    const markedText = []
    let index = 0
    
    for (const [node, nodePath] of Node.children(editor, [])) {
      let text = '';

      if (node[markType]) {
        for (const [textNode, path] of Node.texts(node)) {
            text += textNode.text
        }
        markedText.push({inkLabel: "markup"+index.toString(), text: text });
        index ++
      };
    }

      // markup flag is set on list items, so have to loop through them
      // think again about where this is set.
      // either go and check the list item to see if set there
      // or allow to set on an entire list?

    for (const [listItemNode, ] of Editor.nodes(editor, {at: [], match: n => n.type === "list-item"})) {
      
      if (listItemNode[markType]) {
        let text = '';
        for (const [listTextNode, ] of Node.texts(listItemNode)) {
          text += listTextNode.text
        }
        markedText.push({inkLabel: "markup"+index.toString(), text: text });
        index ++
      }
    }
    
    return markedText
  };

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withReact(createEditor()), [])

  const ToolbarBlockButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          toggleBlock(editor, props.format)
          }}
          sx={{color: isBlockActive(editor, props.format, "type") ? "#3f51b5" : null}}
          size="small"
        >
            {props.format === "heading-one" ? <FormatSizeIcon /> : null}
            {props.format === "bulleted-list" ? <FormatListBulletedIcon /> : null}
            {props.format === "numbered-list" ? <FormatListNumberedIcon /> : null}
            {props.format === "left" ? <FormatAlignLeftIcon /> : null}
            {props.format === "center" ? <FormatAlignCenterIcon /> : null}
            {props.format === "right" ? <FormatAlignRightIcon /> : null}
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarIndentButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          if (props.direction === "increase") {
            increaseListLevel(editor)
          } else {
            decreaseListLevel(editor)
          }
          }}
          size="small"
        >
          {props.direction === "increase" ?
          <FormatIndentIncreaseIcon />
          :
          <FormatIndentDecreaseIcon />
          }
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarMarkUpButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          toggleMarkUp(editor, props.format)
          }}
          sx={{color: isMarkUpActive(editor, props.format) ? "#3f51b5" : null}}
          size="small"
        >
            {props.format === "accept" ? <CheckCircleOutlineIcon /> : null}
            {props.format === "reject" ? <HighlightOffIcon /> : null}
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarMarkButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    return(
        <Tooltip title={props.tooltip} placement='top'>
        <IconButton onMouseDown={event => {
          event.preventDefault()
          toggleMark(editor, props.format)
          }}
          sx={{color: isMarkActive(editor, props.format, "type") ? "#3f51b5" : null}}
          size="small"
        >
            {props.format === "bold" ? <FormatBoldIcon /> : null}
            {props.format === "italic" ? <FormatItalicIcon /> : null}
            {props.format === "underline" ? <FormatUnderlinedIcon /> : null}
            {props.format === "addText" ? <FontDownloadIcon /> : null}
            {props.format === "removeText" ? <FontDownloadOffIcon /> : null}
        </IconButton>
        </Tooltip>
    )
  });

  const ToolbarSaveButton = () => {
    const editor = useSlate()
    return(
      <Button size="small" variant='outlined' color="primary" onClick={() => {
        handleDocumentUpdate(editor)
        }} startIcon={<DoneIcon fontSize='small' />}>
      Save changes
      </Button>
    )
  };

  return (
  <Grid item sx={{width: "100%"}}>
      <Slate editor={editor} initialValue={initialDocData}>
      <Grid container sx={{width: "100%"}}>
      <Stack direction="row" sx={{marginBottom: "8px", marginRight: "20px"}} divider={<Divider orientation="vertical" flexItem />}>
        <ToolbarBlockButton tooltip="Heading" format="heading-one" />
        <ToolbarBlockButton tooltip="Bullet list" format="bulleted-list" />
        <ToolbarBlockButton tooltip="Numbered list" format="numbered-list" />
        <ToolbarMarkButton tooltip="Bold" format="bold" />
        <ToolbarMarkButton tooltip="Italic" format="italic" />
        <ToolbarMarkButton tooltip="Underline" format="underline" />
        <ToolbarIndentButton tooltip="Decrease list level" direction="decrease" />
        <ToolbarIndentButton tooltip="Increase list level" direction="increase" />
        <ToolbarBlockButton tooltip="Left align" format="left" />
        <ToolbarBlockButton tooltip="Centre align" format="center" />
        <ToolbarBlockButton tooltip="Right align" format="right" />
      </Stack>
      <Stack direction="row" sx={{marginBottom: "8px"}} divider={<Divider orientation="vertical" flexItem />}>
        <ToolbarMarkButton tooltip="Add mark-up text" format="addText" />
        <ToolbarMarkButton tooltip="Remove mark-up text" format="removeText" />
        <ToolbarMarkUpButton tooltip="Mark accept change" format="accept" />
        <ToolbarMarkUpButton tooltip="Mark reject change" format="reject" />
      </Stack>
      </Grid>
      <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={event => {
            if (!event.ctrlKey) {
                return
            }
            switch (event.key) {  
                case 'b': {
                event.preventDefault()
                toggleMark(editor, "bold")
                break
                }
                case 'i': {
                event.preventDefault()
                toggleMark(editor, "italic")
                break
                }
                case 'u': {
                event.preventDefault()
                toggleMark(editor, "underline")
                break
                }
            }
          }}
          style={{
            padding: "8px",
            border: "solid 2px #d6dee1",
            borderRadius: "2px"
        }}
      />
      <Grid container sx={{width: "100%", justifyContent: "end", marginTop: "8px"}}>
        <Stack direction="row" spacing={1}>
            <Button size="small" variant='outlined' color="secondary" onClick={() => handleDiscard()} startIcon={<DeleteIcon fontSize='small' />}>
            Discard
            </Button>
            <ToolbarSaveButton />
        </Stack>
      </Grid>
      </Slate>
  </Grid>
  )
  };
export default MarkUpEditor;