import React, { useEffect } from 'react';
import { Grid, Modal, Backdrop } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux'

import Banner from '../features/Banner';
import Footer from '../features/Footer';
import ScrollToTop from '../ScrollToTop';
import WelcomeModal from '../features/WelcomeModal';
import { setAppData, setAccessToken } from '../features/appData/appDataSlice';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Outlet } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from "react-router-dom";

/*

try, if not authenticated, capture params and re-direct to login.

to capture params. try setting state in login request https://auth0.com/docs/secure/attack-protection/state-parameters
plus the re-direct locally
if state matches return from login, re-direct
try using session storage for url params.

http://localhost:3000/explore/9ae97d04-08da-430d-a785-f93c7b49a690/d479effe-1429-41a3-8372-cb6c46ef9929

*/

const BasicPage = (props) => {

  const theme = useTheme();
  let params = useParams();
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const fullWidth = useMediaQuery(theme.breakpoints.up('sm'))
  const fullHeight = useMediaQuery('(min-height:610px)')
  //console.log(fullHeight, fullWidth)

  const skillsTree = useSelector((store) => store.appData.skillsTree);
  const firstTimeLogin = useSelector((store) => store.appData.appUI.firstTimeLogin);
  
  const footer = props.footer;
  const wide = props.wide;
  const dispatch = useDispatch()
  var navigate = useNavigate();
  
  useEffect(() => {

      // run on authentication

      const getAppData = async () => {

        if (isAuthenticated && Object.keys(skillsTree).length === 0) {
          // Generally don't need to reload app data. This might happen if move between editor and learn (and can cause problems)
          try {
          const userAccessToken = await getAccessTokenSilently({
            audience: "https://www.legalreflex.com/api", 
          });
          const appUserDataResponse = await fetch('/api/appuserdata', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
            }
          });

          const appUserData = await appUserDataResponse.json();
          dispatch(setAppData(appUserData));
          dispatch(setAccessToken(userAccessToken));

          // re-direct. set to "" if null

          const practiceAreaID = sessionStorage.getItem("practiceAreaID") ? sessionStorage.getItem("practiceAreaID") : ""
          const topicID = sessionStorage.getItem("topicID") ? sessionStorage.getItem("topicID") : ""
          const subTopicID = sessionStorage.getItem("subTopicID") ? sessionStorage.getItem("subTopicID") : ""
          const wikiSection = sessionStorage.getItem("wikiSection")
          const scenarioID = sessionStorage.getItem("scenarioID") ? sessionStorage.getItem("scenarioID") : ""
          const editorScenarioID = sessionStorage.getItem("editorScenarioID") ? sessionStorage.getItem("editorScenarioID") : ""

          if (scenarioID !== "") {
            navigate(`/play/${scenarioID}`);
          } else {
            if (subTopicID !== "") {
              navigate(`/explore/${practiceAreaID}/${topicID}/${subTopicID}`);
            } else {
              if (topicID !== "") {
                navigate(`/explore/${practiceAreaID}/${topicID}`);
              } else {
                if (practiceAreaID !== "") {
                  navigate(`/explore/${practiceAreaID}`);
                } else {
                  if (editorScenarioID !== "") {
                    navigate(`/editor/${editorScenarioID}`);
                  }
                }
              }
            }
          }

          // clear session data
          sessionStorage.setItem("practiceAreaID", "")
          sessionStorage.setItem("topicID", "")
          sessionStorage.setItem("subTopicID", "")
          sessionStorage.setItem("wikiSection", "")
          sessionStorage.setItem("scenarioID", "")
          sessionStorage.setItem("editorScenarioID", "")

          } catch(e) {
            console.log(e.message)
          };
        };

        if (!isAuthenticated) {
          
          // initiate and set landing params for re-direct

          sessionStorage.setItem("practiceAreaID", "")
          sessionStorage.setItem("topicID", "")
          sessionStorage.setItem("subTopicID", "")
          sessionStorage.setItem("wikiSection", "")
          sessionStorage.setItem("scenarioID", "")
          sessionStorage.setItem("editorScenarioID", "")

          Object.keys(params).forEach((key) => {
            sessionStorage.setItem(key, params[key])
          })

          loginWithRedirect()
        };
      };

      if (!isLoading) {
        getAppData()
      };

  },[isAuthenticated, isLoading]);

  return (
      <>
      <ScrollToTop />
      <Grid item sx={{
        flexGrow: 1,
        display: "flex",
        backgroundColor: "#fafafa",
      }}>
      <Modal
        open={firstTimeLogin}
        slots={{backdrop: Backdrop}}
        slotProps={{backdrop: {timeout: 500}}}
      >
      <WelcomeModal />
      </Modal>
      <Grid container style={{flexDirection: "column"}}>
      {!params.hasOwnProperty("scenarioID") ?
      <Grid item>
          <Banner />
      </Grid>
      :
      <>
      {(fullWidth && fullHeight) ?
      <Grid item>
          <Banner />
      </Grid>
      :
      null
      }
      </>
      }
      <Grid item style={{flexGrow: 1}}>
          <Grid item sx={{
            padding: theme.spacing(3),
            margin: 'auto',
            maxWidth: wide ? "1200px" : "900px",
            height: "100%",
            backgroundColor: "#ffffff",
            [theme.breakpoints.down('md')]: {
              padding: theme.spacing(1)
            }
          }}>
            <Outlet />
          </Grid>
      </Grid>
      </Grid>
      </Grid>
      {footer &&
      <Grid item sx={{width: "100%"}}>
          <Footer />
      </Grid>
      }
      </>
      )
};

export default BasicPage;