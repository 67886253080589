import React, {useEffect} from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";

export default function LoginRedirect() {

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  useEffect(() => {
    
    if (!isAuthenticated) {
      loginWithRedirect()
    };
    
  }, [])

  return (
    <Grid container style={{height: "100vh", alignItems: "center"}}>
        <Grid item style={{width:"100%"}}>
          <Typography align="center" paragraph>Re-directing to LegalReflex.</Typography>
          <Typography align="center" paragraph>Click 'Login' if you are not re-directed automatically.</Typography>
          <Grid container style={{width: "100%", justifyContent: "center"}}>
            <Grid item>
            <Button variant="contained" onClick={() => loginWithRedirect()}>Login</Button>
            </Grid>
          </Grid>
        </Grid>
    </Grid>
  );
}