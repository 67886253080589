import React from 'react';
import RADocument from '../rule_analysis/RADocument';
import RAAtom from '../rule_analysis/RAAtom';
import RAConjunction from '../rule_analysis/RAConjunction';
import { Typography } from '@mui/material';

const ReductAnalysis = () => {

    return (
    <RADocument title="Maintenance of Capital: Reductions of Capital by Solvency Statement - Key Elements">
        <RAAtom atomRef="1." atomID="firstRule">
            <Typography variant="body2">Private limited company.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="2." atomID="secondRule">
            <Typography variant="body2">At least one non-redeemable share is left following the reduction.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="3." atomID="thirdRule">
            <Typography variant="body2">Reducing share capital, share premium or capital redemption reserve.</Typography>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="4." atomID="fourthRule">
            <Typography variant="body2" paragraph>A Solvency Statement:</Typography>
            <RAAtom atomRef="a." atomID="fourA">
                <Typography variant="body2">In the prescribed form.</Typography>
            </RAAtom>
            <RAConjunction content="and" />
            <RAAtom atomRef="b." atomID="fourB">
                <Typography variant="body2">Signed by all the directors.</Typography>
            </RAAtom>
            <RAConjunction content="and" />
            <RAAtom atomRef="c." atomID="fourC">
                <Typography variant="body2">The directors having formed the opinion that the company will be able to pay its debts.</Typography>
            </RAAtom>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="5." atomID="fifthRule">
            <Typography variant="body2" paragraph>A special resolution:</Typography>
            <RAAtom atomRef="a." atomID="fifthA">
                <Typography variant="body2">Accompanyed by the completed solvency statement.</Typography>
            </RAAtom>
            <RAConjunction content="and" />
            <RAAtom atomRef="b." atomID="fifthB">
                <Typography variant="body2">Passed within 15 days of the solvency statement.</Typography>
            </RAAtom>
        </RAAtom>
        <RAConjunction content="and" />
        <RAAtom atomRef="6." atomID="sixthRule">
            <Typography variant="body2" paragraph>Registered:</Typography>
            <RAAtom atomRef="a." atomID="sixthA">
                <Typography variant="body2">Within 15 days of the special resolution.</Typography>
            </RAAtom>
            <RAConjunction content="and" />
            <RAAtom atomRef="b." atomID="sixthB">
                <Typography variant="body2">With a statement confirming the solvency statement was properly made.</Typography>
            </RAAtom>
            <RAConjunction content="and" />
            <RAAtom atomRef="c." atomID="sixthC">
                <Typography variant="body2">The resolution takes effect only when it is registered.</Typography>
            </RAAtom>
        </RAAtom>
    </RADocument>
    )
};

export default ReductAnalysis;