import { Typography, Grid, Paper, Button } from '@mui/material';
import React from 'react';

import ConstructionIcon from '@mui/icons-material/Construction';
import LoopIcon from '@mui/icons-material/Loop';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { setFirstTimeLogin } from './appData/appDataSlice';
import { useDispatch } from 'react-redux';

import { useTheme } from '@mui/material/styles';

const WelcomeModal = React.forwardRef((props, ref) => {

    const theme = useTheme();
    const dispatch = useDispatch();

    return (
      <Grid container sx={{
        width: "800px",
        [theme.breakpoints.down('md')]: {
          width: "300px",
        },
        position: "absolute", top: '30%', left: '50%', transform: 'translate(-50%, -30%)', outline: 0}} ref={ref} tabIndex={-1}>
        <Paper sx={{padding: (theme) => theme.spacing(4), margin: 'auto', elevation: 3, maxWidth: "500px"}}>
        <Typography variant='h5' sx={{marginBottom: "30px", marginTop: "18px"}}>Welcome to LegalReflex!</Typography>
        <Typography>LegalReflex is a platform for building legal expertise:</Typography>
        <Grid container wrap="nowrap" sx={{marginBottom: "16px", marginTop: "24px"}}>
            <Grid item sx={{width: "60px"}}>
            <ConstructionIcon color="primary" sx={{fontSize: "48px"}} />
            </Grid>
            <Grid item sx={{paddingLeft: "12px"}}>
            <Typography variant='h6'>Hands-on-learning</Typography>
            <Typography color="textSecondary" paragraph>Expand your knowledge of legal principles by diving into practical scenarios.</Typography>
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" sx={{marginBottom: "16px"}}>
            <Grid item sx={{width: "60px"}}>
            <LoopIcon color="primary" sx={{fontSize: "48px"}} />
            </Grid>
            <Grid item sx={{paddingLeft: "12px"}}>
            <Typography variant='h6'>Practice freely</Typography>
            <Typography color="textSecondary" paragraph>Try scenarios as many times as you like. You’ll receive feedback each time, and your scores and progress remain private.</Typography>
            </Grid>
        </Grid>
        <Grid container wrap="nowrap" sx={{marginBottom: "24px"}}>
            <Grid item sx={{width: "60px"}}>
            <EventAvailableIcon color="primary" sx={{fontSize: "48px"}} />
            </Grid>
            <Grid item sx={{paddingLeft: "12px"}}>
            <Typography variant='h6'>Flexible learning</Typography>
            <Typography color="textSecondary" paragraph>Engage with bite-size scenarios around your schedule, on the go or in the office.</Typography>
            </Grid>
        </Grid>
        <Grid container sx={{width: "100%", justifyContent: "flex-end"}}>
        <Button size='large' variant='contained' onClick={()=>dispatch(setFirstTimeLogin())}>
        Get Started
        </Button>
        </Grid>
        </Paper>
      </Grid>
    )
    });
  export default WelcomeModal;

  /*

  <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Paper sx={{padding: (theme) => theme.spacing(3), margin: 'auto', elevation: 3, maxWidth: "800px",}}>
              <Grid container>
              <Grid item sx={{minWidth: "100%", marginTop: "20px", marginBottom: "20px"}}>
                <Typography variant="h4" align="center">Achievement Complete!</Typography>
              </Grid>
              <Grid item sx={{width: "300px", margin: "auto"}}>
                <AchievementsBadge badgeID={achievementID} />
              </Grid>
              <Grid item style={{width: "100%", marginTop: 8, marginBottom: 8}}>
                <Typography variant="body1" align="center">{achievementsList[achievementID]['achievementName']}</Typography>
                <Typography variant="body1" color="textSecondary" align="center">{achievementsList[achievementID]['achievementDescription']}</Typography>
              </Grid>
              <Grid container style={{width: "100%", justifyContent: "center"}}>
                <Button variant="contained" color="primary" onClick={() => dispatch(setAchievementHero(false))}>Continue</Button>
              </Grid>
              </Grid>
            </Paper>
        </Grid>

  */