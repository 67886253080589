import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

import { indigo, amber } from '@mui/material/colors';

const SubHeading = (props) => {

    const stripTags = props.stripTags
    const delay = props.delay
    const headingSaturate = props.headingSaturate
    const otherStyles = props.otherStyles;
    const typographyVariant = props.typographyVariant

    return (
        <Grid container sx={{
            display: "flex",
            justifyContent: "center",
          }}
          component={motion.div} 
          initial={{opacity: 0,}}
          animate={{opacity: 1,
                    transition: {                
                        delay: delay,
                        ease: "easeInOut",
                        duration: 0.5,
                    }}}
          >
          <Grid item sx={{
            marginBottom: "30px",
            backgroundColor: "#283593",
            boxShadow: "5px 5px 0px 0px #9fa8da",
            padding: "15px",
            filter: headingSaturate
          }}>
          <Typography sx={{color: "#fafafa"}} variant={typographyVariant} align="center">{stripTags}</Typography>
          </Grid>
          </Grid>
    )
};
export default SubHeading;

/*
<Grid item sx={{
  marginBottom: "40px",
  borderBottom: "10px solid",
  borderBottomColor: indigo[500],
  filer: headingSaturate
}}>
<Grid item sx={{
    paddingLeft: "4px",
    paddingRight: "4px",
    backgroundColor: `${otherStyles.fontColor}88`,
    borderBottom: "5px solid",
    borderBottomColor: amber[500],
    filer: headingSaturate
}}>
<Typography variant={typographyVariant} align="center">{stripTags}</Typography>
</Grid>
</Grid>

*/