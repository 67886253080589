import { useState, useCallback, useEffect } from 'react';

import { useNodeId } from 'reactflow';

import { Grid, TextField } from '@mui/material';

import { setNodeAI } from '../editorSlice';
import { useDispatch, useSelector } from 'react-redux';

// Custom debounce function
const useDebounce = (callback, delay) => {
    const [timer, setTimer] = useState(null);

    const debouncedCallback = useCallback((...args) => {
        if (timer) {
            clearTimeout(timer);
        }
        const newTimer = setTimeout(() => {
            callback(...args);
        }, delay);
        setTimer(newTimer);
    }, [callback, delay, timer]);

    // Clean up
    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);

    return debouncedCallback;
};

const AIQuestions = (props) => {
    
    // Set prompt inputs
    
    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]

    const nodeAI = nodeData.nodeAI
    const setChangesMade = props.setChangesMade;

    const [topic, setTopic] = useState(nodeAI.promptInputs.topic);
    const [topicBackground, setTopicBackground] = useState(nodeAI.promptInputs.topicBackground);
    const [exampleQuestions, setExampleQuestions] = useState(nodeAI.promptInputs.exampleQuestions);

    // Progressive addition
    const [evaluationInstructions, setEvaluationInstructions] = useState(nodeAI.promptInputs?.evaluationInstructions ? nodeAI.promptInputs.evaluationInstructions : "");

    const dispatch = useDispatch();

    const dispatchUpdate = (AIUpdate) => {
        dispatch(setNodeAI(AIUpdate));
        setChangesMade(true);
    };

    // Use the custom debounce function
    const debouncedDispatchUpdate = useDebounce(dispatchUpdate, 300);

    const handlePromptInputChange = (event) => {
        
        const promptID = event.target.id
        const promptContent = event.target.value

        switch (promptID) {
            case "topic":
                setTopic(promptContent)
                break
            case "topicBackground":
                setTopicBackground(promptContent)
                break
            case "exampleQuestions":
                setExampleQuestions(promptContent)
                break
            case "evaluationInstructions":
                setEvaluationInstructions(promptContent)
                break
            default:
                break
        }

        let updatedAI = {...nodeAI}
        let updatedPromptInputs = {...nodeAI.promptInputs}
        
        // Now, update the type property and reset data
        updatedPromptInputs[promptID] = promptContent
        updatedAI["promptInputs"] = updatedPromptInputs

        // Create a new object for the updated actions
        const AIUpdate = {
            nodeID: nodeID,
            nodeAI: updatedAI 
        }
        
        debouncedDispatchUpdate(AIUpdate)
        setChangesMade(true)
    };

    return (
        <>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="topic"
                    label="Topic"
                    value={topic}
                    placeholder="whether or not the communication is confidential"
                    onChange={handlePromptInputChange}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                />
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="topicBackground"
                    label="Topic background"
                    value={topicBackground}
                    multiline
                    rows={3}
                    placeholder="E.g. In establishing whether or not the communication is 'confidential', it's important to understand whether the communication has, in practice, been kept confidential."
                    onChange={handlePromptInputChange}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="exampleQuestions"
                    label="Example Questions"
                    value={exampleQuestions}
                    placeholder='E.g. Who else has access to the communication? Are the people who have access to the communication bound by an NDA or confidentiality agreement?'
                    onChange={handlePromptInputChange}
                    variant="standard"
                    multiline
                    rows={3}
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
            </Grid>
            <Grid item sx={{paddingTop: "10px"}}>
                <TextField
                    id="evaluationInstructions"
                    label="Evaluation Instructions"
                    value={evaluationInstructions}
                    placeholder='Optional: provide additional instructions as to how user responses should be evaluated.'
                    onChange={handlePromptInputChange}
                    multiline
                    rows={3}
                    variant="standard"
                    fullWidth
                    className="nodrag"
                    sx={{
                        '& .MuiInputBase-inputMultiline': {
                            '&::-webkit-scrollbar': {
                                width: "20px",
                            },
                            '&::-webkit-scrollbar-track': {
                                borderTopRightRadius: "10px",
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: "#d6dee1",
                                borderRadius: "20px",
                                border: "6px solid transparent",
                                backgroundClip: "content-box",
                            },
                            '&::-webkit-scrollbar-thumb-hover': {
                                backgroundColor: "#a8bbbf",
                            },
                        }
                    }}
                />
            </Grid>
        </>
    )
};
export default AIQuestions;