import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

import { amber } from '@mui/material/colors';

const Fact = (props) => {

    const stripTags = props.stripTags
    const delay = props.delay
    const defaultStyles = props.defaultStyles

    return (
        <Grid container sx={{display: "flex", justifyContent: "center",}}
            component={motion.div} 
            initial={{opacity: 0,}}
            animate={{opacity: 1,
                        transition: {                
                            delay: delay,
                            ease: "easeInOut",
                            duration: 0.5,
                        }}}
            >
      <Grid item sx={{
        borderLeft: "4px solid",
        padding: defaultStyles.margin,
        backgroundColor: amber[500],
        borderLeftColor: "#aaa",
        borderBottomRightRadius: "10px",
        marginBottom: defaultStyles.marginBottom,
        boxShadow: defaultStyles.boxShadow,
        maxWidth: "80%"
    }}>
      <Typography variant={defaultStyles.typographyVariant} align="center" gutterBottom>{stripTags}</Typography>
      </Grid>
      </Grid>
    )
};
export default Fact;