import React from "react";

function LogoWhiteBook() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 17.229 17.229"
  >
    <path
      fill="#fff"
      fillOpacity="1"
      strokeWidth="0.133"
      d="M7.91 14.86l-.394-.33H.995V4.193h.795l.007.96c.004.53.007 2.676.008 4.771v3.81h3.206c2.228-.002 3.145-.044 3.007-.139-1.023-.698-2.478-1.053-4.315-1.053h-1.25V2.037l1.888.072c2.094.08 2.941.278 3.768.877l.531.385.42-.328c.846-.663 1.762-.874 3.862-.89l1.921-.014v10.337l-1.656.09c-2.046.11-3.02.355-3.777.952-.244.191-.006.209 2.882.213l3.147.004V4.193h.796V14.53h-6.53l-.307.331a.977.977 0 01-.7.332c-.227 0-.561-.141-.788-.332zm.167-11.338c-.116-.128-.519-.368-.896-.535-.576-.254-.98-.311-2.509-.358L2.85 2.573v9.439h.778c1.206 0 3.056.367 3.86.765l.729.361.035-4.692c.03-4.128.01-4.72-.175-4.924zm1.633 9.285c.861-.416 1.975-.665 3.377-.754l1.292-.081V2.577l-1.756.06c-1.82.064-2.383.177-3.114.625l-.43.264V8.3c0 2.625.018 4.773.04 4.773.024 0 .29-.12.59-.265z"
    ></path>
  </svg>
  );
}

export default LogoWhiteBook;