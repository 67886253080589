import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { motion } from "framer-motion";
import StarBadge from './assets/StarBadge';
import RingProgress from './RingProgress';

import { useDispatch, useSelector } from 'react-redux';
import { toggleScenarioDetails, setCurrentSelection } from './appData/appDataSlice';


export default function ScenarioCard(props) {

    const dispatch = useDispatch();
    const scenarioID = props.scenarioID;
    const subTopicID = props.subTopicID;
    const topicID = props.topicID;
    const practiceAreaID = props.practiceAreaID;
    const setCurrentScenario = props.setCurrentScenario;
    const scenarioList = useSelector((state) => state.appData.scenarioList);

    const setScenarioDetails = (scenarioID) => {
        setCurrentScenario(scenarioID)
        dispatch(setCurrentSelection({
          "selectionType":"scenarioID",
          "selection": scenarioID,
        }));
        dispatch(setCurrentSelection({
            "selectionType":"subTopicID",
            "selection": subTopicID,
        }));
        dispatch(setCurrentSelection({
            "selectionType":"topicID",
            "selection": topicID,
        }));
        dispatch(setCurrentSelection({
            "selectionType":"practiceAreaID",
            "selection": practiceAreaID,
        }));
      };

    const scenarioItems = {
        initial: {
          borderLeft: "1px solid #ffffff",
          borderTop: "1px solid #ffffff",
        },
        animate: {
          borderLeft: "1px solid #cccccc66",
          borderTop: "1px solid #cccccc66",
          transition: {
            ease: "easeInOut",
            duration: 1,
          },
        }
    };

return (
    <Grid item 
        sx={{
            boxShadow: "3px 3px 3px #cccccc",
            borderRadius: "15px",
            backgroundColor: "#ffffff",
            padding: "10px",
            minWidth: "200px",
            maxWidth: "200px",
            height: "290px"
        }}
        component={motion.div}
        initial="initial"
        animate="animate"
        variants={scenarioItems}
        onClick={() => {dispatch(toggleScenarioDetails()); setScenarioDetails(scenarioID)}}
        >
        <Grid item style={{height: 75}}>
        <Typography variant="body1">{scenarioList[scenarioID]["scenarioName"]}</Typography>
        <Typography variant="body2" color="textSecondary">{scenarioList[scenarioID]["scenarioShortDescription"]}</Typography>
        </Grid>
        <Grid item style={{width: "100%"}}>
            <Grid container 
            sx={{
                width: "100%",
                justifyContent: 'center',
            }}
            component={motion.div}
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            transition={{
                ease: "easeInOut",
                duration: 1.5,
            }}
            >
            {scenarioList[scenarioID]["userScore"] === 100 ?
            <Grid item 
            style={{position: "relative", top: "25px"}}
            component={motion.div}
            initial={{
                x: 25,
                y: -10,
            }}
            animate={{
                scale: [1, 1.5, 1],
            }}
            transition={{
                delay: 1.5,
                ease: "easeInOut",
                duration: 1.5,
                repeat: "Infinity",
                repeatType: "reverse",
                repeatDelay: 3,
            }}
            >
            <StarBadge color="#ffeb3b" />
            </Grid>
            :
            <Grid item 
            style={{position: "relative", top: "25px"}}
            component={motion.div}
            initial={{
                x: 25,
                y: -10,
                opacity: 0,
            }}
            animate={{
                opacity: 1
            }}
            transition={{
                ease: "easeInOut",
                duration: 1.5,
            }}
            >
            <StarBadge color="#ccc" />
            </Grid>
            }
            </Grid>
        </Grid>
        <Grid item sx={{
            width: "60px",
            height: "60px",
            margin: "auto",
        }}>
            <RingProgress complete={scenarioList[scenarioID]["userScore"]}/>
        </Grid>
        <Typography variant="body2" align="center" paragraph>{scenarioList[scenarioID]["userScore"]}%</Typography>
        <Grid container sx={{
            justifyContent: "center",
            marginBottom: "10px",
        }}>
            <Grid item>
        <Button variant="contained" color="indigo">Begin</Button>
            </Grid>
        </Grid>
    </Grid>
);
}