import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { motion } from "framer-motion";

import { Grid, Typography, Button, IconButton, TextField, Tooltip } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { 
  setEditorFeedbackType, 
  setEditorFeedbackContent, 
  updateEditorScenarioScore, 
  loadScenarioIntoInk, 
  makeEditorChoice, 
  setScenarioPrimarySourceData, 
  setAIDialogue,
  handlePlayerError,
  setAIPendingMessage,
  setEditorScenarioJSONDocumentData,
  setEditorPlayerDocumentData
} from '../editor/editorSlice';

import EditorPlayerBar from './EditorPlayerBar';

import Narrative from '../story/Narrative';
import SpeechOther from '../story/SpeechOther';
import SpeechYour from '../story/SpeechYour';
import Heading from '../story/Heading';
import SubHeading from '../story/SubHeading';
import Fact from '../story/Fact';
import FactMinor from '../story/FactMinor';
import DocumentThumbnail from '../story/DocumentThumbnail';
import CoverImage from '../story/CoverImage';
import PlayerError from '../story/PlayerError';
import PendingText from '../story/PendingText';

import EditorScenarioResources from './EditorScenarioResources';

const EditorStory = (props) => {

  const bottomOfStory = useRef(null);
  const dispatch = useDispatch();
  const setSelectedIndex = props.setSelectedIndex

  const [choiceDateKey, setChoiceDateKey] = useState(String(Date.now()));
  
  // pdf is a prop at this level because can't hold in redux (and don't want rerendering between views)
  
  const documentArray = props.documentArray;
  const setDocumentArray = props.setDocumentArray;
  const coverImage = props.coverImage;
  const setCoverImage = props.setCoverImage;

  // pop this in using an ink string
  // center, set a max width or something

  const [userInputText, setUserInputText] = useState("");
  const [pendingAIResponse, setPendingAIResponse] = useState(false);

  const scrollToBottom = () => {
    bottomOfStory.current?.scrollIntoView({ behavior: "smooth" })
  }

  // Set styles for story components

  const defaultStyles = {
    padding: "8px",
    marginBottom: "10px",
    boxShadow: "2px 2px 3px 0px #ccc",
    saturation: "none",
    typographyVariant: "body1"
  }

  const narrativeStyles = {
    backgroundColor: "#fcfcfc",
    borderBottomRightRadius: "10px",
    borderLeft: "4px solid",
    borderLeftColor: "#aaaaaa",
  }

  const otherStyles = {
    border: "3px solid #3f51b5",
    backgroundColor: "#fafafa",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: "-3px 3px 0px 0px #7986cb",
  }

  const yourStyles = {
    border: "3px solid #009688",
    boxShadow: "3px 3px 0px 0px #4db6ac",
    backgroundColor: "#fafafa",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  }

    // const otherStyles = {
  //   border: "2px solid #3f51b5",
  //   backgroundColor: "#e8eaf6",
  //   borderTopRightRadius: "10px",
  //   borderBottomLeftRadius: "10px",
  //   borderBottomRightRadius: "10px",
  //   boxShadow: "-3px 2px 0px 0px #5c6bc0",
  // }

  // const yourStyles = {
  //   border: "2px solid #009688",
  //   boxShadow: "3px 2px 0px 0px #26a69a",
  //   backgroundColor: "#e0f2f1",
  //   borderTopLeftRadius: "10px",
  //   borderBottomLeftRadius: "10px",
  //   borderBottomRightRadius: "10px",
  // }
  
  const parsingErrors = useSelector((state) => state.editorData.parsingErrors)
  const sceneText = useSelector((state) => state.editorData.sceneText);
  const previousText = useSelector((state) => state.editorData.fullText);
  const choices = useSelector((state) => state.editorData.parseChoices);
  const penultimate = useSelector((state) => state.editorData.parseChoices)[0].text === "Complete"
  const scenarioTotalPoints = useSelector((state) => state.editorData.globals.total_points);
  const pointsReceived = useSelector((state) => state.editorData.globals.points_received);
  const scenarioScore = {
    total_points: scenarioTotalPoints,
    points_received: pointsReceived
  };
  const editorScenarioUI = useSelector((state) => state.editorData.editorScenarioUI);
  const editorScenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);

  const AIMessageStack = useSelector((state) => state.editorData.AIMessageStack);
  const AIPendingMessage = useSelector((state) => state.editorData.AIPendingMessage);

  const userAccessToken = useSelector((state) => state.appData.accessToken);

  /* 
  create an array of figures for the fade transition
  */

  const handleReload = () => {
    var refreshPrimarySource = false
    var refreshDocuments = false
    var refreshCoverImage = false

    // prevent unneccessary refresh of assets

    if (editorScenarioAssets.hasOwnProperty("primarySource")) {
      Object.keys(editorScenarioAssets.primarySource).forEach((sourceID) => {
        if (!editorScenarioAssets.primarySource[sourceID].hasOwnProperty("xml_string")) {
          refreshPrimarySource = true
        }
      })
    };
    if (editorScenarioAssets.hasOwnProperty("documents")) {
      if (documentArray) {
        if (editorScenarioAssets.documents[0]["documentID"] !== documentArray.documentID) {
          refreshDocuments = true
        }
      } else {
        refreshDocuments = true
      };
    }
    if (editorScenarioAssets.hasOwnProperty("coverImage")) {
      if (coverImage) {
        if (editorScenarioAssets.coverImage[0]["documentID"] !== coverImage.documentID) {
          refreshCoverImage = true
        }
      } else {
        refreshCoverImage = true
      };  
    };
    const getPrimarySources = async () => {
      try {
          const scenarioData = {
            sourceIDs: Object.keys(editorScenarioAssets.primarySource)
          }            
          const scenarioResponse = await fetch('/api/assets/getprimarysources', {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(scenarioData),
          });
          const responseData = await scenarioResponse.json()
          dispatch(setScenarioPrimarySourceData(responseData));
          } 
      catch(e) {console.log(e)}        
    };

    const getDocuments = async () => {

      const docType = editorScenarioAssets.documents[0]["type"]
      let endPoint = docType === "pdf" ? '/api/assets/getdocument' : '/api/assets/getjsondocument'
      
      try {
          const documentData = {
              documentID: editorScenarioAssets.documents[0]["documentID"]
          }            
          const dataResponse = await fetch(endPoint, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(documentData),
          });

          // handle pdf streaming

          if (docType === "pdf") {
            let result = new Uint8Array(0);
            const reader = dataResponse.body.getReader();
            while (true) { // eslint-disable-line no-constant-condition
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                const newResult = new Uint8Array(result.length + value.length);
                newResult.set(result);
                newResult.set(value, result.length);
                result = newResult;
            }
            return (result)
          } else {
            // otherwise assume json doc
            const responseData = await dataResponse.json()
            //console.log(responseData)
            dispatch(setEditorScenarioJSONDocumentData(responseData));
            dispatch(setEditorPlayerDocumentData(responseData));
            return ("jsonDocument")
          }
      }
      catch(e) {console.log(e)}
    };

    const getCoverImage = async () => {
      
      try {
          const imageData = {
              documentID: editorScenarioAssets.coverImage[0]["documentID"]
          }            
          const dataResponse = await fetch('/api/assets/getdocument', {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${userAccessToken}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(imageData),
          });
          let result = new Uint8Array(0);
          const reader = dataResponse.body.getReader();
          while (true) { // eslint-disable-line no-constant-condition
              const { done, value } = await reader.read();
              if (done) {
                  break;
              }
              const newResult = new Uint8Array(result.length + value.length);
              newResult.set(result);
              newResult.set(value, result.length);
              result = newResult;
          }
          const binString = Array.from(result, (x) => String.fromCodePoint(x)).join("");
          return(btoa(binString))
      }
      catch(e) {console.log(e)}
    };

    if (refreshPrimarySource) {
      getPrimarySources();
    };
    if (refreshDocuments) {
      getDocuments().then((result) => setDocumentArray({data: result, documentID: editorScenarioAssets.documents[0]["documentID"], type: editorScenarioAssets.documents[0]["type"]}));
    };
    if (refreshCoverImage) {
      getCoverImage().then((result) => setCoverImage({data: result, documentID: editorScenarioAssets.coverImage[0]["documentID"], type: editorScenarioAssets.coverImage[0]["type"]}));
    }
    if (parsingErrors.inkErrors.length > 0 || parsingErrors.reflexErrors.length > 0) {
        console.log(parsingErrors)
    }

    // JSON document reset is handled in loadscenariointoink directly

    // Reset any AI inputs that have gotten stuck. Message stack is reset in redux by loadscenario.

    setPendingAIResponse(false)
    setUserInputText("")
    dispatch(setAIPendingMessage(false))

    dispatch(loadScenarioIntoInk())
  };

  const fullText = [previousText.flat(), sceneText.flat()].flat();

  const getDelay = (idx) => {
    let delay = (idx - previousText.flat().length)/3
    if (delay < 0) {
        return 0
    }
    if (delay >= 0) {
        return delay
    }
  };

  const handleTextChange = (event) => {
    setUserInputText(event.target.value)
  };


  const handleTextSubmit = async () => {

    setPendingAIResponse(true);

    var promptInputs = {}
    var issues = {}

    // TODO update this approach in Choice
    // And the call to API
    // And the playback and complete components...

    sceneText.forEach(str => {
      if (str.startsWith("_AIPromptInput_")) {

        // Deconstruct issues. Create a issues object with keys as issue id, then name and description as fields.
        if (str.startsWith("_AIPromptInput__issue")) {
          let parts = str.split("_")
          let issueIDx = parts[3]
          let key = parts[5]
          let value = parts.slice(6).join("_").trim();
          issues[issueIDx] = {
            ...issues[issueIDx],
            [key]: value
          }

        } else {
          let parts = str.split("_");
          // The key is at index 3 and the value starts from index 4.
          let key = parts[3];
          let value = parts.slice(4).join("_").trim();
          promptInputs[key] = value;
        }
      };
    })

    promptInputs["issues"] = issues

    const interactionType = promptInputs.type
    const promptHeading = interactionType === "explanation" ? "### My Explanation\n" : interactionType === "questions" ? "### My Question\n" : ""

    const AIDialogue = {
      role:"user",
      content: userInputText,
      promptHeading: promptHeading,
      blockID: promptInputs["blockID"]
    };

    dispatch(setAIDialogue({dialogueData: AIDialogue, interactionType: interactionType}));
    dispatch(setAIPendingMessage(true));

    let messageStack = AIMessageStack.hasOwnProperty(promptInputs["blockID"]) ? AIMessageStack[promptInputs["blockID"]] : []
    promptInputs["messageStack"] = messageStack;
    promptInputs["currentMessage"] = {
      role:AIDialogue.role,
      content:promptHeading+AIDialogue.content
    };

    const AIData = {
      interactionType: interactionType,
      interactionData: promptInputs
    }
    //console.log(AIData)

    try {
      const AIResponse = await fetch('/api/scenarioAI/interface', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${userAccessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(AIData),
        });

        const parsedAIResponse = await AIResponse.json()

        if (parsedAIResponse.error === false) {

          const messageResponse = {
            role: "assistant",
            content: JSON.stringify(parsedAIResponse.data)
          }

          if (parsedAIResponse.data.type === "followUp") {
            const followUp = {
              role:"assistant",
              type: "followUp",
              evaluation: parsedAIResponse.data.evaluation,
              content: parsedAIResponse.data.message,
              raw: messageResponse,
              blockID: promptInputs["blockID"]
            };
            dispatch(setAIDialogue({dialogueData: followUp, interactionType: interactionType}));
            setPendingAIResponse(false);
            setUserInputText("");
          } else if (parsedAIResponse.data.type === "feedback") {
            const feedback = {
              role:"assistant",
              type: "feedback",
              evaluation: parsedAIResponse.data.evaluation,
              content: parsedAIResponse.data.message,
              raw: messageResponse,
              blockID: promptInputs["blockID"]
            };
            dispatch(setAIDialogue({dialogueData: feedback, interactionType: interactionType}));
            setPendingAIResponse(false);
            setUserInputText("");
            dispatch(setAIPendingMessage(false));
            dispatch(makeEditorChoice(0));
          } else if (parsedAIResponse.data.type === "issuespot") {
            const feedback = {
              role:"assistant",
              type: "issuespot",
              evaluation: parsedAIResponse.data.evaluation,
              raw: messageResponse,
              blockID: promptInputs["blockID"]
            };
            dispatch(setAIDialogue({dialogueData: feedback, interactionType: interactionType}));
            setPendingAIResponse(false);
            setUserInputText("");
            dispatch(setAIPendingMessage(false));
            dispatch(makeEditorChoice(0));
          }
        } else {
          console.log(parsedAIResponse)
          dispatch(setAIPendingMessage(false));
          dispatch(handlePlayerError())
          setUserInputText("");
        }

    } catch(e) {console.log(e)}
  }

  // check whether have got to the end of the story
  let eos = false

  // Define regexes outside the function
  const regexPatterns = {
    narrative: new RegExp('_narrative_'),
    speechOther: new RegExp('_speechOther_','g'),
    speechOtherTextResponse: new RegExp('_speechOtherTextResponse_'),
    speechYour: new RegExp('_speechYour_'),
    speechYourTextInput : new RegExp('_speechYourTextInput_'),
    heading: new RegExp('_heading_'),
    subHeading: new RegExp('_subHeading_'),
    fact: new RegExp('_fact_'),
    factMinor: new RegExp('_factMinor_'),
    documentThumbnailNeutral: new RegExp('_documentThumbnailNeutral_'),
    feedback: new RegExp('_feedback_'),
    maxims: new RegExp('_maxims_'),
    AIPromptInput: new RegExp('_AIPromptInput_'),
    playerError: new RegExp('_playerError_'),
    inlineFeedback: new RegExp('_inlineFeedback'),
    coverImageRxp: new RegExp('_coverImage_'),
    textInputOption: new RegExp('_textInputOption_'),
  };

  const parseText = (paraText, idx) => {

    if (eos) {
      dispatch(setEditorFeedbackContent(paraText));
      return
    };

    if (regexPatterns.narrative.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.narrative, "");
      const delay = getDelay(idx)
      
      return (
        <Narrative stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} narrativeStyles={narrativeStyles} key={"narrative"+idx} />
      )
    };
    if (regexPatterns.speechOther.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechOther, "");
      const delay = getDelay(idx)
      
      return (
        <SpeechOther stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} otherStyles={otherStyles} key={"speechother"+idx} />
      )
    };
    if (regexPatterns.speechOtherTextResponse.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechOtherTextResponse, "");
      const delay = getDelay(0)
      
      return (
        <SpeechOther stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} otherStyles={otherStyles} key={"speechOtherTextResponse"+idx} />    
      )
    };
    if (regexPatterns.speechYour.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechYour, "").split("_inlineFeedback")[0]
      const delay = getDelay(idx)

      return (
        <SpeechYour stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} yourStyles={yourStyles} key={"speechYour"+idx} />
      )
    };
    if (regexPatterns.speechYourTextInput.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechYourTextInput, "");
      const delay = getDelay(0)

      return (
        <SpeechYour stripTags={stripTags} delay={delay} defaultStyles={defaultStyles} yourStyles={yourStyles} key={"speechYourTextInput"+idx} />
      )
    };
    if (regexPatterns.heading.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.heading, "");
      const delay = getDelay(idx)

      return (
        <Heading stripTags={stripTags} delay={delay} otherStyles={otherStyles} headingSaturate="saturate(100%)" typographyVariant="h3" key={"heading"+idx} />
      )
    };
    if (regexPatterns.subHeading.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.subHeading, "");
      const delay = getDelay(idx)

      return (
        <SubHeading stripTags={stripTags} delay={delay} otherStyles={otherStyles} headingSaturate="saturate(100%)" typographyVariant="h4" key={"subHeading"+idx} />
      )
    };
    if (regexPatterns.fact.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.fact, "");
      const delay = getDelay(idx)
      
      return (
        <Fact stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} key={"fact"+idx} />
      )
    };
    if (regexPatterns.factMinor.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.factMinor, "");
      const delay = getDelay(idx)
      
      return (
        <FactMinor stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} key={"factMinor"+idx} />
      )
    };
    if (regexPatterns.documentThumbnailNeutral.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.documentThumbnailNeutral, "").replaceAll("\n", "")
      const delay = getDelay(idx)
      
      return (
        <DocumentThumbnail stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} readOnly={false} loadSource="editor" key={"documentThumbnail"+idx} />
      )
    };
    if (regexPatterns.coverImageRxp.test(paraText)) {
      if (coverImage) {
        const delay = getDelay(idx)
        return (
          <CoverImage delay={delay} coverImage={coverImage} key={"coverImage"+idx}/>  
        )
      }
    };
    if (regexPatterns.playerError.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.playerError, "");
      const delay = getDelay(idx)
      
      return (
        <PlayerError stripTags={stripTags} defaultStyles={defaultStyles} delay={delay} key={"playError"+idx} />
      )
    };

    // write the feedback type to redux, set eos flag so don't parse any more of the ink content

    // come back to how handle feedback and maxims

    if (regexPatterns.feedback.test(paraText)) {
      eos = true;
      dispatch(setEditorFeedbackType("feedback"));
      return
    };
    if (regexPatterns.maxims.test(paraText)) {
      eos = true;
      dispatch(setEditorFeedbackType("maxims"));
      return
    };
    if (regexPatterns.AIPromptInput.test(paraText) || regexPatterns.inlineFeedback.test(paraText)) {
      return
    };
    if (["\n","_setHintsVisible_\n","_setPrimarySourcesVisible_\n","_coverImage_\n"].includes(paraText)) {
      // skip any UI flags and stray line breaks generated by ink
      return
    } else {
    return (
      <Narrative stripTags={paraText} delay={getDelay(idx)} defaultStyles={defaultStyles} narrativeStyles={narrativeStyles} key={"narrative"+idx} />
    )
    }
  };

  const parseChoice = (choice) => {

    if (regexPatterns.textInputOption.test(choice.text)) {

      return (
        <Grid container sx={{justifyContent: "center", alignItems: "center"}} key={choice.index + choiceDateKey}>
          <Grid item 
            sx={{
              width: "450px",
              paddingTop: "3px",
              paddingBottom: "3px",
              paddingLeft: "8px",
              paddingRight: "8px",
              margin: "3px",
            }}
            key={choice.index + choiceDateKey + "GI"}
          >
            <TextField
              id="outlined-multiline-static"
              value={userInputText}
              onChange={handleTextChange}
              disabled={pendingAIResponse}
              variant="outlined"
              fullWidth
              multiline
              rows={3}
              key={choice.index + choiceDateKey + "TF"}
            />
            <Typography variant='body2' color="textSecondary" sx={{fontSize: "x-small"}}><em>Do not input client or personal data.</em></Typography>
          </Grid>
          <Grid item key={choice.index + choiceDateKey + "GIB"}>
            <IconButton disabled={pendingAIResponse} onClick={() => {handleTextSubmit()}} color='primary'><PlayCircleIcon /></IconButton>
        </Grid>
      </Grid>
      )
    } else {
      return (
        <Grid item 
          sx={{
            border: "1px solid",
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "8px",
            paddingRight: "8px",
            borderColor: "#ccc",
            boxShadow: "0px 3px 0px 0px #ccc",
            borderRadius: "8px",
            margin: "3px",
            marginBottom: "8px",
          }}
          key={choice.index + choiceDateKey}
          onClick={() => {dispatch(makeEditorChoice(choice.index)); setChoiceDateKey(String(Date.now()));}}
          component={motion.div} 
          initial={{
              opacity: 0,
          }}
          animate={{
              opacity: 1,
              transition: {                
                delay: (choice.index/3)+0.5+((sceneText.flat().length-1)/3),
                ease: "easeInOut",
                duration: 0.5,
              }}}
          whileHover={{cursor: "pointer", backgroundColor: "#ced7db"}}
          whileTap={{transform: "translateY(3px)", boxShadow: "0px 0px 0px 0px #ccc", transition: {duration: 0}}}
        >
        <Typography align="center" style={{fontStyle: "italic", paddingLeft: 5, paddingRight: 5,}}>{choice.text}</Typography> 
        </Grid>
      )
    };
  };

  useEffect(() => {
    scrollToBottom()
    dispatch(updateEditorScenarioScore(scenarioScore));
  }, [fullText, choices]);

  return (
    <Grid container style={{width: "100%", height: "100%",border: "1.5px solid #ced7db", flexDirection: "column", backgroundColor: "#ffffff"}}>
      <Grid item sx={{height: "50px", width: "100%"}}>
        <Tooltip title="Refresh & restart" placement='top'>
        <IconButton onClick={() => handleReload()} style={{position: "absolute", right: 20, paddingTop: 12}}><ReplayIcon /></IconButton>
        </Tooltip>
        <EditorPlayerBar />
      </Grid>
      {editorScenarioUI.viewResources ? 
      <EditorScenarioResources setSelectedIndex={setSelectedIndex} documentArray={documentArray} />
      :
      <>
      <Grid item style={{display: "flex", flex: 1, width: "100%"}}>
      <Grid item sx={{
        maxHeight: "calc(100vh - 290px)",
        width: "100%",
        padding: "10px",
        overflow: "auto",
        '&::-webkit-scrollbar': {
          width: "20px",
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: "#ffffff",
          borderTopRightRadius: "10px",
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: "#d6dee1",
          borderRadius: "20px",
          border: "6px solid transparent",
          backgroundClip: "content-box",
        },
        '&::-webkit-scrollbar-thumb-hover': {
          backgroundColor: "#a8bbbf",
        },
        background: "linear-gradient(135deg, #ffffff 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px),\nlinear-gradient(225deg, #ffffff 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px)0 64px",
        backgroundColor:"#ffffff",
        backgroundSize:"64px 128px",
      }}>
        <Grid container component={motion.div} layout={"position"} stlye={{display: 'flex', flexGrow: 1, minWidth: "100%", marginBottom: "10px",}}>
        {fullText.flat(1).map((paraText, idx) => (
          parseText(paraText,idx)
        ))}
        {AIPendingMessage ? 
          <PendingText defaultStyles={defaultStyles} otherStyles={otherStyles} />
        :
        null
        }
      <div ref={bottomOfStory} />
        </Grid>
      </Grid>
      </Grid>
      <Grid item sx={{width: "100%", height: "150px", justifyContent: "center", backgroundColor: "#ffffff"}}>
        <Grid item style={{height: "100%"}}>
          <Grid container sx={{
            maxHeight: "100%",
            justifyContent: "center", 
            borderTop: "1.5px solid #ced7db",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            padding: "10px",
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: "20px",
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: "#ffffff",
              borderTopRightRadius: "10px",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: "#d6dee1",
              borderRadius: "20px",
              border: "6px solid transparent",
              backgroundClip: "content-box",
            },
            '&::-webkit-scrollbar-thumb-hover': {
              backgroundColor: "#a8bbbf",
            },
          }}
          >
          {penultimate ?
          <Button variant="outlined" onClick={() => {dispatch(makeEditorChoice(0))}}>Complete</Button>
          :
          <>
          {choices.map((choice) => (
              parseChoice(choice)
          ))}
          </>
          }
          </Grid>
        </Grid>
      </Grid>
      </>
      }
    </Grid>
  );
}

export default EditorStory;