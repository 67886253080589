import { Grid, Typography, Link} from '@mui/material';

import { Editable, withReact, Slate, useSelected } from 'slate-react'
import {
  createEditor,
} from 'slate'

import React, { useCallback, useMemo } from 'react';

const PracticeNotesReader = (props) => {

  const subTopicPracticeNotes = props.subTopicPracticeNotes;

  /*********************************************

  Slate editor

  *********************************************/

  const withInlines = editor => {
    // extend editor for inline link elements
    const { isInline, isVoid } = editor
  
    editor.isInline = element =>
      ['link'].includes(element.type) || isInline(element)

    editor.isVoid = element => (element.type === 'iframe' ? true : isVoid(element))
  
    return editor
  };

  // Put this at the start and end of an inline component to work around this Chromium bug:
  // https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
  const InlineChromiumBugfix = () => (
    <span
      contentEditable={false}
      style={{fontSize:"0px"}}
    >
      {String.fromCodePoint(160) /* Non-breaking space */}
    </span>
  )

  const Element = (props) => {
    const { attributes, children, element } = props
    switch (element.type) {
      case 'block-quote':
        return (
          <blockquote {...attributes}>
            {children}
          </blockquote>
        )
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
        )
      case 'heading-one':
        return (
          <Typography variant='h6' {...attributes} sx={{paddingBottom: "8px"}}>
            {children}
          </Typography>
        )
      case 'heading-two':
        return (
          <h2 {...attributes}>
            {children}
          </h2>
        )
      case 'list-item':
        return (
            <Typography variant='body1' {...attributes} component="li">
              {children}
            </Typography>
        )
      case 'numbered-list':
        return (
          <ol {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ol>
        )
      case 'link':
        return (
          <EditorLinkComponent element={element} {...attributes}>
            {children}
          </EditorLinkComponent>
        )
      case 'iframe':
        return (
            <IFrameElement {...props} />
        )
      default:
        return (
          <Typography paragraph variant='body1' {...attributes}>
            {children}
          </Typography>
        )
    }
  };

  const EditorLinkComponent = React.forwardRef((props, ref) => {
    // Not really sure why this needs forwardRef tbh.
    const { attributes, children, element } = props
    const selected = useSelected()
    return (
      <Link {...attributes} href={element.url} target="_blank" rel="noopener" underline="hover" sx={{boxShadow: selected ? "0 0 0 2px #d6dee1": null}}>
        <InlineChromiumBugfix />
          {children}
        <InlineChromiumBugfix />
      </Link>
    )
  });

  const IFrameElement = ({ attributes, children, element }) => {
    const { url } = element
    return (
      <div {...attributes}>
        <div contentEditable={false}>
          <div
            style={{
              padding: '75% 0 0 0',
              position: 'relative',
            }}
          >
            <iframe
              src={url}
              title="Video Player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowFullScreen
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: '0px'
              }}
            />
          </div>
        </div>
        {children}
      </div>
    )
  };

  const BugFixText = props => {
    const { attributes, children, leaf } = props
    return (
      <span
        // The following is a workaround for a Chromium bug where,
        // if you have an inline at the end of a block,
        // clicking the end of a block puts the cursor inside the inline
        // instead of inside the final {text: ''} node
        // https://github.com/ianstormtaylor/slate/issues/4704#issuecomment-1006696364
        style={{
          paddingLeft: leaf.text === '' ? "0.1px" : null
        }}
        {...attributes}
      >
        {children}
      </span>
    )
  };

  const renderElement = useCallback(props => <Element {...props} />, [])
  const editor = useMemo(() => withInlines(withReact(createEditor())), [])

  return (
  <Grid item sx={{width: "100%"}}>
      <Slate editor={editor} initialValue={subTopicPracticeNotes}>
      <Editable
          readOnly
          renderElement={renderElement}
          renderLeaf={props => <BugFixText {...props} />}
      />
      </Slate>
  </Grid>
  )
  };
export default PracticeNotesReader;