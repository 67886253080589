import React from 'react';

import { List, ListItemButton, ListItemIcon, ListItemText, Grid, Divider, ListSubheader} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import GamepadIcon from '@mui/icons-material/Gamepad';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TaskIcon from '@mui/icons-material/Task';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const AdminSideBar = (props) => {

  const selectedIndex = props.selectedIndex;
  const setSelectedIndex = props.setSelectedIndex;

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Grid item style={{width: "200px", minHeight: "100%", boxShadow: "3px 0px 3px #cccccc"}}>
    <List component="nav" aria-label="admin-toolbar">
      <ListSubheader sx={{backgroundColor: "#fafafa"}}>Analytics</ListSubheader>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0)}
        sx={{minHeight: "50px"}}
      >
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event) => handleListItemClick(event, 1)}
        sx={{minHeight: "50px"}}
      >
        <ListItemIcon>
          <GamepadIcon />
        </ListItemIcon>
        <ListItemText primary="Scenarios" />
      </ListItemButton>
      <Divider />
      <ListSubheader sx={{backgroundColor: "#fafafa"}}>Site settings</ListSubheader>
      <ListItemButton
        selected={false}
        disabled={true}
        sx={{minHeight: "50px"}}
      >
        <ListItemIcon>
          <ViewModuleIcon />
        </ListItemIcon>
        <ListItemText primary="Modules" />
      </ListItemButton>
      <ListItemButton
        selected={false}
        disabled={true}
        sx={{minHeight: "50px"}}
      >
        <ListItemIcon>
          <TaskIcon />
        </ListItemIcon>
        <ListItemText primary="Assignments" />
      </ListItemButton>
      <ListItemButton
        selected={false}
        disabled={true}
        sx={{minHeight: "50px"}}
      >
        <ListItemIcon>
          <EmojiEventsIcon />
        </ListItemIcon>
        <ListItemText primary="Achievements" />
      </ListItemButton>
    </List>
    </Grid>
  )
};

export default AdminSideBar;