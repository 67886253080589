import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { setScenarioUI } from '../features/choice/choiceSlice'; 

import ScenarioHints from './ScenarioHints';
import PrimarySource from './PrimarySource';
import ScenarioDocuments from './ScenarioDocuments';
import ScenarioOutline from './ScenarioOutline';

import { Grid, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

/*

This may take a little re-working.

*/

const ScenarioResources = (props) => {

  const theme = useTheme();
  const documentArray = props.documentArray;
  const loadSource = props.loadSource;
  const setTriggerModalHelper = props.setTriggerModalHelper;
  const fullWidth = useMediaQuery(theme.breakpoints.up('lg'))

  const dispatch = useDispatch();

  const handleClose = () => {
    setTriggerModalHelper(true);
    dispatch(setScenarioUI({
      "viewResources": false,
      "viewHints": false,
      "viewDocuments": false,
      "viewPrimarySources": false,
      "viewOutline": false,
    }));
  };

  const scenarioUI = useSelector((state) => state.choices.scenarioUI);
    
  return (
    <Grid container sx={{height: "100%"}}>
      <Grid item style={{width: "100%"}}>
        <Grid container style={{width: "100%", justifyContent: "flex-end"}}>
          <Grid item 
          style={{position: "absolute", margin: 5, zIndex: 1000}} 
          >
            <Grid container style={{alignItems: "center"}}>
            {fullWidth ?
            <Button variant="outlined" color="secondary" size='small' onClick={() => handleClose()}>Close</Button>
            :
            <IconButton aria-label="close" size='small' onClick={() => handleClose()}>
              <CloseIcon fontSize='small' />
            </IconButton>
            }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {scenarioUI.viewPrimarySources &&
      <Grid item style={{width: "100%"}}>
      <PrimarySource loadSource={loadSource} />
      </Grid>
      }
      {scenarioUI.viewDocuments &&
      <Grid item style={{width: "100%", height: "100%"}}>
      <ScenarioDocuments loadSource={loadSource} documentArray={documentArray} /> 
      </Grid>
      }
      {scenarioUI.viewOutline &&
      <Grid item style={{width: "100%"}}>
      <ScenarioOutline loadSource="static" /> 
      </Grid>
      }
      {scenarioUI.viewHints &&
      <Grid item style={{width: "100%"}}>
      <ScenarioHints />
      </Grid>
      }
    </Grid>
);
}

export default ScenarioResources;