import React from 'react';

import { Grid, TextField, IconButton, Tooltip, FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import DeleteIcon from '@mui/icons-material/Delete';

const KnotText = (props) => {

    const fullKnotText = props.knotText;
    const setKnotText = props.setKnotText;
    const fullKnotFormat = props.knotFormat;
    const setKnotFormat = props.setKnotFormat;
    const textIndex = props.textIndex;
    const removeKnotText = props.removeKnotText;
    const setChangesMade = props.setChangesMade;

    const knotText = fullKnotText[textIndex];
    const knotFormat = fullKnotFormat[textIndex];    

    const formatMap = {
        _narrative_: {label: "Narrative", color: "#424242"},
        _speechYour_: {label: "Your dialogue", color: "#009688"},
        _speechOther_: {label: "Dialogue", color: "#3f51b5"},
        _factMinor_: {label: "Alert", color: "#ff8f00"},
        _documentThumbnailNeutral_: {label: "Thumbnail", color: "#424242"},
    }

    /*
    #424242 grey 800
    #9e9e9e grey 500
    #009688 teal 500
    #3f51b5 indigo 500
    #ffc107 amber 500
    #ff8f00 amber 800
    */

    const handleTextChange = (event) => {
        setKnotText({
            ...fullKnotText,
            [textIndex]: event.target.value
        });
        setChangesMade(true);
    };

    const toggleFormat = () => {
        const formats = Object.keys(formatMap)
        const currentFormat = formats.indexOf(knotFormat)
        const newFormatIdx = currentFormat === (formats.length - 1) ? 0 : currentFormat + 1
        const newFormat = formats[newFormatIdx]
        setKnotFormat({
            ...fullKnotFormat,
            [textIndex]: newFormat
        });
        setChangesMade(true);
    }

    return (
            <Grid item style={{paddingTop: 10}}>
                <Grid container>
                    <Grid item sx={{flexGrow: 1}}>
                    {formatMap[knotFormat]["label"] === "Thumbnail" ?
                    <FormControl variant="standard">
                    <InputLabel id="select-media">Select media</InputLabel>
                    <Select
                    id="media-select"
                    value={knotText}
                    label="select-media"                    
                    className="nodrag"
                    onChange={handleTextChange}
                    sx={{
                        width: "300px"
                    }}
                    >   
                        <MenuItem value={"Document icon"}>Document icon</MenuItem>
                        <MenuItem value={"Outline icon"}>Outline icon</MenuItem>
                        <MenuItem value={"Steps plan icon"}>Steps plan icon</MenuItem>
                    </Select>
                    </FormControl>
                    :
                    <form autoComplete='off' onSubmit={e => { e.preventDefault()}}>
                    <TextField
                        id="outlined-multiline-static"
                        label={formatMap[knotFormat]["label"]}
                        value={knotText}
                        onChange={handleTextChange}
                        variant="standard"
                        fullWidth
                        className="nodrag"
                        sx={{
                            '& label': {
                                color: formatMap[knotFormat]["color"],
                            },
                            '& label.Mui-focused': {
                                color: formatMap[knotFormat]["color"],
                            },
                            '& .MuiInput-underline': {
                                '&:after': {
                                borderBottomColor: formatMap[knotFormat]["color"],
                                },
                                '&:before': {
                                borderBottomColor: formatMap[knotFormat]["color"],
                                },
                                '&:hover:not(.Mui-disabled):before': {
                                borderBottomColor: formatMap[knotFormat]["color"],
                                },
                            },
                            '& input': {
                                color: formatMap[knotFormat]["color"],
                            },
                        }}
                    />
                    </form>
                    }
                    </Grid>
                    <Grid item>
                    <Grid container>
                        <Grid item>
                            {formatMap[knotFormat]["label"] === "Thumbnail" ?
                            <IconButton onClick={() => toggleFormat()} sx={{marginTop: 2}} className="nodrag">
                                <Tooltip title="Toggle format" placement="top">
                                <PermMediaIcon fontSize='small' style={{color: formatMap[knotFormat]["color"]}}/>
                                </Tooltip>
                            </IconButton>
                            :
                            <IconButton onClick={() => toggleFormat()} sx={{marginTop: 2}} className="nodrag">
                                <Tooltip title="Toggle format" placement="top">
                                <TextFormatIcon fontSize='small' style={{color: formatMap[knotFormat]["color"]}}/>
                                </Tooltip>
                            </IconButton>
                            }
                        </Grid>
                        <Grid item>
                            <IconButton disabled={textIndex === "0"} onClick={() => removeKnotText(textIndex)} sx={{marginTop: 2}} className="nodrag">
                                <Tooltip title="Delete" placement="top">
                                <DeleteIcon fontSize="small"/>
                                </Tooltip>
                            </IconButton>
                        </Grid>
                    </Grid>
                    </Grid>
            </Grid>
            </Grid>
    )
};
export default KnotText;