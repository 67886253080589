import React from 'react';

import MoCBadgeColour from './achievments/MoCBadgeColour';
import FABadgeColour from './achievments/FABadgeColour';
import FiveHundredBadgeColour from './achievments/FiveHundredBadgeColour';
import BadgeComplete from './achievments/BadgeComplete';

/*
Flexible component - pass single achievement name and grey/colour to get back svg.
*/


const AchievementsBadge = (props) => {
  
  const badge = props.badgeID;

  const badgeSet = () => {

    switch(badge) {
      case "67fab572-e5bc-45f2-9083-09723dbd4fd6":
        return(<MoCBadgeColour />)
      case "11ce9b16-057b-4aa0-a464-0490a454727c":
        return(<FABadgeColour />)
      case "4cbf4650-a615-4d70-8635-15a143bb0cac":
        return(<FiveHundredBadgeColour />)
      case "complete":
        return(<BadgeComplete />)
    };
  };
  return (
      badgeSet()
    )
};
export default AchievementsBadge;