import { Grid, Button, Divider, Modal, Typography, Stack } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import ScenarioDetails from './ScenarioDetails';
import AchievementHero from './AchievementHero';
import ScenarioCard from './ScenarioCard';
import ScenarioCardPlaceholder from './ScenarioCardPlaceholder';
import SubTopicOverview from './SubTopicOverview';

import { setCurrentSelection, toggleScenarioDetails, setAchievementHero } from './appData/appDataSlice';

import { useNavigate, useParams } from "react-router-dom";

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PracticeNotes from './PracticeNotes';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SubTopicDetail = () => {

    var navigate = useNavigate();
    let params = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();

    const fullView = useMediaQuery(theme.breakpoints.up('sm'))

    // can set additional parts of params by indicating name in App router (add sub routes)

    const viewScenarioDetails = useSelector((state) => state.appData.appUI.scenarioDetails);
    const achievementHero = useSelector((store) => store.appData.appUI.achievementHero);

    const skillsTree = useSelector((state) => state.appData.skillsTree);
    const scenarioList = useSelector((state) => state.appData.scenarioList);

    const currentPracticeArea = params.hasOwnProperty("practiceAreaID") ? params.practiceAreaID : "9ae97d04-08da-430d-a785-f93c7b49a690"
    const currentTopic = params.hasOwnProperty("topicID") ? params.topicID : "defbb654-1b67-4ac5-a3b1-b2f53f9f6a04"
    const currentSubTopic = params.hasOwnProperty("subTopicID") ? params.subTopicID : "fd0d42fc-cd1d-47e4-afe5-ba9028fd33c9"

    const scenarios = skillsTree[currentPracticeArea]["topics"][currentTopic]["subTopics"][currentSubTopic]["scenarios"]
    
    // set a default scenario? or find a way to stop modal populating

    // Get scenario UI order and sort
    let sortedScenarios = scenarios ? scenarios.map((scenario) => ([scenario, scenarioList[scenario]["UIOrder"]])) : []    
    sortedScenarios.sort((scenarioOne, scenarioTwo) => scenarioOne[1] - scenarioTwo[1])
    
    const goPracticeArea = () => {
      navigate(`/explore/${currentPracticeArea}/${currentTopic}`);
    };

    const [currentScenario, setCurrentScenario] = useState(scenarios ? scenarios[0]: null);

    useEffect(() => {
      
      dispatch(setCurrentSelection({
        "selectionType":"subTopicID",
        "selection": currentSubTopic,
      }));

    }, [params]);

    return (
      <Grid item>
        <Modal
          open={achievementHero.displayHero}
          onClose={() => dispatch(setAchievementHero(false))}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <AchievementHero achievementID={achievementHero.achievementID} />
        </Modal>
        <SubTopicOverview />
        <Grid container sx={{justifyContent: 'center', marginTop: "5px", marginBottom: "20px"}}>
          <Grid item sx={{minWidth: "100%"}}>
            <Divider variant="middle" />
          </Grid>
        </Grid>
        <Grid item sx={{
          marginTop: "15px"
        }}>
        <Typography variant={fullView ? 'h5' : 'h6'} sx={{color: "#424242"}}>Scenarios</Typography>
        <Typography variant='h6' color="textSecondary">Core content ({sortedScenarios.length})</Typography>
        </Grid>
        <Grid container sx={{
          width: "100%", 
          marginRight: "4px",
          marginTop: "4px",
          justifyContent: "flex-start",
          [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
          }
          }}
        spacing={theme.spacing(2)}
        >

          {sortedScenarios.length > 0 ?
          <>
          {sortedScenarios.map((scenario, idx) => (
            <Grid item key={idx+"grid"}>
              <ScenarioCard 
                scenarioID={scenario[0]} 
                subTopicID={params.subTopicID} 
                topicID={params.topicID} 
                practiceAreaID={params.practiceAreaID} 
                setCurrentScenario={setCurrentScenario} 
                key={idx} 
              />
            </Grid>
              
          ))}
          </>
          :
          <Grid item>
          <ScenarioCardPlaceholder />
          </Grid>
          }
          </Grid>
          
          <Grid container>
            <Grid item sx={{marginTop: "20px", marginBottom: "20px"}}>
              <Button variant="outlined" color="primary" onClick={() => goPracticeArea()}>Return to modules</Button>
            </Grid>
          </Grid>
          <Modal
            open={viewScenarioDetails}
            onClose={() => dispatch(toggleScenarioDetails())}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
          >
          <ScenarioDetails 
            title={currentScenario ? scenarioList[currentScenario]["scenarioName"] : null}
            shortDescription={currentScenario ? scenarioList[currentScenario]["scenarioShortDescription"] : null} 
            difficulty={currentScenario ? scenarioList[currentScenario]["scenarioELOText"] : null} 
            playTime={currentScenario ? scenarioList[currentScenario]["scenarioPlayTime"] : null} 
            longDescription={currentScenario ? scenarioList[currentScenario]["scenarioLongDescription"] : null} 
            scenarioID={currentScenario}
          />          
          </Modal>
          <Grid container sx={{justifyContent: 'center', marginTop: "5px", marginBottom: "20px"}}>
          <Grid item sx={{minWidth: "100%"}}>
            <Divider variant="middle" />
          </Grid>
          </Grid>
          <PracticeNotes />
          <Grid container>
            <Grid item sx={{marginTop: "20px"}}>
              <Button variant="outlined" color="primary" onClick={() => goPracticeArea()}>Return to modules</Button>
            </Grid>
          </Grid>
      </Grid>
    )
    };
export default SubTopicDetail;