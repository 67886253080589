import { Button, Grid, Typography, Snackbar, Stack, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Alert as MuiAlert } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { TreeView, TreeItem } from '@mui/x-tree-view';

import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';

import { updateScenarioProfile, loadScenarioIntoInk } from './editorSlice';
import { updateScenarioList } from '../appData/appDataSlice';

import {v4 as uuidv4} from 'uuid';

// re-test publishing flow
// build sharing ui and profile updates

export default function ScenarioPublish() {

    const dispatch = useDispatch();    
    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
    const userAccessToken = useSelector((state) => state.appData.accessToken);
    const [scenarioSubTopic, setScenarioSubTopic] = useState(scenarioProfile.status === "published" ? scenarioProfile.scenarioSubTopics : []);
    const [scenarioSharing, setScenarioSharing] = useState(scenarioProfile.sharing);
    
    const nodes = useSelector((state) => state.editorData.nodes);
    const edges = useSelector((state) => state.editorData.edges);
    const nodeID = useSelector((state) => state.editorData.nodeID);
    const edgeID = useSelector((state) => state.editorData.edgeID);

    const publishedModules = scenarioProfile.scenarioSubTopics;

    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const subTopicList = useSelector((state) => state.appData.subTopicList);
    const skillsTree = useSelector((state) => state.appData.skillsTree);

    const [saveSuccess, setSaveSuccess] = useState(false); 
    const [changesMade, setChangesMade] = useState(false);

    const getUIOrder = () => {
        
        // If not previously published, put at end.
        // TODO: let users edit UI order.
        
        if (scenarioProfile.status === "unpublished") {
            const existingScenarios = Object.keys(scenarioList).filter((scenarioID) => (scenarioList[scenarioID]["scenarioSubTopics"].includes(scenarioSubTopic[0])))
            return existingScenarios.length
        } else {
            return scenarioProfile.UIOrder
        }
    };
    const saveChanges = (action) => {
      
        dispatch(loadScenarioIntoInk());

        var profileUpdate = {
            scenarioSubTopics: scenarioSubTopic,
            sharing: scenarioSharing,
        };
        let versionData = {}

        if (action.type === "publish") {
            // TODO: will publish what's currently in ink - the load step won't reflect here
            
            // Bump publish version. Account for legacy non-versioned.
            let publishVersion = 1
            let publishDate = Date.now()
            if (scenarioProfile?.published) {
                publishVersion = scenarioProfile.published?.version ? scenarioProfile.published.version + 1 : 1
            }

            profileUpdate["UIOrder"] = getUIOrder()
            profileUpdate["published"] = {
                inkString: scenarioProfile.inkString,
                publishDate: publishDate,
                version: publishVersion
            }
            profileUpdate["status"] = "published"
            versionData = {
                version: publishVersion,
                scenarioID: scenarioProfile.scenarioID,
                scenarioData: {
                    nodes: nodes,
                    edges: edges,
                    nodeID: nodeID,
                    edgeID: edgeID,
                    publishData: publishDate
                }
            }
        }

        if (action.type === "unpublish") {
            // in this case, publish date is the date it was unpublished
            profileUpdate["published"] = {
                inkString: scenarioProfile.inkString,
                publishDate: Date.now()
            }
            profileUpdate["status"] = "unpublished"
        };

        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            lastModified: Date.now(),
        };
        
        if (scenarioData.scenarioID === "") {
            const newUUID = uuidv4();
            scenarioData.scenarioID = newUUID;
            profileUpdate["scenarioID"] = newUUID;
        };

        const writeScenario = async () => {
        try {
            const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
            });
            const responseData = await scenarioResponse.json()
            if (responseData.modified > 0 || responseData.upsertID !== "") {
                if (action.type === "publish" || action.type === "unpublish") {
                    // if publish, refresh data from server (to re-build skills tree)
                    setSaveSuccess(true);
                    window.location.reload()
                } else {
                    setSaveSuccess(true);
                    dispatch(updateScenarioProfile(profileUpdate));
                    dispatch(updateScenarioList(scenarioData));
                    setChangesMade(false);
                };
            }
            } 
        catch(e) {console.log(e)}
        }

        const writeVersion = async () => {
            try {
                const versionResponse = await fetch('/api/editor/saveScenarioVersion', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userAccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(versionData),
                });
                const responseData = await versionResponse.json()
                console.log(responseData)
            }
            catch(e) {console.log(e)}
        };

        if (action.type === "publish") {
            writeVersion();
        };
        writeScenario();
    };

    const handleSaveClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSaveSuccess(false);
    };

    const handleSubTopicSelect = (event, nodeIds) => {
        const subTopicIDs = nodeIds.filter((nodeId) => nodeId.slice(0,3) !== "000")
        setScenarioSubTopic(subTopicIDs);
    };

    const handleSharingChange = (event) => {
        setScenarioSharing(event.target.value);
        setChangesMade(true);
    };

    const getEditDate = (timestamp) => {
        const JSTime = new Date(timestamp)
        return(JSTime.toDateString())
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    // need to give some careful thought as to how to handle new / empty topics.

    return (
        <Grid container sx={{padding: "10px", maxHeight: "100%", width: "100%"}}>
            <Grid item sx={{
                maxWidth: "600px", 
                flex: 1,
                maxHeight: `calc(100vh - 90px)`,
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "15px", 
                borderLeft: "1px solid #cccccc66", 
                borderTop: "1px solid #cccccc66", 
                boxShadow: "3px 3px 3px #cccccc",
                overflow: "auto",
                '&::-webkit-scrollbar': {
                width: "20px",
                },
                '&::-webkit-scrollbar-track': {
                backgroundColor: "#ffffff",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
                },
                '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
                },
                '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
                },
                }}>
            <Typography variant="h5" paragraph>Share and Publish</Typography>
            <Typography variant="h6" paragraph>Sharing</Typography>
            <Typography variant="body1" paragraph>Choose who in your organisation can edit this scenario.</Typography>
            <Grid container sx={{marginBottom: "10px"}}>
                <FormControl fullWidth>
                <InputLabel id="select-sharing">Scenario editing</InputLabel>
                <Select
                labelId="select-sharing"
                id="sharing-select"
                size='small'
                value={scenarioSharing}
                label="Scenario editing"
                onChange={handleSharingChange}
                >
                    <MenuItem value="linkSharing">Authors in your organisation with the link</MenuItem>
                    <MenuItem value="openSharing">All authors in your organisation</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Grid container sx={{width: "100%"}}>
            <Button variant="contained" disabled={!changesMade} onClick={() => saveChanges({type: "none"})}>Save changes</Button>
            <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{width: "250px"}}>
            <Alert onClose={handleSaveClose} severity="success" sx={{width: "100%"}}>
                Settings saved.
            </Alert>
            </Snackbar>
            </Grid>
            <Grid item sx={{width: "100%", marginBottom: "20px", marginTop: "20px"}}>
                <Typography variant="h6" paragraph>Publish</Typography>
                <Typography variant="body1" >Publish status: {scenarioProfile.status === "unpublished" ? "Not published" : getEditDate(scenarioProfile.published.publishDate) + " version published"}</Typography>
                {scenarioProfile.status === "published" ? 
                <Typography variant="body1" paragraph>Publish module{publishedModules.length > 1 ? "s" : ""}: {publishedModules.map((subTopicID) => (subTopicList[subTopicID]["subTopicName"])).join(", ")}</Typography>
                :
                <Typography variant="body1" paragraph>Publish module{scenarioSubTopic.length > 1 ? "s" : ""}: {scenarioSubTopic.length === 0 ? "None - select module" : scenarioSubTopic.map((subTopicID) => (subTopicList[subTopicID]["subTopicName"])).join(", ")}</Typography>
                }
            </Grid>
            <Grid container sx={{backgroundColor: "#fafafa", padding: "10px", borderRadius: "8px", marginBottom: "10px"}}>
                <Typography paragraph>Select module:</Typography>
                <TreeView
                aria-label="topic navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
                onNodeSelect={handleSubTopicSelect}
                selected={scenarioSubTopic}
                sx={{width: "100%"}}
                >
                {Object.keys(skillsTree).map((practiceAreaID, idx) => {
                    return (
                        <TreeItem key={practiceAreaID} nodeId={"000"+idx.toString()} label={skillsTree[practiceAreaID]["practiceAreaName"]}>
                            {Object.keys(skillsTree[practiceAreaID]["topics"]).map((topicID, Tidx) => {
                                if (skillsTree[practiceAreaID]["topics"][topicID].hasOwnProperty("topicName")) {
                                    return (
                                        <TreeItem key={topicID} nodeId={"000"+idx.toString() + Tidx.toString()} label={skillsTree[practiceAreaID]["topics"][topicID]["topicName"]} >
                                            {Object.keys(skillsTree[practiceAreaID]["topics"][topicID]["subTopics"]).map((subTopicID) => {
                                                if (subTopicList[subTopicID].hasOwnProperty("subTopicName")) {
                                                    return (
                                                        <TreeItem key={subTopicID} nodeId={subTopicID} label={subTopicList[subTopicID]["subTopicName"]} />
                                                    )
                                                }
                                            })}
                                        </TreeItem>
                                    )
                                }
                            })}
                        </TreeItem>
                    )
                })}
                </TreeView>
            </Grid>
            <Grid item>
                <Stack direction="row" spacing={2}>
                <Button variant="outlined" disabled={scenarioSubTopic.length === 0} onClick={() => saveChanges({type: "publish"})}>Publish lastest</Button>
                <Button variant="outlined" color="secondary" disabled={scenarioProfile.status === "unpublished"} onClick={() => saveChanges({type: "unpublish"})}>Unpublish</Button>
                </Stack>
            </Grid>
        </Grid>
        </Grid>
    );
};