import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

import { red } from '@mui/material/colors';

const PlayerError = (props) => {

    const stripTags = props.stripTags
    const delay = props.delay
    const defaultStyles = props.defaultStyles
    const typographyVariant = props.typographyVariant

    return (
        <Grid container sx={{display: "flex", justifyContent: "center"}}
            component={motion.div} 
            initial={{opacity: 0,}}
            animate={{opacity: 1,
                    transition: {                
                        delay: delay,
                        ease: "easeInOut",
                        duration: 0.5,
                    }}}
            >
        <Grid item sx={{
            borderLeft: "4px solid",
            padding: defaultStyles.padding,
            marginBottom: defaultStyles.marginBottom,
            backgroundColor: red[500],
            borderLeftColor: red[900],
            borderBottomRightRadius: "10px",
            boxShadow: defaultStyles.boxShadow,
            maxWidth: "80%",
        }}>
        <Typography variant={defaultStyles.typographyVariant} align="center" sx={{color: "#ffffff"}} gutterBottom>{stripTags}</Typography>
        </Grid>
        </Grid>
    )
};
export default PlayerError;