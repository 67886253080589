import { Grid, Typography, Stack, Divider, Box, Paper } from '@mui/material';
import { motion } from 'framer-motion';

import { Editable, withReact, Slate } from 'slate-react'
import {
  createEditor,
} from 'slate'

import React, { useMemo, useCallback } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const MarkUpEval = (props) => {

  const initialDocData = props.initialDocData;  

  /*********************************************

  Slate editor

  *********************************************/


  const Element = ({ attributes, children, element }) => {
    const alignVariant = element.align
    const evalColor = element.evalMarkUp === "correct"? "#388e3c66" : element.evalMarkUp === "incorrect" ? "#d32f2f66" : element.evalMarkUp === "unset" ? "#f57c0066" : "#fcfdff66"

    let numberedListStyle = "decimal"
    if (element.listLevel === 0) {
      numberedListStyle = "decimal"
    };
    if (element.listLevel === 1) {
      numberedListStyle = "upper-alpha"
    };
    if (element.listLevel === 2) {
      numberedListStyle = "lower-roman"
    };
    if (element.listLevel > 2) {
      numberedListStyle = "lower-alpha"
    };

    switch (element.type) {
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
          
        )
      case 'heading-one':
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Box
            component={motion.div}
            initial={{
              backgroundColor: "#fcfdff",
              transition: {
                  duration: 1.5,
                  ease: "easeInOut",
              }
            }}
            animate={{
                backgroundColor: evalColor,
                transition: {
                    duration: 1.5,
                    ease: "easeInOut",
                }
            }}
            sx={{
              flex: 1, 
              border: `1px solid #fcfdff`,
              padding: "2px",
              paddingBottom: "6px",
              borderRadius: "3px",
              }}
          >
          <Typography align={alignVariant} variant='h6' {...attributes}>
            {children}
          </Typography>
          </Box>
          <Box sx={{width: "80px", paddingTop: "2px"}}>
          {element?.goldMarkUp ? 
          <>
          <Stack direction="row">
            <CheckCircleOutlineIcon fontSize='small' sx={{color: element.playerMarkUp === "accept" ? "#388e3c" : "#aaaaaa"}} />
            <HighlightOffIcon fontSize='small' sx={{color: element.playerMarkUp === "reject" ? "#d32f2f" : "#aaaaaa"}} />
          </Stack>
          </> :
          null }
          </Box>
          </Stack>
        )
      case 'list-item':
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Box
            component={motion.div}
            initial={{
              backgroundColor: "#fcfdff",
              transition: {
                  duration: 1.5,
                  ease: "easeInOut",
              }
            }}
            animate={{
                backgroundColor: evalColor,
                transition: {
                    duration: 1.5,
                    ease: "easeInOut",
                }
            }}
            sx={{
              flex: 1, 
              border: `1px solid #fcfdff`,
              padding: "2px",
              paddingBottom: "6px",
              borderRadius: "3px",
              }}
          >
            <Typography variant='body2' component="li" {...attributes} sx={{paddingLeft: "10px"}}>
            {children}
            </Typography>
          </Box>
          <Box sx={{width: "80px", paddingTop: "2px"}}>
          {element?.goldMarkUp ? 
          <>
          <Stack direction="row">
            <CheckCircleOutlineIcon fontSize='small' sx={{color: element.playerMarkUp === "accept" ? "#388e3c" : "#aaaaaa"}} />
            <HighlightOffIcon fontSize='small' sx={{color: element.playerMarkUp === "reject" ? "#d32f2f" : "#aaaaaa"}} />
          </Stack>
          </> :
          null }
          </Box>
          </Stack>
        )
      case 'numbered-list':
        return (
          <ol {...attributes} style={{listStyleType: numberedListStyle, marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ol>
        )
      default:
        
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />} sx={{marginBottom: "5px"}}>
          <Box 
            component={motion.div}
            initial={{
              backgroundColor: "#fcfdff",
              transition: {
                  duration: 1.5,
                  ease: "easeInOut",
              }
            }}
            animate={{
                backgroundColor: evalColor,
                transition: {
                    duration: 1.5,
                    ease: "easeInOut",
                }
            }}
            sx={{
              flex: 1, 
              border: `1px solid #fcfdff`,
              padding: "2px",
              paddingBottom: "6px",
              borderRadius: "3px",
              }}
          >
            <Typography align={alignVariant} variant='body2' {...attributes}>
              {children}
            </Typography>
          </Box>
          <Box sx={{width: "80px", paddingTop: "2px"}}>
          {element?.goldMarkUp ? 
          <>
          <Stack direction="row">
            <CheckCircleOutlineIcon fontSize='small' sx={{color: element.playerMarkUp === "accept" ? "#388e3c" : "#aaaaaa"}} />
            <HighlightOffIcon fontSize='small' sx={{color: element.playerMarkUp === "reject" ? "#d32f2f" : "#aaaaaa"}} />
          </Stack>
          </> :
          null }
          </Box>
          </Stack>
        )
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {

    if (leaf.bold) {
      children = <strong>{children}</strong>
    };
    if (leaf.italic) {
      children = <em>{children}</em>
    };
    if (leaf.underline) {
      children = <u>{children}</u>
    };
    if (leaf.addText) {
      children = <ins style={{ textDecoration: 'underline #3f51b5', color: '#3f51b5'}}>{children}</ins>
    };
    if (leaf.removeText) {
      children = <del style={{ textDecoration: 'line-through #f44336', color: '#f44336' }}>{children}</del>
    };
    return (
      <span style={{
        paddingLeft: leaf.text === '' ? "0.1px" : null
      }} {...attributes}>{children}</span>
    )
  };

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withReact(createEditor()), [])

  return (
  <Grid item sx={{width: "100%"}}>
      <Grid item>
        <Stack direction="row" spacing={1} sx={{marginBottom: "3px"}}>
          <Box sx={{
            width: "20px",
            height: "20px",
            borderRadius: "2px",
            backgroundColor: "#388e3c66"
          }}></Box><Typography variant="body2" color="textSecondary">Correct Decision</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{marginBottom: "3px"}}>
          <Box sx={{
            width: "20px",
            height: "20px",
            borderRadius: "2px",
            backgroundColor: "#f57c0066"
          }}></Box><Typography variant="body2" color="textSecondary">No Decision Made</Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{marginBottom: "10px"}}>
          <Box sx={{
            width: "20px",
            height: "20px",
            borderRadius: "2px",
            backgroundColor: "#d32f2f66"
          }}></Box><Typography variant="body2" color="textSecondary">Incorrect Decision</Typography>
        </Stack>
      </Grid>
      <Slate editor={editor} initialValue={initialDocData}>
      <Paper elevation={3}
        sx={{
          paddingLeft: "22px",
          paddingRight: "22px",
          paddingTop: "32px",
          paddingBottom: "32px",
          backgroundColor: "#fcfdff",
      }}
      >
      <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
      />
      </Paper>
      </Slate>
  </Grid>
  )
  };
export default MarkUpEval;