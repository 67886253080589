import React from 'react';

import { useNodeId } from 'reactflow';
import { Grid, FormControl, Select, MenuItem, Typography, InputLabel } from '@mui/material';

import { setNodeAction } from '../editorSlice';

import { useDispatch, useSelector } from 'react-redux';

const GoTo = (props) => {

    const dispatch = useDispatch();

    const actionUUID = props.actionUUID;
    const setChangesMade = props.setChangesMade;

    const nodes = useSelector((store) => store.editorData.nodes)
    const nodeID = useNodeId();
    const nodeData = nodes.filter(node => node.id === nodeID)[0]["data"]
    const action = nodeData.nodeActions[actionUUID]["action"]

    const edges = useSelector((state) => state.editorData.edges);
    const nodeEdges = edges.filter((edge) => edge.source === nodeID);

    const handleGoToSelect = (event) => {
        const goto = event.target.value
        let currentNodeActions = {...nodeData.nodeActions}

        // Deeply clone the object at actionUUID
        let updatedAction = { ...currentNodeActions[actionUUID] };
        updatedAction["action"] = { ...updatedAction["action"] };
        updatedAction["action"]["data"] = { ...updatedAction["action"]["data"] };
    
        if (updatedAction["action"]["data"].hasOwnProperty("goto")) {
            // If "goto" exists, update only the block reference
            updatedAction["action"]["data"]["goto"] = goto
        } else {
            // If "goto" doesn't exist, replace "data" with a new object
            updatedAction["action"]["data"] = {
                "goto": goto
            };
        }
    
        // Return a new object for the updated actions
        const actionUpdate = {
            nodeID: nodeID,
            nodeActions: {
                ...currentNodeActions,
                [actionUUID]: updatedAction
            }
        }
        
        dispatch(setNodeAction(actionUpdate))
        setChangesMade(true)
    };

    return (
        <Grid container>
        <Grid item sx={{paddingRight: "8px", paddingTop: "18px"}}>
            <Typography>Go to:</Typography>
        </Grid>
        <Grid item>
            <FormControl variant="standard" sx={{minWidth: "220px"}}>
            <InputLabel id="select-goto">Select connected block</InputLabel>
            <Select
            id="goto-select"
            labelId='select-goto'
            size='small'
            value={action.data?.goto ? action.data.goto : ''}
            label="Select connected block"
            onChange={handleGoToSelect}
            >
            {nodeEdges.map((edge, index) => (
                <MenuItem value={edge.target} key={index}>Block {edge.target}</MenuItem>
            ))}
            </Select>
            </FormControl>
        </Grid>
        </Grid>
    )
};
export default GoTo;