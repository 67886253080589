import React from 'react';
import { Grid, Paper, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import AchievementsBadge from './AchievementsBadge';

import { setAchievementHero } from '../features/appData/appDataSlice';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';

  //setAchievementHero(false)
  
  const AchievementHero = React.forwardRef((props, ref) => {

    const theme = useTheme();
    const achievementID = props.achievementID
    const achievementsList = useSelector((store) => store.appData.achievementsList);

    const dispatch = useDispatch();

    return (
      <Grid container sx={{
        width: "800px",
        [theme.breakpoints.down('md')]: {
          width: "300px",
        },
        position: "absolute", top: '30%', left: '50%', transform: 'translate(-50%, -30%)', outline: 0}} ref={ref} tabIndex={-1}>
        <Grid item style={{marginLeft: 10, marginRight: 10}}>
            <Paper sx={{padding: (theme) => theme.spacing(3), margin: 'auto', elevation: 3, maxWidth: "800px",}}>
              <Grid container>
              <Grid item sx={{minWidth: "100%", marginTop: "20px", marginBottom: "20px"}}>
                <Typography variant="h4" align="center">Achievement Complete!</Typography>
              </Grid>
              <Grid item sx={{width: "300px", margin: "auto"}}>
                <AchievementsBadge badgeID={achievementID} />
              </Grid>
              <Grid item style={{width: "100%", marginTop: 8, marginBottom: 8}}>
                <Typography variant="body1" align="center">{achievementsList[achievementID]['achievementName']}</Typography>
                <Typography variant="body1" color="textSecondary" align="center">{achievementsList[achievementID]['achievementDescription']}</Typography>
              </Grid>
              <Grid container style={{width: "100%", justifyContent: "center"}}>
                <Button variant="contained" color="primary" onClick={() => dispatch(setAchievementHero(false))}>Continue</Button>
              </Grid>
              </Grid>
            </Paper>
            </Grid>
      </Grid>
    )
    });
  export default AchievementHero;