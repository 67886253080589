import React from "react";

function BadgeComplete() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 26.458 27.517"
    >
      <g
        fillOpacity="1"
        stroke="none"
        strokeDasharray="none"
        strokeOpacity="1"
        transform="translate(-31.376 -16.909)"
      >
        <circle
          cx="44.605"
          cy="31.196"
          r="13.229"
          fill="#424242"
          strokeWidth="1.847"
        ></circle>
        <circle
          cx="44.605"
          cy="30.138"
          r="13.229"
          fill="#1b5e20"
          strokeWidth="1.847"
        ></circle>
        <circle
          cx="44.605"
          cy="30.138"
          r="11.906"
          fill="#4caf50"
          strokeWidth="1.662"
        ></circle>
        <path
          fill="#2e7d32"
          strokeWidth="1.662"
          d="M64.081-13.255a11.906 11.906 0 01-5.953 10.311 11.906 11.906 0 01-11.906 0 11.906 11.906 0 01-5.953-10.31h11.906z"
          transform="rotate(48.3)"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#212121"
        strokeDasharray="none"
        strokeMiterlimit="0.7"
        strokeWidth="0"
        d="M-41.409 -13.624H-31.152V-8.955000000000002H-41.409z"
      ></path>
      <path
        fill="#f5f5f5"
        fillOpacity="1"
        strokeWidth="0.023"
        d="M13.23 23.322L6.67 18.404q-.341-.25-.535-.638-.194-.387-.194-.82V6.016q0-.751.536-1.286.535-.535 1.286-.535h10.93q.752 0 1.287.535.535.535.535 1.286v10.93q0 .433-.194.82-.193.387-.535.638zm0-2.277l5.464-4.099V6.016H7.764v10.93zm-.957-5.01l5.146-5.146-1.275-1.32-3.871 3.87-1.913-1.912-1.32 1.275zm.956-10.019H7.764h10.93z"
      ></path>
      <path
        fill="#43a047"
        fillOpacity="1"
        stroke="none"
        strokeDasharray="none"
        strokeMiterlimit="0.7"
        strokeOpacity="1"
        strokeWidth="0"
        d="M10.483 18.917L7.8 16.911l-.005-5.41-.005-5.408h10.88l-.005 5.409-.005 5.41-2.696 2.008c-1.483 1.105-2.719 2.008-2.746 2.006-.027-.002-1.257-.906-2.734-2.009zm6.506-8.574c-.858-1.013-.685-1.078-2.831 1.065l-1.916 1.913-.9-.894c-.495-.492-.957-.894-1.026-.894-.069 0-.4.279-.736.62l-.61.62 1.665 1.668 1.665 1.668 2.591-2.592 2.592-2.591z"
      ></path>
    </svg>
  );
}

export default BadgeComplete;
