import React from "react";

function MoCBadgeColour() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      viewBox="0 0 26.458 27.517"
    >
      <g transform="translate(-31.376 -16.909)">
        <g
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeOpacity="1"
        >
          <circle
            cx="44.605"
            cy="31.196"
            r="13.229"
            fill="#4d4d4d"
            strokeWidth="1.847"
          ></circle>
          <circle
            cx="44.605"
            cy="30.138"
            r="13.229"
            fill="#3949ab"
            strokeWidth="1.847"
          ></circle>
          <circle
            cx="44.605"
            cy="30.138"
            r="11.906"
            fill="#5c6bc0"
            strokeWidth="1.662"
          ></circle>
          <path
            fill="#3f51b5"
            strokeWidth="1.662"
            d="M64.081-13.255a11.906 11.906 0 01-5.953 10.311 11.906 11.906 0 01-11.906 0 11.906 11.906 0 01-5.953-10.31h11.906z"
            transform="rotate(48.3)"
          ></path>
        </g>
      </g>
      <g fillOpacity="1" transform="translate(-31.376 -16.909)">
        <rect
          width="19.018"
          height="12.9"
          x="35.096"
          y="24.217"
          fill="#c5cae9"
          stroke="none"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="0"
          ry="1.405"
        ></rect>
        <rect
          width="17.008"
          height="11.537"
          x="36.101"
          y="24.899"
          fill="none"
          stroke="#283593"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="0.378"
          ry="1.256"
        ></rect>
        <path
          fill="#ffeb3b"
          stroke="#ffc107"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="0.4"
          d="M45.77 35.217l-1.464.413-1.314-.747-.415-1.46.746-1.318 1.446-.405 1.322.732.426 1.474z"
        ></path>
        <path
          fill="#283593"
          strokeWidth="0.055"
          d="M37.705 33.088H41.085V33.666000000000004H37.705z"
        ></path>
        <path
          fill="#283593"
          strokeWidth="0.055"
          d="M48.046 33.088H51.426V33.666000000000004H48.046z"
        ></path>
        <path
          fill="#283593"
          strokeWidth="0.112"
          d="M37.646 30.378H51.564V30.956H37.646z"
        ></path>
        <path
          fill="#283593"
          strokeWidth="0.112"
          d="M37.646 28.805H51.564V29.383H37.646z"
        ></path>
        <ellipse
          cx="44.605"
          cy="26.797"
          fill="#7986cb"
          stroke="#283593"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="0.352"
          rx="2.286"
          ry="0.837"
        ></ellipse>
      </g>
    </svg>
  );
}

export default MoCBadgeColour;
