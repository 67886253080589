import React from 'react';
import { motion } from "framer-motion"
import { Grid, Typography } from '@mui/material';

import FaceIcon from '@mui/icons-material/Face';

const SpeechOther = (props) => {

    const stripTags = props.stripTags
    const delay = props.delay
    const defaultStyles = props.defaultStyles
    const otherStyles = props.otherStyles

    return (
        <Grid container 
        component={motion.div} 
        initial={{opacity: 0,}}
        animate={{opacity: 1,
                  transition: {                
                      delay: delay,
                      ease: "easeInOut",
                      duration: 0.5,
                  }}}
        >
        
        <Grid item sx={{
          border: otherStyles.border,
          padding: defaultStyles.padding,
          marginBottom: defaultStyles.marginBottom,
          backgroundColor: otherStyles.backgroundColor,
          borderTopRightRadius: otherStyles.borderTopRightRadius,
          borderBottomLeftRadius: otherStyles.borderBottomLeftRadius,
          borderBottomRightRadius: otherStyles.borderBottomRightRadius,
          boxShadow: otherStyles.boxShadow,
          filter: defaultStyles.saturation,
          maxWidth: "80%",
        }}>
        <Typography variant={defaultStyles.typographyVariant}  align="left">{stripTags}</Typography>
        </Grid>
        </Grid>
    )
};
export default SpeechOther;

/*
style={{color: otherStyles.fontColor}}
<Grid item>
        <FaceIcon fontSize='large' sx={{marginRight: "5px", backgroundColor: otherStyles.fontColor, borderRadius: "5px", color: otherStyles.backgroundColor, filter: defaultStyles.saturation}}/>
        </Grid>

*/