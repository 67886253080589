import { Grid, Button, Typography, Snackbar, Alert } from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';

import { setCurrentSelection } from './appData/appDataSlice';
import PracticeNotesEditor from './PracticeNotesEditor';
import PracticeNotesReader from './PracticeNotesReader';

import { useParams } from "react-router-dom";

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const PracticeNotes = () => {

  let params = useParams();
  const dispatch = useDispatch();

  const theme = useTheme();
    
  const fullView = useMediaQuery(theme.breakpoints.up('sm'))

  const subTopicList = useSelector((state) => state.appData.subTopicList);
  const userData = useSelector((store) => store.appData.userData);

  const currentSubTopic = params.hasOwnProperty("subTopicID") ? params.subTopicID : "fd0d42fc-cd1d-47e4-afe5-ba9028fd33c9"
  const subTopicPracticeNotes = subTopicList[currentSubTopic]["subTopicPracticeNotes"]["content"]

  const [editPracticeNotes, setEditPracticeNotes] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false); 

  const getEditDate = () => {
    const JSTime = new Date(subTopicList[currentSubTopic]["subTopicPracticeNotes"]["subTopicUpdatedAt"])
    return(JSTime.toDateString())
  };

  const handleSaveClose = (event, reason) => {
    if (reason === 'clickaway') {
    return;
    }
    setSaveSuccess(false);
};

  useEffect(() => {
    
    dispatch(setCurrentSelection({
      "selectionType":"subTopicID",
      "selection": currentSubTopic,
    }));

  }, [params]);

  return (
  <Grid item sx={{width: "100%"}}>
      <Grid container sx={{width: "100%", justifyContent: "space-between"}}>
      <Grid item sx={{margin: "0px 20px 10px 0px", color: "#424242"}}>
          <Typography variant={fullView ? 'h5' : 'h6'}>Practice Notes</Typography>
      </Grid>
      {userData?.userRoles.includes("write:topicDescriptions") ?
      <Grid item sx={{margin: "5px"}}>
          {editPracticeNotes ?
          null
          :
          <Button size="small" variant='outlined' color="primary" onClick={() => {setEditPracticeNotes(true)}} startIcon={<EditIcon fontSize='small' />}>
          Edit
          </Button>
          }
      </Grid>
      :
      null
      }
      </Grid>
      <Grid item>
      {editPracticeNotes ?
      <PracticeNotesEditor 
        subTopicPracticeNotes={subTopicPracticeNotes} 
        currentSubTopic={currentSubTopic} 
        setEditPracticeNotes={setEditPracticeNotes} 
        setSaveSuccess={setSaveSuccess}
      />
      :
      <PracticeNotesReader subTopicPracticeNotes={subTopicPracticeNotes} />
      }
      <Grid item sx={{marginTop: "10px"}}>
      <Typography variant="body2" color="textSecondary">Last updated {getEditDate()}</Typography>
      </Grid>
      <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{width: "250px"}}>
        <Alert onClose={handleSaveClose} severity="success" variant='filled' sx={{width: "100%"}}>
            Practice Notes saved.
        </Alert>
        </Snackbar>
      </Grid>
  </Grid>
  )
  };
export default PracticeNotes;