import { Stack, Alert } from '@mui/material';

const ParsingErrors = () => {

  return (
    <Stack spacing={2}>
      <Alert variant="filled" severity="warning">
      Your scenario contains errors. Check the highlighted blocks.
      </Alert>
    </Stack>
  );
};
export default ParsingErrors;

/*

common error

Blank choice - if you intended a default fallback choice, use the `* ->` syntax
Apparent loose end exists where the flow runs out. Do you need a '-> DONE' statement, choice or divert?

  const parsingErrors = useSelector((state) => state.editorData.parsingErrors)

  var LRerrors = []

  const blankChoice = new RegExp ("Blank choice - if you intended a default fallback choice, use the")
  const looseEnd = new RegExp("Apparent loose end exists where the flow runs out.")

  parsingErrors.forEach((error) => {
    let LRmessage = false
    if (blankChoice.test(error)) {
    LRerrors.push(["Blank option - provide text for all options",1])
    LRmessage = true
    }
    if (looseEnd.test(error)) {
    LRerrors.push(["Loose end - connect loose blocks to the end or another scenario block",1])
    LRmessage = true
    }
    if (!LRmessage) {
        LRerrors.push(error)
    }
  });

  const errorTypeMap = {
    0: "error",  
    1: "warning",
    2: "warning",
    3: "warning",
    4: "warning",
  };

*/