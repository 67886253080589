import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { motion } from "framer-motion";

import { Grid, Typography, Box, Stack, Button, Divider, Fab } from '@mui/material';

import RedactionEval from '../slateDocs/RedactionEval';
import MarkUpEval from '../slateDocs/MarkUpEval';
import ScenarioPlayback from '../ScenarioPlayback';
import ScenarioCompleteRoundel from '../ScenarioCompleteRoundel';

import ComputerIcon from '@mui/icons-material/Computer';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import Confetti from 'react-confetti';

import { setEditorFeedbackType, clearEditorFeedbackContent } from './editorSlice';
import { loadScenarioIntoInk } from './editorSlice';

const EditorComplete = (props) => {

  const dispatch = useDispatch();

  // Only display incorrect feedback in side panel, but feedback content will include all AI content
  const feedbackContent = useSelector((state) => state.editorData.feedbackContent);
  const scenarioFeedback = feedbackContent.filter(f => !f.startsWith("_inlineFeedbackAICorrect_"))
  
  const scenarioTotalPoints = useSelector((state) => state.editorData.score.total_points);
  const userPoints = useSelector((state) => state.editorData.score.points_received);

  // Guard for no scoring set
  const scenarioScore = scenarioTotalPoints === 0 ? 0 : Math.round((userPoints / scenarioTotalPoints) * 100)

  const scenarioPlayerDoc = useSelector((state) => state.editorData.playerDocument);
  const scenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);

  const confettiWidth = props.confettiWidth
  const confettiHeight = props.confettiHeight

  const playAgain = () => {
    dispatch(loadScenarioIntoInk());

    dispatch(setEditorFeedbackType(""));
    dispatch(clearEditorFeedbackContent());
  };

  const toggleReviewSolution = () => {
    setReviewSolution(!reviewSolution)
  }

  const getContrastColor = (complete) => {
    if (complete >= 75) {
        return "#1b5e20"
    };
    if (complete < 75 && complete >= 25) {
        return "#ff6f00"
    }
    if (complete < 25) {
        return "#880e4f"
    }
  };

  const getColor = (complete) => {
    if (complete >= 75) {
        return "#4caf50"
    };
    if (complete < 75 && complete >= 25) {
        return "#ffc107"
    }
    if (complete < 25) {
        return "#e91e63"
    }
  };

  const [reviewSolution, setReviewSolution] = useState(true);
  let docsForReview = false;

  if (scenarioAssets.hasOwnProperty("documents")) {
    if (scenarioAssets.documents[0]["type"] !== "pdf") {
      docsForReview = true
    }
  };

  const AIFeedbackHint = (props) => {
    // Only being passed "incorrect" feedback

    const content = props.content
    let parsedContent = content.split("_AIExplanation_")
    if (parsedContent.length === 3) {
      
      // Right answer, wrong explanation case.
      return (
        <Box sx={{paddingBottom: "6px", paddingTop: "3px", paddingRight: "3px"}}>
          <Typography paragraph>{parsedContent[0]}</Typography>
          <Typography paragraph>Reference answer:</Typography>
          <Typography paragraph>{parsedContent[1]}</Typography>
          <Box sx={{backgroundColor: "#f3f3f3", padding: "6px", borderBottomRightRadius: "8px"}}>
          <Stack direction="row" spacing={1} sx={{marginBottom: "6px"}}>
          <ComputerIcon fontSize='small' />
          <Typography paragraph>Dynamic Feedback:</Typography>
          </Stack>
          <Typography>{parsedContent[2]}</Typography>  
          </Box>
        </Box>
      )
    } else {
      return (
        <Box sx={{paddingBottom: "6px", paddingTop: "3px", paddingRight: "3px"}}>
          <Typography paragraph>Reference answer:</Typography>
          <Typography paragraph>{parsedContent[0]}</Typography>
          <Box sx={{backgroundColor: "#f3f3f3", padding: "6px", borderBottomRightRadius: "8px"}}>
          <Stack direction="row" spacing={1} sx={{marginBottom: "6px"}}>
          <ComputerIcon fontSize='small' />
          <Typography paragraph>Dynamic Feedback:</Typography>
          </Stack>
          <Typography>{parsedContent[1]}</Typography>  
          </Box>
        </Box>
      )      
    }
  };

  return (
    <Grid container sx={{justifyContent: "center", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}}>
      {scenarioScore === 100 && <Confetti recycle={false} width={confettiWidth} height={confettiHeight} />}
      <Grid item sx={{width: "100%", paddingBottom: "20px"}}>
        <Typography align='center' variant='h4' sx={{color: getColor(scenarioScore)}}>Scenario Complete</Typography>
      </Grid>
      <Grid item style={{width: "100%", border: "1px solid #eee", borderRadius: "10px"}}>
        <Grid item sx={{paddingTop: "12px", paddingBottom: "12px", paddingLeft: "18px", paddingRight: "18px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", backgroundColor: getColor(scenarioScore)}}>
          <Typography variant='h5' sx={{color: getContrastColor(scenarioScore)}}>{scenarioScore < 100 ? "Feedback" : "Learning notes"}</Typography>
        </Grid>
        <Grid container>
          <Grid item 
          sx={{
            width: "30%", 
            paddingLeft: "8px", 
            paddingRight: "8px", 
            paddingTop: "14px", 
            maxHeight: "450px", 
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: "20px",
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: "#ffffff",
              borderTopRightRadius: "10px",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: "#d6dee1",
              borderRadius: "20px",
              border: "6px solid transparent",
              backgroundClip: "content-box",
            },
            '&::-webkit-scrollbar-thumb-hover': {
              backgroundColor: "#a8bbbf",
            },
            }}>
          <ScenarioCompleteRoundel scenarioScore={scenarioScore} />
          <Divider variant='middle' />
          <Box sx={{padding: "8px"}}>
          {scenarioScore === 100 ? 
          <>
          <Typography variant="h6" color="textSecondary" align='center' sx={{paddingBottom: "6px"}}>Notes</Typography>
          {scenarioFeedback.map((content, idx) => {
            return (
              <Typography paragraph variant='body1' key={"feedbacktext" + idx}>{content}</Typography>
            )
          })}
          </>
          :
          <>
          {scenarioFeedback.length > 0 ?
            <>
            <Typography variant="h6" color="textSecondary" align='center' sx={{paddingBottom: "6px"}}>Hints</Typography>
            {scenarioFeedback[0].startsWith("_inlineFeedbackAIIncorrect_") ? 
            <AIFeedbackHint content={scenarioFeedback[0].slice(27)} />
            :
            <Typography paragraph variant='body1'>{scenarioFeedback[0]}</Typography>
            }
            </>
          :
          null
          }
          </>
          }
          </Box>
          </Grid>
          <Grid item sx={{width: "70%"}}>
          {docsForReview ? 
          <Grid container style={{width: "100%", justifyContent: "flex-end"}}>
          <Grid item 
            sx={{position: "absolute", margin: "5px", marginRight: "20px", zIndex: 1000}} 
          >
          <Fab 
              color="primary" 
              variant="extended"
              size="small"
              onClick={() => toggleReviewSolution()}
            >
              <TextSnippetIcon sx={{marginRight: "3px"}} fontSize="small" />
              {reviewSolution ? "Document Solution" : "Scenario Solution"}
          </Fab>
          </Grid>
          </Grid>
          :
          null
          }
          {reviewSolution ?
            <Grid item component={motion.div} 
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: "easeInOut",
                duration: 1,
              }
            }}
            sx={{
              background: "linear-gradient(135deg, #f3f3f3 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px),\nlinear-gradient(225deg, #f3f3f3 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px)0 64px",
              backgroundColor:"#f3f3f3",
              backgroundSize:"64px 128px",
              maxHeight: "450px",
              padding: "8px",
              overflow: "auto",
              
              '&::-webkit-scrollbar': {
                width: "20px",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: "#f3f3f3",
                borderTopRightRadius: "10px",
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
              },
              '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
              },
            }}>
            <ScenarioPlayback loadSource="editor" />
            </Grid>
            :
            <Grid item sx={{
              padding: "8px", 
              maxHeight: "450px",
              overflow: "auto",
              '&::-webkit-scrollbar': {
                width: "20px",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: "#ffffff",
                borderTopRightRadius: "10px",
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
              },
              '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
              },
            }}>
            {scenarioAssets.documents[0]["type"] === "redaction" ?
            <RedactionEval initialDocData={scenarioPlayerDoc} />
            :
            null            
            }
            {scenarioAssets.documents[0]["type"] === "markup" ?
            <MarkUpEval initialDocData={scenarioPlayerDoc} />
            :
            null            
            }
            </Grid>
          }
          </Grid>
        </Grid>
      </Grid>
    <Grid item sx={{width: "100%"}}>
      <Grid container sx={{justifyContent: 'center', width: "100%"}}>
        <Grid item sx={{marginTop: "15px", marginBottom: "15px"}}>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => playAgain()}
          >
          Replay
        </Button>      
        </Grid>
      </Grid>
    </Grid>
    </Grid>
  )
  };
export default EditorComplete;

/*

<Grid item>
          <Grid item style={{maxWidth: 450, marginBottom: 30, backgroundColor: "#eee", paddingTop: 12, paddingBottom: 12, paddingLeft: 18, paddingRight: 18, borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}>
            <Grid container sx={{justifyContent: 'center', width: "100%"}}>
              <Grid item sx={{marginTop: "15px", marginBottom: "15px"}}>
              </Grid>
            </Grid>
          </Grid>
          </Grid>

  


*/