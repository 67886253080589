import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";

export default function ScenarioCardPlaceholder() {

    var navigate = useNavigate();

    const scenarioItems = {
        initial: {
          borderLeft: "1px dashed #ffffff",
          borderTop: "1px dashed #ffffff",
        },
        animate: {
          borderLeft: "1px dashed #cccccc66",
          borderTop: "1px dashed #cccccc66",
          transition: {
            ease: "easeInOut",
            duration: 1,
          },
        }
    };

    const goCreate = () => {
        navigate("/editor");
    }

return (
    <Grid item 
        sx={{
            boxShadow: "3px 3px 3px #cccccc",
            borderRadius: "15px",
            backgroundColor: "#ffffff",
            padding: "10px",
            minWidth: "200px",
            maxWidth: "200px",
            height: "290px"
        }}
        component={motion.div}
        initial="initial"
        animate="animate"
        variants={scenarioItems}
        >
        <Grid container sx={{width: "100%", height: "100%", justifyContent: "center", alignContent: "center"}}>
        <Typography variant="body1" paragraph>No scenarios!</Typography>
        <Button variant="outlined" size="small" onClick={() => goCreate()}>Create scenarios</Button>
        </Grid>
    </Grid>
);
}