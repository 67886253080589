import React, {useState} from 'react';
import { useSelector } from 'react-redux'
import { motion } from "framer-motion";

import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { useParams } from "react-router-dom";

/*

a single feedback endpoint, take user and feedback type content, timestamp, scenario id and name

timestamp
feedback type rating / comment
feedback content positive / content of comment

*/

const AIFeedbackForm = (props) => {
    
    let params = useParams();
    const scenarioID = params.scenarioID;
    // Catch any trailing white space
    const AIBlockID = props.AIBlockID.trim();
    const userMongoID = useSelector((state) => state.appData.userData.mongoID);
    const userScenarios = useSelector((state) => state.appData.userData.userScenarios);
    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const sessionID = useSelector((state) => state.choices.sessionID);
    const version = useSelector((state) => state.choices.version);
    const scenarioScore = userScenarios.hasOwnProperty(scenarioID) ? userScenarios[scenarioID]["score"] : 0

    const [feedbackProvided, setFeedBackProvided] = useState(false);
    const [feedbackError, setFeedBackError] = useState(false);

    const [showFeedbackForm, setShowFeedbackForm] = useState(false);
    const [discreteFeedback, setDiscreteFeedback] = useState(null)

    const handleAnswerCorrect = () => {
        setDiscreteFeedback("answerCorrect")
        writeFeedback("discrete", "answerCorrect")
    };

    const handleFeedbackUnclear = () => {
        setDiscreteFeedback("feedbackUnclear")
        writeFeedback("discrete", "feedbackUnclear")
    };

    const [textFeedback, setTextFeedback] = React.useState("");
    const handleFeedbackChange = (event) => {
        setTextFeedback(event.target.value);
    };

    const submitFeedback = () => {
        if (textFeedback === "") {
            setFeedBackError(true)
        } else {
            setFeedBackError(false)
            setFeedBackProvided(true)
            writeFeedback("comment", textFeedback)
        }
    };

    const writeFeedback = async(feedbackType, feedbackContent) => {

        const feedbackData = {
            scenarioID: scenarioID,
            scenarioName: scenarioList[scenarioID]["scenarioName"],
            scenarioScore: scenarioScore,
            userMongoID: userMongoID,
            AIBlockID: AIBlockID,
            sessionID: sessionID,
            version: version,
            timestamp: Date.now(),
            feedbackContent: feedbackContent,
            feedbackType: feedbackType,
        };

        //console.log(feedbackData)

        try {
            const feedbackResponse = await fetch('/api/scenariofeedback', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(feedbackData),
              });
            } catch(e) {console.log(e)}
    };

    return (
        <Grid item sx={{width: "100%", padding: "16px"}}>
            {showFeedbackForm ?
            <Grid item component={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5, ease: "easeInOut"}}>
                <Grid container sx={{width: "100%", justifyContent: "space-evenly", marginBottom: "12px"}}>
                    <Button size="small" sx={{marginTop: "4px"}} variant='outlined' color="primary" disabled={discreteFeedback !== null} onClick={() => handleAnswerCorrect()}>My answer was correct</Button>
                    <Button size="small" sx={{marginTop: "4px"}} variant='outlined' color="primary" disabled={discreteFeedback !== null} onClick={() => handleFeedbackUnclear()}>The feedback is unclear</Button>
                </Grid>
                <Grid container sx={{width: "100%", justifyContent: "center"}}>
                <Grid item sx={{width: "100%"}}>
                <form noValidate sx={{width: "100%", marginTop: "12px"}} autoComplete="off">
                <TextField
                    id="outlined-multiline-static"
                    label="Tell us more..."
                    value={textFeedback}
                    error={feedbackError}
                    disabled={feedbackProvided}
                    onChange={handleFeedbackChange}
                    size='small'
                    fullWidth
                    multiline
                    rows={3}
                    variant="outlined"
                />
                </form>
                <Grid container sx={{width: "100%", justifyContent: "center", marginTop: "16px"}}>
                <Button
                type="submit"
                size="medium"
                variant="contained"
                color="primary"
                disabled={feedbackProvided}
                onClick={() => submitFeedback()}
                >
                    Submit
                </Button>
                </Grid>
                {(discreteFeedback !== null || feedbackProvided) ?
                <Grid item sx={{width: "100%", marginTop: "6px"}} component={motion.div} initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.5, ease: "easeInOut"}}>
                <Typography variant="body2" color="textSecondary" align="center">Thank you for your feedback.</Typography>
                </Grid>
                :
                null
                }
                </Grid>
            </Grid>
            </Grid>
            :
            <Grid container sx={{width: "100%", justifyContent: "flex-end"}}>
            <Button variant='outlined' color="secondary" onClick={() => setShowFeedbackForm(true)}>Challenge Feedback</Button>    
            </Grid>
            }
        </Grid>
    )
};
export default AIFeedbackForm;