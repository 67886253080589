import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Button, 
    Grid, 
    Typography, 
    Snackbar, 
    TextField,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText } from '@mui/material'
import { Alert as MuiAlert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';

import {v4 as uuidv4} from 'uuid';

import { updateScenarioProfile } from './editorSlice';
import { updateScenarioList } from '../appData/appDataSlice';

/*

write up, deploy, test

*/

export default function ScenarioPrimarySources() {

    const dispatch = useDispatch();
    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
    const scenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);
    const userAccessToken = useSelector((state) => state.appData.accessToken);

    const [saveSuccess, setSaveSuccess] = useState(false); 
    const [saveFail, setSaveFail] = useState(false); 
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [sourceLink, setSourceLink] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteSourceID, setDeleteSourceID] = useState("");
    const [linkError, setLinkError] = useState(false);
    const [linkErrorMessage, setLinkErrorMessage] = useState("")

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };
    const handleSaveClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSaveSuccess(false);
    };
    const handleSaveFailClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSaveFail(false);
    };
    const handleDeleteClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setDeleteSuccess(false);
    };
    const handleSourceLinkChange = (event) => {
        setSourceLink(event.target.value);
    };

    const deletePrimarySource = async() => {

        const {[deleteSourceID]: sourceData, ...rest} = scenarioAssets["primarySource"]
        const primarySourceUpdate = {
            primarySource: rest
        }
        
        var profileUpdate = {
            scenarioAssets: {
                ...scenarioAssets,
                ...primarySourceUpdate
            }
        };
        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            lastModified: Date.now(),
        };
        
        try {
        const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
        });
        const responseData = await scenarioResponse.json()
        if (responseData.modified > 0 || responseData.upsertID !== "") {
            setDeleteSuccess(true)
            dispatch(updateScenarioProfile(profileUpdate));
            // Not sure need a call to scenario list here, but don't think it hurts.
            dispatch(updateScenarioList(scenarioData));
            setDeleteModal(false);
        }
        }
        catch(e) {
            console.log(e)
        }
    }

    const addPrimarySource = (event) => {
        setInputDisabled(true)
        event.preventDefault();

        // do link validation

        var linkValidated = true
        
        // legislation.gov address
        if (sourceLink.slice(0,31) !== "https://www.legislation.gov.uk/") {
            setLinkError(true);
            setLinkErrorMessage("Enter a legislation.gov address")
            linkValidated = false
            setInputDisabled(false)
        } else {
            // enter a section or crossheading
            const linkArray = sourceLink.split("/")
            if ((linkArray[linkArray.length - 2] !== "section") && (linkArray[linkArray.length - 2] !== "crossheading")) {
                setLinkError(true);
                setLinkErrorMessage("Enter a section or crossheading")
                linkValidated = false
                setInputDisabled(false)
            }
        };
        
        const submitBackend = async() => {
            try {

                // call backend to get source data

                const sourceDataResponse = await fetch('/api/assets/getprimarysources', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({sourceLink: sourceLink}),
                });
    
                const sourceData = await sourceDataResponse.json()

                // check if got response

                if (Object.keys(sourceData).length === 0) {
                    setSaveFail(true)
                    setInputDisabled(false)
                    setSourceLink("")
                    return
                }

                // add to scenario metadata

                var primarySourceUpdate = {
                    primarySource: {}
                }

                if (scenarioAssets.hasOwnProperty("primarySource")) {
                    primarySourceUpdate["primarySource"] = {
                        ...scenarioAssets["primarySource"],
                        ...sourceData
                    }
                } else {
                    primarySourceUpdate["primarySource"] = {
                        ...sourceData
                    }
                }
                var profileUpdate = {
                    scenarioAssets: {
                        ...scenarioAssets,
                        ...primarySourceUpdate
                    }
                };
                var scenarioData = {
                    ...scenarioProfile, 
                    ...profileUpdate,
                    lastModified: Date.now(),
                };
                if (scenarioData.scenarioID === "") {
                    const newUUID = uuidv4();
                    scenarioData.scenarioID = newUUID;
                    profileUpdate["scenarioID"] = newUUID;
                }

                const scenarioResponse = await fetch('/api/editor/saveScenario', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userAccessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(scenarioData),
                });
                const responseData = await scenarioResponse.json()

                // add some testing here and in backend to validate stuff

                if (responseData.modified > 0 || responseData.upsertID !== "") {
                    setSaveSuccess(true);
                    dispatch(updateScenarioProfile(profileUpdate));
                    dispatch(updateScenarioList(scenarioData));
                    setInputDisabled(false)
                    setSourceLink("")
                }
            }
            catch(e) {
                console.log(e)
                setInputDisabled(false)
                setSourceLink("")
            }
        }
        if (linkValidated) {
            submitBackend()
            setLinkError(false)
            setLinkErrorMessage("")
        }
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    //
    return (
        <Grid container sx={{padding: "10px", maxHeight: "100%"}}>
            <Grid item sx={{
                maxWidth: "600px", 
                flex: 1,
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "15px", 
                borderLeft: "1px solid #cccccc66", 
                borderTop: "1px solid #cccccc66", 
                boxShadow: "3px 3px 3px #cccccc",
                }}>
            <Typography variant="h5" paragraph>Primary Sources</Typography>
            <Typography paragraph color="textSecondary" style={{wordWrap: "break-word"}}>Add primary sources to your scenario. Enter a link to a legislation.gov resource, e.g. https://www.legislation.gov.uk/ukpga/2006/46/section/288</Typography>
            <Grid item>
            <form autoComplete='off' onSubmit={addPrimarySource}>
            <TextField
                id="outlined-multiline-static"
                label="Source link"
                value={sourceLink}
                error={linkError}
                helperText={linkError ? linkErrorMessage : null}
                onChange={handleSourceLinkChange}
                disabled={inputDisabled}
                variant="outlined"
                size="small"
                sx={{marginBottom: "25px", marginRight: "10px", width: "464px"}}
            />
            <Button type="submit" variant="contained" size="medium" disabled={inputDisabled}>Add</Button>
            </form>
            </Grid>
            {scenarioAssets.hasOwnProperty("primarySource") ? 
            <>
            {Object.keys(scenarioAssets.primarySource).length > 0 ?
            <Grid container >
            <TableContainer >
                <Table aria-label="scenario table" size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Source</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {Object.keys(scenarioAssets["primarySource"]).map((sourceID) => (
                        <TableRow
                        key={sourceID}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell scope="row">
                            {scenarioAssets["primarySource"][sourceID]["label"]}
                        </TableCell>
                        <TableCell align="right">
                            <Button size="small" color="secondary" variant="outlined" onClick={() => {setDeleteModal(true); setDeleteSourceID(sourceID)}}>Delete</Button>
                        </TableCell>                        
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            :
            null
            }
            </>
            :
            null
            }
            <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{width: "250px"}}>
            <Alert onClose={handleSaveClose} severity="success" sx={{width: "100%"}}>
                Source added.
            </Alert>
            </Snackbar>
            <Snackbar open={saveFail} autoHideDuration={6000} onClose={handleSaveFailClose} sx={{width: "250px"}}>
            <Alert onClose={handleSaveFailClose} severity="error" sx={{width: "100%"}}>
                Error adding source.
            </Alert>
            </Snackbar>
            <Snackbar open={deleteSuccess} autoHideDuration={6000} onClose={handleDeleteClose} sx={{width: "250px"}}>
            <Alert onClose={handleDeleteClose} severity="success" sx={{width: "100%"}}>
                Source deleted.
            </Alert>
            </Snackbar>
            <Dialog
                open={deleteModal}
                onClose={handleDeleteModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Confirm source deletion:
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" size="small" onClick={handleDeleteModalClose}>Cancel</Button>
            <Button variant="outlined" size="small" color="secondary" onClick={deletePrimarySource}>
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        </Grid>
        </Grid>
    );
}