import React from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { setEditorScenarioUI } from './editorSlice';

import { Grid, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrimarySource from '../PrimarySource';
import ScenarioDocuments from '../ScenarioDocuments';
import ScenarioOutline from '../ScenarioOutline';
import AuthorGuide from './AuthorGuide';

/*

pass down a loading tracker too?

*/

const EditorScenarioResources = (props) => {

  const dispatch = useDispatch();
  const resourceHeight = window.screen.height - 300
  const setSelectedIndex = props.setSelectedIndex
  const documentArray = props.documentArray

  const handleClose = () => {
    dispatch(setEditorScenarioUI({
      "viewResources": false,
      "viewHints": false,
      "viewDocuments": false,
      "viewPrimarySources": false,
      "viewOutline": false,
    }));
  };
    const editorScenarioUI = useSelector((state) => state.editorData.editorScenarioUI);
    const editorScenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);
    
    return (
      <Grid container
        sx={{
          overflow: "auto",
          '&::-webkit-scrollbar': {
            width: "20px",
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: "#ffffff",
            borderTopRightRadius: "10px",
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#d6dee1",
            borderRadius: "20px",
            border: "6px solid transparent",
            backgroundClip: "content-box",
          },
          '&::-webkit-scrollbar-thumb-hover': {
            backgroundColor: "#a8bbbf",
          },
          height: `${resourceHeight}px`
        }}
      >
        <Grid item style={{width: "100%"}}>
          <Grid container style={{width: "100%", justifyContent: "flex-end"}}>
            <Grid item 
            style={{position: "absolute", margin: "5px", zIndex: 1000}} 
            >
              <Grid container style={{alignItems: "center"}}>
              {window.screen.width >= 1200 ?
              <Button variant="outlined" color="secondary" size='small' onClick={() => handleClose()}>Close</Button>
              :
              <IconButton aria-label="close" size='small' onClick={() => handleClose()}>
                <CloseIcon fontSize='small' />
              </IconButton>
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {editorScenarioUI.viewPrimarySources &&
        <Grid item style={{width: "100%", height: "100%"}}>
          {editorScenarioAssets.hasOwnProperty("primarySource") && Object.keys(editorScenarioAssets["primarySource"]).length > 0 ? 
          <PrimarySource loadSource="editor" />
          :
          <Grid container sx={{width: "100%", justifyContent: "center", alignItems: "center", paddingTop: "200px"}}>
            <Button variant="outlined" onClick={() => setSelectedIndex(1)}>Add primary sources</Button>
          </Grid>
          }
        </Grid>
        }
        {editorScenarioUI.viewDocuments &&
        <Grid item style={{width: "100%", height: "100%"}}>
          {editorScenarioAssets.hasOwnProperty("documents") ? 
          <>
          {documentArray ?
          <ScenarioDocuments loadSource="editor" documentArray={documentArray} key={documentArray.length}/>
          :
          <Grid item sx={{width: "100%", height: "100%", padding: "20px"}}>
          <Typography align='center'>Refresh scenario to view documents.</Typography>
          </Grid>
          }
          </>
          :
          <Grid container sx={{width: "100%", justifyContent: "center", alignItems: "center", paddingTop: "200px"}}>
            <Button variant="outlined" onClick={() => setSelectedIndex(2)}>Add documents</Button>
          </Grid>
          }
        </Grid>
        }
        {editorScenarioUI.viewOutline &&
        <Grid item style={{width: "100%", height: "100%"}}>
          {editorScenarioAssets.hasOwnProperty("outline") ? 
          <>
          <ScenarioOutline loadSource="editor" />
          </>
          :
          <Grid container sx={{width: "100%", justifyContent: "center", alignItems: "center", paddingTop: "200px"}}>
            <Button variant="outlined" onClick={() => setSelectedIndex(2)}>Add outline</Button>
          </Grid>
          }
        </Grid>
        }
        {editorScenarioUI.viewHints &&
        <Grid item sx={{width: "100%", height: "100%", padding: "20px"}}>
        <AuthorGuide />
        </Grid>
        }
      </Grid>
  );
}

export default EditorScenarioResources;