import React from 'react';
import { motion } from "framer-motion";

import { Typography, CircularProgress, Button, Paper } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { useNavigate } from "react-router-dom";

import ProfileCard from '../features/ProfileCard';
import ContinueLearning from '../features/ContinueLearning';
import CurrentTopic from '../features/CurrentTopic';
import AchievementsList from '../features/AchievementsList';
import AchievementsBadge from '../features/AchievementsBadge';
import ActivityChart from '../features/ActivityChart';

import EditIcon from '@mui/icons-material/Edit';

import { useDispatch, useSelector } from 'react-redux';

import { setCurrentSelection } from '../features/appData/appDataSlice';

/*

Learning dashboard

need to make play gaain data robust - capture practice, topic, subtopic infor in user scenarios too.

*/


const Learn = () => {

    const theme = useTheme();
    const skillsTree = useSelector((store) => store.appData.skillsTree);
    const userScenarios = useSelector((store) => store.appData.userData.userScenarios);
    const userAchievements = useSelector((store) => store.appData.userData.userAchievements);
    const scenarioList = useSelector((store) => store.appData.scenarioList);
    const userTopic = useSelector((store) => store.appData.userData.userTopic);

    const headingTypographyVariant = useMediaQuery(theme.breakpoints.down('md')) ? "h5" : "h4"
    const displayProfile = useMediaQuery(theme.breakpoints.up('sm'))

    const getScenarioCount = () => {
        let scenarioCount = 0
        if (userScenarios) {
            for (const scenarioID of Object.keys(userScenarios)) {
                if (scenarioList.hasOwnProperty(scenarioID)) {
                    if (scenarioList[scenarioID]["status"] !== "unpublished") {
                        scenarioCount += 1
                    }
                }
            }
        }
        return scenarioCount
    };
    
    const waitingForAppData = Object.keys(skillsTree).length === 0
    const achievementsList = useSelector((store) => store.appData.achievementsList);

    const weekScore = useSelector((store) => store.appData.userData.weekScore)
    const weekScoreCounter = weekScore > 500 ? 500 : weekScore;
    
    const dispatch = useDispatch();

    var navigate = useNavigate();
    const goExplore = () => {
        navigate(`/explore`);
    };
    const goPlay = (scenarioID) => {
        dispatch(setCurrentSelection({
            "selectionType":"subTopicID",
            "selection": userScenarios[scenarioID]["subTopicID"],
          }));
        dispatch(setCurrentSelection({
            "selectionType":"topicID",
            "selection": userScenarios[scenarioID]["topicID"],
          }));
        dispatch(setCurrentSelection({
            "selectionType":"practiceAreaID",
            "selection": userScenarios[scenarioID]["practiceAreaID"],
          }));
        navigate(`/play/${scenarioID}`);
    };

    // try and do this again with spacing
    
    return (
        <Grid container sx={{width: "100%", justifyContent: "center"}}>
            <Grid sx={{
                width: "100%", 
                paddingLeft: "10px", 
                height: "75px",
                [theme.breakpoints.down('md')]: {
                    height: "50px"
                }
            }}
            component={motion.div} 
            initial={{opacity: 0,}}
            animate={{opacity: 1,
                        transition: {                
                            ease: "easeInOut",
                            duration: 1,
                        }}}
            key={"heading"}
            layout
            >
                <Grid container sx={{height: "100%", alignItems: "center"}}>
                    <Grid sx={{width: "100%"}}>
                    <Typography variant={headingTypographyVariant} sx={{color: "#616161"}}>Learning Dashboard</Typography>
                    </Grid>
                </Grid>
            
            </Grid>
            <Grid container sx={{width: "100%"}}>
                {waitingForAppData ?
                <Grid container sx={{width: "100%", justifyContent: "center"}}>
                <CircularProgress />
                </Grid>
                :
                <>
                <Grid sx={{width: "100%", padding: "10px"}}>
                    <Grid container spacing={1}>
                        {displayProfile ? 
                        <Grid xs={12}>
                            <Grid sx={{padding: "10px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px"}}>
                                <Grid container spacing={1} sx={{padding: "10px"}}>
                                    <Grid xs={12} sm={6} md={4}>
                                    <ProfileCard />
                                    </Grid>
                                    <Grid xs={4} sm={6} md={3}>
                                        <Grid sx={{
                                        padding: "10px", 
                                        backgroundColor: "#4caf50", 
                                        borderRadius: "15px", 
                                        borderLeft: "1px solid #cccccc66", 
                                        borderTop: "1px solid #cccccc66", 
                                        boxShadow: "3px 3px 3px #cccccc",
                                        height: "100%"
                                        }}>
                                        <Grid container sx={{width: "100%", height: "100%", alignContent: "center", justifyContent: "center"}}>
                                            <Grid>
                                            <Typography variant='h4' align="center" sx={{color: "#ffffff", fontWeight: "bold"}}>{getScenarioCount()}</Typography>
                                            <Typography variant='body2' align="center" sx={{color: "#ffffff"}}>{getScenarioCount() === 1 ? "Scenario played" : "Scenarios played"}</Typography>
                                            </Grid>
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid xs>
                                    {userAchievements.length === 0 ?
                                    <Grid sx={{borderRadius: "15px", border: "1px dotted #cccccc", height: "100%", padding: "15px"}}>
                                        <Grid container sx={{width: "100%", height: "100%", alignContent: "center", justifyContent: "center"}}>
                                        <Typography align="center">No achievements!</Typography>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid sx={{
                                        backgroundColor: "#ffffff",
                                        borderRadius: "15px", 
                                        borderLeft: "1px solid #cccccc66", 
                                        borderTop: "1px solid #cccccc66", 
                                        boxShadow: "3px 3px 3px #cccccc",
                                        padding: "10px",
                                        minWidth: "200px",
                                    }}>
                                    <Typography variant="body2">Achievements</Typography>
                                    <AchievementsList />
                                    </Grid>
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        null
                        }
                        <Grid xs={12} md={6}>
                            <Grid sx={{padding: "10px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px", minHeight: "100%"}}>
                                <ContinueLearning />
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid sx={{padding: "10px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px", minHeight: "100%"}}>
                            {userTopic.practiceAreaID === "" ?
                            <>
                            <Grid sx={{paddingLeft: "15px"}}>
                                <Typography variant="h6">Current Topic</Typography>
                                <Typography variant="body1" color="textSecondary" paragraph>Complete all modules.</Typography>
                                <Grid sx={{
                                    borderRadius: "15px",
                                    border: "1px dotted #cccccc",
                                    padding: "10px",
                                    height: "290px",
                                    flex: 1
                                }}>
                                    <Grid container sx={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                        <Grid>
                                            <Typography align="center" paragraph>No topics selected.</Typography>
                                            <Button variant="outlined" onClick={() => goExplore()}>Explore topics</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </>
                            :
                            <CurrentTopic />
                            }
                            </Grid>
                        </Grid>
                        {displayProfile ?
                        <Grid xs={12}>
                            <Grid sx={{padding: "10px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px"}}>
                                <Grid sx={{paddingLeft: "15px"}}>
                                    <Typography variant="h6">Activity</Typography>
                                    <Typography variant="body1" color="textSecondary">Last 7 days.</Typography>
                                </Grid>
                                <Grid container spacing={1} sx={{
                                    padding: "10px", 
                                    justifyContent: "flex-start",
                                    }}>
                                    <Grid sx={{
                                        overflowX: "auto",
                                        overflowY: "hidden",
                                        maxWidth: "calc(100vw - 80px)"
                                    }}>
                                    <Grid sx={{
                                    width: "650px",
                                    height: "350px",
                                    backgroundColor: "#ffffff",
                                    borderRadius: "15px", 
                                    borderLeft: "1px solid #cccccc66", 
                                    borderTop: "1px solid #cccccc66", 
                                    boxShadow: "3px 3px 3px #cccccc",
                                    }}>
                                    <ActivityChart />
                                    </Grid>
                                    </Grid>
                                    <Grid>
                                    <Grid sx={{
                                        width: "220px",
                                        height: "350px",
                                        backgroundColor: "#ffffff",
                                        padding: "10px",
                                        borderRadius: "15px", 
                                        borderLeft: "1px solid #cccccc66", 
                                        borderTop: "1px solid #cccccc66", 
                                        boxShadow: "3px 3px 3px #cccccc",
                                    }}>
                                        <Typography sx={{paddingTop: "5px", marginBottom: "40px"}} variant="body2">Weekly Goal</Typography>
                                        {weekScore >= 500 ?
                                        <Typography sx={{color: "#4caf50"}} variant="h4" paragraph  align="center">Smashed it.</Typography>
                                        :
                                        <Typography sx={{color: "#3f51b5"}} variant="h4" align="center">{500- weekScore} points to go.</Typography>
                                        }
                                        <Grid sx={{width: "80%", margin: "auto", marginBottom: "5px"}}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="100%"
                                            height="100%"
                                            version="1.1"
                                            viewBox="0 0 26.452 1.323"
                                            >
                                            <g transform="translate(-36.587 -85.033)">
                                                <motion.path
                                                fill="none"
                                                stroke="#ccc"
                                                strokeDasharray="none"
                                                strokeLinecap="butt"
                                                strokeLinejoin="miter"
                                                strokeMiterlimit="4"
                                                strokeOpacity="1"
                                                strokeWidth="1.323"
                                                d="M36.587 85.694h26.452"
                                                initial={{
                                                    pathLength: 0,
                                                }}
                                                animate={{
                                                    pathLength: 1,
                                                }}
                                                transition={{ease: "easeInOut",
                                                duration: 0.5,}}
                                                ></motion.path>
                                            </g>
                                            <g transform="translate(-36.587 -85.033)">
                                                <motion.path
                                                fill="none"
                                                stroke={weekScore < 500 ? "#3f51b5" : "#4caf50"}
                                                strokeDasharray="none"
                                                strokeLinecap="butt"
                                                strokeLinejoin="miter"
                                                strokeMiterlimit="4"
                                                strokeOpacity="1"
                                                strokeWidth="1.323"
                                                d="M36.587 85.694h26.452"
                                                initial={{
                                                    pathLength: 0,
                                                }}
                                                animate={{
                                                    pathLength: weekScoreCounter/500,
                                                }}
                                                transition={{ease: "easeInOut",
                                                duration: 1.5,}}
                                                ></motion.path>
                                            </g>
                                            </svg>
                                        </Grid>
                                        <Typography align="center" variant='body2'>{weekScoreCounter} / 500</Typography>
                                        {weekScore >= 500 ?
                                        <Typography variant="body 2" paragraph  align="center">Try and lap yourself?</Typography>
                                        :
                                        null
                                        }
                                        <Grid container sx={{width: "100%", justifyContent: "center", marginTop: "50px"}}>
                                        <Button size="small" disabled={true} variant="outlined" color="secondary" startIcon={<EditIcon fontSize='small' />}>
                                            Edit goal
                                        </Button>
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        null
                        }
                        <Grid xs={12}>
                            <Grid sx={{padding: "10px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px"}}>
                                <Grid sx={{paddingLeft: "15px"}}>
                                <Typography variant="h6">Scenarios</Typography>
                                <Typography variant="body1" color="textSecondary" paragraph>All scenarios.</Typography>
                                {getScenarioCount() > 0 ? 
                                <Grid sx={{
                                    padding: "10px"
                                }}>
                                <TableContainer component={Paper}>
                                <Table aria-label="scenario table" size="small">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="right">Score</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {Object.keys(userScenarios).map((scenarioID) => { 
                                        if (scenarioList.hasOwnProperty(scenarioID)) {
                                            // Scenarios that have been unpublished should not show in this list
                                            if (scenarioList[scenarioID]["status"] !== "unpublished") {
                                                return(
                                                <TableRow
                                                key={scenarioID}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                <TableCell scope="row">
                                                    {scenarioList[scenarioID]["scenarioName"]}
                                                </TableCell>
                                                <TableCell align="right" scope="row" sx={{width: "130px"}}>{userScenarios[scenarioID]["score"]}</TableCell>
                                                <TableCell align="right" scope="row" sx={{width: "140px"}}><Button size="small" variant="outlined" onClick={() => goPlay(scenarioID)}>Play again</Button></TableCell>
                                                </TableRow>
                                                )
                                            }
                                        }})}
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </Grid>
                                :
                                <Grid sx={{
                                    borderRadius: "15px",
                                    border: "1px dotted #cccccc",
                                    padding: "20px",
                                    width: "100%"
                                }}>
                                <Grid container sx={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                    <Grid>
                                        <Typography align="center" paragraph>No scenarios!</Typography>
                                        <Button variant="outlined" onClick={() => goExplore()}>Explore topics</Button>
                                    </Grid>
                                </Grid>
                                </Grid>
                                }
                                </Grid>
                            </Grid>
                            </Grid>
                        <Grid xs={12}>
                        <Grid sx={{padding: "10px", width: "100%", backgroundColor: "#fafafa", borderRadius: "15px"}}>
                            <Grid sx={{paddingLeft: "15px"}}>
                            <Typography variant="h6">Achievements</Typography>
                            <Typography variant="body1" color="textSecondary" paragraph>Available achievements. Play to progress.</Typography>
                            <Grid container spacing={1} sx={{
                                justifyContent: "flex-start",
                                [theme.breakpoints.down('md')]: {
                                    justifyContent: "center",
                                }
                                }}>
                            {Object.keys(achievementsList).map((achievementID,idx) => (
                                <Grid key={idx+"spacer"}>
                                <Grid key={idx} sx={{
                                    padding: "15px",
                                    width: "220px",
                                    height: "326px",
                                    borderLeft: "1px solid #cccccc66",
                                    borderTop: "1px solid #cccccc66",
                                    boxShadow: "3px 3px 3px #cccccc",
                                    backgroundColor: userAchievements.includes(achievementID) ? "#4caf50" : "#ffffff",
                                    borderRadius: "15px",
                                }}>
                                <Typography sx={{minHeight: "50px", color: userAchievements.includes(achievementID) ? "#ffffff" : "#000000"}} align="center" variant="body1" paragraph>{achievementsList[achievementID]['achievementName']}</Typography>
                                <Grid sx={{
                                    width: "80%",
                                    margin: "auto",
                                }}>
                                {userAchievements.includes(achievementID) ?
                                <AchievementsBadge badgeID={"complete"} />
                                :
                                <AchievementsBadge badgeID={achievementID} />
                                }
                                </Grid>
                                {userAchievements.includes(achievementID) ?
                                <Typography align="center" variant="body2" sx={{paddingTop: "5px", color: "#e0e0e0"}}>Complete: {achievementsList[achievementID]['achievementDescription']}</Typography>
                                :
                                <Typography align="center" variant="body2" color="textSecondary" sx={{paddingTop: "5px"}}>{achievementsList[achievementID]['achievementDescription']}</Typography>
                                }
                                </Grid>
                            </Grid>
                            ))}
                            </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </>
                }
            </Grid>
        </Grid>
    )
    };

export default Learn;

/*

                            {Object.keys(userScenarios).filter((scenarioID) => userScenarios[scenarioID]["score"] < 100).length === 0 ? 
                                <>
                                <Grid sx={{paddingLeft: "15px"}}>
                                    <Typography variant="h6">Continue Learning</Typography>
                                    <Typography variant="body1" color="textSecondary" paragraph>Pick up where you left off.</Typography>
                                    <Grid sx={{
                                        borderRadius: "15px",
                                        border: "1px dotted #cccccc",
                                        padding: "10px",
                                        height: "290px",
                                        flex: 1
                                    }}>
                                        <Grid container sx={{width: "100%", height: "100%", justifyContent: "center", alignItems: "center"}}>
                                            <Grid>
                                                <Typography align="center" paragraph>No scenarios!</Typography>
                                                <Button variant="outlined" onClick={() => goExplore()}>Explore topics</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </>
                                :


                                <Grid sx={{maxWidth: "100%", borderLeft: "10px solid #3f51b5", height: "100%"}}>
            <Grid sx={{maxWidth: "100%", borderLeft: "10px solid #e91e63", height: "100%", paddingLeft: "16px"}}>
</Grid>
            </Grid>

*/