import React from 'react';
import { useSelector } from 'react-redux'

import { Grid, Typography } from '@mui/material';
import PracticeNotesReader from './PracticeNotesReader';

import { useParams } from "react-router-dom";

const ScenarioHints = () => {
  
    let params = useParams();

    const scenarioID = params.scenarioID;
    // so need to make this work where no current selection
    const subTopicList = useSelector((state) => state.appData.subTopicList);
    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const currentSelection = useSelector((state) => state.appData.currentSelection);
    var subTopicID = ""
    if (currentSelection.subTopicID === "") {
      // if selection not set (e.g. came in on /play/ID directly, infer from topic. could just make play use other params...)
      subTopicID = scenarioList[scenarioID]["scenarioSubTopics"][0]
    } else {
      subTopicID = currentSelection.subTopicID
    };
    
    return (
        <Grid item sx={{width: "90%"}}>
        {subTopicList[subTopicID]["subTopicPracticeNotes"] ?
        <Grid item sx={{marginLeft: "20px", marginRight: "20px"}}>
        <Typography variant="h5" sx={{marginTop: "20px", marginBottom: "15px"}}>Practice Notes</Typography>
        <PracticeNotesReader subTopicPracticeNotes={subTopicList[subTopicID]["subTopicPracticeNotes"]["content"]} />
        </Grid>
        :
        <Typography variant='body1' sx={{marginTop: "20px"}}>
        Progress through the scenario by selecting options. Check the notes panel for information that you collect as you proceed.
        </Typography>
        }
        </Grid>
  );
}

export default ScenarioHints;