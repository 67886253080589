import React from 'react';
import { Grid, Typography, Button } from '@mui/material';

import { motion } from "framer-motion";
import { useSelector, useDispatch } from 'react-redux';

import { useNavigate } from "react-router-dom";

import BricksPerformant from './assets/BricksPerformant';

import { setCurrentSelection } from './appData/appDataSlice';


export default function ModuleCard(props) {

    // just build something simple to start with and then mess around with motion and whatnot.

    const dispatch = useDispatch();

    const subTopicList = useSelector((store) => store.appData.subTopicList);
    const skillsTree = useSelector((store) => store.appData.skillsTree);
    const scenarioList = useSelector((store) => store.appData.scenarioList);

    const subTopicID = props.subTopicID;
    const currentTopic = props.currentTopic;
    const currentPracticeArea = props.currentPracticeArea;

    var navigate = useNavigate();

    //console.log(subTopicID, subTopicList, currentTopic, currentPracticeArea)

    const idx = props.idx

    const goSubTopic = () => {
        navigate(`../../../explore/${currentPracticeArea}/${currentTopic}/${subTopicID}`);
        dispatch(setCurrentSelection({
            "selectionType":"subTopicID",
            "selection": subTopicID,
          }));
    };

    const numScenarios = skillsTree[currentPracticeArea]["topics"][currentTopic]["subTopics"][subTopicID]["scenarioCount"]
    const getSubTopicScore = () => {
        // get the scenarios for the current subtopic. get their number. look up scores.

        var subTopicTotalScore = 0
        var subTopicScore = 0
        
        if (skillsTree[currentPracticeArea]["topics"][currentTopic]["subTopics"][subTopicID].hasOwnProperty("scenarios")) {

        const scenarios = skillsTree[currentPracticeArea]["topics"][currentTopic]["subTopics"][subTopicID]["scenarios"]
        scenarios.forEach(scenarioID => {
            subTopicTotalScore += scenarioList[scenarioID]["userScore"]
        });
        subTopicScore = subTopicTotalScore / numScenarios
        };

        return subTopicScore
    };

    const subTopicScore = getSubTopicScore();
    const locked = subTopicList[subTopicID]["subTopicLocked"]

    const cardContainerMotion = {
        initial: {
            boxShadow: "3px 3px 3px #cccccc",
        },
        animate: {
            boxShadow: "3px 3px 3px #cccccc",
        },
        hover: {
            boxShadow: locked ? "3px 3px 3px #cccccc" : "3px 3px 3px #388e3c",
            transition: {
                duration: 0.3,
            }
        }
    };

    //backgroundSize:"64px 128px",

    const cardBottomMotion = {
        initial: {
            borderLeft: "1px solid #cccccc66",
        },
        animate: {
            borderLeft: "1px solid #cccccc66",
        },
        hover: {
            borderLeft: locked ? "1px solid #cccccc66" : "1px solid #388e3c",
            transition: {
                duration: 0.3,
            }
        }
    };

    const getSkillLevel = () => {
        const skillLevel = subTopicList[subTopicID]["skillLevel"] ? subTopicList[subTopicID]["skillLevel"] : "foundations"
        switch (skillLevel) {
            case "foundations":
              return (
                "Foundations"
              )
            case "experienced":
              return (
                "Experienced"
              )
            case "expert":
              return (
                "Expert"
              )
            default:
              return (
                "Foundations"
              )
          }
    }

    return (
        <Grid item  component={motion.div} initial="initial" whileHover="hover" whileTap="hover" animate="animate" variants={cardContainerMotion} sx={{
            margin: "15px",
            width: "200px",
            backgroundColor: "#ffffff",
            borderRadius: "15px",
        }}>
            <Grid item 
            sx={{
                width: "100%",
                height: "80px"
            }}>
            <BricksPerformant complete={subTopicScore} />
            </Grid>
            <Grid item  component={motion.div} variants={cardBottomMotion} sx={{
                width: "100%",
                height: "210px",
                borderBottomRightRadius: "15px",
                borderBottomLeftRadius: "15px",
            }}>
            <Grid container sx={{height: "100%", alignContent: "space-between"}}>
            <Grid item sx={{width: "100%", paddingLeft: "20px", paddingTop: "15px", paddingRight: "20px"}}>
                <Typography variant="body1">{subTopicList[subTopicID]["subTopicName"]}</Typography>
                {!locked ? 
                <>
                <Typography color="textSecondary" variant="body2">{getSkillLevel()}</Typography>
                {numScenarios ?
                <Typography color="textSecondary" variant="body2" paragraph>{numScenarios} Scenario{numScenarios > 1 ? "s" : ""}</Typography> 
                : 
                <Typography color="textSecondary" variant="body2" paragraph>0 Scenarios</Typography> 
                }
                </>
                :
                <Typography color="textSecondary" paragraph variant="body2">Coming soon.</Typography>
                }
            </Grid>
            <Grid item sx={{ width: "100%", paddingBottom: "30px"}}>
            <Grid container sx={{width: "100%", justifyContent: "center"}}>
            <Button variant="outlined" disabled={locked} data-cy={`module-button-${idx}`} onClick={() => goSubTopic()}>{subTopicScore === 0 ? "Start" : subTopicScore < 100 ? "Continue" : "Review"}</Button>
            </Grid>
            </Grid>
            </Grid>
            </Grid>
        </Grid>
    );
}

/*

                <Skill 
                    locked={locked} 
                    icon="assignment" 
                    subTopicScore={subTopicScore}
                />


                                <Grid item sx={{height: "45px"}}>
                {!locked ? <Typography color="textSecondary" paragraph align='center' variant="body2">{subTopicScore}% Complete</Typography> : null}
                </Grid>

*/