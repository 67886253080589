import { Button, 
    Grid, 
    Typography, 
    Snackbar, 
    Stack,
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,     
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    FormControl, 
    InputLabel, 
    TextField,
    Select, 
    MenuItem} from '@mui/material'
import { Alert as MuiAlert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';

import {v4 as uuidv4} from 'uuid';

import { updateScenarioProfile, cleanUpNodeActions } from './editorSlice';
import { updateScenarioList } from '../appData/appDataSlice';

// PDF docs sometime seem to produce console warnings about unknown functions - think this is react pdf related and just to do with dodgy fonts.

export default function ScenarioDocuments() {

    const dispatch = useDispatch();
    const scenarioProfile = useSelector((state) => state.editorData.scenarioProfile);
    const scenarioAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets);
    const userAccessToken = useSelector((state) => state.appData.accessToken);
    
    const [saveSuccess, setSaveSuccess] = useState(false); 
    const [deleteSuccess, setDeleteSuccess] = useState(false); 
    const [chooseFileData, setChooseFileData] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteDocumentID, setDeleteScenarioID] = useState("");
    const [createDocData, setCreateDocData] = useState(false);
    const [createDocName, setCreateDocName] = useState("Document 1");
    const [createDocType, setCreateDocType] = useState("redaction");

    const [scenarioOutline, setScenarioOutline] = useState(scenarioAssets.outline ? scenarioAssets.outline[0]["outlineID"] : "");

    const docTypeMap = {
        pdf: {label: "PDF", description: "Any PDF document. Users will not be able to interact with the document."},
        redaction: {label: "Redaction", description: "Redaction documents let users apply a redaction."},
        markup: {label: "Mark-up", description: "Mark-up documents let users accept or reject proposed changes to a document."},
    };

    const handleDeleteModalClose = () => {
        setDeleteModal(false);
    };

    const createDocument = () => {

        var scenarioAssetsUpdate = {...scenarioAssets}
        const newUUID = uuidv4();
        scenarioAssetsUpdate["documents"] = [{type: createDocType, documentID: newUUID, documentName: createDocName}]
        var profileUpdate = {
            scenarioAssets: scenarioAssetsUpdate
        };
        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            lastModified: Date.now(),
        };
        if (scenarioData.scenarioID === "") {
            const newScenarioUUID = uuidv4();
            scenarioData.scenarioID = newScenarioUUID;
            profileUpdate["scenarioID"] = newScenarioUUID;
        };

        const initialDocData = {
            documentID: newUUID,
            documentJSONData: [
                {
                  type: 'paragraph',
                  children: [{ text: 'Edit document contents.' }],
                }
            ]
        };

        const writeScenarioMetaData = async () => {
            try {
                const scenarioResponse = await fetch('/api/editor/saveScenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioData),
                });
    
                const responseData = await scenarioResponse.json()
                if (responseData.modified > 0 || responseData.upsertID !== "") {
                    dispatch(updateScenarioProfile(profileUpdate));
                    dispatch(updateScenarioList(scenarioData));
                }
            } 
            catch(e) {console.log(e)}
        };

        const writeDocumentData = async () => {
            try {
                const scenarioResponse = await fetch('/api/editor/savedocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(initialDocData),
                });
    
                const responseData = await scenarioResponse.json()
                
                if (responseData.insertedCount === 1) {
                    setSaveSuccess(true);
                    setCreateDocData(false);
                }
            }
            catch(e) {console.log(e)}
        };

        writeScenarioMetaData();
        writeDocumentData();

    }
    
    const uploadDocument = () => {

        var scenarioAssetsUpdate = {...scenarioAssets}
        const newUUID = uuidv4();
        scenarioAssetsUpdate["documents"] = [{type: "pdf", documentID: newUUID, documentName: chooseFileData.name}]
        var profileUpdate = {
            scenarioAssets: scenarioAssetsUpdate
        };
        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            lastModified: Date.now(),
        };
        if (scenarioData.scenarioID === "") {
            const newScenarioUUID = uuidv4();
            scenarioData.scenarioID = newScenarioUUID;
            profileUpdate["scenarioID"] = newScenarioUUID;
        }

        let formData = new FormData();
        formData.append("file", chooseFileData);
        formData.append("documentID", newUUID);
        
        const writeScenarioMetaData = async () => {
        try {
            const scenarioResponse = await fetch('/api/editor/saveScenario', {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(scenarioData),
            });

            const responseData = await scenarioResponse.json()
            if (responseData.modified > 0 || responseData.upsertID !== "") {
                dispatch(updateScenarioProfile(profileUpdate));
                dispatch(updateScenarioList(scenarioData));
            }
        } 
        catch(e) {console.log(e)}
        };

        const writeDocumentData = async () => {
            try {
                const scenarioResponse = await fetch('/api/editor/savedocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                },
                body: formData,
                });
    
                const responseData = await scenarioResponse.json()
                
                if (responseData.insertedCount === 1) {
                    setSaveSuccess(true);
                    setChooseFileData(null)
                }
            }
            catch(e) {console.log(e)}
        }
        writeScenarioMetaData();
        writeDocumentData();
    };

    const deleteDocument = () => {

        const {documents: currentDocuments, ...scenarioAssetsUpdate} = scenarioAssets

        const deleteDocumentFromDB = async () => {
            try {
                const scenarioRequest = {
                    documentID: deleteDocumentID, 
                    scenarioID: scenarioProfile.scenarioID,
                    scenarioAssetsUpdate: {"scenarioAssets": scenarioAssetsUpdate}
                }
                const scenarioResponse = await fetch('/api/editor/deletedocument', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioRequest),
                });
                const deleteData = await scenarioResponse.json()
                console.log(deleteData)
                setDeleteModal(false);
            }
            catch(e) {console.log(e)}
        };
        deleteDocumentFromDB();
        setDeleteSuccess(true)
        dispatch(updateScenarioProfile({"scenarioAssets": scenarioAssetsUpdate}));
        dispatch(cleanUpNodeActions({"cleanUpType": "deleteDocument"}))
    };

    const handleSaveClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSaveSuccess(false);
    };
    const handleDeleteClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setDeleteSuccess(false);
    };

    const handleFileSelect = (event) => {
        setCreateDocData(false);
        const fileList = event.target.files
        setChooseFileData(fileList[0])
    };

    const handleDocNameChange = (event) => {
        setCreateDocName(event.target.value)
    };

    const handleDocTypeSelect = (event) => {
        setCreateDocType(event.target.value)
    }

    const handleOutlineSelect = (event) => {
        setScenarioOutline(event.target.value);
        const outlineMap = {
            "FinAsAnalysis": {
                "outlineID": "FinAsAnalysis",
                "type": "js",
                "actions": [
                    {"label": "First limb", "inkLabel": "firstRule"},
                    {"label": "Second limb", "inkLabel": "secondRule"},
                    {"label": "Second limb - part A", "inkLabel": "twoA"},
                    {"label": "Second limb - part B", "inkLabel": "twoB"},
                    {"label": "Second limb - part C", "inkLabel": "twoC"},
                    {"label": "Second limb - part D", "inkLabel": "twoD"},
                    {"label": "Third limb", "inkLabel": "thirdRule"},
                    {"label": "Fourth limb", "inkLabel": "fourthRule"},
                ]
            },
            "PrivLegalAdviceAnalysis": {
                "outlineID": "PrivLegalAdviceAnalysis",
                "type": "js",
                "actions": [
                    {"label": "First limb", "inkLabel": "firstRule"},
                    {"label": "Second limb", "inkLabel": "secondRule"},
                    {"label": "Third limb", "inkLabel": "thirdRule"},
                    {"label": "Fourth limb", "inkLabel": "fourthRule"},
                ]
            },
            "PrivLitAnalysis": {
                "outlineID": "PrivLitAnalysis",
                "type": "js",
                "actions": [
                    {"label": "First limb", "inkLabel": "firstRule"},
                    {"label": "Second limb", "inkLabel": "secondRule"},
                    {"label": "Third limb", "inkLabel": "thirdRule"},
                    {"label": "Fourth limb", "inkLabel": "fourthRule"},
                    {"label": "Fifth limb", "inkLabel": "fifthRule"},
                ]
            },
        }

        var scenarioAssetsUpdate = {...scenarioAssets}
        scenarioAssetsUpdate["outline"] = [outlineMap[event.target.value]]
        var profileUpdate = {
            scenarioAssets: scenarioAssetsUpdate
        };
        var scenarioData = {
            ...scenarioProfile, 
            ...profileUpdate,
            lastModified: Date.now(),
        };
        if (scenarioData.scenarioID === "") {
            const newScenarioUUID = uuidv4();
            scenarioData.scenarioID = newScenarioUUID;
            profileUpdate["scenarioID"] = newScenarioUUID;
        }

        const writeScenarioMetaData = async () => {
            try {
                const scenarioResponse = await fetch('/api/editor/saveScenario', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(scenarioData),
                });
    
                const responseData = await scenarioResponse.json()
                if (responseData.modified > 0 || responseData.upsertID !== "") {
                    dispatch(updateScenarioProfile(profileUpdate));
                    dispatch(updateScenarioList(scenarioData));
                }
            } 
            catch(e) {console.log(e)}
        };
        writeScenarioMetaData();

    };

    const fileTypeMap = {
        "application/pdf": "PDF"
    };

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
    return (
        <Grid container sx={{padding: "10px", maxHeight: "100%"}}>
            <Grid item sx={{
                maxWidth: "600px", 
                flex: 1,
                backgroundColor: "#ffffff",
                padding: "20px",
                borderRadius: "15px", 
                borderLeft: "1px solid #cccccc66", 
                borderTop: "1px solid #cccccc66", 
                boxShadow: "3px 3px 3px #cccccc",
                }}>
            <Typography variant="h5" paragraph>Documents & Outlines</Typography>
            <Typography variant="h6" paragraph>Documents</Typography>
            <Typography paragraph color="textSecondary">Upload a PDF or create a document to include in your scenario.</Typography>
            {scenarioAssets.hasOwnProperty("documents") ? 
            <Grid container sx={{marginBottom: "15px"}}>
            <TableContainer >
                <Table aria-label="scenario table" size="small">
                    <TableHead>
                    <TableRow>
                        <TableCell>Document</TableCell>
                        <TableCell align="right">Type</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {scenarioAssets["documents"].map((document) => (
                        <TableRow
                        key={document.documentID}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell scope="row">
                            {document["documentName"]}
                        </TableCell>
                        <TableCell scope="row" align="right">
                            {docTypeMap[document["type"]]["label"]}
                        </TableCell>
                        <TableCell align="right">
                            <Button size="small" color="secondary" variant="outlined" onClick={() => {setDeleteModal(true); setDeleteScenarioID(document.documentID)}}>Delete</Button>
                        </TableCell>                        
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
            :
            null
            }
            {chooseFileData ? 
                <Grid container sx={{width: "100%", alignItems: "center", margin: "20px 0px 20px 0px", backgroundColor: "#fafafa", padding: "10px", borderRadius: "15px"}}>
                    <Grid item sx={{width: "75%", paddingRight: "20px"}}>
                    <Typography>{chooseFileData["name"]}</Typography>
                    </Grid>
                    <Grid item sx={{flex: 1}}>
                    <Typography >{fileTypeMap[chooseFileData["type"]]}</Typography>
                    </Grid>
                    <Grid item>
                    <Button size="small" variant='outlined' onClick={uploadDocument}>Upload</Button>
                    </Grid>
                </Grid>
            :
            null
            }
            {createDocData ?
            <Grid item sx={{width: "100%", marginBottom: "15px", backgroundColor: "#fafafa", padding: "10px", borderRadius: "15px"}}>
                <Grid item>
                <TextField
                    id="create-doc-name-input"
                    label="Document name"
                    value={createDocName}
                    onChange={handleDocNameChange}
                    variant="outlined"
                    size='small'
                    fullWidth
                    sx={{marginBottom: "15px", marginTop: "8px"}}
                />
                </Grid>
                <Grid item sx={{marginBottom: "15px"}}>
                <FormControl>
                <InputLabel id="select-doc-type-label">Document type</InputLabel>
                <Select
                labelId="select-doc-type-label"
                id="doc-type-select"
                size='small'
                value={createDocType}
                label="Document type"
                onChange={handleDocTypeSelect}
                >
                <MenuItem value="redaction">Redaction</MenuItem>
                <MenuItem value="markup">Mark-up</MenuItem>
                </Select>
                </FormControl>
                </Grid>
                <Grid item>
                <Typography paragraph color="textSecondary">{docTypeMap[createDocType]["description"]}</Typography>
                </Grid>
                <Grid item sx={{width: "100%"}}>
                    <Grid container sx={{width: "100%", justifyContent: "end"}}>
                    <Button size="small" variant='outlined' onClick={createDocument}>Create</Button>
                    </Grid>
                </Grid>
            </Grid>
            :
            null
            }
            <Stack direction="row" sx={{paddingBottom: "20px"}} spacing={2}>
            <Button variant="contained" disabled={scenarioAssets.hasOwnProperty("documents") || (chooseFileData ? true : false)} component="label" onChange={handleFileSelect} size="small">
            Select document
            <input hidden type="file" />
            </Button>
            <Button variant="contained" disabled={scenarioAssets.hasOwnProperty("documents") || createDocData} onClick={() => {setCreateDocData(true); setChooseFileData(null)}} size="small">
            Create document
            </Button>
            </Stack>
            <Typography variant="h6" paragraph>Outlines</Typography>
            <Typography paragraph color="textSecondary">Outlines are interactive summaries of a rule or process. Select one to include in your scenario.</Typography>
            <Grid container sx={{marginBottom: "10px"}}>
                <FormControl fullWidth>
                <InputLabel id="select-outline">Outline</InputLabel>
                <Select
                labelId="select-outline"
                id="outline-select"
                size='small'
                value={scenarioOutline}
                label="select-outline"
                onChange={handleOutlineSelect}
                >
                <MenuItem value="FinAsAnalysis">Financial Assistance: Elements of the Prohibition</MenuItem>
                <MenuItem value="PrivLegalAdviceAnalysis">Privilege: Legal Advice Privilege</MenuItem>
                <MenuItem value="PrivLitAnalysis">Privilege: Litigation Privilege</MenuItem>
                </Select>
                </FormControl>
            </Grid>
            <Snackbar open={saveSuccess} autoHideDuration={6000} onClose={handleSaveClose} sx={{width: "250px"}}>
            <Alert onClose={handleSaveClose} severity="success" sx={{width: "100%"}}>
                Document uploaded.
            </Alert>
            </Snackbar>
            <Snackbar open={deleteSuccess} autoHideDuration={6000} onClose={handleDeleteClose} sx={{width: "250px"}}>
            <Alert onClose={handleDeleteClose} severity="success" sx={{width: "100%"}}>
                Document deleted.
            </Alert>
            </Snackbar>
        </Grid>
        <Dialog
        open={deleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Confirm document deletion:
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" size="small" onClick={handleDeleteModalClose}>Cancel</Button>
            <Button variant="outlined" size="small" color="secondary" onClick={deleteDocument} >
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        </Grid>
    );
}