import React from 'react';
import { useSelector } from 'react-redux'
import { motion } from "framer-motion"

import { Grid, Stack, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ComputerIcon from '@mui/icons-material/Computer';

import Narrative from './story/Narrative';
import SpeechOther from './story/SpeechOther';
import SpeechYour from './story/SpeechYour';
import Heading from './story/Heading';
import SubHeading from './story/SubHeading';
import Fact from './story/Fact';
import FactMinor from './story/FactMinor';
import DocumentThumbnail from './story/DocumentThumbnail';
import PlayerError from './story/PlayerError';

import AIFeedbackForm from './AIFeedbackForm';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const ScenarioPlayback = (props) => {

  const loadSource = props.loadSource
  const theme = useTheme();

  const fullWidth = useMediaQuery(theme.breakpoints.up('sm'))
  const fullHeight = useMediaQuery('(min-height:610px)')
  const titleVariant = (fullWidth && fullHeight) ? "h3" : "h4"
  const subTitleVariant = (fullWidth && fullHeight) ? "h4" : "h5"

  // Set styles for story components

  const defaultStyles = {
    padding: "8px",
    marginBottom: "10px",
    boxShadow: "1px 1px 3px 0px #ccc",
    saturation: "saturate(60%)",
    typographyVariant: "body1"
  }
  
  const narrativeStyles = {
    backgroundColor: "#fafafa",
    borderBottomRightRadius: "10px",
    borderLeft: "4px solid",
    borderLeftColor: "#aaaaaa",
  }

  const otherStyles = {
    border: "3px solid #3f51b5",
    backgroundColor: "#fafafa",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: "-3px 3px 0px 0px #7986cb",
  }

  const yourStyles = {
    border: "3px solid #009688",
    boxShadow: "3px 3px 0px 0px #4db6ac",
    backgroundColor: "#fafafa",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  }

  const editorScenarioText = useSelector((state) => state.editorData.fullText)
  const scenarioText = useSelector((state) => state.choices.fullText)

  let fullText = []
  if (loadSource === "editor") {
      fullText = editorScenarioText
  } else {
      fullText = scenarioText
  }
  
  let fullTextLessFeedback = fullText.slice(0,-1)
  let finalKnot = fullText.slice(fullText.length-1)
  let newFinalKnot = []

  /*

  To do: set the session id
  Find a way to access block ID from feedback component.

  */

  for (const para of finalKnot[0]) {
      if (para.startsWith("_feedback_") || para.startsWith("_maxims_")) {
          break
      } else {
          newFinalKnot.push(para)
      }
  }
  fullTextLessFeedback.push(newFinalKnot)

  const InlineFeedback = (props) => {

      const feedbackType = props.feedbackType
      let feedbackContent = props.feedbackContent
      const feedbackMode = props.feedbackMode
      let AIBlockID = ""

      if (feedbackMode === "AI") {
        // This is all getting a bit messy
        AIBlockID = feedbackContent.split("_AIBlockID_")[1]
        feedbackContent = feedbackContent.split("_AIBlockID_")[0].split("_AIExplanation_")
      }

      // does this work?

      return(
          <Grid container sx={{width: "100%", justifyContent: "center"}}>
          <Grid container sx={{
              justifyContent: "center", 
              marginBottom: "28px",
              backgroundColor: "#ffffff",
              borderBottomRightRadius: "10px",
              boxShadow: feedbackType === "correct" ? "1px 1px 1px 2px #1b5e20" : "1px 1px 1px 2px #880e4f",
              width: "80%"
          }}>
              <Stack direction="row" spacing={1} sx={{width: "100%"}}>
              <Box sx={{
                  minWidth: "60px",
                  backgroundColor: feedbackType === "correct" ? "#4caf50" : "#e91e63"
              }}>
              {feedbackType === "correct" ?
              <CheckCircleOutlineIcon fontSize='large' sx={{color: "#ffffff", marginTop: "5px", marginLeft: "10px"}} />
              :
              <HighlightOffIcon fontSize='large' sx={{color: "#ffffff", marginTop: "5px", marginLeft: "10px"}} />
              }

              </Box>
              {feedbackMode === "AI" ? 
              <Box sx={{paddingBottom: "6px", paddingTop: "3px", paddingRight: "3px"}}>
                {feedbackContent.length === 3 ?
                <>
                <Typography paragraph>{feedbackContent[0]}</Typography>
                <Typography paragraph>Reference answer:</Typography>
                <Typography paragraph>{feedbackContent[1]}</Typography>
                </>
                :
                <>
                <Typography paragraph>Reference answer:</Typography>
                <Typography paragraph>{feedbackContent[0]}</Typography>
                </>
              }
                <Box sx={{backgroundColor: "#f3f3f3", padding: "6px", borderBottomRightRadius: "8px"}}>
                <Stack direction="row" spacing={1} sx={{marginBottom: "6px"}}>
                <ComputerIcon fontSize='small' />
                <Typography paragraph>Dynamic Feedback:</Typography>
                </Stack>
                <Typography>{feedbackContent[feedbackContent.length - 1]}</Typography>  
                </Box>
                {loadSource !== "editor" ? <AIFeedbackForm AIBlockID={AIBlockID} /> : null}
              </Box>
              :
              <Box sx={{paddingBottom: "6px", paddingTop: "3px", paddingRight: "3px"}}>
                <Typography>{feedbackContent}</Typography>
              </Box>
              }
              </Stack>
          </Grid>
          </Grid>
      )
  };

  // Define regexes outside the function
  const regexPatterns = {
    narrative: new RegExp('_narrative_'),
    speechOther: new RegExp('_speechOther_'),
    speechOtherTextResponse: new RegExp('_speechOtherTextResponse_'),
    speechYour: new RegExp('_speechYour_','g'),
    speechYourTextInput : new RegExp('_speechYourTextInput_'),
    heading: new RegExp('_heading_','g'),
    subHeading: new RegExp('_subHeading_'),
    fact: new RegExp('_fact_'),
    factMinor: new RegExp('_factMinor_'),
    documentThumbnailNeutral: new RegExp('_documentThumbnailNeutral_'),
    feedback: new RegExp('_feedback_'),
    maxims: new RegExp('_maxims_'),
    AIPromptInput: new RegExp('_AIPromptInput_'),
    playerError: new RegExp('_playerError_'),
    inlineFeedback: new RegExp('_inlineFeedback'),
  };

  const parseText = (paraText, idx) => {

    if (regexPatterns.narrative.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.narrative, "");
      
      return (
        <Narrative stripTags={stripTags} delay={0} defaultStyles={defaultStyles} narrativeStyles={narrativeStyles} key={"narrative"+idx} />
      )
    };
    if (regexPatterns.speechOther.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechOther, "");
      
      return (
        <SpeechOther stripTags={stripTags} delay={0} defaultStyles={defaultStyles} otherStyles={otherStyles} key={"speechother"+idx} />
      )
    };
    if (regexPatterns.speechOtherTextResponse.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechOtherTextResponse, "");
      
      return (
        <SpeechOther stripTags={stripTags} delay={0} defaultStyles={defaultStyles} otherStyles={otherStyles} key={"speechOtherTextResponse"+idx} />    
      )
    };
    if (regexPatterns.speechYour.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechYour, "").split("_inlineFeedback")
      let feedbackType = ""
      let feedbackContent = ""
      if (stripTags.length > 1) {
        if (stripTags[1].startsWith("Correct_")) {
            feedbackType = "correct"
            feedbackContent = stripTags[1].slice(8)
        }
        if (stripTags[1].startsWith("Incorrect_")) {
            feedbackType = "incorrect"
            feedbackContent = stripTags[1].slice(10)
        }
      }
      
      return (
        <Grid item key={"speechYourPlayback"+idx} sx={{width: "100%"}}>
        <SpeechYour stripTags={stripTags[0]} delay={0} defaultStyles={defaultStyles} yourStyles={yourStyles} key={"speechYour"+idx} />
        {stripTags.length > 1 ?
        <InlineFeedback feedbackType={feedbackType} feedbackContent={feedbackContent} feedbackMode="human" idx={idx}/>
        :
        null
        }
        </Grid>
      )
    };
    if (regexPatterns.speechYourTextInput.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.speechYourTextInput, "");

      return (
        <SpeechYour stripTags={stripTags} delay={0} defaultStyles={defaultStyles} yourStyles={yourStyles} key={"speechYourTextInput"+idx} />
      )
    };
    if (regexPatterns.heading.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.heading, "");

      return (
        <Heading stripTags={stripTags} delay={0} otherStyles={otherStyles} headingSaturate="saturate(80%)" typographyVariant={titleVariant} key={"heading"+idx} />
      )
    };
    if (regexPatterns.subHeading.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.subHeading, "");

      return (
        <SubHeading stripTags={stripTags} delay={0} otherStyles={otherStyles} headingSaturate="saturate(80%)" typographyVariant={subTitleVariant} key={"subHeading"+idx} />
      )
    };
    if (regexPatterns.fact.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.fact, "");
      
      return (
        <Fact stripTags={stripTags} defaultStyles={defaultStyles} delay={0} key={"fact"+idx} />
      )
    };
    if (regexPatterns.factMinor.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.factMinor, "");
      
      return (
        <FactMinor stripTags={stripTags} defaultStyles={defaultStyles} delay={0} key={"factMinor"+idx} />
      )
    };
    if (regexPatterns.documentThumbnailNeutral.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.documentThumbnailNeutral, "").replaceAll("\n", "")
      
      return (
        <DocumentThumbnail stripTags={stripTags} defaultStyles={defaultStyles} delay={0} readOnly={true} loadSource={loadSource} key={"documentThumbnail"+idx} />
      )
    };
    if (regexPatterns.playerError.test(paraText)) {
      const stripTags = paraText.replace(regexPatterns.playerError, "");
      
      return (
        <PlayerError stripTags={stripTags} defaultStyles={defaultStyles} delay={0} key={"playError"+idx} />
      )
    };
    if (regexPatterns.AIPromptInput.test(paraText)) {
      return
    };
    if (paraText.startsWith("_inlineFeedback")) {

      // This is to handle feedback associated with actions and AI dialogue (that don't have a _speechYour_ tag)
      
      let feedbackType = ""
      let feedbackContent = ""
      let feedbackMode = ""
      if (paraText.startsWith("_inlineFeedbackCorrect_")) {
          feedbackType = "correct"
          feedbackMode = "human"
          feedbackContent = paraText.slice(23)
      }
      if (paraText.startsWith("_inlineFeedbackIncorrect_")) {
          feedbackType = "incorrect"
          feedbackMode = "human"
          feedbackContent = paraText.slice(25)
      }
      if (paraText.startsWith("_inlineFeedbackAICorrect_")) {
          feedbackType = "correct"
          feedbackMode = "AI"
          feedbackContent = paraText.slice(25)
      }
      if (paraText.startsWith("_inlineFeedbackAIIncorrect_")) {
          feedbackType = "incorrect"
          feedbackMode = "AI"
          feedbackContent = paraText.slice(27)
      }
      return (
          <InlineFeedback feedbackType={feedbackType} feedbackContent={feedbackContent} feedbackMode={feedbackMode} key={"otherInlineFeedback" + idx} idx={idx}/>
      )
    }
    if (["\n","_setHintsVisible_\n","_setPrimarySourcesVisible_\n","_coverImage_\n"].includes(paraText)) {
      // skip any UI flags and stray line breaks generated by ink
      return
    } else {
      return (
        <Narrative stripTags={paraText} delay={0} defaultStyles={defaultStyles} narrativeStyles={narrativeStyles} key={"narrative"+idx} />
      )
    }
  };

  return (
      <Grid container sx={{display: 'flex', flexGrow: 1, minWidth: "100%", marginBottom: "10px"}} component={motion.div} layout={"position"}>
        {fullTextLessFeedback.flat(1).map((paraText, idx) => (
          parseText(paraText,idx)
        ))}
      </Grid>
  );
}

export default ScenarioPlayback;