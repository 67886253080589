import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

/*

get user activity
get up to three that have < 100%
if none, say "get started" and give up to three from skills tree
bit of work to do on linking and overal design

name is date (short)
start, get the and timestamp for 6 days ago
maybe, start now. go back to midnight. add any activity in that time to 

*/


export default function ActivityChart() {

  const userActivity = useSelector((store) => store.appData.userData.userActivity)
  const monthMap = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  }

  const getActivityData = () => {
    var activityData = []
    const today = new Date(Date.now())
    const todayStart = new Date(Date.now())
    todayStart.setHours(0)
    todayStart.setMinutes(0)


    var relevantActivity = Object.keys(userActivity).filter((timeStamp) => (today - Number(timeStamp) <= 7*24*60*60*1000)).map((string) => Number(string))

    for (let i=0; i<=6; i++) {
      const preDayEpoch = todayStart - i*24*60*60*1000
      const preDay = new Date(preDayEpoch)
      const preDayString = preDay.getDate() + " " + monthMap[preDay.getMonth()]
      var dayScore = 0
      const dayActivity = relevantActivity.filter((timeStamp) => ((preDayEpoch <= timeStamp) && (timeStamp < preDayEpoch+24*60*60*1000)))
      dayActivity.forEach((activity) => {
        dayScore += userActivity[activity.toString()]["score"]
      })
      activityData.push({
        name: preDayString,
        startEpoch: preDay,
        score: dayScore,
      })
    }

    activityData.sort((a,b) => a.startEpoch - b.startEpoch)

    return (activityData)
  }
    
return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={getActivityData()}
          margin={{
            top: 10,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="score" fill="#3f51b5" name="Daily points" />
        </BarChart>
      </ResponsiveContainer>
);
}