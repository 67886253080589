import { motion } from "framer-motion";
import React from 'react';

const RingProgress = (props) => {

    const complete = props.complete

    const getColor = (complete) => {
        if (complete >= 75) {
            //return "#4caf50"
            return "#1b5e2080"
        };
        if (complete < 75 && complete >= 25) {
            return "#ffc10780"
        }
        if (complete < 25) {
            return "#e91e6380"
        }
    };

    const circleLineDraw = {
        initial: { 
            pathLength: 0,
            rotate: 270,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            stroke: "#e91e6380",
        },
        final: { 
            pathLength: complete / 100,
            rotate: 270,
            stroke: getColor(complete),
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: {
                ease: "easeInOut",
                duration: 1.2,
            }
        },
        initialTrack: { 
            pathLength: 0,
            rotate: 270,
            stroke: "#cccccc40",
            strokeLinecap: "round",
            strokeLinejoin: "round",
        },
        finalTrack: { 
            pathLength: 1,
            rotate: 270,
            stroke: "#cccccc40",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            transition: {
                ease: "easeInOut",
                duration: 0.8,
            }
        }
    };

    return (
        <motion.svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            version="1.1"
            viewBox="0 0 26.458 26.458"
        >
            <motion.circle
                initial="initialTrack"
                animate="finalTrack"
                variants={circleLineDraw}
                cx="50%"
                cy="50%"
                fill= "#ffffff"
                fillOpacity="0"
                strokeWidth= "6"
                strokeMiterlimit="3.9"
                strokeOpacity="1"
                r="8"
            />
            <motion.circle
                initial="initial"
                animate="final"
                variants={circleLineDraw}
                cx="50%"
                cy="50%"
                fill= "#ffffff"
                fillOpacity="0"
                strokeWidth= "6"
                strokeMiterlimit="3.9"
                strokeOpacity="1"
                r="8"
            />
        </motion.svg>
    )
    };
export default RingProgress;