import { Grid, Typography, Stack, IconButton, Box, Divider, Paper} from '@mui/material';
import { motion } from 'framer-motion';

import { Editable, withReact, Slate, useSlate, ReactEditor } from 'slate-react'
import {
  Editor,
  Transforms,
  Node,
  createEditor,
} from 'slate'

import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { setEditorPlayerDocumentData, setEditorDocumentEvaluation } from '../editor/editorSlice';
import { setPlayerDocumentData, setDocumentEvaluation } from '../choice/choiceSlice';

const MarkUpPlayer = (props) => {

  const initialDocData = Array.isArray(props.initialDocData) ? props.initialDocData : [{type: 'paragraph',children: [{ text: 'Edit document contents.' },],},]
  const loadSource = props.loadSource;
  const dispatch = useDispatch();
  

  /*********************************************

  Slate editor

  *********************************************/


  const toggleMarkUp = (editor, path, markUp) => {

    // Set player property and run eval.

    const targetNode = Node.get(editor, path)
    let evalNodes = []
    let index = 0

    if (targetNode.playerMarkUp === markUp) {
      Transforms.setNodes(editor, {playerMarkUp: "unset"}, {at: path})

      // unset will always be wrong from an eval perspective
      Transforms.setNodes(editor, {evalMarkUp: "unset"}, {at: path})

    } else {
      Transforms.setNodes(editor, {playerMarkUp: markUp}, {at: path})
      if (targetNode.goldMarkUp === markUp) {
        Transforms.setNodes(editor, {evalMarkUp: "correct"}, {at: path})
      } else {
        Transforms.setNodes(editor, {evalMarkUp: "incorrect"}, {at: path})
      }
    };

    // need a complete list of markUpNodes, so loop through.
    // conceptually, this should be about capturing the player's state - what has the player done in the scenario
    // rather than trying to pre-empt the evaluation
    // because will deal with that in branches in the scenario
    // so go back to redaction and check consistent.

    for (const [node, nodePath] of Editor.nodes(editor, {at: [], match: (node, nodePath) => node.goldMarkUp})) {
      evalNodes.push({inkLabel: "markup"+index.toString(), evaluation: node.playerMarkUp });
      index ++
    }

    // save changes to player copy. so currently this re-renders on every action... feel like there is a better way to do this?

    const updateData = {
      documentJSONData: editor.children
    }
    if (loadSource === "editor") {
      dispatch(setEditorDocumentEvaluation(evalNodes));
      dispatch(setEditorPlayerDocumentData(updateData));
    } else {
      dispatch(setDocumentEvaluation(evalNodes));
      dispatch(setPlayerDocumentData(updateData));
    }
  };
  
  const Element = ({ attributes, children, element }) => {
    const alignVariant = element.align
    const borderColor = element.playerMarkUp === "accept" ? "#388e3c" : element.playerMarkUp === "reject" ? "#d32f2f" : "#fcfdff"

    let numberedListStyle = "decimal"
    if (element.listLevel === 0) {
      numberedListStyle = "decimal"
    };
    if (element.listLevel === 1) {
      numberedListStyle = "upper-alpha"
    };
    if (element.listLevel === 2) {
      numberedListStyle = "lower-roman"
    };
    if (element.listLevel > 2) {
      numberedListStyle = "lower-alpha"
    };

    switch (element.type) {
      case 'bulleted-list':
        return (
          <ul {...attributes} style={{marginTop: "3px", marginBottom: "3px"}}>
            {children}
          </ul>
          
        )
      case 'heading-one':
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
          <Box
            component={motion.div}
            sx={{
              flex: 1, 
              border: `1px solid ${borderColor}`,
              padding: "2px",
              paddingBottom: "6px",
              borderRadius: "3px",
              transition: 'border 0.5s ease'
              }}
          >
          <Typography align={alignVariant} variant='h6' {...attributes}>
            {children}
          </Typography>
          </Box>
          <Box sx={{width: "80px"}}>
          {element?.goldMarkUp ? 
          <>
          <Stack direction="row">
            <ApplyMarkUpButton format="accept" element={element} attributes={attributes}/>
            <ApplyMarkUpButton format="reject" element={element} attributes={attributes}/>
          </Stack>
          </> :
          null }
          </Box>
          </Stack>
        )
      case 'list-item':
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />} sx={{marginBottom: "3px"}}>
          <Box
            component={motion.div}
            sx={{
              flex: 1, 
              border: `1px solid ${borderColor}`,
              padding: "2px",
              paddingBottom: "6px",
              borderRadius: "3px",
              transition: 'border 0.5s ease'
              }}
          >
            <Typography variant='body2' component="li" {...attributes} sx={{paddingLeft: "10px"}}>
            {children}
            </Typography>
          </Box>
          <Box sx={{width: "80px"}}>
          {element?.goldMarkUp ? 
          <>
          <Stack direction="row">
            <ApplyMarkUpButton format="accept" element={element} attributes={attributes}/>
            <ApplyMarkUpButton format="reject" element={element} attributes={attributes}/>
          </Stack>
          </> :
          null }
          </Box>
          </Stack>
        )
      case 'numbered-list':
        return (
          <ol style={{listStyleType: numberedListStyle, marginTop: "3px", marginBottom: "3px"}} {...attributes}>
            {children}
          </ol>
        )
      default:
        
        return (
          <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />} sx={{marginBottom: "5px"}}>
          <Box 
            component={motion.div}
            sx={{
              flex: 1, 
              border: `1px solid ${borderColor}`,
              padding: "2px",
              paddingBottom: "6px",
              borderRadius: "3px",
              transition: 'border 0.5s ease'
              }}
          >
            <Typography align={alignVariant} variant='body2' {...attributes} >
              {children}
            </Typography>
          </Box>
          <Box sx={{width: "80px"}}>
          {element?.goldMarkUp ? 
          <>
          <Stack direction="row">
            <ApplyMarkUpButton format="accept" element={element} attributes={attributes}/>
            <ApplyMarkUpButton format="reject" element={element} attributes={attributes}/>
          </Stack>
          </> :
          null }
          </Box>
          </Stack>
        )
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {

    if (leaf.bold) {
      children = <strong>{children}</strong>
    };
    if (leaf.italic) {
      children = <em>{children}</em>
    };
    if (leaf.underline) {
      children = <u>{children}</u>
    };
    if (leaf.addText) {
      children = <ins style={{ textDecoration: 'underline #3f51b5', color: '#3f51b5'}}>{children}</ins>
    };
    if (leaf.removeText) {
      children = <del style={{ textDecoration: 'line-through #f44336', color: '#f44336' }}>{children}</del>
    };
    return (
      <span style={{
        paddingLeft: leaf.text === '' ? "0.1px" : null
      }} {...attributes}>{children}</span>
    )
  };

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  const editor = useMemo(() => withReact(createEditor()), [])

  const ApplyMarkUpButton = React.forwardRef((props, ref) => {
    const editor = useSlate()
    const path = ReactEditor.findPath(editor, props.element);
    const targetNode = Node.get(editor, path)

    // use this path to set properties.
    // implement an isactive type function here to set style

    return(
      <IconButton onMouseDown={event => {
        event.preventDefault()
        toggleMarkUp(editor, path, props.format)
        }}
        size="small"
        sx={{
          color: (targetNode.playerMarkUp === "accept" && props.format === "accept") ? "#388e3c" : (targetNode.playerMarkUp === "reject" && props.format === "reject") ? "#d32f2f" : null}}
      >
          {props.format === "accept" ? <CheckCircleOutlineIcon /> : null}
          {props.format === "reject" ? <HighlightOffIcon /> : null}
      </IconButton>
    )
  });

  return (
  <Grid item sx={{width: "100%"}}>
      <Slate editor={editor} initialValue={initialDocData}>
      <Typography align="center" variant='body2' color="textSecondary" paragraph>Use the right hand buttons to accept or reject the relevant changes.</Typography>
      <Paper elevation={3}
        sx={{
          paddingLeft: "22px",
          paddingRight: "22px",
          paddingTop: "32px",
          paddingBottom: "32px",
          backgroundColor: "#fcfdff",
      }}
      >
      <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          readOnly
      />
      </Paper>
      </Slate>
  </Grid>
  )
  };
export default MarkUpPlayer;