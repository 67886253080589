import React from 'react';
import { useSelector } from 'react-redux'

import { Grid, Typography } from '@mui/material';
import PDFDisplay from './PDFDisplay';

import { useParams } from "react-router-dom";
import RedactionDocument from './slateDocs/RedactionDocument';
import MarkUpDocument from './slateDocs/MarkUpDocument';

export default function ScenarioDocuments(props) {

    let params = useParams();
    const scenarioID = params.scenarioID;
    const loadSource = props.loadSource;
    const documentArray = props.documentArray;

    const scenarioList = useSelector((state) => state.appData.scenarioList)
    const scenarioEditorAssets = useSelector((state) => state.editorData.scenarioProfile.scenarioAssets)
    var scenarioAssets = {}

    // where to look, depends on if used in player or in editor mode

    if (loadSource === "editor") {
        scenarioAssets = {...scenarioEditorAssets}
    } else {
        scenarioAssets = {...scenarioList[scenarioID]["scenarioAssets"]}
    };

    const selectedDocumentID = useSelector((state) => state.choices.scenarioUI.selectedDocumentID)
    let selectedDocument = {}

    // check if scenario assets has a documents resource
    // check if selectedID exists and if so return document, otherwise return first document
    if (scenarioAssets.hasOwnProperty("documents")){
        selectedDocument = scenarioAssets.documents.filter(document => document.documentID === selectedDocumentID).length === 1 ? scenarioAssets.documents.filter(document => document.documentID === selectedDocumentID)[0] : scenarioAssets.documents[0]
    };

    return (
        <Grid item sx={{height: "100%"}}>
            {scenarioAssets.hasOwnProperty("documents") ?
            <Grid item style={{width: "100%", height: "100%", paddingTop: scenarioAssets.documents.length > 1 ? 45 : 12}}>
                {selectedDocument.type === "pdf" ?
                    <PDFDisplay document={selectedDocument} loadSource={loadSource} documentArray={documentArray} />
                    :
                    null
                }
                {selectedDocument.type === "redaction" ? 
                    <Grid item style={{marginTop: 30}}>
                    <RedactionDocument loadSource={loadSource} />
                    </Grid>
                    :
                    null
                }
                {selectedDocument.type === "markup" ? 
                    <Grid item style={{marginTop: 30}}>
                    <MarkUpDocument loadSource={loadSource} />
                    </Grid>
                    :
                    null
                }
            </Grid>
            :
            <Typography variant='body1' sx={{marginBottom: "20px"}}>No documents.</Typography>
            }
        </Grid>
    );
}