import React from 'react';
import { Grid, IconButton, InputBase, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

/*

This is a container for the explore pages (topic and subtopic)

*/


const SearchBar = () => {

    const theme = useTheme();
    
    return (
        <Grid item>
            <Grid container sx={{width: "100%", justifyContent: "center"}}>
            <Stack direction="row" sx={{
                borderRadius: "3px", 
                backgroundColor: "#fafafa35"}}>
                <InputBase
                    sx={{ 
                        color: "#fafafa",
                        marginLeft: theme.spacing(1),
                        [theme.breakpoints.up('sm')]: {
                            width: "350px",
                        },
                        [theme.breakpoints.down('sm')]: {
                            width: "auto"
                        }
                    }}   
                    size="small"
                    placeholder='Search...'
                    inputProps={{ 'aria-label': 'Search' }}
                    disabled={true}
                />
                <IconButton type="button" size="small" aria-label="search">
                <SearchIcon sx={{color: "#fafafa35"}} />
                </IconButton>
            </Stack>
            </Grid>
        </Grid>
    )
    };

export default SearchBar;