import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { motion } from "framer-motion";

import { Grid, Typography, Button, Divider, Box, Stack, Fab } from '@mui/material';

import FeedbackForm from './FeedbackForm';
import RedactionEval from './slateDocs/RedactionEval';
import MarkUpEval from './slateDocs/MarkUpEval';
import ScenarioPlayback from './ScenarioPlayback';
import ScenarioCompleteRoundel from './ScenarioCompleteRoundel';

import ComputerIcon from '@mui/icons-material/Computer';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';

import { loadGame } from './choice/choiceSlice';
import { setFeedbackType, clearFeedbackContent } from './appData/appDataSlice';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Confetti from 'react-confetti';
import AIFeedbackForm from './AIFeedbackForm';

import { useNavigate, useParams } from "react-router-dom";

const ScenarioComplete = () => {

  const theme = useTheme();
  const dispatch = useDispatch();
  let params = useParams();

  const scenarioID = params.scenarioID;
  const currentSelection = useSelector((state) => state.appData.currentSelection);
  const userScenarios = useSelector((state) => state.appData.userData.userScenarios);
  const scenarioList = useSelector((state) => state.appData.scenarioList);
  const subTopicList = useSelector((state) => state.appData.subTopicList);
  const topicList = useSelector((state) => state.appData.topicList);

  const scenarioScore = userScenarios.hasOwnProperty(scenarioID) ? userScenarios[scenarioID]["score"] : 0
  const userAccessToken = useSelector((state) => state.appData.accessToken);
  const pageID = scenarioList[scenarioID]["pageID"];

  // Only display incorrect feedback in side panel, but feedback content will include all AI content
  const feedbackContent = useSelector((state) => state.appData.userData.feedbackContent);
  const scenarioFeedback = feedbackContent.filter(f => !f.startsWith("_inlineFeedbackAICorrect_"))

  const scenarioPlayerDoc = useSelector((state) => state.choices.playerDocument);
  const scenarioAssets = scenarioList[scenarioID]["scenarioAssets"];
  
  const [reviewSolution, setReviewSolution] = useState(false);
  const [solutionView, setSolutionView] = useState("scenario");
  const fullWidth = useMediaQuery(theme.breakpoints.up('md'))
  const fullHeight = useMediaQuery('(min-height:610px)')

  const solutionViewMap = {
    "scenario": "document",
    "document": "scenario"
  }

  const toggleSolutionView = () => {
    setSolutionView(solutionViewMap[solutionView])
  };
  const toggleReviewSolution = () => {
    setReviewSolution(!reviewSolution)
  };

  let docsForReview = false;

  if (scenarioAssets.hasOwnProperty("documents")) {
    if (scenarioAssets["documents"][0]["type"] !== "pdf") {
      docsForReview = true
    }
  };

  var navigate = useNavigate();

  const playAgain = () => {
    const loadData = {
      scenarioID: scenarioID, 
      userAccessToken: userAccessToken, 
      loadLocation: scenarioList[scenarioID].hasOwnProperty("scenarioEditable") ? "inkString" : "wiki",
      pageID: pageID
    }
    dispatch(loadGame(loadData));
    dispatch(setFeedbackType(""));
    dispatch(clearFeedbackContent());
  };

  const reviewScenario = () => {
    if (currentSelection.subTopicID === "") {

      // if selection not set (e.g. came in on /play/ID directly, infer from topic. could just make play use other params...)

      const subTopicID = scenarioList[scenarioID]["scenarioSubTopics"][0]
      const topicID = subTopicList[subTopicID]["parentTopics"][0]
      const practiceAreaID = topicList[topicID]["topicPracticeAreas"][0]
      navigate(`/explore/${practiceAreaID}/${topicID}/${subTopicID}`)

    } else if (scenarioScore !== 100) {

      const wiki = scenarioList[scenarioID]["scenarioAssets"].hasOwnProperty("wiki") ? "/" + scenarioList[scenarioID]["scenarioAssets"]["wiki"] : ""
      navigate(`/explore/${currentSelection.practiceAreaID}/${currentSelection.topicID}/${currentSelection.subTopicID}${wiki}`);
      
      } else {
        navigate(`/explore/${currentSelection.practiceAreaID}/${currentSelection.topicID}/${currentSelection.subTopicID}`)
      }
  }

  const playNext = () => {
    if (currentSelection.subTopicID === "") {
      // if selection not set (e.g. came in on /play/ID directly, infer from topic. could just make play use other params...)

      const subTopicID = scenarioList[scenarioID]["scenarioSubTopics"][0]
      const topicID = subTopicList[subTopicID]["parentTopics"][0]
      const practiceAreaID = topicList[topicID]["topicPracticeAreas"][0]
      navigate(`/explore/${practiceAreaID}/${topicID}/${subTopicID}`)
      
    } else {
      navigate(`/explore/${currentSelection.practiceAreaID}/${currentSelection.topicID}/${currentSelection.subTopicID}`);
    }
  };

  const getColor = (complete) => {
      if (complete >= 75) {
          return "#4caf50"
      };
      if (complete < 75 && complete >= 25) {
          return "#ffc107"
      }
      if (complete < 25) {
          return "#e91e63"
      }
  };

  const getContrastColor = (complete) => {
      if (complete >= 75) {
          return "#1b5e20"
      };
      if (complete < 75 && complete >= 25) {
          return "#ff6f00"
      }
      if (complete < 25) {
          return "#880e4f"
      }
  };

  const AIFeedbackHint = (props) => {
    // Only being passed "incorrect" feedback

    const content = props.content
    const AIBlockID = content.split("_AIBlockID_")[1]
    let parsedContent = content.split("_AIBlockID_")[0].split("_AIExplanation_")
    if (parsedContent.length === 3) {
      // Right answer, wrong explanation case.
      return (
        <Box sx={{paddingBottom: "6px", paddingTop: "3px", paddingRight: "3px"}}>
          <Typography paragraph>{parsedContent[0]}</Typography>
          <Typography paragraph>Reference answer:</Typography>
          <Typography paragraph>{parsedContent[1]}</Typography>
          <Box sx={{backgroundColor: "#f3f3f3", padding: "6px", borderBottomRightRadius: "8px"}}>
          <Stack direction="row" spacing={1} sx={{marginBottom: "6px"}}>
          <ComputerIcon fontSize='small' />
          <Typography paragraph>Dynamic Feedback:</Typography>
          </Stack>
          <Typography>{parsedContent[2]}</Typography>  
          </Box>
          <AIFeedbackForm AIBlockID={AIBlockID} />
        </Box>
      )
    } else {
      return (
        <Box sx={{paddingBottom: "6px", paddingTop: "3px", paddingRight: "3px"}}>
          <Typography paragraph>Reference answer:</Typography>
          <Typography paragraph>{parsedContent[0]}</Typography>
          <Box sx={{backgroundColor: "#f3f3f3", padding: "6px", borderBottomRightRadius: "8px"}}>
          <Stack direction="row" spacing={1} sx={{marginBottom: "6px"}}>
          <ComputerIcon fontSize='small' />
          <Typography paragraph>Dynamic Feedback:</Typography>
          </Stack>
          <Typography>{parsedContent[1]}</Typography>  
          </Box>
          <AIFeedbackForm AIBlockID={AIBlockID} />
        </Box>
      )      
    }
  };

  return (
    <Grid container style={{justifyContent: "center", paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}}>
      {scenarioScore === 100 && <Confetti recycle={false} width={window.screen.width - 50} />}
      <Grid item sx={{width: "100%", paddingBottom: "20px"}}>
        <Typography align='center' variant='h4' sx={{color: getColor(scenarioScore)}}>Scenario Complete</Typography>
      </Grid>
      <Grid item style={{width: "100%", border: "1px solid #eee", borderRadius: "10px"}}>
        <Grid item sx={{paddingTop: "12px", paddingBottom: "12px", paddingLeft: "18px", paddingRight: "18px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px", backgroundColor: getColor(scenarioScore)}}>
          <Typography variant='h5' sx={{color: getContrastColor(scenarioScore)}}>{scenarioScore < 100 ? "Feedback" : "Learning notes"}</Typography>
        </Grid>
        <Grid container>
          {fullWidth ?
          <>
          <Grid item 
          sx={{
            width: "30%", 
            paddingLeft: "8px", 
            paddingRight: "8px", 
            paddingTop: "14px", 
            maxHeight: fullHeight ? "650px" : "450px",
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: "20px",
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: "#ffffff",
              borderTopRightRadius: "10px",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: "#d6dee1",
              borderRadius: "20px",
              border: "6px solid transparent",
              backgroundClip: "content-box",
            },
            '&::-webkit-scrollbar-thumb-hover': {
              backgroundColor: "#a8bbbf",
            },
            }}>
          <ScenarioCompleteRoundel scenarioScore={scenarioScore} />
          <Divider variant='middle' />
          <Box sx={{padding: "8px"}}>
          {scenarioScore === 100 ? 
          <>
          <Typography variant="h6" color="textSecondary" align='center' sx={{paddingBottom: "6px"}}>Notes</Typography>
          {scenarioFeedback.map((content, idx) => {
            return (
              <Typography paragraph variant='body1' key={"feedbacktext" + idx}>{content}</Typography>
            )
          })}
          </>
          :
          <>
          {scenarioFeedback.length > 0 ?
            <>
            <Typography variant="h6" color="textSecondary" align='center' sx={{paddingBottom: "6px"}}>Hints</Typography>
            {scenarioFeedback[0].startsWith("_inlineFeedbackAIIncorrect_") ? 
            <AIFeedbackHint content={scenarioFeedback[0].slice(27)} />
            :
            <Typography paragraph variant='body1'>{scenarioFeedback[0]}</Typography>
            }
            </>
          :
          null
          }
          </>
          }
          </Box>
          </Grid>
          <Grid item sx={{width: "70%"}}>
          {docsForReview ? 
          <Grid container style={{width: "100%", justifyContent: "flex-end"}}>
          <Grid item 
            sx={{position: "absolute", margin: "5px", marginRight: "20px", zIndex: 1000}} 
          >
          <Fab 
              color="primary" 
              variant="extended"
              size="small"
              onClick={() => toggleSolutionView()}
            >
              <TextSnippetIcon sx={{marginRight: "3px"}} fontSize="small" />
              {solutionView === "document" ? "Document Solution" : "Scenario Solution"}
          </Fab>
          </Grid>
          </Grid>
          :
          null
          }
          {solutionView === "scenario" ?
            <Grid item component={motion.div} 
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: "easeInOut",
                duration: 1,
              }
            }}
            sx={{
              background: "linear-gradient(135deg, #f3f3f3 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px),\nlinear-gradient(225deg, #f3f3f3 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px)0 64px",
              backgroundColor:"#f3f3f3",
              backgroundSize:"64px 128px",
              maxHeight: fullHeight ? "650px" : "450px",
              padding: "8px",
              overflow: "auto",
              
              '&::-webkit-scrollbar': {
                width: "20px",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: "#f3f3f3",
                borderTopRightRadius: "10px",
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
              },
              '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
              },
            }}>
            <ScenarioPlayback loadSource="player" />
            </Grid>
            :
            <Grid item sx={{
              padding: "8px", 
              maxHeight: fullHeight ? "650px" : "450px",
              overflow: "auto",
              '&::-webkit-scrollbar': {
                width: "20px",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: "#ffffff",
                borderTopRightRadius: "10px",
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
              },
              '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
              },
            }}>
            {scenarioAssets["documents"][0]["type"] === "redaction" ?
            <RedactionEval initialDocData={scenarioPlayerDoc} />
            :
            null            
            }
            {scenarioAssets["documents"][0]["type"] === "markup" ?
            <MarkUpEval initialDocData={scenarioPlayerDoc} />
            :
            null
            }
            </Grid>
          }
          </Grid>
          </>
          :
          <>
          {reviewSolution ?
          <Grid item sx={{width: "100%"}}>
          {docsForReview ? 
          <Grid container style={{width: "100%", justifyContent: "flex-end"}}>
          <Grid item 
            sx={{position: "absolute", margin: "5px", marginRight: "20px", zIndex: 1000}} 
          >
          <Fab 
              color="primary" 
              variant="extended"
              size="small"
              onClick={() => toggleSolutionView()}
            >
              <TextSnippetIcon sx={{marginRight: "3px"}} fontSize="small" />
              {solutionView === "document" ? "Document Solution" : "Scenario Solution"}
          </Fab>
          </Grid>
          </Grid>
          :
          null
          }
          {solutionView === "scenario" ?
            <Grid item component={motion.div} 
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1,
              transition: {
                ease: "easeInOut",
                duration: 1,
              }
            }}
            sx={{
              background: "linear-gradient(135deg, #f3f3f3 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px),\nlinear-gradient(225deg, #f3f3f3 22px, #ced7db 22px, #ced7db 22px, transparent 24px, transparent 68px, #ced7db 67px, #ced7db 69px, transparent 69px)0 64px",
              backgroundColor:"#f3f3f3",
              backgroundSize:"64px 128px",
              maxHeight: fullHeight ? "650px" : "450px",
              padding: "8px",
              overflow: "auto",
              
              '&::-webkit-scrollbar': {
                width: "20px",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: "#f3f3f3",
                borderTopRightRadius: "10px",
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
              },
              '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
              },
            }}>
            <ScenarioPlayback loadSource="player" />
            </Grid>
            :
            <Grid item sx={{
              padding: "8px", 
              maxHeight: fullHeight ? "650px" : "450px",
              overflow: "auto",
              '&::-webkit-scrollbar': {
                width: "20px",
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: "#ffffff",
                borderTopRightRadius: "10px",
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: "#d6dee1",
                borderRadius: "20px",
                border: "6px solid transparent",
                backgroundClip: "content-box",
              },
              '&::-webkit-scrollbar-thumb-hover': {
                backgroundColor: "#a8bbbf",
              },
            }}>
            {scenarioAssets["documents"][0]["type"] === "redaction" ?
            <RedactionEval initialDocData={scenarioPlayerDoc} />
            :
            null            
            }
            {scenarioAssets["documents"][0]["type"] === "markup" ?
            <MarkUpEval initialDocData={scenarioPlayerDoc} />
            :
            null
            }
            </Grid>
          }
          </Grid>
          :
          <Grid item 
          sx={{
            width: "100%", 
            paddingLeft: "8px", 
            paddingRight: "8px", 
            paddingTop: "14px", 
            maxHeight: fullHeight ? "650px" : "450px",
            overflow: "auto",
            '&::-webkit-scrollbar': {
              width: "20px",
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: "#ffffff",
              borderTopRightRadius: "10px",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: "#d6dee1",
              borderRadius: "20px",
              border: "6px solid transparent",
              backgroundClip: "content-box",
            },
            '&::-webkit-scrollbar-thumb-hover': {
              backgroundColor: "#a8bbbf",
            },
            }}>
          <ScenarioCompleteRoundel scenarioScore={scenarioScore} />
          <Divider variant='middle' />
          <Box sx={{padding: "8px"}}>
          {scenarioScore === 100 ? 
          <>
          <Typography variant="h6" color="textSecondary" align='center' sx={{paddingBottom: "6px"}}>Notes</Typography>
          {scenarioFeedback.map((content, idx) => {
            return (
              <Typography paragraph variant='body1' key={"feedbacktext" + idx}>{content}</Typography>
            )
          })}
          </>
          :
          <>
          {scenarioFeedback.length > 0 ?
            <>
            <Typography variant="h6" color="textSecondary" align='center' sx={{paddingBottom: "6px"}}>Hints</Typography>
            {scenarioFeedback[0].startsWith("_inlineFeedbackAIIncorrect_") ? 
            <AIFeedbackHint content={scenarioFeedback[0].slice(27)} />
            :
            <Typography paragraph variant='body1'>{scenarioFeedback[0]}</Typography>
            }
            </>
          :
          null
          }
          </>
          }
          </Box>
          <Grid container sx={{justifyContent: "center", marginBottom: "20px"}}>
          <Button 
            variant="outlined" 
            color="primary"
            onClick={() => toggleReviewSolution()}
            >
            Review Solution
          </Button>
          </Grid>
          </Grid>
          }
          </>
          }
        </Grid>
      </Grid>

      <Stack direction="column" spacing={2} sx={{width: "100%", marginTop: "40px"}}>
        {scenarioScore === 100 ?
        <Grid container sx={{justifyContent: "center"}}>
          <Button 
            variant="contained" 
            color="primary"
            onClick={() => playNext()}
            >
            Continue
          </Button>
        </Grid>
        :
        <Grid container sx={{justifyContent: "center"}}>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => playAgain()}
          >
          Play again
        </Button>
        </Grid>
        }
        <Grid container sx={{justifyContent: "center"}}>
        <Button 
          variant="outlined" 
          color="primary"
          onClick={() => reviewScenario()}
          >
          Review Scenarios
        </Button>
        </Grid>
      </Stack>

      <Grid item sx={{width: "50%", marginTop: "40px", marginBottom: "40px"}}>
        <Divider variant="middle" />
      </Grid>

      <Grid item>
      <FeedbackForm />
      </Grid>
    </Grid>
  )
  };
export default ScenarioComplete;