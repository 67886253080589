import React, {useRef} from 'react';

import { Grid, Typography, Stack, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RepeatIcon from '@mui/icons-material/Repeat';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import ReplayIcon from '@mui/icons-material/Replay';

const AuthorGuide = () => {


    const refIntroduction = useRef(null)
    const refBlocks = useRef(null)
    const refAddingblocks = useRef(null)
    const refDeletingblocks = useRef(null)
    const refEditingblocks = useRef(null)
    const refBlocktypes = useRef(null)
    const refScenarioblocks = useRef(null)
    const refActionblocks = useRef(null)
    const refActionBlockConditions = useRef(null)
    const refActionBlockActions = useRef(null)
    const refActionBlockOrderOfExecution = useRef(null)
    const refAIblocks = useRef(null)
    const refAIExplanations = useRef(null)
    const refAIQuestions = useRef(null)
    const refOtherspecialblocks = useRef(null)
    const refOptions = useRef(null)
    const refAddingoptions = useRef(null)
    const refDeletingoptions = useRef(null)
    const refOptiontypes = useRef(null)
    const refHowoptionsbehave = useRef(null)
    const refOptionscanbeselectedonlyonce = useRef(null)
    const refOnlythreeoptionsaredisplayedatonce = useRef(null)
    const refScoring = useRef(null)
    const refFeedback = useRef(null)
    const refOnlyonepieceofincorrectfeedbackpresented = useRef(null)
    const refFeedbackandLearningPoints = useRef(null)
    const refFeedbackinActionBlocks = useRef(null)
    const refAddingDocuments = useRef(null)
    const refAddPDFDocuments = useRef(null)
    const refAddInteractiveDocuments = useRef(null)
    const refRedactionDocuments = useRef(null)
    const refMarkupDocuments = useRef(null)
    const refAddingOutlines = useRef(null)
    const refAddingPrimarySources = useRef(null)
    const refSavingandLoading = useRef(null)
    const refSharingandPublishing = useRef(null)
    const refSharing = useRef(null)
    const refPublishing = useRef(null)
    
   const scrollToHeading = (heading) => {
        heading.current?.scrollIntoView({ behavior: "smooth" })
   }

    return (
        <Grid item>
        <Typography variant='h5' sx={{marginBottom: "8px"}}>Contents</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refIntroduction)} sx={{'&:hover': {cursor: 'pointer'}}}>1. Introduction</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refBlocks)} sx={{'&:hover': {cursor: 'pointer'}}}>2. Blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddingblocks)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>2.1 Adding blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refDeletingblocks)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>2.2 Deleting blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refEditingblocks)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>2.3 Editing blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refBlocktypes)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>2.4 Block types</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refScenarioblocks)}  sx={{marginLeft: "16px", '&:hover': {cursor: 'pointer'}}}>2.4.1 Scenario blocks</Typography>
        <Typography variant='body2' color="primary" sx={{marginLeft: "16px", '&:hover': {cursor: 'pointer'}}} onClick={() => scrollToHeading(refActionblocks)}>2.4.2 Rule blocks</Typography>
        <Typography variant='body2' color="primary" sx={{marginLeft: "24px", '&:hover': {cursor: 'pointer'}}} onClick={() => scrollToHeading(refActionBlockConditions)}>2.4.2.1 Conditions</Typography>
        <Typography variant='body2' color="primary" sx={{marginLeft: "24px", '&:hover': {cursor: 'pointer'}}} onClick={() => scrollToHeading(refActionBlockActions)}>2.4.2.2 Actions</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refActionBlockOrderOfExecution)}  sx={{marginLeft: "24px", '&:hover': {cursor: 'pointer'}}}>2.4.2.3 Order of execution</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAIblocks)}  sx={{marginLeft: "16px", '&:hover': {cursor: 'pointer'}}}>2.4.3 AI blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAIExplanations)}  sx={{marginLeft: "24px", '&:hover': {cursor: 'pointer'}}}>2.4.3.1 Explanation Blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAIQuestions)}  sx={{marginLeft: "24px", '&:hover': {cursor: 'pointer'}}}>2.4.3.2 Question Blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refOtherspecialblocks)}  sx={{marginLeft: "16px", '&:hover': {cursor: 'pointer'}}}>2.4.4 Other special blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refOptions)} sx={{'&:hover': {cursor: 'pointer'}}}>3. Options</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddingoptions)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>3.1 Adding options</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refDeletingoptions)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>3.2 Deleting options</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refOptiontypes)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>3.3 Option types</Typography>
        <Typography variant='body2' color="primary" sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}} onClick={() => scrollToHeading(refHowoptionsbehave)}>3.4 How options behave</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refOptionscanbeselectedonlyonce)}  sx={{marginLeft: "16px", '&:hover': {cursor: 'pointer'}}}>3.4.1 Options can be selected only once</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refOnlythreeoptionsaredisplayedatonce)}  sx={{marginLeft: "16px", '&:hover': {cursor: 'pointer'}}}>3.4.2 Only three options are displayed at once</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refScoring)} sx={{'&:hover': {cursor: 'pointer'}}}>4. Scoring</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refFeedback)} sx={{'&:hover': {cursor: 'pointer'}}}>5. Feedback</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refOnlyonepieceofincorrectfeedbackpresented)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>5.1 Only one hint presented at a time</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refFeedbackandLearningPoints)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>5.2 Feedback and Learning Points</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refFeedbackinActionBlocks)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>5.3 Set feedback in rule blocks</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddingDocuments)} sx={{'&:hover': {cursor: 'pointer'}}}>6. Adding Documents</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddPDFDocuments)} sx={{marginLeft: "8px",'&:hover': {cursor: 'pointer'}}}>6.1 PDFs</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddInteractiveDocuments)} sx={{marginLeft: "8px",'&:hover': {cursor: 'pointer'}}}>6.2 Interactive Documents</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refRedactionDocuments)} sx={{marginLeft: "16px",'&:hover': {cursor: 'pointer'}}}>6.2.1 Redaction Documents</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refMarkupDocuments)} sx={{marginLeft: "16px",'&:hover': {cursor: 'pointer'}}}>6.2.2 Mark-up Documents</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddingOutlines)} sx={{'&:hover': {cursor: 'pointer'}}}>7. Adding Outlines</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refAddingPrimarySources)} sx={{'&:hover': {cursor: 'pointer'}}}>8. Adding Primary Sources</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refSavingandLoading)} sx={{'&:hover': {cursor: 'pointer'}}}>9. Saving and Loading</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refSharingandPublishing)} sx={{'&:hover': {cursor: 'pointer'}}}>10. Sharing and Publishing</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refSharing)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>10.1 Sharing</Typography>
        <Typography variant='body2' color="primary" onClick={() => scrollToHeading(refPublishing)}  sx={{marginLeft: "8px", '&:hover': {cursor: 'pointer'}}}>10.2 Publishing</Typography>
        <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "16px"}} ref={refIntroduction}>1. Introduction</Typography>
            <Typography variant='body2' paragraph>The Editor tool lets you create and edit scenarios for the Legal Reflex platform.</Typography>
            <Typography variant='body2' paragraph>Scenarios take the form of 'choose your own adventure' games, where users navigate a legal topic. In the Editor, scenarios are represented as flows of blocks and connections, which set out the different routes a user can take through the scenario. Each "scene" in the scenario is represented by a "block" and the options presented to users are represented by connections between blocks.</Typography>
            <Typography variant='body2' paragraph>There are three parts to the Editor tool:</Typography>
            <Grid item sx={{marginLeft: "8px"}}>
            <Stack direction="row" spacing={2}>
                <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                    <Grid item>
                    <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                    </Grid>
                </Grid>
                <Typography variant='body2'>
                    The toolbar on the far left of the screen lets you control the scenario's settings.
                </Typography>
            </Stack>
            </Grid>
            <Grid item sx={{marginLeft: "8px"}}>
            <Stack direction="row" spacing={2}>                
                <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                    <Grid item>
                    <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                    </Grid>
                </Grid>
                <Typography variant='body2'>
                    The Editor window on the left of the screen is where you design and edit your scenario.
                </Typography>
            </Stack>
            </Grid>
            <Grid item sx={{marginLeft: "8px"}}>
            <Stack direction="row" spacing={2}>
                <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                    <Grid item>
                    <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                    </Grid>
                </Grid>
                <Typography variant='body2'>
                    The Preview window on the right of the screen allows you to test and play your scenario.
                </Typography>
            </Stack>
            </Grid>
        <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refBlocks}>2. Blocks</Typography>
            <Typography variant='body2' paragraph>Each block represents a "scene" in the scenario. A scene is generally made up of lines of dialogue and a number of different options for the user to select, or some other way for the user to provide their input. In the scenario, the user will select an option and be presented with the block connected to that option.</Typography>
            <Typography variant='body2' paragraph>Blocks have two connectors:</Typography>
            <Grid item sx={{marginLeft: "8px"}}>
            <Stack direction="row" spacing={2}>
                <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                    <Grid item>
                    <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                    </Grid>
                </Grid>
                <Typography variant='body2'>
                An "incoming" connector on the top of the block. These are the options that lead to this block.
                </Typography>
            </Stack>
            </Grid>
            <Grid item sx={{marginLeft: "8px"}}>
            <Stack direction="row" spacing={2}>
                <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                    <Grid item>
                    <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                    </Grid>
                </Grid>
                <Typography variant='body2'>
                An "outgoing" connector at the bottom of the block. These are the options available to the user in the block.
                </Typography>
            </Stack>
            </Grid>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddingblocks}>2.1 Adding blocks</Typography>
                <Typography variant='body2' paragraph>There are two ways to add a block to a scenario:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>
                        1)
                        </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                    From an existing block, click and drag a line (an Option) from the bottom connector. When you release the mouse, a new block will be created.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>
                        2)
                        </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                    In the block editor, click "add option". A new block (connected to the existing block) will be created.
                    </Typography>
                </Stack>
                </Grid>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refDeletingblocks}>2.2 Deleting blocks</Typography>
                <Typography variant='body2' paragraph>To delete a block, click the {true ? <DeleteIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} trash icon in the top right. The block and all its contents will be deleted (not this action cannot be un-done). Any options connecting to the block will also be deleted.</Typography>
                <Typography variant='body2' paragraph>The "Title", "Introduction" and "Completion" blocks cannot be deleted.</Typography>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refEditingblocks}>2.3 Editing blocks</Typography>
                <Typography variant='body2' paragraph>Select the {true ? <EditIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} edit icon on a block to open the block editor. From the block editor, you can change the block's type, set the block's content, options and feedback, and add comments.</Typography>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refBlocktypes}>2.4 Block types</Typography>
                <Typography variant='body2' paragraph>There are different types of block. Toggle the "type" of the block by clicking the {true ? <RepeatIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} toggle icon in the top right of the block editor.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refScenarioblocks}>2.4.1 Scenario blocks</Typography>
                    <Typography variant='body2' paragraph>Scenario blocks are the default block. A scenario block contains up to four lines of content and up to five options.</Typography>
                    <Typography variant='body2' paragraph>The block's content will be displayed when a user arrives at the block. You can add or remove content using the {true ? <AddCircleOutlineIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null}, {true ? <RemoveCircleOutlineIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} and {true ? <DeleteIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} buttons.</Typography>
                    <Typography variant='body2' paragraph>There are different types of content which are controlled by the {true ? <TextFormatIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} format toggle. You can toggle the style of each line of content by clicking the format button. The different styles are:</Typography>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Dialogue: a blue chat style message, representing things said by another character in the scenario.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Alert: a neutral message to provide non-scenario information to the user.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Media: an icon. Document and steps plan icons will, when clicked by the user, open the "Documents" panel. The "outline" icon will, when clicked by the user, open the "Outline" panel.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Narrative: a neutral message to provide scenario information to the user.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Your dialogue: a teal chat style message, representing things said by the user's character.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Typography variant='body2' paragraph>The block editor also gives you access to the options and feedback associated with a block. See below for more detail about options and feedback.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refActionblocks}>2.4.2 Rule blocks</Typography>
                    <Typography variant='body2' paragraph>Rule blocks can be used to control the scenario in more advanced ways. They enable you to configure the flow of the scenario and (for example)  to respond to a user interaction with documents.</Typography>
                    <Typography variant='body2' paragraph>Each rule block is made up of one or more rules. Each rule consists of a condition (when the rule should be applied) and an action (what should be done if the condition is satisfied).</Typography>
                        <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refActionBlockConditions}>2.4.2.1 Conditions</Typography>
                        <Typography variant='body2' paragraph>By default, a rule’s condition is set to “Always”: the rule’s action will always be applied. You can set other conditions:</Typography>
                        <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                        <Stack direction="row" spacing={2}>
                            <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                                <Grid item>
                                <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                                </Grid>
                            </Grid>
                            <Typography variant='body2'>
                            Visited block: the action will be applied if the selected block has / has not been visited, as configured.
                            </Typography>
                        </Stack>
                        </Grid>
                        <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                        <Stack direction="row" spacing={2}>
                            <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                                <Grid item>
                                <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                                </Grid>
                            </Grid>
                            <Typography variant='body2'>
                            User document: the action will be applied if the user has interacted with the document in the relevant way. For example, if a user has or has not redacted a relevant portion of the document.
                            </Typography>
                        </Stack>
                        </Grid>
                        <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refActionBlockActions}>2.4.2.2 Actions</Typography>
                        <Typography variant='body2' paragraph>Rules can apply a number of different actions:</Typography>
                        <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                        <Stack direction="row" spacing={2}>
                            <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                                <Grid item>
                                <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                                </Grid>
                            </Grid>
                            <Typography variant='body2'>
                            Go to: the user will be taken to the selected block (note, a target block must be connected to the action block for it to be the subject of a go to action.
                            </Typography>
                        </Stack>
                        </Grid>
                        <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                        <Stack direction="row" spacing={2}>
                            <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                                <Grid item>
                                <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                                </Grid>
                            </Grid>
                            <Typography variant='body2'>
                            Set outline: if an outline has been added to the scenario, actions can be used to control the outline’s display. For example, marking a limb as “met” or “not met”.
                            </Typography>
                        </Stack>
                        </Grid>
                        <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                        <Stack direction="row" spacing={2}>
                            <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                                <Grid item>
                                <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                                </Grid>
                            </Grid>
                            <Typography variant='body2'>
                            Set feedback: manually set feedback at the given point in the scenario.
                            </Typography>
                        </Stack>
                        </Grid>
                        <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refActionBlockOrderOfExecution}>2.4.2.3 Order of execution</Typography>
                        <Typography variant='body2' paragraph>In general, all of the rules set in an action block will be executed when the user encounters the block. There is an exception for “go to” actions. If a “go to” action is triggered, no further rules will be executed.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAIblocks}>2.4.3 AI blocks</Typography>
                <Typography variant='body2' paragraph>AI blocks allow a user to provide a free text input. Currently, there are two types of AI block, enabling two different types of user behaviour.</Typography>
                <Typography variant='body2' paragraph>When a user passes through an AI block, they will be presented with a text input box. The user's input, together with other information specified in the AI block is then evaluated by an AI and contributes to scoring.</Typography>
                <Typography variant='body2' paragraph>Feedback on AI blocks consists of:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The AI’s evaluation of the user’s input.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The reference information configured in the block.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The AI’s explanation for its evaluation.
                    </Typography>
                </Stack>
                </Grid>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAIExplanations}>2.4.3.1 Explanation Blocks</Typography>
            <Typography variant='body2' paragraph>Explanation blocks can be used to have a user explain their reasoning. For example, a user might conclude that a certain legal test is met. An explanation block can then be used to prompt the user to explain why the test is met (and evaluate whether their explanation is correct).</Typography>
            <Typography variant='body2' paragraph>To configure an explanation block, you must set:</Typography>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        A topic: This is the topic or label that the AI will refer to in its feedback.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The user's analysis: The conclusion that the user has reached having selected the relevant option.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The correct analysis. The correct analysis of the situation.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The correct explanation: The correct explanation against which the user's explanation will be evaluated.
                    </Typography>
                </Stack>
                </Grid>
            <Typography variant='body2' paragraph>When a user provides their input, the AI will assess whether an explanation has been given (in which case it will advance to the next scene), or if further information is needed (in which case it will ask for this information).</Typography>
            <Typography variant='body2' paragraph>An example pattern for using an explanation block is to set up a question in which the user’s choices are “Yes, because…” or “No, because…”. These choices are marked correct / incorrect and then each lead to their own AI explanation block. In the correct limb, the “user analysis” and the “correct analysis” will be the same (e.g. “The test is met”). In the incorrect limb, they will differ.</Typography>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAIQuestions}>2.4.3.2 Question Blocks</Typography>
            <Typography variant='body2' paragraph>Question blocks can be used to have a user suggest questions that would advance a legal analysis. For example, a user might conclude it is unclear whether a legal test is met. They can then be asked to provide a question (or questions) that would help them come to a conclusion.</Typography>
            <Typography variant='body2' paragraph>To configure a question block, you must set:</Typography>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        A topic: This is the topic or label that the AI will refer to in its feedback.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        The topic background: Background context on why there is uncertainty and the types of topics that a user should be asking questions about.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Example questions: Example questions that would be appropriate for the user to ask.
                    </Typography>
                </Stack>
                </Grid>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refOtherspecialblocks}>2.4.4 Other special blocks</Typography>
                <Typography variant='body2' paragraph>There are two other special blocks:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Title block: The scenario begins at the title block, which controls the title and subtitle for the scenario. The title block cannot be deleted and always links to Scenario block ID: 2.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Completion block: The scenario ends at the "Complete" block. The completion block cannot be edited and cannot be deleted.
                    </Typography>
                </Stack>
                </Grid>
        <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refOptions}>3. Options</Typography>
            <Typography variant='body2' paragraph>Options, the connections between blocks, enable users to make choices and move between scenes.</Typography>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddingoptions}>3.1 Adding options</Typography>
                <Typography variant='body2' paragraph>There are two ways of adding an option to a block.</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>1)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Click and drag from the block's bottom connector. If you link this line to another block, an option will be created between them. If you do not link the line to another block, a new block will be created when you release the click.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>2)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        From the block editor, click "add option". This will create a new block and an option linking the current block with this new block.
                    </Typography>
                </Stack>
                </Grid>
                <Typography variant='body2' paragraph>Note, a block can have at most five options leading from it (it can have an unlimited number of incoming options).</Typography>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refDeletingoptions}>3.2 Deleting options</Typography>
                <Typography variant='body2' paragraph>Delete an option by clicking and selecting it near a connector and releasing it away from the block.</Typography>
                <Typography variant='body2' paragraph>If you delete a block, all the options leading to it will be deleted too.</Typography>
            <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refOptiontypes}>3.3 Option types</Typography>
                <Typography variant='body2' paragraph>Just as there are different content types within a block, there are different option types which change how the option is displayed.</Typography>
                <Typography variant='body2' paragraph>Change the option's type by clicking on the {true ? <TextFormatIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} format toggle. The different types are:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>1)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Your Dialogue (default): the option is displayed to the user as a plain text button. When the option is selected, its text is added to the scene in the form of a chat message sent from the user's character (teal).
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>2)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Action: the option is displayed to the user as a plain text button. When it is selected, the scenario advances to the next scene without adding any new text to the scene.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>3)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Pass through: When a block has a pass through option, the scenario will automatically advance to the next scene without input from the user.
                    </Typography>
                </Stack>
                </Grid>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refHowoptionsbehave}>3.4 How options behave</Typography>
                    <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refOptionscanbeselectedonlyonce}>3.4.1 Options can be selected only once</Typography>
                        <Typography variant='body2' paragraph>When a user selects an option, it is removed from future selections: if a user returns to a block later in the scenario, they will only be presented with options that they have not already selected.</Typography>
                    <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refOnlythreeoptionsaredisplayedatonce}>3.4.2 Only three options are displayed at once</Typography>
                        <Typography variant='body2' paragraph>Although a block can have up to five options, only the first three will be displayed to the user. The fourth and fifth options will only be accessible once the user has already selected some of the initial options.</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refScoring}>4. Scoring</Typography>
                <Typography variant='body2' paragraph>Scenarios are scored by setting options as "correct" or "incorrect". The total possible score for a scenario is the sum of all the "correct" options in the scenario. Therefore, to score 100%, a user needs to have selected all the correct options.</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refFeedback}>5. Feedback</Typography>
                <Typography variant='body2' paragraph>Feedback is an important part of the scenario: providing useful, actionable feedback enables users to learn.</Typography>
                <Typography variant='body2' paragraph>To set feedback for an option:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        In the block editor, click the expand arrow.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        You will see one feedback input for each option in the block.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        By default, options are "neutral", meaning that they do not contribute to scoring and no feedback is connected with them.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        You can change an option to be "correct" or "incorrect" by clicking the {true ? <ThumbsUpDownIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} icon.
                    </Typography>
                </Stack>
                </Grid>
                <Typography variant='body2' paragraph>Options that are marked "correct" or "incorrect" must be given written feedback. This is the text that is displayed to the user at the end of the scenario, having selected that option.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refOnlyonepieceofincorrectfeedbackpresented}>5.1 Only one hint presented at a time</Typography>
                    <Typography variant='body2' paragraph>If a user selects multiple incorrect options, the scenario “hints” will only contain the feedback associated with the first incorrect option they selected. This highlights the actionable step the user can take to improve their score. The full set of feedback associated with the scenario is available to the user in the scenario review.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refFeedbackandLearningPoints}>5.2 Feedback and Learning Points</Typography>
                    <Typography variant='body2' paragraph>If a user scores 100% on a scenario, they will be presented with the scenario learning points, not the feedback associated with the options they selected.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refFeedbackinActionBlocks}>5.3 Set feedback in rule blocks</Typography>
                    <Typography variant='body2' paragraph>Feedback can also be configured using rule blocks. This can be useful in situations where you want to present different feedback depending on different combinations of user actions. See above for more details.</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddingDocuments}>6. Adding Documents</Typography>
                <Typography variant='body2' paragraph>You can add documents to your scenario for a user to refer to. Documents can either be static (pdfs) or interactive.</Typography>
                <Typography variant='body2' paragraph>To remove a document, find the document in the list and click "Delete". Currently, you can only upload one document per scenario.</Typography>
                <Typography variant='body2' paragraph>To make users aware that a scenario has a document connected to it, consider adding an icon in the player interface by using the "Media" content. "Document" and "Steps plan" icons will, when clicked, open the Documents tab.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddPDFDocuments}>6.1 PDFs</Typography>
                <Typography variant='body2' paragraph>You can add a pdf document to your scenario which will be accessible to users in the "Documents" tab.</Typography>
                <Typography variant='body2' paragraph>To add a PDF:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>1)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Open the "Documents" window from the Editor toolbar.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>2)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Click "Upload Document" and select the document from your computer.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>3)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                    Click "Upload".
                    </Typography>
                </Stack>
                </Grid>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddInteractiveDocuments}>6.2 Interactive Documents</Typography>
                <Typography variant='body2' paragraph>You can create documents that users can interact with. Two types of interaction are supported: (1) redactions; where users must correctly redact parts of a document; and (2) mark-up; where users must correctly accept or reject document mark-up.</Typography>
                <Typography variant='body2' paragraph>To create an interactive document:</Typography>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>3)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                    Open the “Documents” window from the Editor toolbar.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <Typography variant='body2'>3)</Typography>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                    Click “Create Document”, select the relevant document type, give the document a name and click “Create”.
                    </Typography>
                </Stack>
                </Grid>
                <Typography variant='body2' paragraph>You will then see the document in the “Documents” tab of the player bar. Refresh the scenario if necessary and click “Edit Document” to create its content.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refRedactionDocuments}>6.2.1 Redaction Documents</Typography>
                    <Typography variant='body2' paragraph>Redaction documents allow a user to suggest redactions. The user’s suggestions are evaluated against reference redactions which are set in the editor.</Typography>
                    <Typography variant='body2' paragraph>To set a “correct” redaction, open the document for editing. Highlight the relevant text portion and click the “Redact” button in the formatting toolbar. The redacted content will be given a yellow highlight.</Typography>
                    <Typography variant='body2' paragraph>Save the changes and the document will then be available to play in the scenario.</Typography>
                    <Typography variant='body2' paragraph>When in “play” mode, the correct redactions will not be visible. When you highlight and redact text in play mode, it will be marked purple. The user’s redactions will be evaluated against the correct redactions at the end of the scenario. Redactions can also be referenced in rule blocks.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refMarkupDocuments}>6.2.2 Mark-up Documents</Typography>
                    <Typography variant='body2' paragraph>Mark-up documents allow a user to accept or reject marked-up text. The user’s suggestions are evaluated against reference answers which are set in the editor.</Typography>
                    <Typography variant='body2' paragraph>To set reference mark-up, open the document for editing. Highlight the relevant text block and click the “Accept” or “Reject” button in the formatting toolbar.</Typography>
                    <Typography variant='body2' paragraph>Save the changes and the document will then be available to play in the scenario.</Typography>
                    <Typography variant='body2' paragraph>When in “play” mode, users will be able to accept or reject the relevant text blocks.. The user’s choices will be evaluated against the correct answers at the end of the scenario. Mark-up decisions can also be referenced in rule blocks.</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddingOutlines}>7. Adding Outlines</Typography>
                <Typography variant='body2' paragraph>Outlines are interactive documents that set out the steps or components of a legal test. Users can refer to the Outline during a scenario and the presentation of the Outline can be controlled by rule blocks.</Typography>
                <Typography variant='body2' paragraph>To create a new Outline, contact support@legalreflex.com. To use an existing Outline, open the "Documents" window from the Editor toolbar, select the relevant Outline from the drop-down and click "Add Outline".</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refAddingPrimarySources}>8. Adding Primary Sources</Typography>
                <Typography variant='body2' paragraph>You can add primary sources to your scenario as a resource accessible in the Primary Sources tab.</Typography>
                <Typography variant='body2' paragraph>The Editor currently supports:</Typography>
                <Grid item sx={{marginLeft: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Standalone sections of legislation from legislation.gov.
                    </Typography>
                </Stack>
                </Grid>
                <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                <Stack direction="row" spacing={2}>
                    <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                        <Grid item>
                        <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                        </Grid>
                    </Grid>
                    <Typography variant='body2'>
                        Groups of sections (under cross headings) of legislation from <Link href="https://www.legislation.gov.uk/" target="_blank" rel="noopener" underline="hover">legislation.gov</Link>.
                    </Typography>
                </Stack>
                </Grid>
                <Typography variant='body2' paragraph>To add a primary source, open the Primary Sources window from the Editor toolbar. Copy and paste the URL of the source and click "Add primary source".</Typography>
                <Typography variant='body2' paragraph>Other sources can be added as pdfs in the Documents tab.</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refSavingandLoading}>9. Saving and Loading</Typography>
                <Typography variant='body2' paragraph>Generally, changes to your scenario (for example, changes to scenario blocks) will be saved automatically. You can also manually save your scenario by clicking "Save" in the Editor toolbar.</Typography>
                <Typography variant='body2' paragraph>The "Load scenario" window lists the scenarios you have created or otherwise have access to, together with published status (whether they are available to play in the platform) and their last edit date. From this list you can also copy or delete scenarios.</Typography>
                <Typography variant='body2' paragraph>When you have made changes to your scenario, click the {true ? <ReplayIcon fontSize="small" sx={{"transform":"translateY(3px)"}} /> : null} replay icon in the player bar to load those changes into the game.</Typography>
            <Typography variant='h5' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refSharingandPublishing}>10. Sharing and Publishing</Typography>
                <Typography variant='body2' paragraph>You can control who is able to play and / or edit your scenario from the "Sharing and Publishing" window.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refSharing}>10.1 Sharing</Typography>
                    <Typography variant='body2' paragraph>By default, ordinary users are not able to view or edit scenarios. In order to view and edit scenarios in the Editor, a user must be made an "Author". Contact support@legalreflex.com to request that a user be made an author.</Typography>
                    <Typography variant='body2' paragraph>When you create a scenario it will only appear in your list of scenarios. To make it available to other Authors:</Typography>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <Typography variant='body2'>1)</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Open the Share and Publish window from the Editor toolbar.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <Typography variant='body2'>2)</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            In the "Scenario Editing" drop-down, select "All authors in your organisation".
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <Typography variant='body2'>3)</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Click "Save Changes".
                        </Typography>
                    </Stack>
                    </Grid>
                    <Typography variant='body2' paragraph>Other Authors will then be able to open the scenario from the Load Scenario window. Note, other Authors will be able to view and edit the scenario by using a direct link (e.g. legalreflex/editor/abc-efg-123-456), regardless of its Sharing status.</Typography>
                <Typography variant='h6' sx={{marginBottom: "8px", marginTop: "12px"}} ref={refPublishing}>10.2 Publishing</Typography>
                    <Typography variant='body2' paragraph>To publish a scenario so that it is playable by users:</Typography>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <Typography variant='body2'>1)</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Open the Share and Publish window from the Editor toolbar.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <Typography variant='body2'>2)</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            In the "Publish" section, navigate the module drop-downs and select the module where the scenario should appear.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <Typography variant='body2'>3)</Typography>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            Click "Publish Latest".
                        </Typography>
                    </Stack>
                    </Grid>
                    <Typography variant='body2' paragraph>The Publish section also displays:</Typography>
                    <Grid item sx={{marginLeft: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            The current publish status of the scenario.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Grid item sx={{marginLeft: "8px", marginBottom: "8px"}}>
                    <Stack direction="row" spacing={2}>
                        <Grid container sx={{height: "100%", alignItems: "center", width: "10px"}}>
                            <Grid item>
                            <FiberManualRecordIcon sx={{fontSize: "12px", color: "#212121"}}/>
                            </Grid>
                        </Grid>
                        <Typography variant='body2'>
                            The module that the scenario is published to.
                        </Typography>
                    </Stack>
                    </Grid>
                    <Typography variant='body2' paragraph>Changes you make to a scenario in the editor are not automatically published to the platform. To republish the latest version of a scenario, first ensure your scenario is saved, then click "Publish Latest".</Typography>
                    <Typography variant='body2' paragraph>You can "Unpublish" a scenario by selecting "Unpublish" from the Publish section.</Typography>
        </Grid>
    )
};
export default AuthorGuide;