import React from "react";

function FiveHundredBadgeColour() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    version="1.1"
    viewBox="0 0 26.458 27.517"
  >
    <g fillOpacity="1" transform="translate(-88.707 -89.437)">
      <g stroke="none" strokeDasharray="none" strokeOpacity="1">
        <circle
          cx="101.936"
          cy="103.724"
          r="13.229"
          fill="#4d4d4d"
          strokeWidth="1.847"
        ></circle>
        <circle
          cx="101.936"
          cy="102.666"
          r="13.229"
          fill="#fbc02d"
          strokeWidth="1.847"
        ></circle>
        <circle
          cx="101.936"
          cy="102.666"
          r="11.906"
          fill="#ffeb3b"
          strokeWidth="1.662"
        ></circle>
        <path
          fill="#fdd835"
          strokeWidth="1.662"
          d="M109.857 111.555a11.906 11.906 0 01-11.659 2.415 11.906 11.906 0 01-7.92-8.89 11.906 11.906 0 013.738-11.304l7.92 8.89z"
        ></path>
      </g>
      <g stroke="none" transform="translate(-34.376 -8.99)">
        <g fill="#f57c00" transform="translate(77.106 -2.587)">
          <path
            strokeWidth="0.4"
            d="M-47.319 117.403H-37.80800000000001V120.239H-47.319z"
            transform="rotate(-45)"
          ></path>
          <path
            strokeWidth="0.346"
            d="M117.617 37.842H124.718V40.678H117.617z"
            transform="rotate(45)"
          ></path>
          <path
            strokeWidth="0.366"
            d="M-40.412 121.899H-32.467V124.735H-40.412z"
            transform="rotate(-45)"
          ></path>
          <path
            strokeWidth="0.4"
            d="M59.708 102.449l-5.567-.106 2.848-4.789z"
            transform="rotate(45 51.217 115.023)"
          ></path>
        </g>
        <g fill="#ff9800" transform="translate(76.577 -3.116)">
          <path
            strokeWidth="0.4"
            d="M-47.319 117.403H-37.80800000000001V120.239H-47.319z"
            transform="rotate(-45)"
          ></path>
          <path
            strokeWidth="0.346"
            d="M117.617 37.842H124.718V40.678H117.617z"
            transform="rotate(45)"
          ></path>
          <path
            strokeWidth="0.366"
            d="M-40.412 121.899H-32.467V124.735H-40.412z"
            transform="rotate(-45)"
          ></path>
          <path
            strokeWidth="0.4"
            d="M59.708 102.449l-5.567-.106 2.848-4.789z"
            transform="rotate(45 51.217 115.023)"
          ></path>
        </g>
      </g>
      <g transform="translate(-4.233 -13.23)">
        <g fill="#e65100" transform="translate(2.83 12.903)">
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "1.25" }}
            x="98.41"
            y="110.713"
            fill="#e65100"
            fillOpacity="1"
            stroke="none"
            strokeWidth="0.265"
            fontFamily="sans-serif"
            fontSize="4.939"
            fontStretch="normal"
            fontStyle="italic"
            fontVariant="normal"
            fontWeight="bold"
            letterSpacing="0"
            wordSpacing="0"
          >
            <tspan
              style={{}}
              x="98.41"
              y="110.713"
              fill="#e65100"
              fillOpacity="1"
              strokeWidth="0.265"
              fontFamily="sans-serif"
              fontSize="4.939"
              fontStretch="normal"
              fontStyle="italic"
              fontVariant="normal"
              fontWeight="bold"
            >
              500
            </tspan>
          </text>
        </g>
        <text
          xmlSpace="preserve"
          style={{ lineHeight: "1.25" }}
          x="101.24"
          y="123.087"
          fill="#f57c00"
          stroke="none"
          strokeWidth="0.265"
          fontFamily="sans-serif"
          fontSize="4.939"
          fontStretch="normal"
          fontStyle="italic"
          fontVariant="normal"
          fontWeight="bold"
          letterSpacing="0"
          wordSpacing="0"
        >
          <tspan
            style={{}}
            x="101.24"
            y="123.087"
            fill="#f57c00"
            fillOpacity="1"
            strokeWidth="0.265"
            fontFamily="sans-serif"
            fontSize="4.939"
            fontStretch="normal"
            fontStyle="italic"
            fontVariant="normal"
            fontWeight="bold"
          >
            500
          </tspan>
        </text>
      </g>
    </g>
  </svg>
  );
}

export default FiveHundredBadgeColour;
