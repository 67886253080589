import React, { useState } from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import ScenarioCard from './ScenarioCard';
import ScenarioDetails from './ScenarioDetails';

import { toggleScenarioDetails } from './appData/appDataSlice';

import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';

export default function ContinueLearning() {

    const theme = useTheme();
    const dispatch = useDispatch();
    const nextAchievement = useSelector((store) => store.appData.userData.nextAchievement);
    const userScenarios = useSelector((store) => store.appData.userData.userScenarios);
    const scenarioList = useSelector((state) => state.appData.scenarioList);
    const achievementsList = useSelector((state) => state.appData.achievementsList);
    const viewScenarioDetails = useSelector((state) => state.appData.appUI.scenarioDetails);

    const getContinueScenarioList = () => {

        // need to sort these? better to do in redux? think about ui for more than 1 etc.
        // got a bad error on playing for first time from /learn

        var continueScenarios = []

        // first see if have played to less than 100%
        for (const scenarioID of Object.keys(userScenarios)) {
            if (userScenarios[scenarioID]["score"] !== 100 && scenarioList.hasOwnProperty(scenarioID)) {
                // Scenarios that have been unpublished should not show in this list
                if (scenarioList[scenarioID]["status"] !== "unpublished") {
                    continueScenarios.push(scenarioID)
                }
            }
        };

        if (continueScenarios.length >= 2) {
            // get the last two (last played). should really sort on date.
            return (continueScenarios.slice(-2))
        } else {

            if (nextAchievement !== "") {
                
                if (achievementsList[nextAchievement]["achievementCriteria"].hasOwnProperty("scenarios")) {
                    // check if next achievement is a scenario completion achievement
                    for (const scenarioID of achievementsList[nextAchievement]["achievementCriteria"]["scenarios"]) {
                        
                        if (continueScenarios.includes(scenarioID)) {
                            // Don't double count scenarios
                            continue
                        } else {
                            if (userScenarios.hasOwnProperty(scenarioID) && userScenarios[scenarioID]["score"] === 100) {
                                // Don't count completed scenarios
                                continue
                            } else {
                                if (scenarioList.hasOwnProperty(scenarioID) && scenarioList[scenarioID]["status"] !== "unpublished") {
                                    // user played scenarios come first, and achievement scenarios listed in order
                                    continueScenarios.unshift(scenarioID)
                                }
                            }
                        }
                    }
                }
            };
            if (continueScenarios.length >= 2) {
                // get the last two (last played). should really sort on date.
                return (continueScenarios.slice(-2))
            }

            // fall back if empty (still assumes you have at least one published scenario...)

            if (continueScenarios.length === 0) {
                for (const scenarioID of Object.keys(scenarioList)) {
                    if (scenarioList[scenarioID]["status"] === "published") {
                        continueScenarios.push(scenarioID)
                        break;
                    }
                }
            }
            return (continueScenarios)
        };
    };

    const [currentScenario, setCurrentScenario] = useState(getContinueScenarioList()[0]);
    
    /*

    plumb topics properly
    the scenario card ids don't exist in scenario list. should use user list where available and if not pass ""; will then get resolved in scenario complete

    */

return (
    <>
    <Modal
    open={viewScenarioDetails}
    onClose={() => dispatch(toggleScenarioDetails())}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    closeAfterTransition
    >
    <ScenarioDetails 
        title={scenarioList[currentScenario]["scenarioName"]} 
        shortDescription={scenarioList[currentScenario]["scenarioShortDescription"]} 
        difficulty={scenarioList[currentScenario]["scenarioELOText"]} 
        playTime={scenarioList[currentScenario]["scenarioPlayTime"]} 
        longDescription={scenarioList[currentScenario]["scenarioLongDescription"]} 
        scenarioID={currentScenario}
    />          
    </Modal>
    <Grid item sx={{paddingLeft: "15px"}}>
        <Typography variant="h6">Continue Learning</Typography>
        <Typography variant="body1" color="textSecondary">Pick up where you left off.</Typography>
    </Grid>
    <Grid container spacing={2} sx={{
        width: "100%",
        justifyContent: "flex-start",
        [theme.breakpoints.down('md')]: {
            justifyContent: "center",
        },
        padding: "10px"
    }}>
        {getContinueScenarioList().map((scenarioID, idx) => (
            <Grid key={"g"+idx} item>
            <ScenarioCard 
            scenarioID={scenarioID} 
            setCurrentScenario={setCurrentScenario} 
            subTopicID={userScenarios.hasOwnProperty(scenarioID) ? userScenarios[scenarioID]["subTopicID"]  : ""} 
            topicID={userScenarios.hasOwnProperty(scenarioID) ? userScenarios[scenarioID]["topicID"] : ""} 
            practiceAreaID={userScenarios.hasOwnProperty(scenarioID) ? userScenarios[scenarioID]["practiceAreaID"] : ""} 
            key={idx} 
            />
            </Grid>
        ))}        
    </Grid>
    </>
);
}