import React from 'react';

import {Outlet} from "react-router-dom";

import { Grid } from '@mui/material';
import { indigo, pink } from "@mui/material/colors";

import { ThemeProvider, StyledEngineProvider, createTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto';

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: pink,
    indigo: {
      main: indigo[500],
      dark: indigo[600],
    }
  },
});

function App() {

  const fullWidth = useMediaQuery(theme.breakpoints.up('sm'))
  const fullHeight = useMediaQuery('(min-height:610px)')
  const useVHHeight = fullWidth && fullHeight

  return (
    <React.Fragment>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={createTheme(theme, {
        components: {
          MuiButton: {
            variants: [
              {
                props: { variant: "contained", color: "indigo" },
                style: {
                  color: "#fff"
                }
              },
              {
                props: { variant: "outlined", color: "indigo" },
                style: {
                  color: theme.palette.text.primary,
                  borderColor:
                    theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.23)"
                      : "rgba(255, 255, 255, 0.23)",
                  "&.Mui-disabled": {
                    border: `1px solid ${theme.palette.action.disabledBackground}`
                  },
                  "&:hover": {
                    borderColor:
                      theme.palette.mode === "light"
                        ? "rgba(0, 0, 0, 0.23)"
                        : "rgba(255, 255, 255, 0.23)",
                    backgroundColor: alpha(
                      theme.palette.text.primary,
                      theme.palette.action.hoverOpacity
                    )
                  }
                }
              },
              {
                props: { color: "indigo", variant: "text" },
                style: {
                  color: theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: alpha(
                      theme.palette.text.primary,
                      theme.palette.action.hoverOpacity
                    )
                  }
                }
              }
            ]
          }
        }
      })
      }>
      <Grid container style={{ 
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between",
            minHeight: useVHHeight ? "100vh" : "100dvh",
          }}>
      <Outlet />
      </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
    </React.Fragment>
  );
}

export default App;

/*

*/